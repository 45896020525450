// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-OffsetAnchor-style__root__Egr9f{position:absolute}.components-OffsetAnchor-style__anchor__ITCcw{position:absolute;top:-130px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-OffsetAnchor-style__root__Egr9f`,
	"anchor": `components-OffsetAnchor-style__anchor__ITCcw`
};
export default ___CSS_LOADER_EXPORT___;
