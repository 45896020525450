import classNames from "classnames";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";

import handleGqlError from "data/graphqlErrors";

import Head from "components/Head";
import Main from "components/Main";
import LogInButton from "components/LogInButton";
import Help from "components/Help";

import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { animateScroll as scroll } from "react-scroll";

import StackViewNavigator from "components/StackViewNavigator";

import ViewAd from "./elements/ViewAd";
import ViewOffer from "./elements/ViewOffer";
import ViewShare from "./elements/ViewShare";
import ViewUpload from "./elements/ViewUpload";
import ViewSparkShare from "./elements/ViewSparkShare";

import styles from "./style.scss";

import { refetchHandles } from "~/auth";
import HeaderForAuthorizedUsers from "../../components/HeaderForAuthorizedUsers";
import PageAudioPlayer from "../../components/PageAudioPlayer";

const mapStateToProps = function (state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
  };
};

const influencerSteps = [
  {
    content:
      "From your phone or laptop, add your opening Spark video asking fans for ideas or suggestions for your video collaboration.",
    observe: '[data-tut="influencerPost__step1--observe"]',
    selector: '[data-tut="influencerPost__step1"]',
  },
  {
    content:
      "Camapign-specific hashtags will autopopulate in your Spark. To add your own hashtags, type in and press <enter>.",
    selector: '[data-tut="influencerPost__step2"]',
  },
  {
    content:
      "Choose a location (general or specific) for your Spark finale. e.g. livingroom, park, restaurant, airport, Vancouver, Los Angeles ... ",
    selector: '[data-tut="influencerPost__step3"]',
  },
  {
    content:
      "Choose a length of time you will allow fans to make suggestions and vote. 1 week (1w) is recommended for first Sparks to give you lots of time to share over social and ramp-up voting.",
    selector: '[data-tut="influencerPost__step4"]',
  },
  {
    content:
      "Add a personal note, more information or added incenitve/giveaways for fans here.",
    observe: '[data-tut="influencerPost__step5--observe"]',
    position: "top",
    selector: '[data-tut="influencerPost__step5"]',
  },
];

class SparkPost extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool, // Auth reducer
    className: PropTypes.string,
    history: PropTypes.object.isRequired, // From router
    mutate: PropTypes.func.isRequired,
    user: PropTypes.object, // Auth reducer
  };

  static defaultProps = {
    className: "",
  };

  constructor() {
    super();

    this.state = {
      chosenPosterUrl: null,
      closesAt: null,
      errors: null,
      isTourOpen: false,
      locationName: "",
      locationPlaceId: "",
      mediaHashid: "",
      mediaType: "",
      message: "",
      offer: {},
      skipAdMessage: false,
      tags: [],
      thumbnailIndex: 0,
      viewIndex: 0,
      selectAddName: "",

      isPlayerOpen: false,
      isTrackPlaying: false,
      trackValues: {
        id: "",
        trackName: "",
        groupName: "",
        trackImg: "",
        track: "",
      },
    };
  }

  componentDidMount() {
    this.nullTrackValues();
  }
  componentWillUnmount() {
    this.StopPlaying();
  }

  nullTrackValues = () => {
    const nullTrackValues = {
      id: "",
      trackName: "",
      groupName: "",
      trackImg: "",
      track: "",
    };
    this.setState({
      trackValues: nullTrackValues,
      isTrackPlaying: false,
    });
  };

  handlePlayTrack = (getedValues) => {
    if (this.state.isTrackPlaying) {
      this.nullTrackValues();
    }
    const newTrackValues = getedValues;
    if (this.state.trackValues.id === newTrackValues.id) {
      this.setState({
        isTrackPlaying: true,
        isPlayerOpen: true,
      });
    } else {
      this.setState({
        trackValues: newTrackValues,
        isTrackPlaying: true,
        isPlayerOpen: true,
      });
    }
  };

  handleChangePlaying = (isCommentCard = false) => {
    if (isCommentCard) {
      if (this.state.isTrackPlaying) {
        this.setState({
          isPlayerOpen: false,
        });
      } else {
        this.setState({
          isPlayerOpen: true,
        });
      }
      this.setState({
        isTrackPlaying: !this.state.isTrackPlaying,
      });
    } else {
      this.setState({
        isTrackPlaying: !this.state.isTrackPlaying,
      });
    }
  };

  StopPlaying = () => {
    this.nullTrackValues();
  };

  handleChangePlayerVisibility = () => {
    this.setState({
      isPlayerOpen: !this.state.isPlayerOpen,
      isTrackPlaying: false,
    });
  };

  handleTrackPause = () => {
    this.setState({
      isPlayerOpen: false,
      isTrackPlaying: false,
    });
  };

  handleSelectAddName = (name) => {
    this.setState({
      selectAddName: name,
    });
  };

  handleNavigate = (delta) => {
    this.setState((prevState) => {
      return {
        viewIndex: prevState.viewIndex + delta,
      };
    });
  };

  handleSelectOffer = (offer) => {
    const tags = offer
      ? offer.tags.map((v) => {
          const h = {
            __id: v,
            value: v,
          };
          return h;
        })
      : null;

    this.setState(
      {
        offer: offer,
        skipAdMessage: !offer,
        tags: tags,
      },
      () => {
        this.handleNavigate(1);
      }
    );
  };

  handleSubmitVideoPost = (data) => {
    this.setState(
      {
        closesAt: data.closesAt,
        locationName: data.locationName,
        locationPlaceId: data.locationPlaceId,
        mediaHashid: data.mediaHashid,
        mediaType: data.mediaType,
        message: data.message,
        tags: data.tags,
      },
      () => {
        this.handleNavigate(this.state.skipAdMessage ? 2 : 1);
      }
    );
  };

  handleSubmitAdMessage = (data) => {
    this.setState(
      {
        adMessage: data.adMessage,
      },
      () => {
        this.handleNavigate(1);
      }
    );
  };

  handleThumbnailIndexChange = (index, url) => {
    this.setState({
      chosenPosterUrl: url,
      thumbnailIndex: index,
    });
  };

  handleSubmitPost = (shareOn) => {
    const tags = [];
    for (const tag in this.state.tags) {
      tags.push(this.state.tags[tag].value);
    }

    this.setState(
      {
        shareOn: shareOn,
      },
      () => {
        const mutationVars = {
          adMessage: this.state.adMessage,
          closesAt: this.state.closesAt,
          locationName: this.state.locationName,
          locationPlaceId: this.state.locationPlaceId,
          mediaHashid: this.state.mediaHashid,
          message: this.state.message,
          offerHashid: this.state.offer && this.state.offer.hashid,
          shareOn: this.state.shareOn,
          tags: tags,
        };
        if (this.state.mediaType === "video") {
          mutationVars.video_thumbnail_index = this.state.thumbnailIndex;
        }
        this.props
          .mutate({
            variables: mutationVars,
          })
          .then((response) => {
            if (refetchHandles.sparkCardsHandle) {
              refetchHandles.sparkCardsHandle.refetch();
            }
            this.props.history.push(`/spark/${response.data.postSpark.hashid}`);
          })
          .catch((error) => {
            const e = handleGqlError(error, [
              "closes_at",
              "location_name",
              "location_place_id",
              "message",
              "tags",
            ]);
            this.setState({
              errors: e,
            });
          })
          .finally(() => {
            window.location.reload();
          });
      }
    );
  };

  getSteps = (user) => {
    if (user && user.is_influencer) {
      return influencerSteps;
    }
    return [];
  };

  helpClick = () => {
    this.setIsTourOpen(true);
  };

  setIsTourOpen = (open) => {
    this.setState({ isTourOpen: open });
  };

  disableBody = (target) => disableBodyScroll(target);
  enableBody = (target) => enableBodyScroll(target);

  tourClose = () => {
    this.setIsTourOpen(false);
    scroll.scrollToTop();
  };

  render() {
    const { className, user } = this.props;

    const headerProps = [
      {
        leftContent: {
          content: [{ type: "link", icon: "homeFilled", link: "/" }],
        },
        heading: "Select Campaign",
        rightContent: {
          content: {
            type: "button",
            buttonText: "Skip",
            onClick: () => {
              this.handleSelectOffer(null);
            },
          },
          classNames: styles.rightBlockWrap,
        },
      },
      {
        leftContent: {
          content: [{ type: "link", icon: "homeFilled", link: "/" }],
        },
        heading: "Start Spark",
        rightContent: { content: { type: "avatar" } },
      },
      {
        leftContent: {
          content: [{ type: "link", icon: "homeFilled", link: "/" }],
        },
        heading: "Start Spark",
      },
      {
        leftContent: {
          content: [{ type: "link", icon: "homeFilled", link: "/" }],
        },
        heading: "Start Spark",
      },
      {
        leftContent: {
          content: [{ type: "link", icon: "homeFilled", link: "/" }],
        },
        heading: "Start Spark",
      },
    ];

    const headProps = {
      back: "/",
      heading: "Post",
      minimal: true,
    };

    if (!this.props.authenticated) {
      return (
        <div>
          <Head {...headProps} />
          <Main full>
            <LogInButton />
          </Main>
        </div>
      );
    }

    if (this.props.user && !this.props.user.is_influencer) {
      return (
        <div>
          <Head {...headProps} />
          <Main full>
            <p>Need to be an influencer</p>
          </Main>
        </div>
      );
    }

    return (
      <div>
        {/* <Head minimal back /> */}
        <HeaderForAuthorizedUsers {...headerProps[this.state.viewIndex]} />

        <Main>
          {this.state.viewIndex === 1 && (
            <Help
              message="Need help on how to create a Spark?"
              name="helpInfluencerPoste"
              onClick={this.helpClick}
              visible={true}
            />
          )}
          <div
            className={classNames(
              styles.root,
              className,
              "wrap",
              "bg-off-white"
            )}
          >
            <div
              className={classNames(
                "wrap__inner",
                "wrap__inner--skinny",
                "wrap__gutter",
                "wrap__gutter--padded"
              )}
            >
              <StackViewNavigator indexSelected={this.state.viewIndex}>
                <ViewOffer
                  onNavigate={this.handleNavigate}
                  onSelectOffer={this.handleSelectOffer}
                  handleSelectAddName={this.handleSelectAddName}
                />
                <ViewUpload
                  onNavigate={this.handleNavigate}
                  onSubmit={this.handleSubmitVideoPost}
                  tags={this.state.tags}
                />
                <ViewAd
                  onNavigate={this.handleNavigate}
                  onSubmit={this.handleSubmitAdMessage}
                  offer={this.state.offer}
                  tags={this.state.tags}
                  selectAddName={this.state.selectAddName}
                  locationName={this.state.locationName}
                />
                <ViewShare
                  adMessage={this.state.adMessage}
                  chosenPosterUrl={this.state.chosenPosterUrl}
                  locationName={this.state.locationName}
                  locationPlaceId={this.state.locationPlaceId}
                  mediaHashid={this.state.mediaHashid}
                  mediaType={this.state.mediaType}
                  message={this.state.message}
                  closesAt={this.state.closesAt}
                  onNavigate={this.handleNavigate}
                  onSubmit={this.handleSubmitPost}
                  onThumbnailIndexChange={this.handleThumbnailIndexChange}
                  previousSteps={this.state.skipAdMessage ? 2 : 1}
                  tags={this.state.tags}
                  thumbnailIndex={this.state.thumbnailIndex}
                  handleChangePlaying={this.handleChangePlaying}
                  handlePlayTrack={this.handlePlayTrack}
                  isTrackPlaying={this.state.isTrackPlaying}
                  trackValues={this.state.trackValues}
                  handleTrackPause={this.handleTrackPause}
                />
                <ViewSparkShare
                  adMessage={this.state.adMessage}
                  chosenPosterUrl={this.state.chosenPosterUrl}
                  locationName={this.state.locationName}
                  locationPlaceId={this.state.locationPlaceId}
                  mediaHashid={this.state.mediaHashid}
                  mediaType={this.state.mediaType}
                  message={this.state.message}
                  closesAt={this.state.closesAt}
                  onNavigate={this.handleNavigate}
                  onSubmit={this.handleSubmitPost}
                  onThumbnailIndexChange={this.handleThumbnailIndexChange}
                  tags={this.state.tags}
                  thumbnailIndex={this.state.thumbnailIndex}
                  username={user.handle}
                />
              </StackViewNavigator>
            </div>
          </div>
          <div
            className={classNames({
              [styles.pageAudioPlayerNone]: !this.state.isPlayerOpen,
              [styles.pageAudioPlayerBlock]: this.state.isPlayerOpen,
            })}
          >
            <PageAudioPlayer
              isPlaying={this.state.isTrackPlaying}
              setIsPlaying={this.handleChangePlaying}
              setIsOpen={this.handleChangePlayerVisibility}
              trackName={this.state.trackValues.trackName}
              groupName={this.state.trackValues.groupName}
              trackImg={this.state.trackValues.trackImg}
              track={this.state.trackValues.track}
            />
          </div>
        </Main>
        <div>
          <Tour
            steps={this.getSteps(user)}
            inViewThreshold={50}
            isOpen={this.state.isTourOpen}
            onRequestClose={this.tourClose}
            onAfterOpen={this.disableBody}
            onBeforeClose={this.enableBody}
            startAt={0}
          />
        </div>
      </div>
    );
  }
}

const SparkPostWithRouter = withRouter(SparkPost);

const POST_MUTATION = gql`
  mutation PostedSpark(
    $adMessage: String
    $closesAt: Timestamp!
    $mediaHashid: ID!
    $message: String!
    $locationName: String
    $locationPlaceId: ID
    $offerHashid: ID
    $shareOn: [SocialNetworkEnum!]
    $tags: [String!]
    $videoThumbnailIndex: Int
  ) {
    postSpark(
      ad_message: $adMessage
      closes_at: $closesAt
      location_name: $locationName
      location_place_id: $locationPlaceId
      media_hashid: $mediaHashid
      message: $message
      offer_hashid: $offerHashid
      share_on: $shareOn
      tags: $tags
      video_thumbnail_index: $videoThumbnailIndex
    ) {
      hashid
    }
  }
`;

export default compose(
  connect(mapStateToProps),
  graphql(POST_MUTATION)
)(SparkPostWithRouter);
