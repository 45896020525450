import React, { Fragment } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import gql from "graphql-tag";
import { withRouter } from "react-router";

import CountryDropdown from "components/CountryDropdown";
import RegionDropdown from "components/RegionDropdown";

import BodyColour from "components/BodyColour";
import Main from "components/Main";

import Avatar from "components/Avatar";
import Checkbox from "components/Checkbox";
import FormError from "components/FormError";
import UploadButton from "components/UploadButton";
import Icon from "components/Icon";

import TextArea from "components/TextArea";
import HandleInput from "components/HandleInput";
import Loading from "sections/Loading";
import handleGqlError from "data/graphqlErrors";
import * as authActions from "actions/auth";
import store from "../../store";
import HeaderForAuthorizedUsers from "../../components/HeaderForAuthorizedUsers";
import HelpIcon from "../../components/HelpIcon";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import PasswordInput from "../../components/PasswordInput";
import CustomDropDown from "../../components/CustomDropDown";

import image1 from "./image1.png";
import styles from "./style.scss";
import "react-phone-number-input/style.css";

import ErrorMessage from "components/ErrorMessage";

const mapStateToProps = function (state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
  };
};

class EditProfile extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool, // Auth reducer
    data: PropTypes.shape({
      categories: PropTypes.object,
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
      me: PropTypes.object,
      // refetch: PropTypes.func,
    }), // From GraphQL query
    history: PropTypes.object.isRequired, // From router
    mutate: PropTypes.func.isRequired, // From GraphQL mutation
    user: PropTypes.object, // Auth reducer
  };

  constructor(props) {
    super(props);

    this.form = null;

    this.state = {
      avatarUpload: null,
      country: null,
      doneLocked: false,
      errors: null,
      phone: null,
      region: null,
      isLocationDropdownMenuOpen: false,
      isPhoneNumbersDropdownMenuOpen: false,
      category_primary: "",
      category_secondary: "",
      category_tertiary: "",
      editError: null,
    };
  }

  handleSetPhone = (value) => {
    this.setState({ phone: value });
  };

  handleShowLocationDropdownMenu = () => {
    this.setState({
      isLocationDropdownMenuOpen: !this.state.isLocationDropdownMenuOpen,
    });
  };

  handlePhoneNumbersDropdownMenu = () => {
    this.setState({
      isPhoneNumbersDropdownMenuOpen:
        !this.state.isPhoneNumbersDropdownMenuOpen,
    });
  };

  UNSAFE_componentWillMount() {
    this.setState({
      editError: null,
    });
  }
  componentDidMount() {
    this.initializeState();
    this.props.data.refetch();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.data === this.props.data) {
      return;
    }
    this.initializeState();
  };

  onNavigate = (delta) => {
    if (delta < 0) {
      this.onBackClick();
    }
  };

  initializeState() {
    const { data } = this.props;

    if (data && data.me && data.me.influencer) {
      const influencer = data.me.influencer;
      this.setState({
        // avatarUpload:
        region: influencer.region || null,
        country: influencer.country || null,
        phone: data.me.phone,
        category_primary: influencer.category_primary || "",
        category_secondary: influencer.category_secondary || "",
        category_tertiary: influencer.category_tertiary || "",
      });
    }
  }

  checkValidity = () => {
    this.setState({
      doneLocked: !this.form.checkValidity(),
    });
  };

  onFormInput = () => {
    this.checkValidity();
  };

  onSubmit = (event) => {
    event.preventDefault();

    let variables = {};

    // Retrieve values
    const formData = new FormData(this.form);

    if (formData.has("perSparkMin")) {
      const perSparkMin = this.form.elements.perSparkMin.value;
      variables.perSparkMin = perSparkMin;
    }
    if (formData.get("avatarHashid")) {
      variables.avatarHashid = formData.get("avatarHashid");
    }

    // User
    for (const field of ["bio", "handle"]) {
      // ,"phone"
      if (formData.get(field) !== this.props.data.me[field]) {
        variables[field] = formData.get(field);
      }
    }

    // email_notification_checkboxes
    if (formData.get("notification_email_enabled") === "on") {
      variables.notification_email_enabled = true;
    } else if (formData.get("notification_email_enabled") === null) {
      variables.notification_email_enabled = false;
      variables.notification_email_closedspark_enabled = false;
      variables.notification_email_newspark_enabled = false;
      variables.notification_email_votedcomment_enabled = false;
    }

    // User checkbox
    // for (const field of [
    //   "notification_email_closedspark_enabled",
    //   "notification_email_enabled",
    //   "notification_email_newspark_enabled",
    //   "notification_email_votedcomment_enabled",
    // ]) {
    //   if ((formData.get(field) === "on") !== this.props.data.me[field]) {
    //     variables[field] = formData.get(field) === "true";
    //   }
    // }

    // Influencer
    if (this.props.data.me.influencer) {
      for (const field of [
        "category_primary",
        "category_secondary",
        "category_tertiary",
        "country",
        "paypal",
        "region",
      ]) {
        if (formData.get(field) !== this.props.data.me.influencer[field]) {
          variables[field] = formData.get(field);
        }
      }

      // Influencer checkbox
      // if (
      //   (formData.get("notification_email_sparkreminder_enabled") ===
      //     "true") !==
      //   this.props.data.me.influencer.notification_email_sparkreminder_enabled
      // ) {
      //   variables.notification_email_sparkreminder_enabled =
      //     formData.get("notification_email_sparkreminder_enabled") === "true";
      // }

      // Country / Region
      variables.country = this.state.country;
      variables.region = this.state.region;
      variables.category_primary = this.state.category_primary;
      variables.category_secondary = this.state.category_secondary;
      variables.category_tertiary = this.state.category_tertiary;
    }
    variables.phone = this.state.phone === null ? null : this.state.phone;
    // Promise for us to be finished
    let finished = null;

    // If nothing is to be sent, nothing needs to be done
    if (Object.keys(variables).length === 0) {
      finished = Promise.resolve();
    } else {
      finished = this.props
        .mutate({
          variables: variables,
        })
        .then(() => {
          this.setState({
            errors: null,
          });

          // Update stored user information if necessary
          if (variables.handle != null) {
            store.dispatch(
              authActions.update(
                Object.assign(this.props.user, {
                  handle: variables.handle,
                })
              )
            );
          }
        })
        .catch((error) => {
          const e = handleGqlError(error, [
            "bio",
            "handle",
            "category_primary",
            "category_secondary",
            "category_tertiary",
            "country",
            "notification_email_closedspark_enabled",
            "notification_email_enabled",
            "notification_email_newspark_enabled",
            "notification_email_sparkreminder_enabled",
            "notification_email_votedcomment_enabled",
            "paypal",
            "phone",
            "region",
          ]);
          this.setState({
            errors: e,
            editError: error,
          });
        });
    }

    // Go back to the profile page
    finished.then(() => {
      if (!this.state.errors) {
        this.goToProfile();
        // window.location.reload();
        // this.props.data.refetch();
      }
    });
  };

  onBackClick = () => {
    this.goToProfile();
  };

  goToProfile = () => {
    this.props.history.push(`/profile/${this.props.data.me.handle}`);
  };

  onAvatarUploadComplete = (media) => {
    this.setState({
      avatarUpload: media,
    });
    this.uploadButton.reset();
  };

  onSelectCountry = (val) => {
    this.setState({
      country: val,
      region: "",
    });
  };

  onSelectRegion = (val) => {
    this.setState({ region: val[0] });
  };

  ConnectedWallets = () => (
    <div className={styles.connectedWalletsWrapper}>
      <h2 className={styles.subtitle}>Connected Wallets/Authentication</h2>
      <div className={styles.connectedWalletsContainer}>
        <img src={image1} />
        {/* <img src={image2} />
        <img src={image3} /> */}
      </div>
      <div className={styles.changeBtnContainer}>
        <button>Change</button>
      </div>
    </div>
  );

  renderNotifications = () => {
    const { data, user } = this.props;

    return (
      <div className={styles.notificationsWrapper}>
        <div
          className={classNames(
            styles.notificationsHeader,
            styles.headerWithQuestionIcon
          )}
        >
          <h2 className={classNames(styles.subtitle)}>Email Notifications</h2>
          <HelpIcon helperText="Enable notifications to stay current with Spark engagement." />
        </div>
        <ul
          className={classNames(
            "list",
            "list--unstyle",
            "list--border",
            styles.list
          )}
        >
          <li>
            <label>
              <span className={classNames(styles.labelText)}>
                Allow email notifications.
              </span>

              <Checkbox
                name="notification_email_enabled"
                defaultChecked={data.me.notification_email_enabled}
              />
            </label>
          </li>
        </ul>
        {data.me.notification_email_enabled && (
          <ul
            className={classNames(
              "list",
              "list--unstyle",
              "list--border",
              styles.list
            )}
          >
            <li>
              <label>
                <span className={classNames(styles.labelText)}>
                  When someone you follow posts a new Spark.
                </span>
                <Checkbox
                  // isProfileEdit={true}
                  name="notification_email_newspark_enabled"
                  defaultChecked={data.me.notification_email_newspark_enabled}
                  // value={true}
                />
              </label>
            </li>
            <li>
              <label>
                <span className={classNames(styles.labelText)}>
                  When someone you follow posts a Final Spark.
                </span>
                <Checkbox
                  isProfileEdit={true}
                  name="notification_email_closedspark_enabled"
                  defaultChecked={
                    data.me.notification_email_closedspark_enabled
                  }
                  // value={true}
                />
              </label>
            </li>
            <li>
              <label>
                <span className={classNames(styles.labelText)}>
                  When someone votes for your Spark Suggestion.
                </span>
                <Checkbox
                  isProfileEdit={true}
                  name="notification_email_votedcomment_enabled"
                  defaultChecked={
                    data.me.notification_email_votedcomment_enabled
                  }
                  // value={true}
                />
              </label>
            </li>
          </ul>
        )}
        {user.is_influencer && data.me.notification_email_enabled && (
          <ul
            className={classNames(
              "list",
              "list--unstyle",
              styles.list,
              styles.lastItem
            )}
          >
            <li>
              <label>
                <span className={classNames(styles.labelText)}>
                  Allow email reminders when your Final Spark is almost due.
                </span>
                <Checkbox
                  isProfileEdit={true}
                  name="notification_email_sparkreminder_enabled"
                  defaultChecked={
                    data.me.influencer.notification_email_sparkreminder_enabled
                  }
                  // value={true}
                />
              </label>
            </li>
          </ul>
        )}
      </div>
    );
  };

  RenderChooseCategories = () => {
    const data = this.props.data;
    const { errors } = this.state;

    return (
      <div className={styles.chooseCategoriesWrap}>
        {errors && errors.fields && errors.fields.category_primary && (
          <FormError errors={errors.fields.category_primary} />
        )}
        {errors && errors.fields && errors.fields.category_secondary && (
          <FormError errors={errors.fields.category_secondary} />
        )}
        {errors && errors.fields && errors.fields.category_tertiary && (
          <FormError errors={errors.fields.category_tertiary} />
        )}

        <div className={styles.chooseCategoriesHead}>
          <span>Choose your talent categories</span>
        </div>
        <div className={styles.categoriesInputsContainer}>
          {data.categories.primary && (
            <div>
              <div className={styles.InputHeader}>
                <span>
                  First Category<p className={styles.requiredMark}>*</p>
                </span>
              </div>
              <CustomDropDown
                onChange={(value) => {
                  this.setState({
                    category_primary: value,
                  });
                }}
                selectedValue={this.state.category_primary}
                items={data.categories.primary}
              />
              {/* <CategoriesInput name='category_primary' selectedValue={data.me.influencer.category_primary} data={data.categories.primary} /> */}
            </div>
          )}
          {data.categories.secondary && (
            <Fragment>
              <div>
                <div className={styles.InputHeader}>
                  <span>
                    Second Category<p className={styles.requiredMark}>*</p>
                  </span>
                </div>
                <CustomDropDown
                  onChange={(value) => {
                    this.setState({
                      category_secondary: value,
                    });
                  }}
                  selectedValue={this.state.category_secondary}
                  items={data.categories.secondary}
                />
                {/* <CategoriesInput name='category_secondary' selectedValue={data.me.influencer.category_secondary} data={data.categories.secondary} /> */}
              </div>
              <div>
                <div className={styles.InputHeader}>
                  <span>
                    Third Category<p className={styles.requiredMark}>*</p>
                  </span>
                </div>
                <CustomDropDown
                  onChange={(value) => {
                    this.setState({
                      category_tertiary: value,
                    });
                  }}
                  selectedValue={this.state.category_tertiary}
                  items={data.categories.secondary}
                />
                {/* <CategoriesInput name='category_tertiary' selectedValue={data.me.influencer.category_tertiary} data={data.categories.secondary} /> */}
              </div>
            </Fragment>
          )}
          <div>
            <div
              className={classNames(
                styles.InputHeader,
                styles.headerWithQuestionIcon
              )}
            >
              <span>Per Spark Min If Required</span>
              <HelpIcon helperText="If you require a per Spark minimum payment, indicate it here. If not, we will tabulate your engagement data to determine what offers will be sent to you. Engagement data = votes, suggestions, clicks, CPMs and conversions." />
            </div>
            <div className={classNames(styles.inputContainer)}>
              <input
                type="number"
                name="perSparkMin"
                className={classNames(styles.input)}
                // DefaultValue={data.me.influencer.perSparkMin}
              />
            </div>
          </div>
          <div>
            <div
              className={classNames(
                styles.InputHeader,
                styles.headerWithQuestionIcon
              )}
            >
              <span>Paypal Email</span>
              <HelpIcon helperText="If valid crypto wallet and bank info is shared, PayPal is required for payments." />
            </div>
            <div className={classNames(styles.inputContainer)}>
              <input
                className={classNames(styles.input)}
                name="paypal"
                type="email"
                defaultValue={data.me.influencer.paypal}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderOldLocation = () => {
    const { country, region } = this.state;

    return (
      <div>
        <div
          className={classNames(
            styles.InputHeader,
            styles.headerWithQuestionIcon
          )}
        >
          <span>Country</span>
          <HelpIcon helperText="Be specific about your location so we can send appropriate offers and info  to you about your favorite Creators." />
        </div>
        <label className={styles.labelBLock}>
          <CountryDropdown
            value={country}
            onChange={(val) => this.onSelectCountry(val)}
          />
        </label>
        {country && (
          <div>
            <div
              className={classNames(
                styles.InputHeader,
                styles.headerWithQuestionIcon
              )}
            >
              <span>Region</span>
            </div>
            <label className={styles.labelBLock}>
              <RegionDropdown
                value={region}
                country={country}
                onChange={(val) => this.onSelectRegion(val)}
              />
            </label>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { authenticated, data, user } = this.props;

    const { errors } = this.state;

    const headProps = {
      leftContent: {
        content: [
          { type: "link", icon: "homeFilled", link: "/" },
        ],
      },
      heading: "Profile",
      rightContent: { content: [{ type: "link", icon: "leave", link: "/" }] },
    };

    if (authenticated === null || !data.me) {
      return <Loading headProps={headProps} />;
    }

    // HeadProps.back = `/profile/${data.me.handle}`;

    const avatarUser = this.state.avatarUpload
      ? {
          avatar: this.state.avatarUpload,
          handle: user.handle,
          hashid: user.hashid,
        }
      : user;

    return (
      <div>
        <BodyColour offWhite />
        <HeaderForAuthorizedUsers {...headProps} />
        <Main>
          <div className={classNames(styles.root)}>
            <div className="wrap">
              <div
                className={classNames(
                  "wrap__inner",
                  "wrap__inner--skinny",
                  "wrap__gutter",
                  "wrap__gutter--padded"
                )}
              >
                <div className={styles.avatarArea}>
                  <div className={styles.avatarBlock}>
                    <Avatar
                      size="lg"
                      user={avatarUser}
                      className={styles.avatar}
                      link={false}
                      shadow
                    />
                    <div className={styles.uploadImageButtonContainer}>
                      <UploadButton
                        accept={[
                          "image/png",
                          ".png",
                          "image/jpeg",
                          ".jpg",
                          ".jpeg",
                          "image/webp",
                          ".webp",
                        ].join(",")}
                        buttonSize="sm"
                        height="auto"
                        onComplete={this.onAvatarUploadComplete}
                        plain
                        ref={(ref) => (this.uploadButton = ref)}
                      >
                        <div className={styles.uploadImageButton}>
                          <Icon icon="camera" className={styles.icon} />
                        </div>
                      </UploadButton>
                    </div>
                  </div>
                </div>

                <form
                  ref={(ref) => (this.form = ref)}
                  onInput={this.onFormInput}
                  onSubmit={this.onSubmit}
                  className={styles.form}
                >
                  {this.state.avatarUpload && (
                    <input
                      type="hidden"
                      name="avatarHashid"
                      value={this.state.avatarUpload.hashid}
                    />
                  )}

                  <div>
                    {errors && errors.fields && errors.fields.handle && (
                      <FormError errors={errors.fields.handle} />
                    )}
                    <div>
                      <div
                        className={classNames(
                          styles.headerWithQuestionIcon,
                          styles.InputHeader
                        )}
                      >
                        <span>Handle</span>
                        <HelpIcon helperText="Choose a handle similar to your other social channels so you are easily recognizable." />
                      </div>
                      <HandleInput
                        defaultValue={data.me.handle}
                        name="handle"
                        onInvalid={this.checkValidity}
                        onValid={this.checkValidity}
                        whitelistedHandle={data.me.handle}
                        className={styles.HandleInput}
                      />
                    </div>
                  </div>

                  {/* Need to add logic for a password input   \/ */}
                  <PasswordInput
                    type="password"
                    name="password"
                    placeholder="Password"
                    helperText=""
                    required={false}
                    className={classNames(styles.input, styles.passwordInput)}
                  />
                  <div>
                    {errors && errors.fields && errors.fields.bio && (
                      <FormError errors={errors.fields.bio} />
                    )}
                    <div
                      className={classNames(
                        styles.headerWithQuestionIcon,
                        styles.InputHeader
                      )}
                    >
                      <span>Bio Statement</span>
                      <HelpIcon helperText="A few words about who you are and what you do!" />
                    </div>
                    <TextArea
                      name="bio"
                      defaultValue={data.me.bio}
                      isEditTextArea={true}
                    />
                  </div>

                  {/* {!user.is_influencer && this.renderOldLocation()} */}
                  <PhoneNumberInput
                    type="text"
                    className={styles.phoneNumberInput}
                    placeholder="Phone number"
                    name="phone"
                    maxLength="12"
                    isCountryFlagShowed
                    editProfile
                    value={data.me.phone ? data.me.phone : null}
                    setPhone={this.handleSetPhone}
                    helperText={
                      "This is required for SMS notification of new contests, payments and special offers."
                    }
                  />
                  {user.is_influencer && this.renderOldLocation()}
                  {user.is_influencer && this.RenderChooseCategories()}

                  {/* <div>
                    {errors && errors.fields && errors.fields.phone &&
                      <FormError errors={errors.fields.phone} />
                    }
                    <label>Phone number
                      <PhoneInput
                        name="phone"
                        value={data.me.phone}
                        onChange={phone => this.setState({phone})}
                        placeholder=""
                        aria-describedby="sms-note"
                        required={this.props.user.is_influencer}
                      /> */}
                  {/* <small id="sms-note">We’ll send a text to verify this after you register.</small> */}
                  {/* </label>
                  </div> */}

                  {this.ConnectedWallets()}
                  {this.renderNotifications()}

                  {/* <Navigator
                    label="Done"
                    hasFormValidation
                    onNavigate={this.onNavigate}
                    isNextLocked={this.state.doneLocked}
                  /> */}
                  <div className={styles.btnWrap}>
                    <div className={styles.errorMessageWrap}>
                      <span>
                        {this.state.editError === null
                          ? null
                          : Object.keys(this.state.editError.data)[0]}
                      </span>
                      {this.state.editError ? (
                        <ErrorMessage error={this.state.editError} />
                      ) : null}
                    </div>
                    <button
                      onClick={this.handleFormSubmission}
                      type="submit"
                      // eslint-disable-next-line react/no-unknown-property
                      onNavigate={this.onNavigate}
                      className={classNames(styles.btn, {
                        [styles.btnDisabled]: this.state.doneLocked,
                      })}
                    >
                      Updates
                    </button>
                  </div>

                  {/* <button
                    type='submit'
                    onNavigate={this.onNavigate}
                    isNextLocked={this.state.doneLocked}
                    className={classNames(styles.btn, {
                      [styles.btnDisabled]: this.state.doneLocked,
                    })}
                  >Updates</button> */}
                </form>
              </div>
            </div>
          </div>
        </Main>
      </div>
    );
  }
}

const PROFILE_QUERY = gql`
  query Profile {
    me {
      hashid
      avatar {
        ... on Media {
          hashid
          is_processed
          square_image_sources {
            url
            width
          }
          type
        }
        ... on Url {
          url
        }
      }
      bio
      handle
      phone
      influencer {
        category_primary
        category_secondary
        category_tertiary
        country
        notification_email_sparkreminder_enabled
        paypal
        region
      }
      notification_email_closedspark_enabled
      notification_email_enabled
      notification_email_newspark_enabled
      notification_email_votedcomment_enabled
    }
    categories {
      primary
      secondary
    }
  }
`;

// $perSparkMin: String
// perSparkMin: $perSparkMin

const EDIT_PROFILE_MUTATION = gql`
  mutation EditProfile(
    $avatarHashid: ID
    $bio: String
    $handle: String
    $phone: String
    $paypal: String
    $category_primary: String
    $category_secondary: String
    $category_tertiary: String
    $country: String
    $region: String
    $notification_email_closedspark_enabled: Boolean
    $notification_email_enabled: Boolean
    $notification_email_newspark_enabled: Boolean
    $notification_email_sparkreminder_enabled: Boolean
    $notification_email_votedcomment_enabled: Boolean
  ) {
    editProfile(
      avatar_hashid: $avatarHashid
      bio: $bio
      category_primary: $category_primary
      category_secondary: $category_secondary
      category_tertiary: $category_tertiary
      country: $country
      handle: $handle
      notification_email_closedspark_enabled: $notification_email_closedspark_enabled
      notification_email_enabled: $notification_email_enabled
      notification_email_newspark_enabled: $notification_email_newspark_enabled
      notification_email_sparkreminder_enabled: $notification_email_sparkreminder_enabled
      notification_email_votedcomment_enabled: $notification_email_votedcomment_enabled
      paypal: $paypal
      phone: $phone
      region: $region
    ) {
      hashid
      avatar {
        ... on Media {
          hashid
          is_processed
          square_image_sources {
            url
            width
          }
          type
        }
        ... on Url {
          url
        }
      }
      bio
      handle
      phone
      influencer {
        category_primary
        category_secondary
        category_tertiary
        country
        notification_email_sparkreminder_enabled
        paypal
        region
      }
      notification_email_closedspark_enabled
      notification_email_enabled
      notification_email_newspark_enabled
      notification_email_votedcomment_enabled
    }
  }
`;

export default compose(
  withRouter,
  connect(mapStateToProps),
  graphql(PROFILE_QUERY),
  graphql(EDIT_PROFILE_MUTATION)
)(EditProfile);
