// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sections-SparkPost-elements-ViewOffer-style__filterBar__CTP5F{margin-bottom:11px}.sections-SparkPost-elements-ViewOffer-style__filterBar__CTP5F .sections-SparkPost-elements-ViewOffer-style__left__gnCz2{border-top-left-radius:10px;border-bottom-left-radius:10px}.sections-SparkPost-elements-ViewOffer-style__filterBar__CTP5F .sections-SparkPost-elements-ViewOffer-style__right__fiQFf{border-top-right-radius:10px;border-bottom-right-radius:10px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterBar": `sections-SparkPost-elements-ViewOffer-style__filterBar__CTP5F`,
	"left": `sections-SparkPost-elements-ViewOffer-style__left__gnCz2`,
	"right": `sections-SparkPost-elements-ViewOffer-style__right__fiQFf`
};
export default ___CSS_LOADER_EXPORT___;
