import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { Link } from "react-router-dom";

import Button from "components/Button";
import Icon from "components/Icon";

import styles from "./style.scss";

export default class IconButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    filled: PropTypes.bool,
    icon: PropTypes.string.isRequired,
    link: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.string,
    // TODO require one of link or onclick
  };

  static defaultProps = {
    color: "black",
    disabled: false,
    filled: false,
    size: "md",
  };

  render = () => {
    if (this.props.link == null) {
      return (
        <Button
          color="none"
          className={classNames(styles.root, this.props.className)}
          disabled={this.props.disabled}
          onClick={this.props.onClick}
        >
          <Icon
            icon={this.props.icon}
            size={this.props.size}
            color={this.props.color}
            filled={this.props.filled}
          />
        </Button>
      );
    }

    return (
      <Link
        to={this.props.link}
        className={classNames(styles.root, this.props.className)}
      >
        <Icon
          icon={this.props.icon}
          size={this.props.size}
          color={this.props.color}
          filled={this.props.filled}
        />
      </Link>
    );
  };
}
