import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';

import {CountryRegionData} from 'react-country-region-selector';

import CustomDropDown from './CustomDropDown';

const CountryDropdown = ({value, onChange}) => {
  const {getCountryFullName, dropdownData, options, selectedCountryIndex} = useMemo(()=> {
    const memoOptions = CountryRegionData.map(([countryName, countrySlug]) => [countrySlug, countryName]);
    const memoPreparedOptionsToDropDown = memoOptions.map(item => item[1]);
    const memoCountryCodes = memoOptions.map(item => item[0]);
    const memoSelectedCountryIndex = memoCountryCodes.findIndex(item => item === value);

    const memoGetCountryFullName = memoSelectedCountryIndex === -1 ? "" : memoOptions[memoSelectedCountryIndex][1];
    return {
      getCountryFullName: memoGetCountryFullName,
      selectedCountryIndex: memoSelectedCountryIndex,
      dropdownData: memoPreparedOptionsToDropDown,
      options: memoOptions,
    };
  }, [value]);
  const [selectedValue, setSelectedValue] = useState(getCountryFullName);

  useEffect(()=> {
    if (selectedCountryIndex !== -1) {
      setSelectedValue(options[selectedCountryIndex][1]);
    }
  }, [selectedCountryIndex]);


  const handleSelectValue = (country) => {
    setSelectedValue(country);
    const countryIndex = dropdownData.findIndex(item => item === country);
    onChange(options[countryIndex][0]);
  };

  return (
    <CustomDropDown items={dropdownData} selectedValue={selectedValue} onChange={handleSelectValue} />
  );
};

CountryDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

CountryDropdown.defaultProps = {
  value: "",
};

export default CountryDropdown;
