import classNames from "classnames";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";
import PropTypes from "prop-types";

import Avatar from "components/Avatar";
import Button from "components/Button";
import DateRangePicker from "components/DateRangePicker";
import Handle from "components/Handle";
import Loading from "components/Loading";
import Main from "components/Main";
import ReportComponent from "components/Report";
import NotLoggedIn from "components/NotLoggedIn";

import styles from "./style.scss";
import HeaderForAuthorizedUsers from "../../components/HeaderForAuthorizedUsers";

const mapStateToProps = function (state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
  };
};

class Report extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool,
    data: PropTypes.shape({
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      reports: PropTypes.arrayOf(PropTypes.object),
    }),
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      endDate: moment(),
      focusedInput: null,
      startDate: moment().subtract(15, "days"),
    };
  }

  refreshStats = () => {
    this.props.data.refetch({
      end: this.state.endDate.format("YYYY-MM-DD"),
      start: this.state.startDate.format("YYYY-MM-DD"),
    });
  };

  renderReports = () => {
    return <ReportComponent report={this.props.data.reports[0]} />;

    // This code duplicates the "Report" component on the page

    // return this.props.data.reports.map((report) => {
    //   return (
    //     <ReportComponent key={report.hashid} report={report} />
    //   );
    // });
  };

  onDatesChange = (dates) => {
    this.setState({
      endDate: dates.endDate,
      startDate: dates.startDate,
    });
  };

  onFocusChange = (inputName) => {
    this.setState({
      focusedInput: inputName,
    });
  };

  render() {
    const { authenticated, user } = this.props;

    const headProps = {
      leftContent: {
        content: [
          { type: "link", icon: "homeFilled", link: "/" },
        ],
      },
      rightContent: {
        content: [
          {
            type: "link",
            icon: "leaderboardNew",
            link: "/profile/leaderboard",
          },
          { type: "link", icon: "reportNew", link: "/profile/report" },
          { type: "link", icon: "video", link: "/spark/post" },
        ],
        className: styles.rightIcons,
      },
    };

    if (!this.props.data.reports && this.props.data.loading) {
      return <Loading headProps={headProps} />;
    }

    if (this.props.data.error) {
      return <p>Error</p>;
    }

    if (!authenticated) {
      return <NotLoggedIn headProps={headProps} />;
    }

    const InfluencerHeaderProps = {
      leftContent: {
        content: [
          { type: "link", icon: "homeFilled", link: "/" },
        ],
      },
      rightContent: {
        content: [
          {
            type: "link",
            icon: "leaderboardNew",
            link: "/profile/leaderboard",
          },
          { type: "link", icon: "reportNew", link: "/profile/report" },
          { type: "link", icon: "video", link: "/spark/post" },
        ],
      },
    };
    const FanHeaderProps = {
      leftContent: { content: { type: "logo" } },
      rightContent: { content: { type: "avatar" } },
    };

    const headerProps =
      user && user.is_influencer ? InfluencerHeaderProps : FanHeaderProps;

    return (
      <div>
        {/* <Head {...headProps} /> */}
        <HeaderForAuthorizedUsers {...headerProps} />
        <Main>
          <div className={styles.wrap}>
            <div
              className={classNames(
                "wrap__inner",
                "wrap__inner--skinny",
                "wrap__gutter",
                "wrap__gutter--padded"
              )}
            >
              <div className={styles.content}>
                <div className={styles.topContainer}>
                  <div className={styles.header}>
                    <Avatar
                      size="lg"
                      user={this.props.user}
                      className={styles.avatar}
                    />
                    <span className={classNames("title", "title-md")}>
                      <Handle handle={this.props.user.handle} />
                    </span>
                  </div>
                  <div className={styles.line}></div>
                  <div className={classNames(styles.summary)}>
                    <h1
                      className={classNames(
                        "title",
                        "align-c",
                        styles.summaryTitle
                      )}
                    >
                      Your Spark Report
                    </h1>
                    <div>
                      <div className={classNames(styles.date)}>
                        <span>Date Range</span>
                        <div className={styles.datePickerWrap}>
                          <DateRangePicker
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            focusedInput={this.state.focusedInput}
                            isOutsideRange={() => false}
                            onDatesChange={this.onDatesChange}
                            onFocusChange={this.onFocusChange}
                          />
                        </div>
                      </div>

                      <div className={classNames(styles.btnWrapper)}>
                        <Button
                          onClick={this.refreshStats}
                          size="sm"
                          className={classNames(styles.btn)}
                        >
                          Refresh
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                {this.renderReports()}
              </div>
            </div>
          </div>
        </Main>
      </div>
    );
  }
}

export const REPORT_QUERY = gql`
  query ReportDetail($start: String!, $end: String!) {
    reports(start: $start, end: $end) {
      hashid
      offer {
        hashid
        name
        thumbnail_url
        tags
      }
      clicks
      impressions
      conversions
      comments
      votes
      video_starts
      ad_shows
      payout
    }
  }
`;

export default compose(
  connect(mapStateToProps),
  graphql(REPORT_QUERY, {
    options: {
      variables: {
        end: moment().format("YYYY-MM-DD"),
        start: moment().subtract(15, "days").format("YYYY-MM-DD"),
      },
    },
  })
)(Report);
