import React, {useRef, useState} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './style.scss';
import useClickOutside from '../../hooks/useClickOutside';


export default function HelpIcon({helperText, rootClassName}) {
  const [isHelpTextOpen, setIsHelpTextOpen] = useState(false);
  const wrapRef = useRef(null);


  const validationCheck = helperText && isHelpTextOpen;

  const handleChangeOpenState = () => {
    setIsHelpTextOpen(!isHelpTextOpen);
  };

  const closeHint = () => {
    setIsHelpTextOpen(false);
  };
  useClickOutside(closeHint, isHelpTextOpen, wrapRef);

  return (
    <div className={classNames(styles.root, rootClassName)}>
      {
        validationCheck && <div className={styles.hintOpen}>
          <p className={styles.helperText}>{helperText}</p>
        </div>
      }

      <div ref={wrapRef} className={classNames(styles.iconWrapper)}>
        <div
          onClick={handleChangeOpenState}
          className={classNames(styles.questionIconWrapper)}
        >
          {validationCheck ? "X" : "?"}
        </div>
      </div>
    </div>
  );
}

HelpIcon.propTypes = {
  rootClassName: PropTypes.string,
  helperText: PropTypes.string,
};

