// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-Checkbox-style__checkboxWrapper__YYC1E{position:relative;cursor:pointer}.components-Checkbox-style__switchCheckbox__FqMKg{position:absolute;left:0;top:0;height:100%;width:100%;opacity:0;z-index:2}.components-Checkbox-style__switchLabel__kmkjb{width:40px;height:20px;z-index:1;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;cursor:pointer;background:gray;border-radius:100px;position:relative;-webkit-transition:background-color .2s;transition:background-color .2s;cursor:pointer}.components-Checkbox-style__switchLabel__kmkjb .components-Checkbox-style__switchButton___NYds{content:"";position:absolute;top:2px;left:2px;width:16px;height:16px;border-radius:45px;-webkit-transition:.2s;transition:.2s;background:#b7b7b7;-webkit-box-shadow:0 0 2px 0 rgba(10,10,10,.29);box-shadow:0 0 2px 0 rgba(10,10,10,.29);cursor:pointer}.components-Checkbox-style__switchCheckbox__FqMKg:checked+.components-Checkbox-style__switchLabel__kmkjb .components-Checkbox-style__switchButton___NYds{left:calc(100% - 2px);-webkit-transform:translateX(-100%);transform:translateX(-100%)}.components-Checkbox-style__switchLabel__kmkjb{background-color:#f3f3f3}.components-Checkbox-style__switchCheckbox__FqMKg:checked+.components-Checkbox-style__switchLabel__kmkjb{background-color:#ffede5}.components-Checkbox-style__switchCheckbox__FqMKg:checked+.components-Checkbox-style__switchLabel__kmkjb .components-Checkbox-style__switchButton___NYds{background-color:#f75a00}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxWrapper": `components-Checkbox-style__checkboxWrapper__YYC1E`,
	"switchCheckbox": `components-Checkbox-style__switchCheckbox__FqMKg`,
	"switchLabel": `components-Checkbox-style__switchLabel__kmkjb`,
	"switchButton": `components-Checkbox-style__switchButton___NYds`
};
export default ___CSS_LOADER_EXPORT___;
