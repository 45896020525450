const demoCampaignData = {
  cpa_goal: {
    payout: 0.1,
    payout_name: "Bonus Revenue",
    payout_percentage: null,
    payout_type: "cpa_demo",
  },
  cpl_goal: {
    payout: 0.1,
    payout_name: "Revenue Per Clicks",
    payout_percentage: null,
    payout_type: "cpc_demo",
  },
  description:
    "Hi Creator! If you do not see specific campaign details here, Start your first Spark by selecting the orange skip button in the top right. We will syndicate your engagement levels and demographic information to our Featured Sponsors to find a match for you. Check back here after each Spark to view your available campaigns and income potential.",
  display_goal: {
    payout: 0.1,
    payout_name: "Revenue Per Impressions",
    payout_percentage: null,
    payout_type: "cpm_demo",
  },
  hashid: 1,
  is_exclusive: false,
  name: "Demo Campaign",
  payout_remaining: 75000,
  tags: [],
  thumbnail_url:
    "https://media.go2speed.org/brand/files/sparkit/8/thumbnails_100/sparkit-logo_beta.png",
};

export default demoCampaignData;
