import PropTypes from 'prop-types';
import React from 'react';
import {Redirect} from 'react-router-dom';

import {storeAppToken} from '~/auth';

export default class AppRedirection extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired, // From router
  };

  constructor(props) {
    super(props);

    if (this.props.match.params.token) {
      storeAppToken(this.props.match.params.token);
    }
  }

  render() {
    return <Redirect to='/' />;
  }
}
