import {applyMiddleware, createStore, compose} from 'redux';
import {routerMiddleware} from 'react-router-redux';
import thunk from 'redux-thunk';

import reducers from './reducers';

function configure(initialState = {}, history = {}) {
  let config = null;

  if (process.env.NODE_ENV === 'production') {
    config = configureProd(initialState, history);
  } else {
    config = configureDev(initialState, history);
  }
  return config;
}

function configureProd(initialState, history) {
  const middleware = applyMiddleware(thunk, routerMiddleware(history));
  const store = middleware(createStore)(reducers, initialState);

  return store;
}

function configureDev(initialState, history) {
  const enhancer = compose(
    applyMiddleware(
      thunk,
      routerMiddleware(history)
    ),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  );

  const store = createStore(reducers, initialState, enhancer);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextReducers = require('./reducers/index');
      store.replaceReducer(nextReducers);
    });
  }

  return store;
}

const configureStore = configure();

export default configureStore;
