import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/Button';
import Modal from 'components/Modal';

export default class DeleteSparkModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
  }

  render() {
    const {
      isOpen,
      onConfirm,
      onRequestClose,
    } = this.props;

    return (
      <Modal
        contentLabel="Delete spark"
        extraPadding
        isOpen={isOpen}
        onRequestClose={onRequestClose}
      >
        <p>
          This action will permanently delete the entire Spark, including all videos, suggestions, and votes. Are you sure you want to delete this Spark?
        </p>
        <div>
          <Button size="lg" color="danger" onClick={onConfirm}>
            Yes, delete this spark.
          </Button>
          <Button size="lg" color="secondary" onClick={onRequestClose}>
            Never mind. Don’t delete it.
          </Button>
        </div>
      </Modal>
    );
  }
}
