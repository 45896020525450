import PropTypes from "prop-types";
import React from "react";

import { withRouter } from "react-router";

class RouteHandler extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.object.isRequired, // From router
    location: PropTypes.object.isRequired, // From router
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      location,
      history: { action },
    } = nextProps;

    if (location !== this.props.location) {
      if (action === "PUSH") {
        // New navigation; scroll to top
        window.scrollTo(0, 0);
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(RouteHandler);
