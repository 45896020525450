import moment from "moment";
import momentPropTypes from "react-moment-proptypes";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import styles from "./style.scss";

const DATETIME_FORMAT = "YYYY-MM-DD[T]HH:mm:ss";
const DATETIME_FORMAT_NO_SEC = "YYYY-MM-DD[T]HH:mm";
const DATETIME_FORMAT_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2})?$/;

const DatetimePicker = ({ className, max, min, onChange, value }) => {
  const formatMoment = (momentObj) => momentObj.format(DATETIME_FORMAT);

  const [currentValue, setCurrentValue] = useState(
    value ? formatMoment(value) : ""
  );
  const [_, setPrevValid] = useState(null);

  useEffect(() => {
    if (value) {
      setPrevValid(value);
    }
  }, [value]);

  const parse = (string) => {
    if (!DATETIME_FORMAT_REGEX.test(string)) {
      return null;
    }
    const format =
      string.length === 16 ? DATETIME_FORMAT_NO_SEC : DATETIME_FORMAT;
    const momentObj = moment(string, format);
    return momentObj.isValid() ? momentObj : null;
  };

  const handleChange = (event) => {
    const momentObj = parse(event.target.value);
    if (momentObj && onChange) {
      onChange(momentObj);
      setCurrentValue(formatMoment(momentObj));
      setPrevValid(momentObj);
    } else {
      setCurrentValue(event.target.value);
    }
  };

  return (
    <input
      type="datetime-local"
      onChange={handleChange}
      pattern="\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2})?"
      value={currentValue}
      min={min ? formatMoment(min) : null}
      max={max ? formatMoment(max) : null}
      className={classNames(styles.input, className)}
    />
  );
};

DatetimePicker.propTypes = {
  className: PropTypes.string,
  max: momentPropTypes.momentObj,
  min: momentPropTypes.momentObj,
  onChange: PropTypes.func,
  value: momentPropTypes.momentObj,
};

export default DatetimePicker;
