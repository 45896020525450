// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-Grid-style__root__w8JFg{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start}.components-Grid-style__column__hKqas{margin-left:20px;-webkit-box-flex:1;-ms-flex:1;flex:1;max-width:100vw;width:100% !important}.components-Grid-style__column__hKqas:first-child{margin-left:0}.components-Grid-style__item__jPB4W{margin-top:20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-Grid-style__root__w8JFg`,
	"column": `components-Grid-style__column__hKqas`,
	"item": `components-Grid-style__item__jPB4W`
};
export default ___CSS_LOADER_EXPORT___;
