// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-Help-style__root__HVenH{padding:10px 20px;text-align:center}.components-Help-style__root__HVenH a{color:currentColor;text-decoration:underline}.components-Help-style__root__HVenH a:hover{color:currentColor}.components-Help-style__inner__gOHxX{position:relative;padding:0 20px}.components-Help-style__button__PcX1K{position:absolute;top:50%;right:0;-webkit-transform:translateY(-50%);transform:translateY(-50%);margin:0;padding:0;line-height:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-Help-style__root__HVenH`,
	"inner": `components-Help-style__inner__gOHxX`,
	"button": `components-Help-style__button__PcX1K`
};
export default ___CSS_LOADER_EXPORT___;
