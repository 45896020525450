import React from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import classNames from "classnames";
import IconButton from "components/IconButton";
import styles from "./style.scss";

export default class Modal extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    extraPadding: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func,
    showCloseButton: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    contentLabel: "",
    extraPadding: false,
    showCloseButton: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });
  };

  render() {
    const {
      children,
      className,
      extraPadding,
      onRequestClose,
      showCloseButton,
      deleteCommentCheck,
      ...otherProps
    } = this.props;
    const { windowHeight, windowWidth } = this.state;

    return (
      <ReactModal
        className={styles.modal}
        overlayClassName={deleteCommentCheck ? styles.overlays : styles.overlay}
        style={{ overlay: { minHeight: windowHeight, minWidth: windowWidth } }}
        {...otherProps}
      >
        <div
          className={classNames(
            styles.box,
            className,
            extraPadding && styles.extraPadding
          )}
        >
          {showCloseButton && (
            <IconButton
              icon="close"
              ratio="square"
              className="fill-black icon-standard pos-a right-15 top-15"
              onClick={onRequestClose}
            />
          )}
          <div className={styles.content}>{children}</div>
        </div>
      </ReactModal>
    );
  }
}
