import classNames from "classnames";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";

import Loading from "components/Loading";

import styles from "./style.scss";

function getSmallestImageSource(media, minWidth) {
  const sources = media.square_image_sources;
  if (sources.length === 0) {
    return 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg"/%3E';
  }
  for (const image of sources) {
    if (image.width >= minWidth) {
      return image.url;
    }
  }
  return sources[sources.length - 1].url;
}

function getSourceSet(media) {
  return media.square_image_sources
    .map((source) => {
      return `${source.url} ${source.width}w`;
    })
    .join(", ");
}

function getSizes(sizeProp, sizesProp) {
  if (sizesProp) {
    return sizesProp;
  }
  switch (sizeProp) {
    case "thumbnail":
      return "50px";
    case "sm":
      return "33.333vw";
    case "md":
      return "50vw";
    case "lg":
      return "100vw";
  }
  return null;
}

export default class Media extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    imageOnly: PropTypes.bool.isRequired,
    media: PropTypes.object.isRequired,
    mediaRef: PropTypes.func,
    onEnded: PropTypes.func,
    onPause: PropTypes.func,
    onPlay: PropTypes.func,
    onPlaying: PropTypes.func,
    poster: PropTypes.string,
    roughWidth: PropTypes.number,
    rounded: PropTypes.bool.isRequired,
    showPlayButton: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(["thumbnail", "sm", "md", "lg"]).isRequired,
    sizes: PropTypes.string,
  };

  static defaultProps = {
    imageOnly: false,
    mediaRef: function () {},
    onPause: function () {},
    poster: null,
    roughWidth: null,
    rounded: true,
    showPlayButton: true,
    size: "md",
    sizes: null,
  };

  handleOnPlay = (event) => {
    if (this.props.onPlay) {
      this.props.onPlay(event);
    }
  };

  handleOnEnded = (event) => {
    if (this.props.onEnded) {
      this.props.onEnded(event);
    }
  };

  handleOnPause = (event) => {
    if (this.props.onPause) {
      this.props.onPause(event);
    }
  };

  handleOnPlaying = (event) => {
    if (this.props.onPlaying) {
      this.props.onPlaying(event);
    }
    this.mediaContainer.classList.remove(styles.hasPlayButton);
  };

  handleClick = () => {
    if (this.media.paused) {
      this.media.play();
    } else {
      this.media.pause();
    }
  };

  render() {
    const {
      className,
      imageOnly,
      media,
      mediaRef,
      onPlaying,
      poster,
      roughWidth,
      rounded,
      showPlayButton,
      size,
      sizes,
      ...otherProps
    } = this.props;

    let sizeClass = null;
    let modifiedRoughWidth = roughWidth;
    switch (size) {
      case "thumbnail":
        sizeClass = styles.thumbnail;
        if (!roughWidth) {
          modifiedRoughWidth = 50;
        }
        break;
      case "sm":
        sizeClass = styles.sm;
        if (!roughWidth) {
          modifiedRoughWidth = 200;
        }
        break;
      case "md":
        sizeClass = styles.md;
        if (!roughWidth) {
          modifiedRoughWidth = 320;
        }
        break;
      case "lg":
        sizeClass = styles.lg;
        if (!roughWidth) {
          modifiedRoughWidth = 640;
        }
        break;
    }

    // If processing isn't finished, we can't show the media.
    // We probably shouldn't even have gotten this far: we shouldn't be trying
    // to display sparks or comments where the media processing is incomplete.
    if (!media.is_processed) {
      return <Loading />;
    }

    let type = media.type;
    if (imageOnly) {
      type = "image";
    }

    switch (type) {
      case "video":
        return (
          <div
            className={classNames(
              styles.root,
              className,
              sizeClass,
              styles.video,
              showPlayButton && styles.hasPlayButton,
              rounded && styles.rounded
            )}
            ref={(ref) => (this.mediaContainer = ref)}
            onClick={this.handleClick}
            data-tut="reactour__step1"
          >
            <div className={styles.container}>
              <video
                controls
                data-tut="reactour__step1--observe"
                controlsList="nodownload"
                poster={
                  poster || this.getSmallestImageSource(modifiedRoughWidth)
                }
                ref={(ref) => {
                  mediaRef(ref);
                  this.media = ref;
                }}
                onPlay={this.handleOnPlay}
                onPause={this.handleOnPause}
                onPlaying={this.handleOnPlaying}
                onEnded={this.handleOnEnded}
                {...otherProps}
              >
                {media.video_sources.map((source) => (
                  <source
                    key={source.type + source.url}
                    type={source.type}
                    src={source.url}
                  />
                ))}
              </video>
            </div>
          </div>
        );
      case "image":
        return (
          <div
            className={classNames(
              styles.root,
              className,
              sizeClass,
              media.type === "video" && showPlayButton
                ? styles.hasPlayButton
                : null,
              rounded && styles.rounded
            )}
            ref={(ref) => (this.mediaContainer = ref)}
          >
            <div className={styles.container}>
              <img
                src={this.getSmallestImageSource(0)}
                srcSet={this.getSourceSet()}
                sizes={this.getSizes()}
                ref={(ref) => {
                  mediaRef(ref);
                  this.media = ref;
                }}
                {...otherProps}
              />
            </div>
          </div>
        );
    }

    return null;
  }

  getSmallestImageSource = (minWidth) => {
    return getSmallestImageSource(this.props.media, minWidth);
  };

  getSourceSet = () => {
    return getSourceSet(this.props.media);
  };

  getSizes = () => {
    return getSizes(this.props.size, this.props.sizes);
  };
}

class DataBackedMedia extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
      media: PropTypes.object,
      startPolling: PropTypes.func.isRequired,
      stopPolling: PropTypes.func.isRequired,
    }),
    hashid: PropTypes.string.isRequired,
    onProcessedStatusChange: PropTypes.func,
    size: PropTypes.string,
  };

  UNSAFE_componentWillUpdate(nextProps) {
    if (!nextProps.onProcessedStatusChange) {
      return;
    }

    const processed = !this.props.data.media
      ? null
      : this.props.data.media.is_processed;
    const nextProcessed = !nextProps.data.media
      ? null
      : nextProps.data.media.is_processed;

    if (processed !== nextProcessed) {
      nextProps.onProcessedStatusChange(nextProcessed);
    }
  }

  componentDidMount() {
    if (this.props.onProcessedStatusChange) {
      this.props.onProcessedStatusChange(
        !this.props.data.media ? null : this.props.data.media.is_processed
      );
    }
  }

  render() {
    const { data, hashid, onProcessedStatusChange, ...otherProps } = this.props;

    if (!data.media) {
      return <Loading size={this.props.size} />;
    }

    if (data.media.is_processed) {
      data.stopPolling();
    } else {
      data.startPolling(3000);
    }

    return <Media media={data.media} {...otherProps} />;
  }
}

const MEDIA_QUERY = gql`
  query UploadedMedia($hashid: ID!) {
    media(hashid: $hashid) {
      hashid
      is_processed
      square_image_sources {
        url
        width
      }
      type
      video_sources {
        type
        url
      }
    }
  }
`;

export const GraphqlBackedMedia = graphql(MEDIA_QUERY, {
  options: (props) => ({
    variables: {
      hashid: props.hashid,
    },
  }),
})(DataBackedMedia);
