import { AppContainer } from "react-hot-loader";
import React from "react";
import ReactDOM from "react-dom/client";
import ReactModal from "react-modal";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import { createBrowserHistory } from "history";
import { init, configureScope } from "@sentry/browser";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";
import { getEnvironment, getVersion } from "./config";
import LogRocket from "logrocket";

import App from "./sections/App";
import { checkUserStatus } from "./auth";
import store from "./store";
import "modernizr";
import "./polyfill";

ReactModal.setAppElement("#app");

const history = createBrowserHistory();

if (process.env.SENTRY_DSN && process.env.NODE_ENV !== "development") {
  init({
    dsn: process.env.SENTRY_DSN,
    environment: getEnvironment(),
    ignoreErrors: [
      "auth_token_expired: Token has expired and can no longer be refreshed",
    ],
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsoleIntegration({
        levels: ["warn", "error"],
      }),
    ],
    release: "sparkit-web@" + getVersion(),
    tracesSampleRate: 0.1, // Transmit 10% of the captured transactions
  });

  configureScope((scope) => {
    scope.setLevel("warning");
  });

  if (getEnvironment() === "prod") {
    LogRocket.init("ijm1gt/sparkit-web", {
      release: getVersion(),
    });

    LogRocket.getSessionURL((sessionURL) => {
      configureScope((scope) => {
        scope.setExtra("sessionURL", sessionURL);
      });
    });
  }
}

// Get user if logged in
checkUserStatus();

const root = ReactDOM.createRoot(document.getElementById("app"));
const render = (Component) => {
  root.render(
    <AppContainer>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Component />
        </ConnectedRouter>
      </Provider>
    </AppContainer>
  );
};

render(App);

if (module.hot) {
  module.hot.accept("./sections/App", () => {
    const hotApp = require("./sections/App").default;
    render(hotApp);
  });
}
