import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './style.scss';

export default class FilterBar extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  }

  render() {
    return (
      <nav className={classNames(styles.root, this.props.className, "bar", "bar--dropshadow")}>
        <ul className="list list--unstyle list--inline">
          {this.props.children}
        </ul>
      </nav>
    );
  }
}
