// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sections-SparkPost-elements-ViewAd-style__root__Wzhel{padding:15px;border-radius:22px;background-color:#fff}.sections-SparkPost-elements-ViewAd-style__textAreaHeader__h1B15{position:relative;width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:6px}.sections-SparkPost-elements-ViewAd-style__textAreaHeader__h1B15 span{color:#929292;font-family:"Poppins",sans-serif;font-size:13px;font-style:normal;font-weight:500;line-height:normal}.sections-SparkPost-elements-ViewAd-style__avatarBlock__NOxWE{width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.sections-SparkPost-elements-ViewAd-style__locationWrap__Oxh7Y{margin-top:20px;display:-webkit-box;display:-ms-flexbox;display:flex;gap:10px}.sections-SparkPost-elements-ViewAd-style__headerAddText__hLFED{text-align:center;color:#000;text-align:center;font-family:"Inter",sans-serif;font-size:20px;font-style:italic;font-weight:700;line-height:normal}.sections-SparkPost-elements-ViewAd-style__postSparkBtn__dxdes{-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;align-items:center;width:100%;border-radius:10px;background:#f75a00;-webkit-box-shadow:0px 8px 24px 0px rgba(252,67,26,.25);box-shadow:0px 8px 24px 0px rgba(252,67,26,.25)}.sections-SparkPost-elements-ViewAd-style__tagList__Y6O1q{margin-top:12px;margin-bottom:95px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `sections-SparkPost-elements-ViewAd-style__root__Wzhel`,
	"textAreaHeader": `sections-SparkPost-elements-ViewAd-style__textAreaHeader__h1B15`,
	"avatarBlock": `sections-SparkPost-elements-ViewAd-style__avatarBlock__NOxWE`,
	"locationWrap": `sections-SparkPost-elements-ViewAd-style__locationWrap__Oxh7Y`,
	"headerAddText": `sections-SparkPost-elements-ViewAd-style__headerAddText__hLFED`,
	"postSparkBtn": `sections-SparkPost-elements-ViewAd-style__postSparkBtn__dxdes`,
	"tagList": `sections-SparkPost-elements-ViewAd-style__tagList__Y6O1q`
};
export default ___CSS_LOADER_EXPORT___;
