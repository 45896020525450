import classNames from "classnames";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";

import clipboardCopy from "clipboard-copy";

import Avatar from "components/Avatar";
import Button from "components/Button";
import CommentForm from "components/CommentForm";
import Icon from "components/Icon";
import IconButton from "components/IconButton";
import Handle from "components/Handle";
import Loading from "components/Loading";
import Media from "components/Media";
import OffsetAnchor from "components/OffsetAnchor";
import ShareMenu from "components/ShareMenu";
import Timer from "components/Timer";
import DeleteCommentModal from "components/DeleteCommentModal";

import { SPARK_QUERY } from "sections/Spark";

import * as authActions from "actions/auth";
import store from "~/store";

import getConfig from "~/getConfig";
import scrollIntoView from "~/scrollIntoView";
import { sessionUrl, shareUrl } from "~/shareUrl";

import styles from "./style.scss";
import CommentCardWithData from "../CommentCard";

window.dataLayer = window.dataLayer || [];

const mapStateToProps = function (state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
  };
};

class Comment extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool, // Auth reducer
    data: PropTypes.shape({
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
      me: PropTypes.object,
      pageMetadata: PropTypes.object,
    }),
    childComments: PropTypes.arrayOf(PropTypes.object.isRequired),
    childOfTheClosingComment: PropTypes.array,
    closingCommentThread: PropTypes.bool,
    className: PropTypes.string,
    comment: PropTypes.object.isRequired,
    depth: PropTypes.number.isRequired,
    highlight: PropTypes.bool.isRequired,
    isClosingComment: PropTypes.bool.isRequired,
    onAnonymousVoteRetracted: PropTypes.func,
    onAnonymousVoteUsed: PropTypes.func,
    onMediaEnded: PropTypes.func,
    showChildren: PropTypes.bool.isRequired,
    sparkAuthorHashid: PropTypes.string.isRequired,
    sparkClosesAt: PropTypes.number.isRequired,
    sparkClosed: PropTypes.bool.isRequired,
    sparkClosingCommentHashid: PropTypes.string,
    sparkHasClosingComment: PropTypes.bool.isRequired,
    sparkHashid: PropTypes.string.isRequired,
    sparkVotesRemaining: PropTypes.number,
    unvote: PropTypes.func, // GraphQL mutation
    delete: PropTypes.func.isRequired,
    user: PropTypes.object, // Auth reducer
    vote: PropTypes.func, // GraphQL mutation

    handleChangePlaying: PropTypes.func,
    handlePlayTrack: PropTypes.func,
    isTrackPlaying: PropTypes.bool,
    trackValues: PropTypes.object,
  };

  static defaultProps = {
    authenticated: false,
    highlight: false,
    isClosingComment: false,
    showChildren: true,
    childOfTheClosingComment: [],
    closingCommentThread: false,
  };

  constructor(props) {
    super(props);
    this.$root = null;
    this.$replyForm = null;

    this.state = {
      anonymousVote: this.getAnonymousVote(),
      config: null,
      hideReplyForm: false, // Visually hide
      isPlaying: false,
      shareOptionsOpen: false,
      showReplyForm: false, // Make/remove element
      isEditing: false,
      deleteId: null,
      deleteModalOpen: false,
      deleted: false,
    };

    getConfig().then((config) => {
      this.setState({
        config: config,
      });
    });
  }
  componentDidMount() {
    window.addEventListener("storage", this.handleStorage);
  }

  isSubjectOfFragment = () => {
    return (
      window.location.hash &&
      window.location.hash.substr(1) === `comment-${this.props.comment.hashid}`
    );
  };

  /**
   * Handle confirmation that a spark should be deleted
   *
   * @return {void}
   */
  handleDeleteConfirmation = () => {
    this.props
      .delete({
        hashid: this.props.comment.hashid,
      })
      .then(() => {
        this.setState({
          deleted: false,
          deleteModalOpen: false,
        });
      })
      .catch((error) => {
        this.setState({
          deleted: false,
          deleteModalOpen: false,
        });
      });
  };

  /**
   * Handle a request to close the delete spark modal
   *
   * @return {void}
   */
  handleDeleteModalClose = () => {
    this.setState({
      deleteModalOpen: false,
    });
  };

  handleEditClick = () => {
    this.setState((prevState) => ({
      isEditing: !prevState.isEditing,
    }));
  };

  handleDeleteComment = () => {
    this.setState({ deleteId: this.props.comment.id });
    this.setState({
      deleteModalOpen: true,
    });
  };

  /**
   * Handle a request to close the delete spark modal
   *
   * @return {void}
   */

  handleSaveEdit = (newMessage) => {
    this.setState({
      isEditing: false,
      comment: { ...this.props.comment, message: newMessage },
    });
  };

  componentWillUnmount() {
    window.removeEventListener("storage", this.handleStorage);
  }

  isAuthor = () => {
    return (
      this.props.authenticated &&
      this.props.user.hashid === this.props.comment.user.hashid
    );
  };

  isSparkAuthor = () => {
    return (
      this.props.authenticated &&
      this.props.user.hashid === this.props.sparkAuthorHashid
    );
  };

  canMakeDecision = () => {
    return (
      this.isSparkAuthor() &&
      this.props.depth === 1 &&
      !this.isAuthor() && // Don't allow author's own comment to be chosen
      !this.props.sparkHasClosingComment &&
      this.props.sparkClosed
    );
  };

  handleVoteClick = () => {
    const positive = !this.isVotedOnByMe();

    const method = positive ? this.props.vote : this.props.unvote;

    // Do nothing if mutation isn't available
    if (!method) {
      return;
    }

    const promise = method({
      hashid: this.props.comment.hashid,
    });

    if (!this.props.authenticated) {
      // Immediately update local storage
      if (positive) {
        if (this.props.onAnonymousVoteUsed) {
          this.props.onAnonymousVoteUsed();
        }
        window.localStorage.setItem(this.getAnonymousVoteKey(), "1");
      } else {
        if (this.props.onAnonymousVoteRetracted) {
          this.props.onAnonymousVoteRetracted();
        }
        window.localStorage.removeItem(this.getAnonymousVoteKey());
      }
      this.setState({
        anonymousVote: positive,
      });

      // Once response comes, undo the storage change on failure
      promise.catch(() => {
        if (positive) {
          if (this.props.onAnonymousVoteRetracted) {
            this.props.onAnonymousVoteRetracted();
          }
          window.localStorage.removeItem(this.getAnonymousVoteKey());
        } else {
          if (this.props.onAnonymousVoteUsed) {
            this.props.onAnonymousVoteUsed();
          }
          window.localStorage.setItem(this.getAnonymousVoteKey(), "1");
        }
        this.setState({
          anonymousVote: !positive,
        });
      });
    }
  };

  handleStorage = (event) => {
    if (event.key !== this.getAnonymousVoteKey()) {
      return;
    }
    this.setState({
      anonymousVote: this.getAnonymousVote(),
    });
  };

  getAnonymousVoteKey = () => {
    return `anonymousVote:${this.props.comment.hashid}`;
  };

  getAnonymousVote = () => {
    return !!window.localStorage.getItem(this.getAnonymousVoteKey());
  };

  isVotedOnByMe = () => {
    if (this.props.authenticated) {
      return this.props.comment.is_voted_on_by_me;
    }
    return this.state.anonymousVote;
  };

  canVoteOrUnvote = () => {
    // Can't vote or unvote if the spark is closed
    if (this.props.sparkClosed) {
      return false;
    }

    // Can't vote or unvote if the comment isn't at depth 1
    if (this.props.depth !== 1) {
      return false;
    }

    // Can't vote if comment is not voted on by this user and the user is out of
    // votes for this spark
    if (
      this.props.sparkVotesRemaining === null ||
      this.props.sparkVotesRemaining <= 0
    ) {
      if (!this.isVotedOnByMe()) {
        return false;
      }
    }

    return true;
  };

  canSeeVotesCount = () => {
    // User can see vote counts of all comments if the spark is closed
    if (this.props.sparkClosed) {
      return true;
    }

    // User can see vote counts of all comments if they are the spark author
    if (this.isSparkAuthor()) {
      return true;
    }

    // User can see the vote count if they have voted on this comment
    if (this.isVotedOnByMe()) {
      return true;
    }

    // User can see the vote count of all comments if they are out of votes for
    // this spark
    if (this.props.sparkVotesRemaining === 0) {
      return true;
    }

    return false;
  };

  handleReplyClick = () => {
    this.setState((prevState) => ({
      showReplyForm: !prevState.showReplyForm,
      hideReplyForm: false,
    }));
    if (!this.props.authenticated) {
      store.dispatch(authActions.authRequest());
    }
  };

  handleCancelReplyClick = () => {
    this.setState({
      showReplyForm: false,
    });
  };

  handlePostReplyConfirm = () => {
    this.setState({
      showReplyForm: false,
    });
  };

  handlePostReplyError = () => {
    this.setState(
      {
        hideReplyForm: false,
        showReplyForm: true,
      },
      () => {
        scrollIntoView(this.$replyForm, {
          behavior: "smooth",
          block: "center",
        });
      }
    );
  };

  handlePostReplyProvisional = () => {
    this.setState({
      hideReplyForm: true,
    });

    // Wait one tick to ensure the new reply has been added to the DOM
    setTimeout(() => {
      if (this.childRefs.length) {
        const lastChild = this.childRefs[this.childRefs.length - 1];
        lastChild.$root = this.$root;
        lastChild.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    });
  };

  isClosingComment = () => {
    if (this.props.comment.hashid === this.props.sparkClosingCommentHashid) {
      return true;
    }
    return false;
  };

  isPlaying = () => {
    return this.state.isPlaying;
  };

  /**
   * Handle a click to share the comment
   *
   * @return {void}
   */
  handleShareClick = () => {
    if (navigator.share) {
      window.dataLayer.push({
        event: "share",
        gaEventAction: "navigator.share",
        gaEventCategory: "Comment Share",
        gaEventLabel: this.props.comment.hashid,
      });
      navigator.share({
        url: sessionUrl(this.props.comment.permalink),
      });
    } else {
      this.setState({
        shareOptionsOpen: !this.state.shareOptionsOpen,
      });
    }
  };

  /**
   * Handle a click to share via a particular social network
   *
   * @param {string} provider - Provider
   *
   * @return {void}
   */
  handleShareMenuClick = (provider) => {
    window.dataLayer.push({
      event: "share",
      gaEventAction: provider,
      gaEventCategory: "Comment Share",
      gaEventLabel: this.props.comment.hashid,
    });
    if (provider === "_copy") {
      clipboardCopy(sessionUrl(this.props.comment.permalink));
    } else {
      const url = shareUrl(
        provider,
        {
          url: sessionUrl(this.props.comment.permalink),
        },
        this.state.config.services
      );
      const newWin = window.open(url);
      newWin.focus();
    }
    this.setState({
      shareOptionsOpen: false,
    });
  };

  handleMediaOnEnded = (event) => {
    if (this.isClosingComment()) {
      window.dataLayer.push({
        event: "video",
        gaEventAction: "End",
        gaEventCategory: "Spark Closing Comment",
        gaEventLabel: this.props.sparkHashid,
      });
    }
    this.setState({ isPlaying: false });
    if (this.props.onMediaEnded) {
      this.props.onMediaEnded(event);
    }
  };

  handleMediaOnPlay = () => {
    if (this.isClosingComment()) {
      if (!this.isPlaying()) {
        window.dataLayer.push({
          event: "video",
          gaEventAction: "Play",
          gaEventCategory: "Spark Closing Comment",
          gaEventLabel: this.props.sparkHashid,
        });
        this.setState({ isPlaying: true });
      }
    }
  };

  scrollIntoView(options) {
    scrollIntoView(this.$root, options);
  }

  findUrlInMessage = (message) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = message.match(urlRegex);
    if (!matches) {
      return <React.Fragment></React.Fragment>;
    }
    return matches.map((url, index) => (
      <CommentCardWithData
        key={index}
        url={url}
        className={styles.commentCard}
        handleChangePlaying={this.props.handleChangePlaying}
        handlePlayTrack={this.props.handlePlayTrack}
        isTrackPlaying={this.props.isTrackPlaying}
        trackValues={this.props.trackValues}
      />
    ));
  };

  preparedMessage = (message) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const allowedUrls = ["soundcloud", "unitedmasters"];

    const parts = message.split(urlRegex);
    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        const url = part;
        const urlMatch = url.match(/https?:\/\/([^/]+)/);
        if (urlMatch) {
          const hostname = urlMatch[1];
          if (allowedUrls.some((allowedUrl) => hostname.includes(allowedUrl))) {
            return null;
          } else if (
            !allowedUrls.some((allowedUrl) => hostname.includes(allowedUrl))
          ) {
            return (
              <span key={index} style={{ fontStyle: "italic" }}>
                link removed
              </span>
            );
          }
        }
      }
      return part;
    });
  };

  isCommentSelected = () => {
    if (this.props.selectedCommentsHashids.length >= 1) {
      return true;
    }
    return false;
  };

  render() {
    const children =
      this.props.childComments || this.props.comment.children || [];

    this.childRefs = new Array(children.length);
    if (this.props.comment.hashid === "") {
      return <Loading />;
    }
    return (
      <div
        className={classNames(styles.root, this.props.className)}
        ref={(ref) => (this.$root = ref)}
        data-tut="reactour__step2"
      >
        <OffsetAnchor id={`comment-${this.props.comment.hashid}`} />

        <div
          className={classNames(
            styles.commentWrapper
            // This.props.highlight || this.isSubjectOfFragment()
            //   ? styles.highlight
            //   : null
          )}
        >
          <div className={classNames(styles.content)}>
            <div className={styles.avatarAndEditingIconsCont}>
              <div className={classNames(styles.avatarAndNicknameCont)}>
                <Avatar
                  size={this.props.depth === 0 ? "md" : "sm"}
                  user={this.props.comment.user}
                  className={classNames(styles.avatar)}
                />
                <span className={styles.nickNameContainer}>
                  <Handle
                    className={styles.userName}
                    handle={this.props.comment.user.handle}
                  />

                  {this.props.authenticated &&
                    (this.props.user.hashid === this.props.sparkAuthorHashid ||
                      this.props.comment.user.hashid ===
                        this.props.user.hashid) &&
                    this.props.depth !== 0 && (
                      <div className={styles.actionsItem}>
                        <Button
                          className={classNames(styles.actionItemText)}
                          color="none"
                          onClick={this.handleEditClick}
                        >
                          <Icon icon="edit" size="sm" />
                        </Button>
                        <Button
                          onClick={this.handleDeleteComment}
                          className={classNames(styles.actionItemText)}
                          color="none"
                        >
                          <IconButton icon="remove" size="sm" />
                        </Button>
                      </div>
                    )}
                  {this.state.deleteModalOpen && (
                    <DeleteCommentModal
                      isOpen={this.state.deleteModalOpen}
                      onConfirm={this.handleDeleteConfirmation}
                      onRequestClose={this.handleDeleteModalClose}
                    />
                  )}
                  {this.props.authenticated &&
                    this.props.user.hashid === this.props.sparkAuthorHashid &&
                    this.props.depth !== 0 && (
                      <div className={styles.helpIcons}>
                        {this.props.selectedCommentsHashids.includes(
                          this.props.comment.hashid
                        ) ? (
                          <div
                            className={styles.checkboxChecked}
                            onClick={() =>
                              this.props.handleSelectComment(
                                this.props.comment.hashid,
                                this.props.comment
                              )
                            }
                          >
                            <div className={styles.checkmarkWrap}>
                              <Icon icon="checkmark" />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={styles.checkbox}
                            onClick={() =>
                              this.props.handleSelectComment(
                                this.props.comment.hashid,
                                this.props.comment
                              )
                            }
                          ></div>
                        )}
                        {/* <IconButton icon="remove" size="sm" /> */}
                      </div>
                    )}
                </span>
                {this.props.depth === 0 && (
                  <div className={styles.winningCommentLikesBlock}>
                    <div className={styles.winningCommentLikesCountAndIcon}>
                      {this.props.comment.votes_count}
                      <IconButton
                        className={styles.voteButton}
                        color="active"
                        disabled={true}
                        filled={true}
                        icon="heart"
                      />
                    </div>
                    <div className={styles.winningCommentLikesText}>
                      Votes made this happen
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              {this.state.isEditing ? (
                <div className={styles.editFormContainer}>
                  <CommentForm
                    initialMessage={this.props.comment.message}
                    onPostConfirm={this.handleSaveEdit}
                    onCancel={() => this.setState({ isEditing: false })}
                    parentComment={this.props.comment}
                    isEditing={this.state.isEditing}
                    isDeletingId={this.state.deleteId}
                  />
                </div>
              ) : (
                <p
                  className={classNames(styles.message, {
                    [styles.winningMessage]: this.props.depth === 0,
                  })}
                >
                  {this.preparedMessage(this.props.comment.message)}
                </p>
              )}
              {this.findUrlInMessage(this.props.comment.message)}
              {this.props.comment.media && (
                <Media
                  className={styles.media}
                  media={this.props.comment.media}
                  onEnded={this.handleMediaOnEnded}
                  onPlay={this.handleMediaOnPlay}
                  shape="square"
                  size="lg"
                />
              )}
            </div>

            <div
              className={classNames(styles.actions, {
                [styles.closingAction]:
                  (this.props.depth === 2 && !this.isClosingComment()) ||
                  this.props.comment.media === null,
              })}
              data-tut="reactour__step3"
            >
              <div className={styles.likeAndCommentAction}>
                {!this.props.closingCommentThread
                  ? this.props.depth === 1 &&
                    !this.isClosingComment() && (
                      <div className={styles.voteCounter}>
                        <IconButton
                          className={styles.voteButton}
                          // Color={this.isVotedOnByMe() ? "active" : "black"}
                          disabled={!this.canVoteOrUnvote()}
                          filled={this.isVotedOnByMe()}
                          icon="heart"
                          onClick={this.handleVoteClick}
                          size={this.isVotedOnByMe() ? "lg" : "md"}
                        />
                        {this.canSeeVotesCount() && (
                          <span className={styles.votesCount}>
                            {this.props.comment.votes_count}
                          </span>
                        )}
                      </div>
                    )
                  : ""}
                {!this.props.closingCommentThread
                  ? this.props.depth < 2 &&
                    !this.props.isClosingComment && (
                      <div className={styles.actionsItem}>
                        <Button
                          className={classNames(styles.actionItemText)}
                          color="none"
                          onClick={this.handleReplyClick}
                        >
                          <IconButton icon="comment" color="primary" />
                          Comment
                        </Button>
                      </div>
                    )
                  : ""}
                {}
              </div>
              {
                // && this.props.depth === 0
                this.canMakeDecision() ? (
                  !this.isCommentSelected() ? (
                    <Button
                      color="none"
                      className={classNames(
                        styles.makeDecisionButton,
                        styles.actionsItem
                      )}
                      link={`/spark/${this.props.sparkHashid}/decision/${this.props.comment.hashid}`}
                    >
                      <div className={styles.selectTextBlock}>
                        <Icon icon="share" size="md" />
                        Select
                      </div>
                    </Button>
                  ) : (
                    <></>
                  )
                ) : (
                  <ShareMenu
                    className={classNames(
                      styles.shareContainer,
                      styles.actionsItem
                    )}
                    listClassName={styles.shareOptionsMenu}
                    onButtonClick={this.handleShareClick}
                    onShareMenuItemClick={this.handleShareMenuClick}
                    open={this.state.shareOptionsOpen}
                  />
                )
              }
              {/* <ShareMenu
                className={classNames(
                  styles.shareContainer,
                  styles.actionsItem
                )}
                listClassName={styles.shareOptionsMenu}
                onButtonClick={this.handleShareClick}
                onShareMenuItemClick={this.handleShareMenuClick}
                open={this.state.shareOptionsOpen}
              />
              {this.canMakeDecision() && (
                <Button
                  color="none"
                  className={classNames(
                    styles.makeDecisionButton,
                    styles.actionsItem
                  )}
                  link={`/spark/${this.props.sparkHashid}/decision/${this.props.comment.hashid}`}
                >
                  Choose idea
                </Button>
              )} */}
            </div>
            {this.isClosingComment() && (
              <div className={styles.timerWrap}>
                {this.props.sparkClosesAt && (
                  <Timer expiresAt={this.props.sparkClosesAt} />
                )}
              </div>
            )}
          </div>
        </div>

        {this.state.showReplyForm && (
          <CommentForm
            depth={this.props.depth + 1}
            isReplay={!this.isClosingComment()}
            parentComment={this.props.comment}
            onCancel={this.handleCancelReplyClick}
            onPostConfirm={this.handlePostReplyConfirm}
            onPostError={this.handlePostReplyError}
            onPostProvisional={this.handlePostReplyProvisional}
            rootRef={(ref) => (this.$replyForm = ref)}
            style={this.state.hideReplyForm ? { display: "none" } : null}
          />
        )}

        {this.props.showChildren &&
          children.map((comment, index) => {
            return (
              <Comment
                authenticated={this.props.authenticated}
                user={this.props.user}
                vote={this.props.vote}
                delete={this.props.delete}
                key={comment.hashid}
                comment={comment}
                depth={this.props.depth + 1}
                onMediaEnded={this.handleMediaOnEnded}
                sparkAuthorHashid={this.props.sparkAuthorHashid}
                sparkClosed={this.props.sparkClosed}
                sparkClosingCommentHashid={this.props.sparkClosingCommentHashid}
                sparkHasClosingComment={this.props.sparkHasClosingComment}
                sparkHashid={this.props.sparkHashid}
                sparkVotesRemaining={this.props.sparkVotesRemaining}
                highlight={comment.hashid === ""}
                ref={(ref) => (this.childRefs[index] = ref)}
                handleChangePlaying={this.props.handleChangePlaying}
                handlePlayTrack={this.props.handlePlayTrack}
                isTrackPlaying={this.props.isTrackPlaying}
                trackValues={this.props.trackValues}
                selectedComments={this.props.selectedComments}
                selectedCommentsHashids={this.props.selectedCommentsHashids}
                handleSelectComment={this.props.handleSelectComment}
              />
            );
          })}

        {!!this.props.childOfTheClosingComment.length &&
          this.props.childOfTheClosingComment.map((comment, index) => {
            if (comment.media === null) {
              return (
                <Comment
                  authenticated={this.props.authenticated}
                  user={comment.user}
                  key={comment.hashid}
                  comment={comment}
                  depth={1}
                  ref={(ref) => (this.childRefs[index] = ref)}
                  closingCommentThread
                />
              );
            }
            return "";
          })}
      </div>
    );
  }
}

const VOTE_MUTATION = gql`
  mutation Vote($hashid: ID!) {
    vote(hashid: $hashid) {
      hashid
      is_voted_on_by_me
      spark {
        hashid
        my_votes_remaining
      }
      votes_count
    }
  }
`;

const UNVOTE_MUTATION = gql`
  mutation Unvote($hashid: ID!) {
    unvote(hashid: $hashid) {
      hashid
      is_voted_on_by_me
      spark {
        hashid
        my_votes_remaining
      }
      votes_count
    }
  }
`;

const DELETE_COMMENT_MUTATION = gql`
  mutation deleteComment($hashid: ID!) {
    deleteComment(hashid: $hashid) {
      children {
        hashid
      }
      created_at
      hashid
      id
      status
      is_voted_on_by_me
      media {
        hashid
        is_processed
        square_image_sources {
          url
          width
        }
        type
        video_sources {
          type
          url
        }
      }
      message
      permalink
      spark {
        hashid
        user {
          hashid
        }
      }
      user {
        avatar {
          ... on Media {
            hashid
            is_processed
            square_image_sources {
              url
              width
            }
            type
          }
          ... on Url {
            url
          }
        }
        handle
        hashid
      }
      votes_count
    }
  }
`;

const DataBackedComment = compose(
  graphql(VOTE_MUTATION, {
    props: ({ ownProps, mutate }) => ({
      vote: function (variables) {
        return mutate({
          optimisticResponse: {
            vote: {
              __typename: "Comment",
              hashid: ownProps.comment.hashid,
              is_voted_on_by_me: true,
              spark: {
                __typename: "Spark",
                hashid: ownProps.sparkHashid,
                my_votes_remaining: ownProps.sparkVotesRemaining - 1,
              },
              votes_count: ownProps.comment.votes_count + 1,
            },
          },
          variables: variables,
        });
      },
    }),
  }),
  graphql(UNVOTE_MUTATION, {
    props: ({ ownProps, mutate }) => ({
      unvote: function (variables) {
        return mutate({
          optimisticResponse: {
            unvote: {
              __typename: "Comment",
              hashid: ownProps.comment.hashid,
              is_voted_on_by_me: false,
              spark: {
                __typename: "Spark",
                hashid: ownProps.sparkHashid,
                my_votes_remaining: ownProps.sparkVotesRemaining + 1,
              },
              votes_count: ownProps.comment.votes_count - 1,
            },
          },
          variables: variables,
        });
      },
    }),
  }),

  graphql(DELETE_COMMENT_MUTATION, {
    props: ({ ownProps, mutate }) => ({
      delete: function (variables) {
        return mutate({
          optimisticResponse: {
            deleteComment: {
              __typename: "Comment",
              id: variables.id,
              hashid: ownProps.comment.hashid,
              status: "deleted",
            },
          },
          update: (gqlStore, { data: { deleteComment } }) => {
            const data = gqlStore.readQuery({
              query: SPARK_QUERY,
              variables: {
                hashid: ownProps.comment.spark.hashid,
              },
            });

            const removeDeletedComment = (comments) => {
              return comments
                .filter((comment) => comment.hashid !== deleteComment.hashid)
                .map((comment) => ({
                  ...comment,
                  children: removeDeletedComment(comment.children || []),
                }));
            };

            data.spark.opening_comment.children = removeDeletedComment(
              data.spark.opening_comment.children
            );

            gqlStore.writeQuery({
              query: SPARK_QUERY,
              data: data,
              variables: {
                hashid: ownProps.comment.spark.hashid,
              },
            });
          },
          variables: variables,
        });
      },
    }),
  }),
  connect(mapStateToProps, null)
)(Comment);

export default DataBackedComment;

class DumbSimpleComment extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    comment: PropTypes.object.isRequired,
    showVotes: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    showVotes: false,
  };

  render() {
    const { className, comment, showVotes, ...otherProps } = this.props;

    return (
      <div
        className={classNames(styles.simpleComment, className)}
        {...otherProps}
      >
        <div className={classNames(styles.commentWrapper)}>
          <Avatar
            size="sm"
            user={comment.user}
            className={classNames(styles.avatar)}
          />
          <div className={classNames(styles.content)}>
            <Handle handle={comment.user.handle} />
            {showVotes && (
              <div className={styles.voteCounter}>
                <span className={styles.votesCount}>{comment.votes_count}</span>
                <Icon className={styles.votesIcon} icon="heart" />
              </div>
            )}
            <p className={classNames(styles.message)}>{comment.message}</p>
            {comment.media && (
              <Media media={comment.media} shape="square" size="lg" />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const COMMENT_QUERY = gql`
  query Comment($hashid: ID!) {
    comment(hashid: $hashid) {
      created_at
      hashid
      media {
        hashid
        is_processed
        square_image_sources {
          url
          width
        }
        type
        video_sources {
          type
          url
        }
      }
      message
      user {
        avatar {
          ... on Media {
            hashid
            is_processed
            square_image_sources {
              url
              width
            }
            type
          }
          ... on Url {
            url
          }
        }
        handle
        hashid
      }
      votes_count
    }
  }
`;

class DataBackedSimpleComment extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      comment: PropTypes.object,
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
    }),
  };

  render() {
    const { data, ...otherProps } = this.props;

    if (data.error) {
      return <div>Error</div>;
    }

    if (!data.comment && data.loading) {
      return <Loading />;
    }

    return <DumbSimpleComment comment={data.comment} {...otherProps} />;
  }
}

export const GraphqlBackedSimpleComment = graphql(COMMENT_QUERY, {
  options: (props) => ({
    variables: {
      hashid: props.hashid,
    },
  }),
})(DataBackedSimpleComment);

export { Comment, DumbSimpleComment, DataBackedSimpleComment };
