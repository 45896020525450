// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-TagsInput-elements-Tag-style__root__HJ9Ce{background-color:#f4f4f2;color:#f75a00;font-size:1em;line-height:1.8em;height:1.8em;display:inline-block;padding-left:.5em;border-radius:10px;overflow:hidden}.components-TagsInput-elements-Tag-style__root__HJ9Ce .components-TagsInput-elements-Tag-style__nobtn__a7eo6{border:none;padding:0 .4em;margin-left:.4em;line-height:1.8em;height:1.8em;font-weight:bold;display:inline-block}.components-TagsInput-elements-Tag-style__root__HJ9Ce .components-TagsInput-elements-Tag-style__btn__z9bUc{border:none;background-color:rgba(0,0,0,.1);color:#9b9b9b;padding:0 .4em;margin-left:.4em;line-height:1.8em;height:1.8em;font-weight:bold;display:inline-block}.components-TagsInput-elements-Tag-style__root__HJ9Ce .components-TagsInput-elements-Tag-style__btn__z9bUc:hover{background-color:rgba(0,0,0,.7);color:#fff}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-TagsInput-elements-Tag-style__root__HJ9Ce`,
	"nobtn": `components-TagsInput-elements-Tag-style__nobtn__a7eo6`,
	"btn": `components-TagsInput-elements-Tag-style__btn__z9bUc`
};
export default ___CSS_LOADER_EXPORT___;
