import {getApiUrl} from './config';

let promise = null;

/**
 * Get the app configuration
 *
 * @return {Promise} A promise for an object containing app configuration
 */
export default function getConfig() {
  if (promise === null) {
    promise = fetch(getApiUrl() + '/v1/config')
    .then(response => response.json());
  }
  return promise;
}
