import classNames from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import React from 'react';

import Modal from 'components/Modal';
import RegistrationForm from 'components/RegistrationForm';

const mapStateToProps = function(state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
  };
};

class CompleteRegistrationModal extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool,
    user: PropTypes.object,
  }

  static defaultProps = {
    authenticated: false,
    user: null,
  }

  getEmail = () => {
    if (this.props.user) {
      return this.props.user.email;
    }
    return null;
  }

  shouldBeOpen = () => {
    return !!(this.props.authenticated && this.props.user && this.props.user.handle == null);
  }

  render() {
    return (
      <Modal
        contentLabel="Complete registration"
        isOpen={this.shouldBeOpen()}
        shouldCloseOnOverlayClick={false}
        showCloseButton={false}
      >
        <h1 className={classNames("title", "title-md", "text-secondary")}>
          Complete registration
        </h1>
        <RegistrationForm
          email={this.getEmail()}
        />
      </Modal>
    );
  }
}

export default connect(mapStateToProps, null)(CompleteRegistrationModal);
