// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-TagsInput-style__root__nrOR9{margin-bottom:15px}.components-TagsInput-style__root__nrOR9 .components-TagsInput-style__field__Fj814{position:relative}.components-TagsInput-style__root__nrOR9 .components-TagsInput-style__input__xXe_1{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-flow:row wrap;flex-flow:row wrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:3px;width:100%;min-height:41px;padding:0 6px;font-size:14px;line-height:1.5;background-image:none;cursor:text;background-color:#f9f9f9;border-radius:8px}.components-TagsInput-style__root__nrOR9 .components-TagsInput-style__text__kP7kO{font-size:14px;display:inline-block;outline:none;border:none;line-height:1.5;width:0;background-color:rgba(0,0,0,0);margin:0 0 0 5px;padding:0}.components-TagsInput-style__root__nrOR9 .components-TagsInput-style__textarea__I_AdP{outline:none;background-color:rgba(0,0,0,0);display:inline-block;width:100%;border:none;line-height:1.5;font-size:14px;font-weight:600;resize:none;padding:0;margin:0;overflow:hidden}.components-TagsInput-style__root__nrOR9 .components-TagsInput-style__tag__r6TRu{display:inline-block}.components-TagsInput-style__root__nrOR9 .components-TagsInput-style__label__PeTxC{font-size:14px;line-height:1.5;color:#333;font-weight:bold;margin-bottom:3px}.components-TagsInput-style__root__nrOR9 .components-TagsInput-style__placeholder__bnCNX{font-size:14px;color:gray;position:absolute;top:50%;left:10px;-webkit-transform:translateY(-50%);transform:translateY(-50%)}.components-TagsInput-style__textAreaWarning__uk2b3{border-radius:8px;border:1px solid red}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-TagsInput-style__root__nrOR9`,
	"field": `components-TagsInput-style__field__Fj814`,
	"input": `components-TagsInput-style__input__xXe_1`,
	"text": `components-TagsInput-style__text__kP7kO`,
	"textarea": `components-TagsInput-style__textarea__I_AdP`,
	"tag": `components-TagsInput-style__tag__r6TRu`,
	"label": `components-TagsInput-style__label__PeTxC`,
	"placeholder": `components-TagsInput-style__placeholder__bnCNX`,
	"textAreaWarning": `components-TagsInput-style__textAreaWarning__uk2b3`
};
export default ___CSS_LOADER_EXPORT___;
