export const getEnvironment = () => {
  switch (window.location.hostname) {
    case "app.sparkit.buzz":
      return "prod";
    default:
      return window.location.hostname.split(".")[1];
  }
};

export const getApiUrl = () => {
  const environment = getEnvironment();

  switch (environment) {
    case "prod":
      return "https://api.sparkit.buzz";
    case "dev":
    case "preview":
      return "https://api.dev.sparkit.buzz";
    case "local":
      return process.env.API_URL;
    default:
      return `${window.location.protocol}//api.${environment}.sparkit.buzz`;
  }
};

export const getVersion = () => process.env.VERSION;
