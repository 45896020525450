import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import styles from './style.scss';

export default class Loading extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'white']).isRequired,
    inline: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
  }

  static defaultProps = {
    className: '',
    color: 'primary',
    inline: false,
    size: "md",
  }

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  getSize() {
    switch (this.props.size) {
      case 'sm':
        return 14;
      case 'lg':
        return 100;
    }

    return 100;
  }

  render() {
    const {
      className,
      color,
      inline,
    } = this.props;

    const style = {
      height: this.getSize(),
      width: this.getSize(),
    };

    const children = [];
    for (let i = 1; i <= 13; i++) {
      children.push(<div key={i} className={classNames(styles['circle' + i], styles.child)} />);
    }

    return (
      <div
        className={classNames(styles.root, styles[color], className)}
      >
        <div
          className={classNames(styles.inner, inline && styles.inline)}
          style={style}
        >
          {children}
        </div>
      </div>
    );
  }
}
