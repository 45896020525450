import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Avatar from "components/Avatar";
import Handle from "components/Handle";
import Loading from "components/Loading";
import Icon from "components/Icon";

import { GraphqlBackedMedia } from "components/Media";
import { GraphqlBackedVideoFrameChooser } from "components/VideoFrameChooser";

import styles from "./style.scss";
import CommentCardWithData from "../../../../components/CommentCard";
import VoiceRecorder from "../../record";

export default class ViewShare extends React.Component {
  static propTypes = {
    chosenPosterUrl: PropTypes.string,
    className: PropTypes.string,
    mediaHashid: PropTypes.string.isRequired,
    mediaType: PropTypes.string,
    message: PropTypes.string,
    onNavigate: PropTypes.func,
    onSubmit: PropTypes.func,
    style: PropTypes.object,
    thumbnailIndex: PropTypes.number,
    onThumbnailIndexChange: PropTypes.func.isRequired,
    setPosters: PropTypes.func,
    posters: PropTypes.array,
    handleAddPoster: PropTypes.func,
    data: PropTypes.shape({
      comment: PropTypes.object,
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
    }),

    handleChangePlaying: PropTypes.func,
    handlePlayTrack: PropTypes.func,
    isTrackPlaying: PropTypes.bool,
    trackValues: PropTypes.object,
    handleTrackPause: PropTypes.func,
  };

  static defaultProps = {
    chosenPosterUrl: null,
    className: "",
    mediaType: "",
    message: "",
    onNavigate: (f) => f,
    onSubmit: (f) => f,
    style: {},
  };

  constructor() {
    super();

    this.$form = null;

    this.state = {
      mediaProcessed: null,
    };
  }

  handleFormSubmit = (event) => {
    event.preventDefault();

    const form = this.$form.elements;

    const shareOn = [];
    if (form.facebook && form.facebook.checked) {
      shareOn.push("facebook");
    }
    if (form.twitter && form.twitter.checked) {
      shareOn.push("twitter");
    }

    this.props.onSubmit(shareOn);
  };

  handleNavigate = (index) => {
    this.props.onNavigate(index);
  };

  onProcessedStatusChange = (processed) => {
    this.setState({
      mediaProcessed: processed,
    });
  };

  isUrlInMessage = (message) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = message.match(urlRegex);
    if (!matches) {
      return false;
    }
    return true;
  };

  findUrlInMessage = (message) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = message.match(urlRegex);
    if (!matches) {
      return <React.Fragment></React.Fragment>;
    }
    return matches.map((url, index) => (
      <CommentCardWithData
        key={index}
        url={url}
        className={styles.commentCard}
        handleChangePlaying={this.props.handleChangePlaying}
        handlePlayTrack={this.props.handlePlayTrack}
        isTrackPlaying={this.props.isTrackPlaying}
        trackValues={this.props.trackValues}
        hidePlayBtn
      />
    ));
  };

  render() {
    const {
      chosenPosterUrl,
      className,
      mediaHashid,
      mediaType,
      message,
      thumbnailIndex,
      onThumbnailIndexChange,
      style,
      data,
    } = this.props;

    let poster = null;
    if (mediaType === "video" && this.state.mediaProcessed && chosenPosterUrl) {
      poster = chosenPosterUrl;
    }
    if (!data || data.error) {
      return <div>Error</div>;
    }

    if (!data.comment && data.loading) {
      return <Loading />;
    }

    return (
      <div className={classNames(styles.root, className)} style={style}>
        <div className={styles.content}>
          {mediaHashid && (
            <div>
              <GraphqlBackedMedia
                size="lg"
                hashid={mediaHashid}
                onProcessedStatusChange={this.onProcessedStatusChange}
                poster={poster}
              />
              {mediaType === "video" &&
                this.state.mediaProcessed &&
                mediaHashid && (
                  <div className={styles.thumbnail}>
                    <h3 className={styles.postersTopText}>Other Video Clips</h3>
                    <div className={styles.frameChooserWrapper}>
                      <GraphqlBackedVideoFrameChooser
                        hashid={mediaHashid}
                        index={thumbnailIndex}
                        onIndexChange={onThumbnailIndexChange}
                        isSparkCollab
                        setPosters={this.props.setPosters}
                        posters={this.props.posters}
                        handleAddPoster={this.props.handleAddPoster}
                      />
                    </div>
                  </div>
                )}
            </div>
          )}
          {/* <hr className={styles.separator} /> */}
          {this.isUrlInMessage(this.props.message) && (
            <div className={styles.audioCardsWrapper}>
              <p className={styles.titleText}>Fan Music Clip To Add</p>
              <div className={styles.musicCardsWrap}>
                {this.findUrlInMessage(this.props.message)}
              </div>
            </div>
          )}
          <div>
            <p className={styles.titleText}>Collab Audio To Add</p>
            <VoiceRecorder />
          </div>
          <form
            ref={(ref) => (this.$form = ref)}
            onSubmit={this.handleFormSubmit}
            className={styles.btnsWrap}
          >
            <button className={styles.btn}>Save</button>
            <button className={styles.btn}>Next</button>
            {/* <div>
              <h1>Share This Post</h1>
              <ShareOptions />
            </div> */}
            {/* <Navigator
              label="Post"
              onNavigate={this.handleNavigate}
            /> */}
          </form>
        </div>
      </div>
    );
  }
}
