import PropTypes from "prop-types";
import React from "react";

import classNames from "classnames";

import { compose, graphql } from "react-apollo";
import gql from "graphql-tag";

import Main from "components/Main";
import HeaderForAuthorizedUsers from "./../../components/HeaderForAuthorizedUsers";

import Button from "components/Button";
import FormError from "components/FormError";

import Loading from "sections/Loading";

import handleGqlError from "data/graphqlErrors";

import styles from "./style.scss";
import PasswordInput from "../../components/PasswordInput";

class SettingsPassword extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
      me: PropTypes.object,
    }),
    mutate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: "",
  };

  constructor() {
    super();

    this.state = {
      currentPassword: "",
      errors: null,
      newPassword: "",
      newPasswordConfirmation: "",
      passwordChanged: false,
    };
  }

  handleFormSubmit = (event) => {
    const { currentPassword, newPassword } = this.state;

    event.preventDefault();

    this.props
      .mutate({
        variables: {
          currentPassword: currentPassword,
          password: newPassword,
        },
      })
      .then(() => {
        this.setState({
          currentPassword: "",
          errors: null,
          newPassword: "",
          newPasswordConfirmation: "",
          passwordChanged: true,
        });
      })
      .catch((error) => {
        const e = handleGqlError(error, ["current_password", "password"]);
        this.setState({
          errors: e,
        });
      });
  };

  handleCurrentChange = (event) => {
    this.setState({
      currentPassword: event.target.value,
    });
  };

  handleNewChange = (event) => {
    this.setState({
      newPassword: event.target.value,
    });
  };

  handleNewConfirmationInput = (event) => {
    const input = event.target;

    this.setState(
      {
        newPasswordConfirmation: input.value,
      },
      () => {
        if (
          this.state.newPasswordConfirmation.length &&
          this.state.newPasswordConfirmation !== this.state.newPassword
        ) {
          input.setCustomValidity("Passwords must match.");
        } else {
          input.setCustomValidity("");
        }
      }
    );
  };

  render() {
    const { className, data } = this.props;

    const headProps = {
      leftContent: {
        content: [{ type: "link", link: "/profile", icon: "back" }],
      },
      heading: "Change password",
    };

    if (!data.me) {
      if (data.loading) {
        return <Loading headProps={headProps} />;
      }

      headProps.leftContent.content[0].link = "/";

      return (
        <div>
          {/* <Head {...headProps} /> */}
          <HeaderForAuthorizedUsers {...headProps} />
          <Main full>
            <p>Not logged in</p>
          </Main>
        </div>
      );
    }

    headProps.back = `/profile/${data.me.handle}`;

    return (
      <div>
        {/* <Head {...headProps} /> */}
        <HeaderForAuthorizedUsers {...headProps} />
        <Main className="bg-off-white">
          <div className={classNames("color-dark-grey", styles.wrapper)}>
            <div
              className={classNames(
                "wrap__inner",
                "wrap__gutter",
                "wrap__gutter--padded",
                className,
                styles.content
              )}
            >
              {/* <h2 className="title title-md">Change password</h2> */}
              <form
                onSubmit={this.handleFormSubmit}
                className={classNames("m-t-20")}
              >
                {this.state.passwordChanged && (
                  <div className="m-b-20 bg-secondary">Password updated!</div>
                )}
                {this.props.data.me && this.props.data.me.has_password && (
                  <div>
                    {this.state.errors &&
                      this.state.errors.fields &&
                      this.state.errors.fields.current_password && (
                        <FormError
                          errors={this.state.errors.fields.current_password}
                        />
                      )}
                    <PasswordInput
                      title="Current password"
                      autoComplete="current-password"
                      value={this.state.currentPassword}
                      onChange={this.handleCurrentChange}
                    />
                    {/* <label>
                      Current password
                      <input
                        type="password"
                        autoComplete="current-password"
                        required
                        value={this.state.currentPassword}
                        onChange={this.handleCurrentChange}
                      />
                    </label> */}
                  </div>
                )}

                {this.state.errors &&
                  this.state.errors.fields &&
                  this.state.errors.fields.password && (
                    <FormError errors={this.state.errors.fields.password} />
                  )}
                <PasswordInput
                  title="New password"
                  minLength="8"
                  autoComplete="new-password"
                  value={this.state.newPassword}
                  onChange={this.handleNewChange}
                />
                <PasswordInput
                  title="Confirm new password"
                  autoComplete="new-password"
                  value={this.state.newPasswordConfirmation}
                  onInput={this.handleNewConfirmationInput}
                />
                {/* <label>
                  New password
                  <input
                    type="password"

                    required
                    minLength="8"
                    autoComplete="new-password"
                    value={this.state.newPassword}
                    onChange={this.handleNewChange}
                  />
                </label> */}

                {/* <label>
                  Confirm new password
                  <input
                    type="password"
                    autoComplete="new-password"
                    required
                    value={this.state.newPasswordConfirmation}
                    onInput={this.handleNewConfirmationInput}
                  />
                </label> */}
                <div className="align-c">
                  <Button
                    color="primary"
                    type="submit"
                    className={styles.button}
                  >
                    Update
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Main>
      </div>
    );
  }
}

const PROFILE_QUERY = gql`
  query HasPassword {
    me {
      handle
      has_password
      hashid
    }
  }
`;

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($currentPassword: String, $password: String!) {
    editProfile(current_password: $currentPassword, password: $password) {
      has_password
      hashid
    }
  }
`;

export default compose(
  graphql(PROFILE_QUERY),
  graphql(CHANGE_PASSWORD_MUTATION)
)(SettingsPassword);
