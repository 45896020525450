import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./style.scss";
import HelpIcon from "../HelpIcon";
import FlagsDropdown from "./flagsDropdown";

const COUNTRIES = [
  { name: "Unknown", shortName: "xx", number: "+0" },

  { name: "Afghanistan", shortName: "af", number: "+93" },
  { name: "Aland Islands", shortName: "ax", number: "+358" },
  { name: "Albania", shortName: "al", number: "+355" },
  { name: "Algeria", shortName: "dz", number: "+213" },
  { name: "American Samoa", shortName: "as", number: "+1-684" },
  { name: "Andorra", shortName: "ad", number: "+376" },
  { name: "Angola", shortName: "ao", number: "+244" },
  { name: "Anguilla", shortName: "ai", number: "+1-264" },
  { name: "Antarctica", shortName: "aq", number: "+93" },
  { name: "Antigua and Barbuda", shortName: "ag", number: "+1268" },
  { name: "Argentina", shortName: "ar", number: "+54" },
  { name: "Armenia", shortName: "am", number: "+374" },
  { name: "Aruba", shortName: "aw", number: "+297" },
  { name: "Australia", shortName: "au", number: "+61" },
  { name: "Austria", shortName: "at", number: "+43" },
  { name: "Azerbaijan", shortName: "az", number: "+994" },

  { name: "Bahamas", shortName: "bs", number: "+1-242" },
  { name: "Bahrain", shortName: "bh", number: "+973" },
  { name: "Bangladesh", shortName: "bd", number: "+880" },
  { name: "Barbados", shortName: "bb", number: "+1-246" },
  { name: "Belarus", shortName: "by", number: "+375" },
  { name: "Belgium", shortName: "be", number: "+32" },
  { name: "Belize", shortName: "bz", number: "+501" },
  { name: "Benin", shortName: "bj", number: "+229" },
  { name: "Bermuda", shortName: "bm", number: "+1-441" },
  { name: "Bhutan", shortName: "bt", number: "+975" },
  { name: "Bolivia", shortName: "bo", number: "+591" },
  { name: "Bonaire", shortName: "bq", number: "+599" },
  { name: "Bosnia and Herzegovina", shortName: "ba", number: "+387" },
  { name: "Botswana", shortName: "bw", number: "+267" },
  { name: "Brazil", shortName: "br", number: "+55" },
  { name: "British Indian Ocean Territory", shortName: "io", number: "+246" },
  { name: "British Virgin Islands", shortName: "vg", number: "+1-284" },
  { name: "Brunei", shortName: "bn", number: "+673" },
  { name: "Bulgaria", shortName: "bg", number: "+359" },
  { name: "Burkina Faso", shortName: "bf", number: "+226" },
  { name: "Burundi", shortName: "bi", number: "+257" },

  { name: "Cambodia", shortName: "kh", number: "+855" },
  { name: "Cameroon", shortName: "cm", number: "+237" },

  { name: "Canada", shortName: "ca", number: "+1" },

  { name: "Cape Verde", shortName: "cv", number: "+238" },
  { name: "Cayman Islands", shortName: "ky", number: "+1-345" },
  { name: "Central African Republic", shortName: "cf", number: "+236" },
  { name: "Chad", shortName: "td", number: "+235" },
  { name: "Chile", shortName: "cl", number: "+56" },
  { name: "China", shortName: "cn", number: "+86" },
  { name: "Christmas Island", shortName: "cx", number: "+61" },
  { name: "Cocos Islands", shortName: "cc", number: "+61" },
  { name: "Colombia", shortName: "co", number: "+57" },
  { name: "Comoros", shortName: "km", number: "+269" },
  { name: "Cook Islands", shortName: "ck", number: "+682" },
  { name: "Costa Rica", shortName: "cr", number: "+506" },
  { name: "Croatia", shortName: "hr", number: "+385" },
  { name: "Cuba", shortName: "cu", number: "+53" },
  { name: "Curacao", shortName: "cw", number: "+599" },
  { name: "Cyprus", shortName: "cy", number: "+357" },
  { name: "Czech Republic", shortName: "cz", number: "+420" },
  { name: "Democratic Republic of the Congo", shortName: "cd", number: "+243" },
  { name: "Denmark", shortName: "dk", number: "+45" },
  { name: "Djibouti", shortName: "dj", number: "+253" },
  { name: "Dominica", shortName: "dm", number: "+1-767" },
  { name: "Dominican Republic", shortName: "do", number: "+1-809" },
  { name: "East Timor", shortName: "tl", number: "+670" },
  { name: "Ecuador", shortName: "ec", number: "+593" },
  { name: "Egypt", shortName: "eg", number: "+20" },
  { name: "El Salvador", shortName: "sv", number: "+503" },
  { name: "Equatorial Guinea", shortName: "gq", number: "+240" },
  { name: "Eritrea", shortName: "er", number: "+291" },
  { name: "Estonia", shortName: "ee", number: "+372" },
  { name: "Ethiopia", shortName: "et", number: "+251" },
  { name: "Falkland Islands", shortName: "fk", number: "+500" },
  { name: "Faroe Islands", shortName: "fo", number: "+298" },
  { name: "Fiji", shortName: "fj", number: "+679" },
  { name: "Finland", shortName: "fi", number: "+358" },
  { name: "France", shortName: "fr", number: "+33" },
  { name: "French Polynesia", shortName: "pf", number: "+689" },
  { name: "Gabon", shortName: "ga", number: "+241" },
  { name: "Gambia", shortName: "gm", number: "+220" },
  { name: "Georgia", shortName: "ge", number: "+995" },
  { name: "Germany", shortName: "de", number: "+49" },
  { name: "Ghana", shortName: "gh", number: "+233" },
  { name: "Gibraltar", shortName: "gi", number: "+350" },
  { name: "Greece", shortName: "gr", number: "+30" },
  { name: "Greenland", shortName: "gl", number: "+299" },
  { name: "Grenada", shortName: "gd", number: "+1-473" },
  { name: "Guam", shortName: "gu", number: "+1-671" },
  { name: "Guatemala", shortName: "gt", number: "+502" },
  { name: "Guernsey", shortName: "gg", number: "+44-1481" },
  { name: "Guinea", shortName: "gn", number: "+224" },
  { name: "Guinea-Bissau", shortName: "gw", number: "+245" },
  { name: "Guyana", shortName: "gy", number: "+592" },
  { name: "Haiti", shortName: "ht", number: "+509" },

  { name: "Honduras", shortName: "hn", number: "+504" },
  { name: "Hong Kong", shortName: "hk", number: "+852" },
  { name: "Hungary", shortName: "hu", number: "+36" },
  { name: "Iceland", shortName: "is", number: "+354" },
  { name: "India", shortName: "in", number: "+91" },
  { name: "Indonesia", shortName: "id", number: "+62" },
  { name: "Iran", shortName: "ir", number: "+98" },
  { name: "Iraq", shortName: "iq", number: "+964" },
  { name: "Ireland", shortName: "ie", number: "+353" },
  { name: "Isle of Man", shortName: "im", number: "+44-1624" },
  { name: "Israel", shortName: "il", number: "+972" },
  { name: "Italy", shortName: "it", number: "+39" },
  { name: "Ivory Coast", shortName: "ci", number: "+225" },
  { name: "Jamaica", shortName: "jm", number: "+1-876" },
  { name: "Japan", shortName: "jp", number: "+81" },
  { name: "Jersey", shortName: "je", number: "+44-1534" },
  { name: "Jordan", shortName: "jo", number: "+962" },
  { name: "Kazakhstan", shortName: "kz", number: "+7" },
  { name: "Kenya", shortName: "ke", number: "+254" },
  { name: "Kiribati", shortName: "ki", number: "+686" },
  { name: "Kosovo", shortName: "xk", number: "+383" },
  { name: "Kuwait", shortName: "kw", number: "+965" },
  { name: "Kyrgyzstan", shortName: "kg", number: "+996" },
  { name: "Laos", shortName: "la", number: "+856" },
  { name: "Latvia", shortName: "lv", number: "+371" },
  { name: "Lebanon", shortName: "lb", number: "+961" },
  { name: "Lesotho", shortName: "ls", number: "+266" },
  { name: "Liberia", shortName: "lr", number: "+231" },
  { name: "Libya", shortName: "ly", number: "+218" },
  { name: "Liechtenstein", shortName: "li", number: "+423" },
  { name: "Lithuania", shortName: "lt", number: "+370" },
  { name: "Luxembourg", shortName: "lu", number: "+352" },
  { name: "Macau", shortName: "mo", number: "+853" },
  { name: "Macedonia", shortName: "mk", number: "+389" },
  { name: "Madagascar", shortName: "mg", number: "+261" },
  { name: "Malawi", shortName: "mw", number: "+265" },
  { name: "Malaysia", shortName: "my", number: "+60" },
  { name: "Maldives", shortName: "mv", number: "+960" },
  { name: "Mali", shortName: "ml", number: "+223" },
  { name: "Malta", shortName: "mt", number: "+356" },
  { name: "Marshall Islands", shortName: "mh", number: "+692" },
  { name: "Mauritania", shortName: "mr", number: "+222" },
  { name: "Mauritius", shortName: "mu", number: "+230" },
  { name: "Mayotte", shortName: "yt", number: "+262" },
  { name: "Mexico", shortName: "mx", number: "+52" },
  { name: "Micronesia", shortName: "fm", number: "+691" },
  { name: "Moldova", shortName: "md", number: "+373" },
  { name: "Monaco", shortName: "mc", number: "+377" },
  { name: "Mongolia", shortName: "mn", number: "+976" },
  { name: "Montenegro", shortName: "me", number: "+382" },
  { name: "Montserrat", shortName: "ms", number: "+1-664" },
  { name: "Morocco", shortName: "ma", number: "+212" },
  { name: "Mozambique", shortName: "mz", number: "+258" },
  { name: "Myanmar", shortName: "mm", number: "+95" },
  { name: "Namibia", shortName: "na", number: "+264" },
  { name: "Nauru", shortName: "nr", number: "+674" },
  { name: "Nepal", shortName: "np", number: "+977" },
  { name: "Netherlands", shortName: "nl", number: "+31" },
  { name: "New Caledonia", shortName: "nc", number: "+687" },
  { name: "New Zealand", shortName: "nz", number: "+64" },
  { name: "Nicaragua", shortName: "ni", number: "+505" },
  { name: "Niger", shortName: "ne", number: "+227" },
  { name: "Nigeria", shortName: "ng", number: "+234" },
  { name: "Niue", shortName: "nu", number: "+683" },
  { name: "Norfolk Island", shortName: "nf", number: "+672" },
  { name: "North Korea", shortName: "kp", number: "+850" },
  { name: "Northern Mariana Islands", shortName: "mp", number: "+1-670" },
  { name: "Norway", shortName: "no", number: "+47" },
  { name: "Oman", shortName: "om", number: "+968" },
  { name: "Pakistan", shortName: "pk", number: "+92" },
  { name: "Palau", shortName: "pw", number: "+680" },
  { name: "Palestine", shortName: "ps", number: "+970" },
  { name: "Panama", shortName: "pa", number: "+507" },

  { name: "Papua New Guinea", shortName: "pg", number: "+675" },
  { name: "Paraguay", shortName: "py", number: "+595" },
  { name: "Peru", shortName: "pe", number: "+51" },
  { name: "Philippines", shortName: "ph", number: "+63" },
  { name: "Pitcairn Islands", shortName: "pn", number: "+64" },
  { name: "Poland", shortName: "pl", number: "+48" },
  { name: "Portugal", shortName: "pt", number: "+351" },
  { name: "Puerto Rico", shortName: "pr", number: "+1-787" },
  { name: "Qatar", shortName: "qa", number: "+974" },
  { name: "Republic of the Congo", shortName: "cg", number: "+242" },
  { name: "Reunion", shortName: "re", number: "+262" },
  { name: "Romania", shortName: "ro", number: "+40" },
  { name: "Russia", shortName: "ru", number: "+7" },
  { name: "Rwanda", shortName: "rw", number: "+250" },
  { name: "Saint Barthelemy", shortName: "bl", number: "+590" },
  { name: "Saint Helena", shortName: "sh", number: "+290" },
  { name: "Saint Kitts and Nevis", shortName: "kn", number: "+1-869" },
  { name: "Saint Lucia", shortName: "lc", number: "+1-758" },
  { name: "Saint Martin", shortName: "mf", number: "+590" },
  { name: "Saint Pierre and Miquelon", shortName: "pm", number: "+508" },
  {
    name: "Saint Vincent and the Grenadines",
    shortName: "vc",
    number: "+1-784",
  },
  { name: "Samoa", shortName: "ws", number: "+685" },
  { name: "San Marino", shortName: "sm", number: "+378" },
  { name: "Sao Tome and Principe", shortName: "st", number: "+239" },
  { name: "Saudi Arabia", shortName: "sa", number: "+966" },
  { name: "Senegal", shortName: "sn", number: "+221" },
  { name: "Serbia", shortName: "rs", number: "+381" },
  { name: "Seychelles", shortName: "sc", number: "+248" },
  { name: "Sierra Leone", shortName: "sl", number: "+232" },
  { name: "Singapore", shortName: "sg", number: "+65" },
  { name: "Sint Maarten", shortName: "sx", number: "+1-721" },
  { name: "Slovakia", shortName: "sk", number: "+421" },
  { name: "Slovenia", shortName: "si", number: "+386" },
  { name: "Solomon Islands", shortName: "sb", number: "+677" },
  { name: "Somalia", shortName: "so", number: "+252" },
  { name: "South Africa", shortName: "za", number: "+27" },
  { name: "South Korea", shortName: "kr", number: "+82" },
  { name: "South Sudan", shortName: "ss", number: "+211" },
  { name: "Spain", shortName: "es", number: "+34" },
  { name: "Sri Lanka", shortName: "lk", number: "+94" },
  { name: "Sudan", shortName: "sd", number: "+249" },
  { name: "Suriname", shortName: "sr", number: "+597" },
  { name: "Svalbard and Jan Mayen", shortName: "sj", number: "+47" },
  { name: "Swaziland", shortName: "sz", number: "+268" },
  { name: "Sweden", shortName: "se", number: "+46" },
  { name: "Switzerland", shortName: "ch", number: "+41" },
  { name: "Syria", shortName: "sy", number: "+963" },
  { name: "Taiwan", shortName: "tw", number: "+886" },
  { name: "Tajikistan", shortName: "tj", number: "+992" },
  { name: "Tanzania", shortName: "tz", number: "+255" },
  { name: "Thailand", shortName: "th", number: "+66" },
  { name: "Togo", shortName: "tg", number: "+228" },
  { name: "Tokelau", shortName: "tk", number: "+690" },
  { name: "Tonga", shortName: "to", number: "+676" },
  { name: "Trinidad and Tobago", shortName: "tt", number: "+1-868" },
  { name: "Tunisia", shortName: "tn", number: "+216" },
  { name: "Turkey", shortName: "tr", number: "+90" },
  { name: "Turkmenistan", shortName: "tm", number: "+993" },
  { name: "Turks and Caicos Islands", shortName: "tc", number: "+1-649" },
  { name: "Tuvalu", shortName: "tv", number: "+688" },
  { name: "U.S. Virgin Islands", shortName: "vi", number: "+1-340" },
  { name: "Uganda", shortName: "ug", number: "+256" },
  { name: "Ukraine", shortName: "ua", number: "+380" },
  { name: "United Arab Emirates", shortName: "ae", number: "+971" },
  { name: "United Kingdom", shortName: "gb", number: "+44" },
  { name: "United States", shortName: "us", number: "+1" },
  { name: "Uruguay", shortName: "uy", number: "+598" },
  { name: "Uzbekistan", shortName: "uz", number: "+998" },
  { name: "Vanuatu", shortName: "vu", number: "+678" },
  { name: "Vatican City", shortName: "va", number: "+379" },
  { name: "Venezuela", shortName: "ve", number: "+58" },
  { name: "Vietnam", shortName: "vn", number: "+84" },

  { name: "Wallis and Futuna", shortName: "wf", number: "+681" },
  { name: "Western Sahara", shortName: "eh", number: "+212" },
  { name: "Yemen", shortName: "ye", number: "+967" },
  { name: "Zambia", shortName: "zm", number: "+260" },
  { name: "Zimbabwe", shortName: "zw", number: "+263" },
];

const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return null;
  }

  const cleanedNumber = phoneNumber
    .substring(phoneNumber.length - 10)
    .replace(/\D/g, "");
  const maxLength = 10;

  if (cleanedNumber.length <= maxLength) {
    return cleanedNumber.replace(
      /(\d{3})(\d{0,3})(\d{0,4})/,
      function (match, p1, p2, p3) {
        let part1 = p1;
        let part2 = p2 ? "-" + p2 : "";
        let part3 = p3 ? "-" + p3 : "";
        return part1 + part2 + part3;
      }
    );
  }

  return cleanedNumber.slice(0, maxLength);
};

const PhoneNumberInput = ({
  className,
  value,
  setPhone,
  isCountryFlagShowed,
  withRequiredMark,
  helperText,
  name,
  title,
  placeholder,
  required,
  titleStyles,
  editProfile,
  ...otherProps
}) => {
  const [selectedCountry, setSelectedCountry] = useState({
    name: "Unknown",
    shortName: "xx",
    number: "+0",
  });
  const [displayedPhoneNumber, setDisplayedPhoneNumber] = useState(
    formatPhoneNumber(value)
  );

  const handleChangeCountry = (country) => {
    setSelectedCountry(country);
    if (!value) {
      return;
    }
    const phoneNumberWithoutDashes = displayedPhoneNumber.replace(/-/g, "");
    setPhone(`${country.number}${phoneNumberWithoutDashes}`);
  };

  const onChangePhoneNumber = (event) => {
    const inputNumber = event.target.value.replace(/\D/g, "");
    const maxLength = 10;
    let formattedNumber = "";

    if (inputNumber.length <= maxLength) {
      formattedNumber = inputNumber.replace(
        /(\d{3})(\d{0,3})(\d{0,4})/,
        function (match, p1, p2, p3) {
          let part1 = p1;
          let part2 = p2 ? "-" + p2 : "";
          let part3 = p3 ? "-" + p3 : "";
          return part1 + part2 + part3;
        }
      );
    } else {
      formattedNumber = inputNumber.slice(0, maxLength);
    }

    setDisplayedPhoneNumber(formattedNumber);
    setPhone(`${selectedCountry.number}${inputNumber}`);
  };

  useEffect(() => {
    if (editProfile) {
      if (value) {
        const countryCode = value.substring(0, value.length - 10);
        const foundCountry = COUNTRIES.find(
          (country) => country.number === countryCode
        );
        if (foundCountry) {
          setSelectedCountry(foundCountry);
        } else {
          setSelectedCountry({
            name: "Unknown",
            shortName: "xx",
            number: "+0",
          });
        }
      }
    }
  }, [value]);

  return (
    <div className={classNames(styles.phoneNumberInputContainer, className)}>
      <div
        className={classNames(
          styles.InputHeader,
          styles.headerWithQuestionIcon
        )}
      >
        <span className={classNames(styles.title, titleStyles)}>
          {title}
          {withRequiredMark && <p className={styles.requiredMark}>*</p>}
        </span>
        {helperText && <HelpIcon helperText={helperText} />}
      </div>
      <div className={classNames(styles.inputContainer)}>
        <FlagsDropdown
          countries={COUNTRIES.filter((country) => country.shortName !== "xx")}
          selectedValue={selectedCountry}
          onChange={handleChangeCountry}
        />

        <input
          type="text"
          className={classNames(styles.input, styles.phoneNumberInput)}
          placeholder={placeholder}
          name={name}
          {...otherProps}
          value={displayedPhoneNumber}
          onChange={onChangePhoneNumber}
          maxLength="12"
          required={required}
        />
      </div>
    </div>
  );
};

export default PhoneNumberInput;

PhoneNumberInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])])
    .isRequired,
  setPhone: PropTypes.func.isRequired,
  isCountryFlagShowed: PropTypes.bool,
  withRequiredMark: PropTypes.bool,
  helperText: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  title: PropTypes.string,
  titleStyles: PropTypes.string,
  editProfile: PropTypes.bool,
};

PhoneNumberInput.defaultProps = {
  isCountryFlagShowed: false,
  withRequiredMark: false,
  value: "",
  name: "phone",
  placeholder: "Phone",
  editProfile: false,
  required: false,
  title: "Phone",
};
