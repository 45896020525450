// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-AdModal-style__recommendation__dC_tA{margin:10px 0;font-weight:normal}.components-AdModal-style__crowdReferral__yEjO9{margin:10px 0}.components-AdModal-style__offerButton__PTE4D.components-AdModal-style__offerButton__PTE4D{margin-bottom:0;background-color:#f75a00;-webkit-box-shadow:0 8px 24px 0 rgba(247,90,0,.25);box-shadow:0 8px 24px 0 rgba(247,90,0,.25);text-transform:capitalize;border-radius:8px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.components-AdModal-style__root__pbRmr{width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.components-AdModal-style__cardContent__Gr_OH{min-width:285px;max-width:calc(285px + 10%);gap:18px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.components-AdModal-style__avatarAndNicknameCont__zoz8H span{margin-top:12px}.components-AdModal-style__text__nlg4H{font-weight:700;font-size:15px;line-height:14.52px;text-align:center}.components-AdModal-style__avatarImg__f7Sxd{width:52px;height:52px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recommendation": `components-AdModal-style__recommendation__dC_tA`,
	"crowdReferral": `components-AdModal-style__crowdReferral__yEjO9`,
	"offerButton": `components-AdModal-style__offerButton__PTE4D`,
	"root": `components-AdModal-style__root__pbRmr`,
	"cardContent": `components-AdModal-style__cardContent__Gr_OH`,
	"avatarAndNicknameCont": `components-AdModal-style__avatarAndNicknameCont__zoz8H`,
	"text": `components-AdModal-style__text__nlg4H`,
	"avatarImg": `components-AdModal-style__avatarImg__f7Sxd`
};
export default ___CSS_LOADER_EXPORT___;
