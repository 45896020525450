import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./style.scss";
import gql from "graphql-tag";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import Loading from "sections/Loading";
import LogInButton from "components/LogInButton";

const GET_FOLLOWERS = gql`
  query GetFollowers($handle: String!) {
    follower(handle: $handle) {
      handle
      hashid
      is_followed_by_me
      avatar {
        ... on Media {
          square_image_sources {
            url
          }
        }
        ... on Url {
          url
        }
      }
    }
  }
`;

const FOLLOW_USER_MUTATION = gql`
  mutation FollowUser($hashid: ID!) {
    followUser(hashid: $hashid) {
      followee {
        hashid
        is_followed_by_me
      }
    }
  }
`;

const UNFOLLOW_USER_MUTATION = gql`
  mutation UnfollowUser($hashid: ID!) {
    unfollowUser(hashid: $hashid) {
      followee {
        hashid
        is_followed_by_me
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
});

class Followers extends Component {
  state = {
    followers: [],
    logInButtonHandler: null,
    handleCloseModal: null,
    isModalShowed: false,
  };

  componentDidMount() {
    this.updateFollowers();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.data.loading && prevProps.data !== this.props.data) {
      this.updateFollowers();
    }
    if (this.props.authenticated && this.state.isModalShowed) {
      this.closeModalAndRefetch();
    }
  }

  updateFollowers = () => {
    const { data } = this.props;
    if (!data.loading && data.follower) {
      this.setState({ followers: data.follower });
    }
  };

  closeModalAndRefetch = () => {
    this.state.handleCloseModal?.();
    this.props.data.refetch();
    this.setState({ isModalShowed: false });
  };

  // eslint-disable-next-line consistent-return
  handleFollowToggle = (user, follow) => {
    if (!this.props.authenticated) {
      return this.handleSignInClick();
    }
    const action = follow ? this.props.followUser : this.props.unfollowUser;
    action(user.hashid);
  };

  handleSignInClick = () => {
    this.setState({ isModalShowed: true });
    this.state.logInButtonHandler?.();
  };

  render() {
    if (this.props.data.loading) {
      return <Loading />;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>Followers</div>
        <div className={styles.contentWrapper}>
          <div className={styles.title}>Followers</div>
          <div className={styles.content}>
            <div className={styles.items}>
              {this.state.followers.map((user) => (
                <div key={user.hashid} className={styles.userCardWrapper}>
                  <div className={styles.avatarAndHandleWrap}>
                    <img
                      src={
                        user.avatar.square_image_sources?.[0]?.url ||
                        user.avatar.url
                      }
                      className={styles.userAvatar}
                    />
                    <div className={styles.userHandle}>{user.handle}</div>
                  </div>
                  <button
                    className={
                      user.is_followed_by_me
                        ? styles.unfollowBtn
                        : styles.followBtn
                    }
                    onClick={() =>
                      this.handleFollowToggle(user, !user.is_followed_by_me)
                    }
                  >
                    {user.is_followed_by_me ? "Unfollow" : "Follow"}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        <LogInButton
          isButtonHidden
          handleSignInClickRef={(handler) =>
            this.setState({ logInButtonHandler: handler })
          }
          handleCloseModalRef={(handler) =>
            this.setState({ handleCloseModal: handler })
          }
        />
      </div>
    );
  }
}

Followers.propTypes = {
  authenticated: PropTypes.bool,
  followUser: PropTypes.func.isRequired,
  unfollowUser: PropTypes.func.isRequired,
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    follower: PropTypes.array,
    refetch: PropTypes.func.isRequired,
  }).isRequired,
};

export default compose(
  graphql(GET_FOLLOWERS, {
    options: (props) => ({ variables: { handle: props.match.params.hashid } }),
  }),
  graphql(FOLLOW_USER_MUTATION, {
    props: ({ mutate }) => ({
      followUser: (hashid) => mutate({ variables: { hashid } }),
    }),
  }),
  graphql(UNFOLLOW_USER_MUTATION, {
    props: ({ mutate }) => ({
      unfollowUser: (hashid) => mutate({ variables: { hashid } }),
    }),
  }),
  connect(mapStateToProps)
)(Followers);
