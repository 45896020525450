import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import Head from "components/Head";
import Main from "components/Main";

import Icon from "components/Icon";
import Button from "components/Button";
import Loading from "components/Loading";
import withLinkedSocialAccounts from "components/withLinkedSocialAccounts";

import LoadingPage from "sections/Loading";

import { login } from "~/auth";

const SettingsSocial = withLinkedSocialAccounts(
  class DumbSettingsSocial extends React.Component {
    static propTypes = {
      linkedSocialAccounts: PropTypes.shape({
        data: PropTypes.shape({
          error: PropTypes.object,
          loading: PropTypes.bool.isRequired,
          me: PropTypes.object,
          refetch: PropTypes.func.isRequired,
        }),
        getLabel: PropTypes.func.isRequired,
        getStatus: PropTypes.func.isRequired,
      }),
    };

    static defaultProps = {
      linkedSocialAccounts: {
        data: {
          error: null,
          loading: true,
          me: null,
        },
      },
    };

    constructor(props) {
      super(props);

      this.defaultChecked = {};
    }

    handleLoginClick = (provider) => {
      this.defaultChecked[provider] = true;
      login(provider).then(() => {
        this.props.linkedSocialAccounts.data.refetch();
      });
    };

    getWidget(provider) {
      switch (this.props.linkedSocialAccounts.getStatus(provider)) {
        case "rw":
          return (
            <span className="btn btn--sm btn--dark-grey text-none">
              Connected
            </span>
          );
        case "ro":
          return (
            <span className="btn btn--sm btn--dark-grey text-none">
              Connected
            </span>
          );
        case "unconnected":
          return (
            <Button
              size="sm"
              color="secondary"
              onClick={() => this.handleLoginClick(provider)}
            >
              Connect
            </Button>
          );
      }

      return null;
    }

    render() {
      const { linkedSocialAccounts } = this.props;

      const headProps = {
        back: "/profile",
        heading: "Link Your Social Accounts",
        minimal: true,
      };

      if (!linkedSocialAccounts.data.me) {
        if (linkedSocialAccounts.data.loading) {
          return <LoadingPage headProps={headProps} />;
        }

        headProps.back = "/";

        return (
          <div>
            <Head {...headProps} />
            <Main full>
              <p>Not logged in</p>
            </Main>
          </div>
        );
      }

      headProps.back = `/profile/${linkedSocialAccounts.data.me.handle}`;

      const links = ["google"];

      return (
        <div>
          <Head {...headProps} />
          <Main>
            <div className={classNames("color-dark-grey", "text-upper")}>
              <div
                className={classNames(
                  "wrap__inner",
                  "wrap__gutter",
                  "wrap__gutter--padded"
                )}
              >
                <ul className={classNames("list--border")}>
                  {links.map((provider) => {
                    return (
                      <li
                        key={provider}
                        className={classNames(
                          "flex",
                          "flex--justify-sb",
                          "flex--align-c"
                        )}
                      >
                        <span className={classNames("flex", "flex--align-c")}>
                          <span className="p-r-15">
                            <Icon
                              icon={linkedSocialAccounts
                                .getLabel(provider)
                                .toLowerCase()}
                              color="dark-grey"
                            />
                          </span>
                          <h3>{linkedSocialAccounts.getLabel(provider)}</h3>
                        </span>
                        {(linkedSocialAccounts.data.loading && <Loading />) ||
                          this.getWidget(provider)}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </Main>
        </div>
      );
    }
  }
);

export default SettingsSocial;
