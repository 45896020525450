import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import gql from "graphql-tag";
import { Link } from "react-router-dom";

import { GraphqlBackedMedia } from "components/Media";

import { refetchHandles } from "~/auth";

import styles from "./style.scss";

const mapStateToProps = function (state) {
  return {
    authenticated: state.auth.authenticated,
    userAuth: state.auth.user,
  };
};

function getSizes(sizeProp) {
  switch (sizeProp) {
    case "sm":
      return "30px";
    case "md":
      return "66px";
    case "lg":
      return "135px";
  }
  return null;
}

class Avatar extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool, // Auth reducer
    className: PropTypes.string,
    data: PropTypes.shape({
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
      me: PropTypes.object,
    }),
    link: PropTypes.bool.isRequired,
    shadow: PropTypes.bool.isRequired,
    size: PropTypes.string,
    user: PropTypes.shape({
      avatar: PropTypes.object.isRequired,
      handle: PropTypes.string,
      hashid: PropTypes.string,
    }),
    userAuth: PropTypes.object, // Auth reducer
  };

  static defaultProps = {
    link: true,
    shadow: false,
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (props.data) {
      refetchHandles.avatarHandle = props.data;
    }
  }

  render = () => {
    const {
      authenticated,
      className,
      data,
      link,
      shadow,
      size,
      user,
      userAuth,
    } = this.props;

    // Get user object
    let avatarUser = user;
    if (!avatarUser && data) {
      avatarUser = data.me;
    }

    if (!user && (!data || !data.me)) {
      return <div />;
    }

    let sizeClass = null;
    switch (size) {
      case "sm":
        sizeClass = styles.sm;
        break;
      case "md":
        sizeClass = styles.md;
        break;
      case "lg":
        sizeClass = styles.lg;
        break;
    }

    const isMe =
      authenticated &&
      (!user ||
        (avatarUser.hashid != null &&
          userAuth &&
          userAuth.hashid === avatarUser.hashid) ||
        (userAuth &&
          userAuth.handle != null &&
          userAuth.handle === avatarUser.handle));

    const alt = `${isMe ? "My" : `${avatarUser.handle}’s`} profile picture`;

    const WrapperEl = link
      ? Link
      : ({ children, ...otherProps }) => <div {...otherProps}>{children}</div>;
    const wrapperProps = {
      className: classNames(
        styles.root,
        sizeClass,
        shadow && styles.shadow,
        className
      ),
    };
    if (link) {
      wrapperProps.to = `/profile/${avatarUser.handle}`;
    }

    const imgProps = {
      alt: alt,
      // className: classNames("dis-b", styles.mirrorImage),
      className: classNames("dis-b"),
    };

    if (avatarUser.avatar.url) {
      // URL type
      // imgProps.src = avatarUser.avatar.url;
      return (
        <WrapperEl {...wrapperProps}>
          <img {...imgProps} src={avatarUser.avatar.url} />
        </WrapperEl>
      );
    }

    // Media type
    return (
      <WrapperEl {...wrapperProps}>
        <GraphqlBackedMedia
          hashid={avatarUser.avatar.hashid}
          imageOnly
          size={size}
          sizes={getSizes(size)}
          rounded={false}
        />
      </WrapperEl>
    );
  };
}

const ME_QUERY = gql`
  query CurrentUserForAvatar {
    me {
      avatar {
        ... on Media {
          hashid
          is_processed
          square_image_sources {
            url
            width
          }
          type
        }
        ... on Url {
          url
        }
      }
      handle
      hashid
    }
  }
`;

export default compose(
  connect(mapStateToProps, null),
  graphql(ME_QUERY, {
    skip: (ownProps) => !ownProps.authenticated || ownProps.user,
  })
)(Avatar);
