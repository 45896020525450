import classNames from 'classnames';
import {Link} from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.scss';

export default class Handle extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    handle: PropTypes.string.isRequired,
    link: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    link: true,
  }

  render() {
    const {
      link,
    } = this.props;

    const classes = classNames(styles.root, this.props.className);

    if (!link) {
      return <Text className={classes} handle={this.props.handle} />;
    }

    return (
      <Link className={classNames(classes, styles.link)} to={`/profile/${this.props.handle}`}>
        <Text handle={this.props.handle} />
      </Link>
    );
  }
}

class Text extends React.Component {
  static propTypes = {
    handle: PropTypes.string.isRequired,
  }

  render() {
    const {
      handle,
      ...otherProps
    } = this.props;

    return (
      <span {...otherProps}>
        @{this.props.handle}
      </span>
    );
  }
}

export {
  Text,
};
