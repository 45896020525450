import classNames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";

import Icon from "components/Icon";
import Button from "components/Button";
import VideoUploadButton from "components/VideoUploadButton";
import RadioButton from "components/RadioButton";
import DatetimePicker from "components/DatetimePicker";
import FormError from "components/FormError";
import TagsInput from "components/TagsInput";
import TextArea from "components/TextArea";

import styles from "./style.scss";
import HelpIcon from "../../../../components/HelpIcon";

const placeAutoCompleteStyle = {
  autocompleteContainer: {
    borderBottom: "honeydew",
    borderLeft: "honeydew",
    borderRadius: "0 0 2px 2px",
    borderRight: "honeydew",
    borderTop: "1px solid #e6e6e6",
    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    position: "relative",
    top: "-20px",
  },
  autocompleteItemActive: {
    color: "#f75a00",
  },
  input: {
    padding: "10px 10px 10px 30px",
    background: "#F9F9F9",
    borderRadius: "8px",
    color: "#000000",
    border: "none",
    fontFamily: "inherit",
    fontSize: "inherit",
  },
  root: {
    paddingBottom: "0px",
  },
};

export default class ViewUpload extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onNavigate: PropTypes.func,
    onSubmit: PropTypes.func,
    style: PropTypes.object,
    tags: PropTypes.array,
  };

  static defaultProps = {
    className: "",
    onNavigate: (f) => f,
    onSubmit: (f) => f,
    style: {},
  };

  constructor() {
    super();

    this.state = {
      closesAt: this.getClosesAt("1w"),
      closesAtPreset: "1w",
      isNextLocked: true,
      locationName: "",
      locationPlaceId: "",
      tags: [],
      uploadedMedia: null,
    };

    this.$form = null;
  }

  handleFormSubmit = () => {
    if (this.state.isNextLocked) {
      return;
    }

    const form = this.$form.elements;

    const data = {
      closesAt: Math.round(this.getClosesAt().format("X")),
      locationName: this.state.locationName,
      locationPlaceId: this.state.locationPlaceId,
      mediaHashid: form.mediaHashid.value,
      mediaType: form.mediaType.value,
      message: form.message.value,
      tags: this.state.tags,
    };

    this.props.onSubmit(data);
  };

  handleCustomClosesAtChange = (momentObj) => {
    this.setState(
      {
        closesAt: momentObj,
      },
      this.setNextStepState
    );
  };

  getClosesAt = (preset = this.state.closesAtPreset) => {
    if (preset === null) {
      return null;
    }

    if (preset === "custom") {
      return this.state.closesAt;
    }

    let unit = null;
    switch (preset.substr(-1)) {
      case "h":
        unit = "hours";
        break;
      case "m":
        unit = "minutes";
        break;
      case "s":
        unit = "seconds";
        break;
      case "d":
        unit = "days";
        break;
      case "w":
        unit = "weeks";
        break;
      default:
        throw new Error("Unknown unit");
    }
    return moment().startOf("minute").add(parseInt(preset), unit);
  };

  handleClosesAtPresetChange = (event) => {
    const preset = event.currentTarget.value;

    this.setState(
      {
        closesAt: this.getClosesAt(preset),
        closesAtPreset: preset,
      },
      this.setNextStepState
    );
  };

  setNextStepState = () => {
    const getLocked = () => {
      if (this.state.uploadedMedia === null) {
        return true;
      }
      if (!this.getClosesAt()) {
        return true;
      }
      if (!this.getClosesAt().isAfter(moment().seconds(0))) {
        return true;
      }
      return false;
    };

    this.setState({
      isNextLocked: getLocked(),
    });
  };

  handleFileComplete = (media) => {
    this.setState(
      {
        uploadedMedia: media,
      },
      this.setNextStepState
    );
  };

  componentDidMount() {
    this.rerenderInterval = setInterval(() => {
      // If a custom date of today is set, rerender since the allowed range of
      // times then depends on the current time
      if (
        this.state.closesAtPreset === "custom" &&
        this.state.closesAt &&
        this.state.closesAt.isSame(moment(), "day")
      ) {
        this.setNextStepState();
      }
    }, 1e3);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.tags) {
      this.setState({
        tags: nextProps.tags,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.rerenderInterval);
  }

  handleChangeTags = (tags) => {
    function hasTag(list, value) {
      return list.reduce((alreadyFound, entry) => {
        if (alreadyFound) {
          return true;
        }
        return entry.value === value;
      }, false);
    }

    let hasSponsor = hasTag(tags, "sponsor");

    // Need to have at least one brand tag and the #sponsor tag (if its in the list)
    if (this.props.tags !== null && this.props.tags.length) {
      // Check whether the new list of tags contains at least one brand tag, which is not the sponsor tag
      if (
        !this.props.tags.reduce((alreadyFound, entry) => {
          if (alreadyFound) {
            return true;
          }
          if (hasSponsor && entry.value === "sponsor") {
            return false;
          }
          return hasTag(tags, entry.value);
        }, false)
      ) {
        // There are no brand tags in the new list;
        // look for the one which was just removed
        let restoredTag = this.state.tags.reduce((toRestore, entry) => {
          if (toRestore) {
            return toRestore;
          }
          if (hasTag(this.props.tags, entry.value)) {
            return entry;
          }
          return null;
        }, null);

        // If one wasn't found
        // (not sure in which circumstances this is possible),
        // just restore the first brand tag
        if (restoredTag === null) {
          restoredTag = this.props.tags[0];
        }

        // Restore the tag
        tags.unshift(restoredTag);
      }
    }

    this.setState({
      tags,
    });
  };

  handleSelectAddress = (address, placeId) => {
    this.setState({
      locationName: address,
      locationPlaceId: placeId,
    });
  };

  handleChangeAddress = (address) => {
    this.setState({
      locationName: address,
    });
  };

  handleClick = () => {
    this.props.onNavigate(1);
  };

  render() {
    const { className, style } = this.props;

    const inputLocationProps = {
      onChange: this.handleChangeAddress,
      placeholder: "Choose location",
      value: this.state.locationName,
    };

    const AutocompleteItem = ({ formattedSuggestion }) => (
      <div>
        <Icon icon="marker" />
        <strong>{formattedSuggestion.mainText}</strong>{" "}
        <small>{formattedSuggestion.secondaryText}</small>
      </div>
    );

    return (
      <div
        className={classNames("wrap__page", styles.root, className)}
        style={style}
        data-tut="influencerPost__step1--observe"
      >
        <VideoUploadButton
          onComplete={this.handleFileComplete}
          endpoint={`/v1/media/video`}
          className={styles.VideoUploadButtonWrap}
        >
          {/* <Button color="none" dataTut="influencerPost__step1" className={styles.addVideoBtn}> */}
          <Icon icon="addVideo" size="md" />
          Add Video
          {/* </Button> */}
        </VideoUploadButton>
        <form ref={(ref) => (this.$form = ref)}>
          <input
            type="hidden"
            name="mediaHashid"
            value={
              this.state.uploadedMedia ? this.state.uploadedMedia.hashid : ""
            }
            required
          />
          <input
            type="hidden"
            name="mediaType"
            value={
              this.state.uploadedMedia ? this.state.uploadedMedia.type : ""
            }
            required
          />
          <div className={styles.hashtagsInputCont}>
            <div className={classNames(styles.InputHeader)}>
              <span>Hashtags</span>
              <HelpIcon helperText="Non-branded hashtags are what connects each campaign contest to you and sponsors. Sparks aligned with hashtags can be easily found on social and search thereby increasing reach and exposure. This attracts new followers and sponsors." />
            </div>
            <TagsInput
              className="m-b-20"
              dataTut="influencerPost__step2"
              label="tags"
              placeholder="Hashtags"
              tags={this.state.tags}
              onChange={this.handleChangeTags}
            />
          </div>
          <div
            className={styles.locationInputCont}
            data-tut="influencerPost__step3"
          >
            <div className={classNames(styles.InputHeader)}>
              <span>Location</span>
              <HelpIcon helperText="The region in which you live/work is important to identify fans and sponsors in your same City/Country. This is particularly important for time-sensitive Sparks in a specific place e.g. Los Angeles Forum. Waikiki Beach, Hawaii." />
            </div>
            <PlacesAutocomplete
              classNames={{
                root: styles.placeInput,
              }}
              inputProps={inputLocationProps}
              onSelect={this.handleSelectAddress}
              autocompleteItem={AutocompleteItem}
              styles={placeAutoCompleteStyle}
            />
          </div>
          <div>
            {this.state.errors &&
              this.state.errors.fields &&
              this.state.errors.fields.closesAt && (
                <FormError errors={this.state.errors.fields.closesAt} />
              )}
            <div
              data-tut="influencerPost__step4"
              className={styles.selectDurationBlockWrap}
            >
              <div className={styles.InputHeader}>
                <span>Duration</span>
                <HelpIcon helperText="Set the timeframe by which your fans can give you ideas, lobby their friends to vote, and boost engagement over social. Choose one of the set durations or create your own e.g. 30 minutes. Shorter durations create excitement and work well if you have a highly engaged fanbase. Longer durations give your fans time to lobby, boost and comment which often results in more ideas and new followers." />
              </div>
              <div className={styles.durationButtonsWrap}>
                <RadioButton
                  name="closesAtPreset"
                  value="6h"
                  label="6 h"
                  checked={this.state.closesAtPreset === "6h"}
                  onChange={this.handleClosesAtPresetChange}
                  className={styles.radioButton}
                />
                <RadioButton
                  name="closesAtPreset"
                  value="24h"
                  label="24 h"
                  checked={this.state.closesAtPreset === "24h"}
                  onChange={this.handleClosesAtPresetChange}
                />
                <RadioButton
                  name="closesAtPreset"
                  value="1w"
                  label="1 w"
                  checked={this.state.closesAtPreset === "1w"}
                  onChange={this.handleClosesAtPresetChange}
                />
                <RadioButton
                  name="closesAtPreset"
                  value="custom"
                  label="custom"
                  checked={this.state.closesAtPreset === "custom"}
                  onChange={this.handleClosesAtPresetChange}
                />
              </div>

              {this.state.closesAtPreset === "custom" && (
                <div className={styles.customDatetimePickerWrap}>
                  <label>Closes at</label>
                  <DatetimePicker
                    style={{
                      display: "inline-block",
                      marginLeft: "10px",
                      width: "auto",
                    }}
                    required
                    min={moment().startOf("minute")}
                    max={moment().add(31, "days").endOf("day")}
                    step="1"
                    value={this.state.closesAt}
                    onChange={this.handleCustomClosesAtChange}
                  />
                </div>
              )}
            </div>
          </div>
          <div data-tut="influencerPost__step5--observe">
            {this.state.errors &&
              this.state.errors.fields &&
              this.state.errors.fields.message && (
                <FormError errors={this.state.errors.fields.message} />
              )}
            <div className={styles.InputHeader}>
              <span>Encourage Creative Ideas</span>
              <HelpIcon helperText="Using the campaign hashtags provided, encourage your fans to use the hashtags as thought-starters and be creative with Spark ideas, think outside the box, stand out from the crowd. The more creative the idea, the more entertaining the Spark. This will send you up our content leaderboard, generate more income, shared widely over social, and attract attention of other sponsors. " />
            </div>
            <TextArea
              name="message"
              maxLength={200}
              placeholder="Type message..."
            />
            {/* <TextArea

              dataTut="influencerPost__step5"
            /> */}
          </div>

          {/* <Navigator
            isNextLocked={this.state.isNextLocked}
            hasPrevious={true}
            onNavigate={this.props.onNavigate}
          /> */}
          <Button
            onClick={this.handleFormSubmit}
            className={classNames(styles.nextBtn, {
              [styles.nextBtnDisabled]: this.state.isNextLocked,
            })}
          >
            Next
          </Button>
        </form>
      </div>
    );
  }
}
