// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-Modal-style__overlay__Q85MW{width:100%;height:100%;position:absolute;z-index:10;top:0;left:0;background-color:rgba(98,98,97,.7);overflow:auto}.components-Modal-style__overlays__ei21v{width:100%;height:100%;position:fixed;z-index:10;top:0;left:0;background-color:rgba(98,98,97,.7);overflow:auto}.components-Modal-style__modal__prgOo{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-pack:distribute;justify-content:space-around;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;min-height:100%;padding:20px}.components-Modal-style__box__oPfV_{position:relative;background-color:#fff;-webkit-overflow-scrolling:touch;outline:none;padding:40px;border-radius:20px;text-align:center;width:100%;max-width:600px;margin:auto}.components-Modal-style__box__oPfV_.components-Modal-style__extraPadding__PM5EH{padding-top:70px;padding-bottom:70px}.components-Modal-style__content__uCa1v input{margin-bottom:10px}.components-Modal-style__content__uCa1v button{display:block;margin:10px auto}.components-Modal-style__section__CEApn:not(:last-child){margin-bottom:40px}.components-Modal-style__title__MyAXO{margin-bottom:10px}.components-Modal-style__titleMain__ExjsB{margin-bottom:20px}@media only screen and (max-width: "(max-width:767px)"){.components-Modal-style__box__oPfV_{padding:20px}.components-Modal-style__box__oPfV_.components-Modal-style__extraPadding__PM5EH{padding-top:50px;padding-bottom:50px}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `components-Modal-style__overlay__Q85MW`,
	"overlays": `components-Modal-style__overlays__ei21v`,
	"modal": `components-Modal-style__modal__prgOo`,
	"box": `components-Modal-style__box__oPfV_`,
	"extraPadding": `components-Modal-style__extraPadding__PM5EH`,
	"content": `components-Modal-style__content__uCa1v`,
	"section": `components-Modal-style__section__CEApn`,
	"title": `components-Modal-style__title__MyAXO`,
	"titleMain": `components-Modal-style__titleMain__ExjsB`
};
export default ___CSS_LOADER_EXPORT___;
