import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import SVGInline from "react-svg-inline";
import styles from "./style.scss";

export default class Icon extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    filled: PropTypes.bool,
    icon: PropTypes.string.isRequired,
    size: PropTypes.string,
    isFlag: PropTypes.bool,
  };

  static defaultProps = {
    color: "black",
    filled: false,
    size: "md",
    isFlag: false,
  };

  render = () => {
    const { default: svgContent } = require(`./${
      this.props.isFlag ? "flags/" : ""
    }${this.props.icon}${this.props.filled ? "Filled" : ""}.svg`);

    let colorClass = null;
    switch (this.props.color) {
      case "black":
        colorClass = styles.black;
        break;
      case "grey":
        colorClass = styles.grey;
        break;
      case "dark-grey":
        colorClass = styles.darkGrey;
        break;
      case "active":
        colorClass = styles.active;
        break;
      case "inactive":
        colorClass = styles.inactive;
        break;
      case "primary":
        colorClass = styles.primary;
        break;
      case "secondary":
        colorClass = styles.secondary;
        break;
      case "tertiary":
        colorClass = styles.tertiary;
        break;
      case "locationOrange":
        colorClass = styles.locationOrange;
        break;
      case "white":
        colorClass = styles.white;
        break;
      case "gray":
        colorClass = styles.gray;
        break;
    }

    let sizeClass = null;
    switch (this.props.size) {
      case "xs":
        sizeClass = styles.xs;
        break;
      case "sm":
        sizeClass = styles.sm;
        break;
      case "md":
        sizeClass = styles.md;
        break;
      case "lg":
        sizeClass = styles.lg;
        break;
    }

    return (
      <span
        className={classNames(
          styles.root,
          this.props.className,
          colorClass,
          sizeClass,
          {
            [styles.flag]: this.props.isFlag,
          }
        )}
      >
        {svgContent && <SVGInline svg={svgContent} />}
      </span>
    );
  };
}
