// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-Dropdown-style__root__EtOap{width:100%;height:50px;background:#f9f9f9;color:#000;font-family:"Poppins",sans-serif;font-size:13px;font-style:normal;font-weight:400;line-height:normal;border-radius:16px !important;position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.components-Dropdown-style__root__EtOap .components-Dropdown-style__react-dropdown-select-clear__Rdxj9,.components-Dropdown-style__root__EtOap .components-Dropdown-style__react-dropdown-select-dropdown-handle__dDnyP{color:#fff}.components-Dropdown-style__root__EtOap span{height:50px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-left:8px}.components-Dropdown-style__root__EtOap input{height:50px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin:0 0 0 8px !important}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-Dropdown-style__root__EtOap`,
	"react-dropdown-select-clear": `components-Dropdown-style__react-dropdown-select-clear__Rdxj9`,
	"react-dropdown-select-dropdown-handle": `components-Dropdown-style__react-dropdown-select-dropdown-handle__dDnyP`
};
export default ___CSS_LOADER_EXPORT___;
