import React from "react";
import useDropdown from "../../hooks/useDropdown";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./flagsDropdown.scss";
import Icon from "components/Icon";

export default function FlagsDropdown({
  countries,
  selectedValue,
  onChange,
  disableClickOutside,
  initiallyOpen,
}) {
  const { wrapperRef, dropdownOpen, toggleDropdown, closeDropdown } =
    useDropdown(disableClickOutside, initiallyOpen);

  const handleItemClick = (item) => {
    onChange(item);
    closeDropdown();
  };

  return (
    <div ref={wrapperRef} className={styles.wrapper}>
      <div onClick={toggleDropdown} className={styles.head}>
        <div className={styles.headWithArrow}>
          {selectedValue === "" ? (
            <div className={styles.headItem}>
              <Icon icon="ca" isFlag className={styles.flagIcon} />
              <span className={styles.regionNumber}>+1</span>
            </div>
          ) : (
            <div className={styles.headItem}>
              <Icon
                icon={selectedValue.shortName}
                isFlag
                className={styles.flagIcon}
              />
              <span className={styles.regionNumber}>
                {selectedValue.number}
              </span>
            </div>
          )}
          <Icon
            icon="arrowDown"
            size="sm"
            className={classNames(styles.arrowIcon, {
              [styles.flippedIcon]: dropdownOpen,
            })}
          />
        </div>
      </div>
      {dropdownOpen && (
        <div className={classNames(styles.dropdown)}>
          <ul className={styles.list}>
            {countries.map((flag, i) => (
              <li
                key={i}
                onClick={() => handleItemClick(flag)}
                className={styles.item}
              >
                <Icon
                  icon={flag.shortName}
                  isFlag
                  className={styles.flagIcon}
                />
                <span>{flag.name}</span>
                <span>{flag.number}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

FlagsDropdown.propTypes = {
  countries: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  selectedValue: PropTypes.object,
  disableClickOutside: PropTypes.bool,
  initiallyOpen: PropTypes.bool,
};

FlagsDropdown.defaultProps = {
  selectedValue: "",
  countries: [],
  disableClickOutside: false,
  initiallyOpen: false,
};
