import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Ad from "components/Ad";
import Avatar from "components/Avatar";
import Button from "components/Button";
import Handle from "components/Handle";
import Modal from "components/Modal";

import styles from "./style.scss";

export default class AdModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
    onRequestClose: PropTypes.func,
    spark: PropTypes.object,
  };

  static defaultProps = {
    spark: {
      offer: {
        name: "Demo",
      },
    },
  };

  constructor() {
    super();

    this.state = {
      showModal: true,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.state.showModal) {
      this.setState({
        showModal: nextProps.isOpen,
      });
    }
  }

  handleYes = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }

    window.open(this.props.spark.offer.offer_url, "_blank");
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });

    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
  };

  renderCrowdReferral = () => {
    const { spark } = this.props;

    if (spark.offer.clicks > 0 && spark.offer.impressions > 0) {
      let percent = 0;
      // If there are ad shows, then use it to calculate the click percentage, if not, use the old calculation
      if (spark.offer.ad_shows >= spark.offer.impressions) {
        percent = spark.offer.clicks / spark.offer.ad_shows;
      } else {
        percent = spark.offer.impressions / spark.offer.clicks;
      }

      percent = Math.min(percent, 1);
      const outputPercent = percent.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        style: "percent",
      });
      return (
        <div className={classNames(styles.crowdReferral)}>
          <h1>{outputPercent} of fans have clicked on this Sponsor</h1>
        </div>
      );
    }
    return null;
  };

  render() {
    const { spark } = this.props;

    // console.log({ spark });

    if (!spark.offer || spark.offer.status?.toLowerCase() !== "active") {
      return null;
    }

    return (
      <Modal
        contentLabel="Partner Message"
        shouldCloseOnOverlayClick={true}
        showCloseButton={true}
        isOpen={this.state.showModal}
        onRequestClose={this.handleCloseModal}
        extraPadding={true}
      >
        <div className={styles.root}>
          <img width="1" height="1" src={spark.offer.ad_impression_pixel} />
          <div className={classNames(styles.cardContent)}>
            <div className={classNames(styles.avatarAndNicknameCont)}>
              <Avatar
                className={classNames("dis-ib", styles.avatarImg)}
                user={spark.user}
              />
              <span className="dis-b">
                <Handle handle={spark.user.handle} />
              </span>
            </div>

            <span
              className={classNames(
                "dis-b",
                styles.recommendation,
                styles.text
              )}
            >
              {"I'm working with " + spark.offer.name}. {spark.ad_message}
            </span>

            <div className={styles.addWrap}>
              <Ad
                offer={spark.offer}
                className={classNames("dis-ib", styles.ad)}
                onClick={this.props.onClick}
              />
            </div>
            <p className={classNames("title", "title-sm")}>
              This Spark is brought to you by {spark.offer.name}
            </p>

            {this.renderCrowdReferral()}

            <Button onClick={this.handleYes} className={styles.offerButton}>
              Check it out
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
