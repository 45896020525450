import PropTypes from 'prop-types';
import React from 'react';
import {Redirect} from 'react-router-dom';

import {storeReferralId} from '~/auth';

export default class ReferralRedirection extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired, // From router
  };

  constructor(props) {
    super(props);
    if (this.props.match.params.referralId) {
      localStorage["Referral-openSignup"] = true;
      storeReferralId(this.props.match.params.referralId);
    }
  }

  render() {
    return <Redirect to='/' />;
  }
}
