import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { CountryRegionData } from "react-country-region-selector";
import CustomDropDown from "./CustomDropDown";

const REGION_LIST_DELIMITER = "|";
const SINGLE_REGION_DELIMITER = "~";

const RegionDropdown = ({ country, value, onChange }) => {
  const {
    options,
    countryRegionData,
    dropdownData,
    selectedRegionIndex,
    getRegionFullName,
  } = useMemo(() => {
    const memoCountryRegionData = CountryRegionData.find(
      ([, countrySlug]) => countrySlug === country
    );

    if (!memoCountryRegionData) {
      return {
        options: [],
        countryRegionData: null,
        dropdownData: [],
        selectedRegionIndex: -1,
        getRegionFullName: "Error",
      };
    }

    const memoOptions = (memoCountryRegionData[2] || "")
      .split(REGION_LIST_DELIMITER)
      .map((region) => {
        let [regionName, regionShortCode = null] = region.split(
          SINGLE_REGION_DELIMITER
        );
        return [regionShortCode, regionName];
      });

    const memoDropdownData = memoOptions.map((item) => item[1]);
    const memoRegionsCodes = memoOptions.map((item) => item[0]);
    const memoSelectedRegionIndex = memoRegionsCodes.findIndex(
      (item) => item === value
    );
    const memoGetRegionFullName =
      memoSelectedRegionIndex === -1
        ? "Error"
        : memoOptions[memoSelectedRegionIndex][1];

    return {
      options: memoOptions,
      countryRegionData: memoCountryRegionData,
      dropdownData: memoDropdownData,
      selectedRegionIndex: memoSelectedRegionIndex,
      getRegionFullName: memoGetRegionFullName,
    };
  }, [value, country]);

  const [selectedValue, setSelectedValue] = useState(
    !value ? "" : getRegionFullName
  );

  useEffect(() => {
    if (selectedRegionIndex !== -1) {
      setSelectedValue(options[selectedRegionIndex][1]);
    }
  }, [selectedRegionIndex, value]);

  useEffect(() => {
    if (value === "") {
      setSelectedValue("");
    }
  }, [value]);

  const handleSelectValue = (region) => {
    setSelectedValue(region);
    const regionIndex = dropdownData.findIndex((item) => item === region);
    onChange(options[regionIndex]);
  };

  if (!country || !countryRegionData) {
    return null;
  }

  return (
    <CustomDropDown
      items={dropdownData}
      selectedValue={selectedValue}
      onChange={handleSelectValue}
    />
  );
};

RegionDropdown.propTypes = {
  country: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

RegionDropdown.defaultProps = {
  country: "",
  value: "",
};

export default RegionDropdown;
