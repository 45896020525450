// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-ShareMenu-style__root__aCWkf{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.components-ShareMenu-style__shareIcon__expkG{margin-right:8px}.components-ShareMenu-style__btn__Z_tR8{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.components-ShareMenu-style__shareIconWithText__JyGp0{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.components-ShareMenu-style__dropdown__Prb6l{z-index:999}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-ShareMenu-style__root__aCWkf`,
	"shareIcon": `components-ShareMenu-style__shareIcon__expkG`,
	"btn": `components-ShareMenu-style__btn__Z_tR8`,
	"shareIconWithText": `components-ShareMenu-style__shareIconWithText__JyGp0`,
	"dropdown": `components-ShareMenu-style__dropdown__Prb6l`
};
export default ___CSS_LOADER_EXPORT___;
