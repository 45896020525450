import * as Sentry from '@sentry/browser';

const Log = (message, level) => {
  if (process.env.NODE_ENV === 'development') {
    switch (level) {
      case 'info':
        console.info(message); // eslint-disable-line no-console
        break;
      case 'warning':
        console.warn(message); // eslint-disable-line no-console
        break;
      case 'error':
        console.error(message); // eslint-disable-line no-console
        break;
      case 'exception':
        console.error(message); // eslint-disable-line no-console
        break;
      default:
        console.log(message); // eslint-disable-line no-console
    }
  } else {
    switch (level) {
      case 'exception':
        Sentry.captureException(message);
        break;
      default:
        Sentry.captureMessage(message, level);
    }
  }
};

export default Log;
