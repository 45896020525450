import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/Button';
import styles from './style.scss';

export default class IconButton extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    // TODO limit to string of 1 character kength?
    className: PropTypes.string,
    link: PropTypes.string,
    onClick: PropTypes.func,
    // TODO require one of link or onclick
  }

  render = () => {
    return (
      <Button
        color="none"
        className={classNames(styles.root, this.props.className)}
        link={this.props.link}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </Button>
    );
  }
}
