import React from "react";
import PropTypes from "prop-types";
import styles from "./style.scss";
import classNames from "classnames";
import Icon from "components/Icon";

export default function Alert({
  message,
  className,
  type,
  variant,
  alertClassName,
}) {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div
        className={classNames(styles.alertWrapper, alertClassName, {
          [styles.warningFilled]: type === "warning" && variant !== "outlined",
          [styles.infoFilled]: type === "info" && variant !== "outlined",
          [styles.errorFilled]: type === "error" && variant !== "outlined",
          [styles.successFilled]: type === "success" && variant !== "outlined",

          [styles.warningOutlined]: type === "warning" && variant !== "filled",
          [styles.infoOutlined]: type === "info" && variant !== "filled",
          [styles.errorOutlined]: type === "error" && variant !== "filled",
          [styles.successOutlined]: type === "success" && variant !== "filled",
        })}
      >
        <Icon size="xl" filled={false} icon={type} />
        {message}
      </div>
    </div>
  );
}

Alert.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["outlined", "filled"]),
  type: PropTypes.oneOf(["warning", "info", "error", "success"]).isRequired,
  fullWidth: PropTypes.bool,
  alertClassName: PropTypes.string,
};

Alert.defaultProps = {
  message: "",
  alertClassName: "",
  className: "",
  variant: "filled",
  type: "warning",
  fullWidth: false,
};
