import { graphql } from "react-apollo";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";

import Checkbox from "components/Checkbox";
import Loading from "components/Loading";
import Log from "~/log";

import TextArea from "components/TextArea";
import HandleInput from "components/HandleInput";

import UploadButton from "components/UploadButton";
import FormError from "components/FormError";
import Icon from "components/Icon";
import CountryDropdown from "components/CountryDropdown";
import RegionDropdown from "components/RegionDropdown";
import ErrorMessage from "components/ErrorMessage";

import image1 from "./image1.png";

import styles from "./style.scss";

import { completeRegistration, login } from "~/auth";

import "react-phone-number-input/style.css";
import classNames from "classnames";
import HelpIcon from "../HelpIcon";
import PasswordInput from "../PasswordInput";
import PhoneNumberInput from "../PhoneNumberInput";
import CustomDropDown from "../CustomDropDown";

class RegistrationForm extends React.Component {
  static propTypes = {
    accessToken: PropTypes.string,
    data: PropTypes.shape({
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
      me: PropTypes.object,
      categories: PropTypes.object,
    }),
    email: PropTypes.string,
    fullPage: PropTypes.bool,
    history: PropTypes.object.isRequired, // From router
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
    referralId: PropTypes.string,
    suggestedHandle: PropTypes.string,
    viaEmail: PropTypes.bool,
  };

  static defaultProps = {
    accessToken: null,
    data: {
      error: null,
      loading: true,
      me: null,
    },
    email: null,
    fullPage: false,
    location: {
      pathname: "",
    },
    suggestedHandle: "",
    viaEmail: false,
  };

  constructor() {
    super();

    this.state = {
      avatarUpload: null,
      errors: null,
      categories_primary: null,
      categories_secondary: null,
      country: null,
      isSubmitLoading: false,
      password: "",
      phone: "",
      region: null,
      registrationError: null,
      category_primary: "",
      category_secondary: "",
      category_tertiary: "",
    };
  }

  onSelectCountry = (val) => {
    this.setState({
      country: val,
      region: "",
    });
  };
  onSelectRegion = (val) => {
    this.setState({ region: val[0] });
  };

  handleSetPhone = (value) => {
    this.setState({ phone: value });
  };

  handleLogin = (provider) => {
    login(provider);
  };

  handleGoogle = () => {
    return this.handleLogin("google");
  };

  UNSAFE_componentWillMount() {
    this.setState({
      password: "",
      phone: "",
      registrationError: null,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.form &&
      this.form.elements &&
      this.form.elements.handle &&
      this.form.elements.handle.value
    ) {
      this.handleInput.checkHandle(this.form.elements.handle);
    }

    if (!prevProps.data.categories && this.props.data.categories) {
      this.setState({
        categories_primary: this.props.data.categories.primary,
        categories_secondary: this.props.data.categories.secondary,
      });
    }
  }

  handlePasswordChange = (event) => {
    this.setState({
      password: event.target.value,
    });
  };

  handlePasswordConfirmationInput(event) {
    const input = event.target;
    const form = input.form;

    if (input.value.length && input.value !== form.elements.password.value) {
      input.setCustomValidity("Passwords must match.");
    } else {
      input.setCustomValidity("");
    }
  }

  handleFormSubmission = (event) => {
    event.preventDefault();
    this.setState({ isSubmitLoading: true });

    // Retrieve values
    const formData = new FormData(this.form);
    formData.set("phone", this.state.phone);
    formData.set("country", this.state.country);
    formData.set("region", this.state.region);

    formData.set("category_primary", this.state.category_primary);
    formData.set("category_secondary", this.state.category_secondary);
    formData.set("category_tertiary", this.state.category_tertiary);

    // No need to send the password confirmation
    // formData.delete('passwordConfirmation');

    // Replace visible phone number with international format
    // if (formData.has('phone')) {
    //   formData.set('phone', this.state.phone);
    // }
    // Attempt the user registration
    completeRegistration(formData, this.props.accessToken)
      .catch((error) => {
        if (error.name === "ValidationError") {
          // TODO: use error.data and a language map to show messages
        }
        this.setState({
          registrationError: error,
        });
        throw error;
      })
      .then(() => {
        // If at the registration page, navigate away from it
        if (this.props.location.pathname === "/auth/register") {
          this.props.history.push("/");
        }
        window.location.reload();
      });
    this.setState({ isSubmitLoading: false });
  };

  getDefaultHandle = () => {
    if (this.props.suggestedHandle) {
      return this.props.suggestedHandle;
    }

    if (
      !this.props.data.loading &&
      !this.props.data.error &&
      this.props.data.me
    ) {
      if (this.props.data.me.handle) {
        return this.props.data.me.handle;
      }

      // First pass: look for Twitter handle
      for (const linkedSocialAccount of this.props.data.me
        .linked_social_accounts) {
        if (
          linkedSocialAccount.provider === "twitter" &&
          linkedSocialAccount.handle
        ) {
          return linkedSocialAccount.handle;
        }
      }

      // Second pass: use first available handle
      for (const linkedSocialAccount of this.props.data.me
        .linked_social_accounts) {
        if (linkedSocialAccount.handle) {
          return linkedSocialAccount.handle;
        }
      }
    }

    // Try local part of passed email address
    if (this.props.email) {
      return this.props.email.replace(/@.*/, "");
    }

    // Try local part of user's email address
    if (
      !this.props.data.loading &&
      !this.props.data.error &&
      this.props.data.me &&
      this.props.data.me.email
    ) {
      return this.props.data.me.email.replace(/@.*/, "");
    }

    // Give up
    return null;
  };

  getDefaultEmail = () => {
    if (this.props.email) {
      return this.props.email;
    }

    if (
      !this.props.data.loading &&
      !this.props.data.error &&
      this.props.data.me &&
      this.props.data.me.email
    ) {
      return this.props.data.me.email;
    }

    Log("RegistrationForm::getDefaultEmail returning null", "error");

    return null;
  };

  onAvatarUploadComplete = (media) => {
    this.setState({
      avatarUpload: media,
    });
    this.uploadButton.reset();
  };

  render = () => {
    if (
      !this.props.viaEmail &&
      this.props.data.loading &&
      !this.props.data.error
    ) {
      return <Loading />;
    }
    if (this.state.isSubmitLoading) {
      return <Loading />;
    }

    let isInfluencer =
      (this.props.data.me && this.props.data.me.is_influencer) ||
      this.props.referralId;

    return (
      <div className={styles.wrap}>
        <div
          className={classNames(
            "wrap__inner",
            "wrap__inner--skinny",
            "wrap__gutter",
            "wrap__gutter--padded",
            styles.content
          )}
        >
          {/* {isInfluencer && this.renderAvatarSection()} */}

          {
            <form
              ref={(ref) => (this.form = ref)}
              onSubmit={this.handleFormSubmission}
            >
              {isInfluencer
                ? this.renderInfluencerForm()
                : this.renderFanForm()}

              {this.renderEmailNotifications()}

              {isInfluencer && (
                <div className={styles.termsBlock}>
                  <label className={styles.termsHead}>Terms</label>
                  <div className={styles.termsContent}>
                    <span>
                      I agree to the Sparkit Influencer{" "}
                      <a href="/influencerterms" target="_blank">
                        terms and conditions
                      </a>
                      .
                    </span>
                    <Checkbox
                      name="terms"
                      className={styles.termsCheckbox}
                      required={true}
                      value="1"
                    />
                  </div>
                </div>
              )}
              <div className={styles.btnWrap}>
                <div className={styles.errorMessageWrap}>
                  <span>
                    {this.state.registrationError === null
                      ? null
                      : Object.keys(this.state.registrationError.data)[0]}{" "}
                  </span>
                  {this.state.registrationError ? (
                    <ErrorMessage error={this.state.registrationError} />
                  ) : null}
                </div>
                <button
                  onClick={this.handleFormSubmission}
                  type="submit"
                  className={styles.btn}
                >
                  Next
                </button>
              </div>
            </form>
          }
        </div>
      </div>

      // <div className="bg-off-white add-padding">
      //   <form ref={ref => this.form = ref} onSubmit={this.handleFormSubmission}>
      //     <label>
      //       Handle
      //       <HandleInput
      //         ref={ref => this.handleInput = ref}
      //         name="handle"
      //         defaultValue={this.getDefaultHandle()}
      //       />
      //     </label>
      //     <label>
      //       Email address
      //       <input
      //         name="email"
      //         type="email"
      //         disabled
      //         defaultValue={this.getDefaultEmail()}
      //       />
      //     </label>

      //     <label>
      //       Bio
      //       <TextArea name="bio" />
      //     </label>

      //     <label>Phone number
      //       <Phone
      //         name="phone"
      //         country="CA"
      //         onChange={phone => this.setState({phone})}
      //         placeholder=""
      //         aria-describedby="sms-note"
      //         required={isInfluencer}
      //       />
      //       {/* <small id="sms-note">We’ll send a text to verify this after you register.</small> */}
      //     </label>

      //     <label>Password
      //       {this.props.viaEmail ? null : this.renderPasswordOptional()}
      //       <input
      //         type="password"
      //         name="password"
      //         placeholder="Password"
      //         required={this.props.viaEmail}
      //         minLength="8"
      //         value={this.state.password}
      //         onChange={this.handlePasswordChange}
      //       />
      //       {this.renderPasswordConfirmation()}
      //     </label>

      //     {isInfluencer &&
      //       <div>
      //         <label>Terms</label>
      //         <Checkbox name="terms" required={true} value='1' />
      //         <span>I agree to the Sparkit Influencer <a href='/influencerterms' target='_blank'>terms and conditions</a>.</span>
      //       </div>
      //     }
      //     <div className="align-c">
      //       <Button type="submit">Register</Button>
      //     </div>
      //     {this.state.registrationError ? <ErrorMessage error={this.state.registrationError} /> : null}
      //   </form>
      // </div>
    );
  };

  renderConnectedWalletsWrapper = () => (
    <div className={styles.connectedWalletsWrapper}>
      <div
        className={classNames(
          styles.headerWithQuestionIcon,
          styles.WalletsHeader
        )}
      >
        <h2 className={styles.subtitle}>Connected Wallets/Authentication</h2>
        <HelpIcon helperText="This will connect crypto wallets for payment and/or secured 2-factor authentication." />
      </div>
      <div className={styles.connectedWalletsContainer}>
        <img src={image1} onClick={this.handleGoogle} />
      </div>
      <div className={styles.changeBtnContainer}>
        <button>Change</button>
      </div>
    </div>
  );

  renderFanForm = () => (
    <div>
      <PasswordInput
        titleStyles={styles.title}
        title="Password"
        name="password"
        placeholder="Password"
        required={this.props.viaEmail}
        minLength="8"
        value={this.state.password}
        onChange={this.handlePasswordChange}
        withRequiredMark
      />
      <PasswordInput
        titleStyles={styles.title}
        title="Confirm Password"
        name="ConfirmPassword"
        minLength="8"
        value={this.state.password}
        onChange={this.handlePasswordChange}
        placeholder="Confirm password"
        required
        onInput={this.handlePasswordConfirmationInput}
        withRequiredMark
      />
      <div>
        <div
          className={classNames(
            styles.InputHeader,
            styles.headerWithQuestionIcon
          )}
        >
          <span>
            Handle <p className={styles.requiredMark}>*</p>
          </span>
          <HelpIcon helperText="Choose a handle similar to your other social channels so you are easily recognizable." />
        </div>
        {this.state.errors &&
          this.state.errors.fields &&
          this.state.errors.fields.handle && (
            <FormError errors={this.state.errors.fields.handle} />
          )}
        <HandleInput
          ref={(ref) => (this.handleInput = ref)}
          name="handle"
          defaultValue={this.getDefaultHandle()}
          className={styles.HandleInput}
        />
      </div>

      {this.state.errors &&
        this.state.errors.fields &&
        this.state.errors.fields.bio && (
          <FormError errors={this.state.errors.fields.bio} />
        )}
      <div
        className={classNames(styles.bioInputContainer, styles.bioInputMargin)}
      >
        <div className={styles.InputHeader}>
          <span>
            Bio <p className={styles.requiredMark}>*</p>
          </span>
        </div>
        <TextArea name="bio" />
      </div>
      <PhoneNumberInput
        helperText="This is required for SMS notification of new campaign contests, payments and special offers."
        name="phone"
        title="Phone Number"
        placeholder="Phone number"
        required
        value={this.state.phone}
        setPhone={this.handleSetPhone}
        withRequiredMark
        className={styles.PhoneNumberInputBlock}
        type="text"
        maxLength="12"
        isCountryFlagShowed
      />
    </div>
  );

  renderInfluencerForm = () => (
    <div>
      <div>
        <div
          className={classNames(
            styles.InputHeader,
            styles.headerWithQuestionIcon
          )}
        >
          <span>
            Handle <p className={styles.requiredMark}>*</p>
          </span>
          <HelpIcon helperText="Choose a handle similar to your other social channels so you are easily recognizable." />
        </div>
        <HandleInput
          ref={(ref) => (this.handleInput = ref)}
          name="handle"
          defaultValue={this.getDefaultHandle()}
          className={styles.HandleInput}
        />
      </div>
      {this.props.viaEmail ? null : this.renderPasswordOptional()}
      <PasswordInput
        titleStyles={styles.title}
        title="Password"
        name="password"
        placeholder="Password"
        required={this.props.viaEmail}
        minLength="8"
        value={this.state.password}
        onChange={this.handlePasswordChange}
        withRequiredMark
      />
      <div className={styles.changePasswordWrapper}>
        <p className={styles.changeWrapperText}>Change Password</p>
      </div>
      <div className={styles.bioInputContainer}>
        <div className={styles.InputHeader}>
          <span>
            Bio <p className={styles.requiredMark}>*</p>
          </span>
        </div>
        <TextArea name="bio" />
      </div>

      <PhoneNumberInput
        title="Phone Number"
        placeholder="Phone number"
        name="phone"
        value={this.state.phone}
        setPhone={this.handleSetPhone}
        withRequiredMark
        className={styles.PhoneNumberInputBlock}
        type="text"
        maxLength="12"
        isCountryFlagShowed
        helperText="This is required for SMS notification of new campaign contests, payments and special offers."
      />

      {this.renderLocation()}

      {this.RenderChooseCategories()}
      {this.renderConnectedWalletsWrapper()}
    </div>
  );

  renderLocation = () => {
    const { country, region } = this.state;

    return (
      <div>
        <div
          className={classNames(
            styles.InputHeader,
            styles.headerWithQuestionIcon
          )}
        >
          <span>Country</span>
          <HelpIcon helperText="Be specific about your location so we can send appropriate offers and info  to you about your favorite Creators." />
        </div>
        <label className={styles.labelBLock}>
          <CountryDropdown
            value={country}
            onChange={(val) => this.onSelectCountry(val)}
          />
          {/* <CountryDropdown name='country' value={country} onChange={(val) => {
            this.onSelectCountry(val);
          }} valueType='short'
          /> */}
        </label>
        {country && (
          <div>
            <div className={styles.InputHeader}>
              <span>Region</span>
            </div>
            <label className={styles.labelBLock}>
              <RegionDropdown
                value={region}
                country={country}
                onChange={(val) => this.onSelectRegion(val)}
              />
              {/* <RegionDropdown name='region' value={region} country={country} onChange={val => this.onSelectRegion(val)} countryValueType='short' valueType='short' /> */}
            </label>
          </div>
        )}
      </div>
    );
  };

  renderAvatarSection = () => (
    <div className={styles.avatarArea}>
      <div className={styles.avatarBlock}>
        {this.avatarUpload ? (
          <img src={this.avatarUpload} alt="img" className={styles.avatar} />
        ) : (
          <div className={styles.nullAvatar} />
        )}
        <div className={styles.uploadImageButtonContainer}>
          <UploadButton
            accept={[
              "image/png",
              ".png",
              "image/jpeg",
              ".jpg",
              ".jpeg",
              "image/webp",
              ".webp",
            ].join(",")}
            buttonSize="sm"
            height="auto"
            onComplete={this.onAvatarUploadComplete}
            plain
            ref={(ref) => (this.uploadButton = ref)}
          >
            <div className={styles.uploadImageButton}>
              <Icon icon="camera" className={styles.icon} />
            </div>
          </UploadButton>
        </div>
      </div>
    </div>
  );

  RenderChooseCategories = () => {
    const { errors } = this.state;

    return (
      <div className={styles.chooseCategoriesWrap}>
        {this.state.errors &&
          errors.fields &&
          errors.fields.category_primary && (
            <FormError errors={errors.fields.category_primary} />
          )}
        {errors && errors.fields && errors.fields.category_secondary && (
          <FormError errors={errors.fields.category_secondary} />
        )}
        {errors && errors.fields && errors.fields.category_tertiary && (
          <FormError errors={errors.fields.category_tertiary} />
        )}
        <div className={styles.chooseCategoriesHead}>
          <span>First Category</span>
        </div>
        <div className={styles.categoriesInputsContainer}>
          <div>
            <div className={styles.InputHeader}>
              <span>
                First Category<p className={styles.requiredMark}>*</p>
              </span>
            </div>
            {this.state.categories_primary ? (
              <CustomDropDown
                onChange={(value) => {
                  this.setState({
                    category_primary: value,
                  });
                }}
                selectedValue={this.state.category_primary}
                items={this.props.data.categories.primary}
              />
            ) : (
              "Loading..."
            )}
            {/* <CategoriesInput name='category_primary' data={this.state.categories_primary} /> */}
          </div>
          <div>
            <div className={styles.InputHeader}>
              <span>
                Second Category<p className={styles.requiredMark}>*</p>
              </span>
            </div>
            {this.state.categories_secondary ? (
              <CustomDropDown
                onChange={(value) => {
                  this.setState({
                    category_secondary: value,
                  });
                }}
                selectedValue={this.state.category_secondary}
                items={this.props.data.categories.secondary}
              />
            ) : (
              "Loading..."
            )}
            {/* <CategoriesInput name='category_secondary'  data={this.state.categories_secondary} /> */}
          </div>
          <div>
            <div className={styles.InputHeader}>
              <span>
                Third Category<p className={styles.requiredMark}>*</p>
              </span>
            </div>
            {this.state.categories_secondary ? (
              <CustomDropDown
                onChange={(value) => {
                  this.setState({
                    category_tertiary: value,
                  });
                }}
                selectedValue={this.state.category_tertiary}
                items={this.props.data.categories.secondary}
              />
            ) : (
              "Loading..."
            )}
            {/* <CategoriesInput name='category_tertiary'  data={this.state.categories_secondary} /> */}
          </div>
          <div>
            <div
              className={classNames(
                styles.InputHeader,
                styles.headerWithQuestionIcon
              )}
            >
              <span>Per Spark Min If Required</span>
              <HelpIcon helperText="If you require a per Spark minimum payment, indicate it here. If not, we will tabulate your engagement data to determine what offers will be sent to you. Engagement data = votes, suggestions, clicks, CPMs and conversions." />
            </div>
            <div className={classNames(styles.inputContainer)}>
              <input
                type="number"
                name="per_spark_min"
                className={classNames(styles.input)}
                required
              />
            </div>
          </div>
          <div>
            <div
              className={classNames(
                styles.InputHeader,
                styles.headerWithQuestionIcon
              )}
            >
              <span>Paypal Email</span>
              <HelpIcon helperText="If valid crypto wallet and bank info is shared, PayPal is required for payments." />
            </div>
            <div className={classNames(styles.inputContainer)}>
              <input
                type="email"
                name="paypal"
                className={classNames(styles.input)}
                defaultValue={this.getDefaultEmail()}
                required
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderEmailNotifications = () => (
    <div className={styles.notificationsWrapper}>
      <div className={styles.notificationsHeader}>
        <h2 className={classNames(styles.subtitle)}>Email Notifications</h2>
        <HelpIcon helperText="Ensure email notifications are ON to receive campaign contest announcements and special offers." />
      </div>

      <ul
        className={classNames(
          "list",
          "list--unstyle",
          "list--border",
          styles.list
        )}
      >
        <li>
          <label>
            <span className={classNames(styles.labelText)}>
              Allow email notifications.
            </span>
            <Checkbox
              name="notification_email_enabled"
              // DefaultChecked={data.me.notification_email_enabled}
              value={true}
            />
          </label>
        </li>
      </ul>

      <ul
        className={classNames(
          "list",
          "list--unstyle",
          "list--border",
          styles.list
        )}
      >
        <li>
          <label>
            <span className={classNames(styles.labelText)}>
              When someone you follow posts a new Spark.
            </span>
            <Checkbox
              name="notification_email_newspark_enabled"
              // DefaultChecked={data.me.notification_email_newspark_enabled}
              value={true}
            />
          </label>
        </li>
      </ul>

      <ul
        className={classNames(
          "list",
          "list--unstyle",
          "list--border",
          styles.list
        )}
      >
        <li>
          <label>
            <span className={classNames(styles.labelText)}>
              When someone votes for your Spark Suggestion.
            </span>
            <Checkbox
              name="notification_email_votedcomment_enabled"
              // DefaultChecked={data.me.notification_email_votedcomment_enabled}
              value={true}
            />
          </label>
        </li>
      </ul>

      <ul
        className={classNames(
          "list",
          "list--unstyle",
          "list--border",
          styles.list,
          styles.lastItem
        )}
      >
        <li>
          <label>
            <span className={classNames(styles.labelText)}>
              Allow email reminders when your Final Spark is almost due.
            </span>
            <Checkbox
              name="notification_email_sparkreminder_enabled"
              //  DefaultChecked={data.me.influencer.notification_email_sparkreminder_enabled}
              value={true}
            />
          </label>
        </li>
      </ul>
    </div>
  );

  renderPasswordConfirmation = () => {
    if (!this.props.viaEmail && this.state.password.length === 0) {
      return null;
    }

    return (
      <input
        type="password"
        name="passwordConfirmation"
        placeholder="Confirm password"
        required
        onInput={this.handlePasswordConfirmationInput}
      />
    );
  };

  renderPasswordOptional = () => {
    return (
      <p>
        This is optional. If you set a password you’ll be able to sign in with
        your email address and password as an alternative to via the social
        network.
      </p>
    );
  };
}

const ME_QUERY = gql`
  query CurrentUserForRegistrationForm {
    me {
      bio
      email
      handle
      hashid
      is_influencer
      phone
      linked_social_accounts {
        email
        handle
        provider
      }
    }
    categories {
      primary
      secondary
    }
  }
`;

const RegistrationFormWithRouter = withRouter(RegistrationForm);
const RegistrationFormWithRouterAndData = graphql(ME_QUERY)(
  RegistrationFormWithRouter
);

export default RegistrationFormWithRouterAndData;
