import gql from 'graphql-tag';

const brandOpportunitiesQuery = gql`
  query BrandOpportunities {
    offers {
      hashid
      name
      description
      tags
      ad_preview_url
      thumbnail_url
      payout_remaining
      revenue_remaining
      display_goal {
        id
        payout
        payout_name
        payout_type
        payout_percentage
        promo
      }
      cpl_goal {
        id
        payout
        payout_name
        payout_type
        payout_percentage
        promo
      }
      cpa_goal {
        id
        payout
        payout_name
        payout_type
        payout_percentage
        promo
      }
      is_exclusive
    }
  }
`;

export default brandOpportunitiesQuery;
