// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1{margin-bottom:5px}h1 a{color:#000}h2{margin-top:10px}h2 a{color:#000}ol,ul{list-style-position:inside}li.sections-InfluencerHelp-style__help__Hkr75{margin-top:5px;margin-bottom:5px}p.sections-InfluencerHelp-style__help__Hkr75{margin-top:10px;margin-bottom:10px}p.sections-InfluencerHelp-style__blue__EDU6p{color:blue;margin-top:10px;margin-bottom:10px}.sections-InfluencerHelp-style__screen__GLSBT{max-width:320px;margin-top:10px;margin-bottom:10px}.sections-InfluencerHelp-style__screenWide__e_WJn{max-width:640px;margin-top:10px;margin-bottom:10px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"help": `sections-InfluencerHelp-style__help__Hkr75`,
	"blue": `sections-InfluencerHelp-style__blue__EDU6p`,
	"screen": `sections-InfluencerHelp-style__screen__GLSBT`,
	"screenWide": `sections-InfluencerHelp-style__screenWide__e_WJn`
};
export default ___CSS_LOADER_EXPORT___;
