import { graphql } from "react-apollo";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import Loading from "components/Loading";
import BrandOpportunity from "components/BrandOpportunity";
import demoCampaignData from "./campaign";

import styles from "./style.scss";

import brandOpportunitiesQuery from "./query";

export class BrandOpportunityList extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
      offers: PropTypes.arrayOf(PropTypes.object),
    }),
    exclusive: PropTypes.bool.isRequired,
    onNoOffers: PropTypes.func,
    onSelectOffer: PropTypes.func.isRequired,
    handleSelectAddName: PropTypes.func.isRequired,
  };

  static defaultProps = {
    exclusive: false,
  };

  componentDidUpdate() {
    const { data, onNoOffers } = this.props;
    const prevOffers = this.prevData?.offers;

    const offersChanged = prevOffers !== data?.offers;

    const noOffers = data && data.offers && data.offers.length === 0;

    if (noOffers && offersChanged && onNoOffers) {
      onNoOffers();
    }

    this.prevData = data;

    // old code
    // if (
    //   this.props.data &&
    //   this.props.data.offers &&
    //   this.props.data.offers.length === 0
    // ) {
    //   if (this.props.onNoOffers) {
    //     this.props.onNoOffers();
    //   }
    // }
  }

  render = () => {
    return (
      <div className={classNames(styles.root)}>
        {this.renderBrandOpportunities()}
      </div>
    );
  };

  handleOnCreateVideo = (offer) => {
    if (this.props.onSelectOffer) {
      this.props.onSelectOffer(offer);
    }
  };

  renderBrandOpportunities = () => {
    if (this.props.data.loading) {
      return <Loading />;
    }

    if (this.props.data.error) {
      return <p>Error</p>;
    }

    if (this.props.data.offers && this.props.data.offers.length === 0) {
      return (
        <BrandOpportunity
          key={demoCampaignData.hashid}
          label={demoCampaignData.name}
          opportunity={demoCampaignData}
          handleSelectAddName={this.props.handleSelectAddName}
          exclusive={false}
          onCreateVideo={() => null}
          demo={true}
        />
      );
    }

    const brandOpportunities = this.props.data.offers
      .slice()
      .sort((a, b) => a.is_exclusive < b.is_exclusive)
      .map((opportunity) => {
        return (
          <BrandOpportunity
            key={opportunity.hashid}
            label={opportunity.name}
            opportunity={opportunity}
            exclusive={this.props.exclusive}
            onCreateVideo={this.handleOnCreateVideo}
            handleSelectAddName={this.props.handleSelectAddName}
          />
        );
      });

    return <div>{brandOpportunities}</div>;
  };
}

const BrandOpportunityListWithData = graphql(brandOpportunitiesQuery)(
  BrandOpportunityList
);

export default BrandOpportunityListWithData;
