import PropTypes from 'prop-types';
import React from 'react';

import styles from './style.scss';

export default class Grid extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    columns: PropTypes.number.isRequired,
  };

  static defaultProps = {
    columns: 1,
  };

  render() {
    let columns = [];

    for (let columnIndex = 0; columnIndex < this.props.columns; columnIndex++) {
      let columnChildren = this.props.children
      .filter((child, childIndex) => {
        return childIndex % this.props.columns === columnIndex;
      })
      .map((child, childIndex) => {
        return (
          <div className={styles.item} key={childIndex}>
            {child}
          </div>
        );
      });

      columns[columnIndex] = (
        <div className={styles.column} key={columnIndex}>
          {columnChildren}
        </div>
      );
    }

    return (
      <div className={styles.root}>
        {columns}
      </div>
    );
  }
}
