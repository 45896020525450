// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sections-Leaderboard-styles__left__WhgrY{border-top-left-radius:10px;border-bottom-left-radius:10px}.sections-Leaderboard-styles__right__FISgA{border-top-right-radius:10px;border-bottom-right-radius:10px}.sections-Leaderboard-styles__rightIcons__KWkWn{gap:20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"left": `sections-Leaderboard-styles__left__WhgrY`,
	"right": `sections-Leaderboard-styles__right__FISgA`,
	"rightIcons": `sections-Leaderboard-styles__rightIcons__KWkWn`
};
export default ___CSS_LOADER_EXPORT___;
