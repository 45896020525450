// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-Tag-style__root__EggJx{color:#f75a00;border-radius:8px;background-color:rgba(247,90,0,.1);padding:5px 10px;display:inline-block}.components-Tag-style__root__EggJx.components-Tag-style__linked__OXrJA:hover{color:#e53400}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-Tag-style__root__EggJx`,
	"linked": `components-Tag-style__linked__OXrJA`
};
export default ___CSS_LOADER_EXPORT___;
