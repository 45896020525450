import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withRouter} from 'react-router-dom';

import Head from 'components/Head';
import Main from 'components/Main';
import Button from 'components/Button';

import styles from './styles.scss';

NotLoggedIn.propTypes = {
  headProps: PropTypes.object,
  history: PropTypes.object.isRequired, // From router
};

NotLoggedIn.defaultProps = {
  headProps: {},
};

function NotLoggedIn({headProps, history}) {
  const routeChange = ()  => {
    history.push('/');
  };

  return (
    <div>
      <Head {...headProps} />
      <Main full>
        <div className={classNames(styles.section)}>
          <p>Not logged in</p>
          <Button size="lg" color="primary" className={classNames(styles.button)} onClick={() => routeChange()}>Home</Button>
        </div>
      </Main>
    </div>
  );
}

export default withRouter(NotLoggedIn);
