// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-profileSparksCounter-style__statsItem__ZFSZm{width:calc(50% - .5px);font-family:"Roboto",sans-serif;font-style:normal;font-weight:500;line-height:normal;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.components-profileSparksCounter-style__statsCount__Fypn1{color:#172331;font-size:27px}.components-profileSparksCounter-style__statsText___nY9u{color:#929292;font-size:13px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statsItem": `components-profileSparksCounter-style__statsItem__ZFSZm`,
	"statsCount": `components-profileSparksCounter-style__statsCount__Fypn1`,
	"statsText": `components-profileSparksCounter-style__statsText___nY9u`
};
export default ___CSS_LOADER_EXPORT___;
