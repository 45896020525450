import PropTypes from 'prop-types';
import React from 'react';

export default class ErrorMessage extends React.Component {
  static propTypes = {
    error: PropTypes.object.isRequired,
  }

  render = () => {
    if (this.props.error) {
      return (
        <p>{this.props.error.message}</p>
      );
    }

    return (
      <p>We have a problem!!</p>
    );
  }
}
