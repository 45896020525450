import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";

import styles from "./style.scss";
import { connect } from "react-redux";

const mapStateToProps = function (state) {
  return {
    tagsState: state.hashtag.tags,
  };
};

class ProfileSparksCounter extends React.Component {
  static propTypes = {
    className: PropTypes.string,

    data: PropTypes.shape({
      refetch: PropTypes.func.isRequired,
      error: PropTypes.object,
      loadMoreEntries: PropTypes.func,
      loading: PropTypes.bool.isRequired,
      sparks: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.object.isRequired,
          })
        ).isRequired,
        pageInfo: PropTypes.shape({
          hasNextPage: PropTypes.bool.isRequired,
        }).isRequired,
      }),
    }),
    user: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    user: "",
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { className, data } = this.props;

    if (data.error) {
      return <p>Error</p>;
    }

    return (
      <div className={styles.statsItem}>
        <span className={styles.statsCount}>
          {data?.sparks?.edges.length ? data.sparks.edges.length : 0}
        </span>
        <span className={styles.statsText}>Sparks</span>
      </div>
    );
  }
}

export const SPARKS_QUERY = gql`
  query SparksForHome($cursor: String, $user: String, $tags: [String!]) {
    sparks(first: 1000, after: $cursor, user: $user, tags: $tags) {
      edges {
        node {
          hashid
        }
      }
      __typename
    }
  }
`;

const ProfileSparksCounterWithData = compose(
  connect(mapStateToProps, null),
  graphql(SPARKS_QUERY, {
    options: (props) => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        cursor: null,
        user: props.user,
        tags: [],
      },
    }),
  })
)(ProfileSparksCounter);

export default ProfileSparksCounterWithData;
