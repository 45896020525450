// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-VideoFrameChooser-style__frameUploader__ADQGg{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;gap:9px;background-color:#f5f5f5;min-width:60px !important;height:42px;border-radius:17px}.components-VideoFrameChooser-style__iconWrap__Yzk6L{width:15px !important;height:15px !important}.components-VideoFrameChooser-style__addVideoIconWrap__soTLD{width:15px !important;height:15px !important;margin-bottom:6.5px}.components-VideoFrameChooser-style__uploadInput__TLhk0{display:none}.components-VideoFrameChooser-style__wrapper__K7EIn{width:100%;min-height:47px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start}.components-VideoFrameChooser-style__imgContainer__LOcKl{max-width:100%;display:-webkit-box;-webkit-box-flex:1;-ms-flex:1;flex:1;gap:10px;overflow-x:auto}.components-VideoFrameChooser-style__imgWrapper__P1Kc9{width:45px !important;height:45px !important;cursor:pointer;border-radius:4px;position:relative}.components-VideoFrameChooser-style__img__kOk9L{width:45px !important;height:45px !important;border-radius:3px}.components-VideoFrameChooser-style__arrowLeft___8Csb{-webkit-transform:rotate(90deg);transform:rotate(90deg);margin-top:11px}.components-VideoFrameChooser-style__arrowRight__H4URb{-webkit-transform:rotate(-90deg);transform:rotate(-90deg);margin-top:11px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"frameUploader": `components-VideoFrameChooser-style__frameUploader__ADQGg`,
	"iconWrap": `components-VideoFrameChooser-style__iconWrap__Yzk6L`,
	"addVideoIconWrap": `components-VideoFrameChooser-style__addVideoIconWrap__soTLD`,
	"uploadInput": `components-VideoFrameChooser-style__uploadInput__TLhk0`,
	"wrapper": `components-VideoFrameChooser-style__wrapper__K7EIn`,
	"imgContainer": `components-VideoFrameChooser-style__imgContainer__LOcKl`,
	"imgWrapper": `components-VideoFrameChooser-style__imgWrapper__P1Kc9`,
	"img": `components-VideoFrameChooser-style__img__kOk9L`,
	"arrowLeft": `components-VideoFrameChooser-style__arrowLeft___8Csb`,
	"arrowRight": `components-VideoFrameChooser-style__arrowRight__H4URb`
};
export default ___CSS_LOADER_EXPORT___;
