import React from "react";
import PropTypes from "prop-types";
import styles from "./style.scss";
import gql from "graphql-tag";
import { compose, graphql } from "react-apollo";
import Loading from "sections/Loading";

const GET_FOLLOWERS = gql`
  query GetFollowers($handle: String!) {
    follower(handle: $handle) {
      handle
      is_followed_by_me
      avatar {
        ... on Media {
          hashid
          is_processed
          square_image_sources {
            url
            width
          }
          type
        }
        ... on Url {
          url
        }
      }
    }
  }
`;

const FOLLOW_USER_MUTATION = gql`
  mutation FollowUser($hashid: ID!) {
    followUser(hashid: $hashid) {
      followee {
        followers_count
        hashid
        is_followed_by_me
      }
    }
  }
`;

const UNFOLLOW_USER_MUTATION = gql`
  mutation UnfollowUser($hashid: ID!) {
    unfollowUser(hashid: $hashid) {
      followee {
        followers_count
        hashid
        is_followed_by_me
      }
    }
  }
`;

class Followers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      followers: [],
    };
  }

  componentDidMount() {
    if (!this.props.data.loading && this.props.data.follower) {
      const updatedFollowers = this.props.data.follower.map((user) => ({
        ...user,
        isFollowed: false,
      }));
      this.setState({ followers: updatedFollowers });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.data.loading && prevProps.data !== this.props.data) {
      const updatedFollowers = this.props.data.follower.map((user) => ({
        ...user,
        isFollowed: false,
      }));
      this.setState({ followers: updatedFollowers });
    }
  }

  handleFollow = (index) => {
    const updatedFollowers = [...this.state.followers];
    updatedFollowers[index].isFollowed = true;
    this.setState({ followers: updatedFollowers });
  };

  handleUnfollow = (index) => {
    const updatedFollowers = [...this.state.followers];
    updatedFollowers[index].isFollowed = false;
    this.setState({ followers: updatedFollowers });
  };

  render() {
    const { data } = this.props;

    if (this.props.data.loading) {
      return <Loading />;
    }

    // console.log(data);

    const { followers } = this.state;

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>Followers</div>
        <div className={styles.contentWrapper}>
          <div className={styles.title}>Followers</div>

          <div className={styles.content}>
            <div className={styles.items}>
              {followers.map((user, index) => (
                <div key={index} className={styles.userCardWrapper}>
                  <div className={styles.avatarAndHandleWrap}>
                    {user.avatar.square_image_sources ? (
                      <img
                        src={user.avatar.square_image_sources[0].url}
                        className={styles.userAvatar}
                      />
                    ) : (
                      <img
                        src={user.avatar.url}
                        className={styles.userAvatar}
                      />
                    )}

                    <div className={styles.userHandle}>{user.handle}</div>
                  </div>
                  {user.is_followed_by_me ? (
                    <button
                      className={styles.unfollowBtn}
                      onClick={() => this.handleUnfollow(index)}
                    >
                      Unfollow
                    </button>
                  ) : (
                    <button
                      className={styles.followBtn}
                      onClick={() => this.handleFollow(index)}
                    >
                      Follow
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Followers.propTypes = {
  match: PropTypes.object.isRequired,
  data: PropTypes.shape({
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    user: PropTypes.object,
  }),
};

Followers.defaultProps = {};

export default compose(
  graphql(
    GET_FOLLOWERS,
    {
      options: (props) => ({
        variables: {
          handle: props.match.params.hashid,
        },
      }),
    },
    graphql(FOLLOW_USER_MUTATION, {
      props: ({ ownProps, mutate }) => ({
        followUser: function (variables) {
          return mutate({
            optimisticResponse: {
              followUser: {
                __typename: "FollowEdge",
                followee: {
                  __typename: "User",
                  followers_count: ownProps.data.user.followers_count + 1,
                  hashid: ownProps.data.user.hashid,
                  is_followed_by_me: true,
                },
              },
            },
            variables: variables,
          });
        },
      }),
    })
  )
)(Followers);
