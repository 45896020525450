// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sections-SparkPost-style__pageAudioPlayerNone__LIbWX{display:none}.sections-SparkPost-style__pageAudioPlayerBlock__Lq7WM{display:block}@media screen and (min-width: 280px)and (max-width: 779px){.sections-SparkPost-style__pageAudioPlayerNone__LIbWX{display:none}.sections-SparkPost-style__pageAudioPlayerBlock__Lq7WM{display:none}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageAudioPlayerNone": `sections-SparkPost-style__pageAudioPlayerNone__LIbWX`,
	"pageAudioPlayerBlock": `sections-SparkPost-style__pageAudioPlayerBlock__Lq7WM`
};
export default ___CSS_LOADER_EXPORT___;
