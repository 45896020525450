import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/Button';
import Icon from 'components/Icon';

import styles from './style.scss';

export default class StackViewNavigator extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element,
    ]),
    className: PropTypes.string,
    indexSelected: PropTypes.number,
  }

  static defaultProps = {
    children: [],
    className: '',
    indexSelected: 0,
  }

  render() {
    const {children, className, indexSelected} = this.props;

    return (
      <div className={classNames(styles.root, className)}>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            style: {
              display: index === indexSelected ? 'block' : 'none',
            },
          });
        })}
      </div>
    );
  }
}

export class Navigator extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    hasFormValidation: PropTypes.bool,
    hasNext: PropTypes.bool,
    hasPrevious: PropTypes.bool,
    isNextLocked: PropTypes.bool,
    label: PropTypes.string,
    onNavigate: PropTypes.func,
    previousSteps: PropTypes.number.isRequired,
  }

  static defaultProps = {
    className: '',
    hasFormValidation: true,
    hasNext: true,
    hasPrevious: true,
    isNextLocked: false,
    label: "Next",
    onNavigate: f => f,
    previousSteps: 1,
  }

  handleClick = () => {
    if (!this.props.hasFormValidation) {
      this.props.onNavigate(1);
    }
  }

  render() {
    const {
      className,
      label,
      hasNext,
      hasPrevious,
      hasFormValidation,
      isNextLocked,
      onNavigate,
      previousSteps,
    } = this.props;

    return (
      <div>
        <div className={styles.navigatorPlaceholder} />
        <div className={classNames(styles.navigator, className, "overflow-h", "bg-white")}>
          <div className={styles.navigatorContainer}>
            {hasPrevious &&
              <button
                type="button"
                onClick={() => onNavigate(-previousSteps)}
              >
                <Icon icon="back" />
              </button>
            }
            {hasNext &&
              <Button
                size="sm"
                type={hasFormValidation ? 'submit' : 'button'}
                label={label}
                disabled={isNextLocked}
                onClick={this.handleClick}
                className="float-r text-upper"
              >
                {label}
              </Button>
            }
          </div>
        </div>
      </div>
    );
  }
}
