// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-HeaderForAuthorizedUsers-style__wrapper__HqaTu{height:60px;padding:0 20px;width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;background-color:#fff;position:relative}.components-HeaderForAuthorizedUsers-style__pageName__bJjqR{color:#172331;font-family:"Roboto",sans-serif;font-size:18px;font-style:normal;font-weight:600;line-height:normal;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:50%;height:100%}.components-HeaderForAuthorizedUsers-style__additionalContentWrapper__zSNQO{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:6px;width:25%}.components-HeaderForAuthorizedUsers-style__leftContent__naMBe{-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start}.components-HeaderForAuthorizedUsers-style__rightContent__prZGu{-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}.components-HeaderForAuthorizedUsers-style__additionalContentButton__o1r8C{color:#f75a00;font-family:"Roboto",sans-serif;font-size:15px;font-style:normal;font-weight:600;line-height:normal}.components-HeaderForAuthorizedUsers-style__logo__GAsBc{height:40px}.components-HeaderForAuthorizedUsers-style__simplifiedLogo__M8wJQ{height:60px}.components-HeaderForAuthorizedUsers-style__hidden__ClNxH{opacity:0;pointer-events:none}.components-HeaderForAuthorizedUsers-style__hashtagSearchWrap__Fa2T_{width:30%}.components-HeaderForAuthorizedUsers-style__arrayAvatar__pLA6I{margin-left:10px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `components-HeaderForAuthorizedUsers-style__wrapper__HqaTu`,
	"pageName": `components-HeaderForAuthorizedUsers-style__pageName__bJjqR`,
	"additionalContentWrapper": `components-HeaderForAuthorizedUsers-style__additionalContentWrapper__zSNQO`,
	"leftContent": `components-HeaderForAuthorizedUsers-style__leftContent__naMBe`,
	"rightContent": `components-HeaderForAuthorizedUsers-style__rightContent__prZGu`,
	"additionalContentButton": `components-HeaderForAuthorizedUsers-style__additionalContentButton__o1r8C`,
	"logo": `components-HeaderForAuthorizedUsers-style__logo__GAsBc`,
	"simplifiedLogo": `components-HeaderForAuthorizedUsers-style__simplifiedLogo__M8wJQ`,
	"hidden": `components-HeaderForAuthorizedUsers-style__hidden__ClNxH`,
	"hashtagSearchWrap": `components-HeaderForAuthorizedUsers-style__hashtagSearchWrap__Fa2T_`,
	"arrayAvatar": `components-HeaderForAuthorizedUsers-style__arrayAvatar__pLA6I`
};
export default ___CSS_LOADER_EXPORT___;
