// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-HelpIcon-style__questionIconWrapper__qllGa{position:relative;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;z-index:3;width:24px;height:20px;border-radius:50%;background-color:#d9d9d9;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#172331;font-family:"Poppins",sans-serif;font-size:13px;font-style:normal;font-weight:500;line-height:normal}.components-HelpIcon-style__helperText__mwiJr{color:#000;font-family:"Poppins",sans-serif;font-size:10px;font-style:normal;font-weight:400;line-height:normal;margin-bottom:3px;max-width:92%;white-space:pre-wrap;overflow:hidden}.components-HelpIcon-style__rootIsHelpTextOpen__kTYYD{background-color:#fff;z-index:2;width:100%;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;padding:10px 2px 4px 18px;border-radius:16px;border:1px solid #f75a00}.components-HelpIcon-style__iconWrapperIsHelpTextOpen__cmKm3{width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}.components-HelpIcon-style__hintOpen__hfolW{background-color:#fff;z-index:3;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;border-radius:16px;border:1px solid #f75a00;padding:10px 2px 4px 18px;width:101%;position:absolute;right:-3px;bottom:-5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"questionIconWrapper": `components-HelpIcon-style__questionIconWrapper__qllGa`,
	"helperText": `components-HelpIcon-style__helperText__mwiJr`,
	"rootIsHelpTextOpen": `components-HelpIcon-style__rootIsHelpTextOpen__kTYYD`,
	"iconWrapperIsHelpTextOpen": `components-HelpIcon-style__iconWrapperIsHelpTextOpen__cmKm3`,
	"hintOpen": `components-HelpIcon-style__hintOpen__hfolW`
};
export default ___CSS_LOADER_EXPORT___;
