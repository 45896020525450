// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-Main-style__root__yDq3o{min-height:calc(100vh - 48px);background-color:#f9f9f9}.components-Main-style__root__yDq3o.components-Main-style__full__USafw{position:absolute;top:0;height:100vh;width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-Main-style__root__yDq3o`,
	"full": `components-Main-style__full__USafw`
};
export default ___CSS_LOADER_EXPORT___;
