import React, { useRef, useEffect, useState } from "react";

import styles from "./style.scss";
import PropTypes from "prop-types";

import TrackLine from "./TrackLine";
import VolumeLine from "./VolumeLine";
import IconButton from "components/IconButton";

function PageAudioPlayer({
  isPlaying,
  setIsPlaying,
  setIsOpen,
  trackName,
  groupName,
}) {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(0.5);
  const audioRef = useRef(null);

  const handleTrackClick = (position) => {
    audioRef.current.currentTime = position;
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [audioRef, isPlaying]);

  useEffect(() => {
    audioRef.current.volume = volume;
  }, [audioRef, volume]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {isPlaying ? (
          <IconButton
            icon="pauseBtn"
            ratio="square"
            size="lg"
            onClick={() => {
              setIsPlaying();
            }}
          />
        ) : (
          <IconButton
            icon="playBtn"
            ratio="square"
            size="lg"
            onClick={() => {
              setIsPlaying();
            }}
          />
        )}

        <div className={styles.trackInfo}>
          <div className={styles.description}>
            <span className={styles.groupName}>
              {groupName}-<span className={styles.trackName}>{trackName}</span>
            </span>
            <IconButton
              icon="close"
              size="sm"
              ratio="square"
              onClick={setIsOpen}
              className={styles.closeIcon}
            />
          </div>
          <div className={styles.player}>
            <TrackLine
              currentTime={currentTime}
              duration={duration}
              handleTrackClick={handleTrackClick}
            />
            <audio
              ref={audioRef}
              onLoadedMetadata={(e) => setDuration(e.target.duration)}
              onTimeUpdate={(e) => setCurrentTime(e.target.currentTime)}
            >
              <source src={""} type="audio/mp3" />
            </audio>
            <VolumeLine volume={volume} setVolume={setVolume} />
          </div>
        </div>
      </div>
    </div>
  );
}

PageAudioPlayer.propTypes = {
  setIsPlaying: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired,

  trackName: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  trackImg: PropTypes.string.isRequired,
  track: PropTypes.string.isRequired,
};

export default PageAudioPlayer;
