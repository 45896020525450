// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-NotLoggedIn-styles__button__x6i2f{text-transform:uppercase;font-weight:700;border-radius:15px;font-size:.9em;display:block;margin:10px auto}.components-NotLoggedIn-styles__titleMain__FhJyX{margin-bottom:20px}.components-NotLoggedIn-styles__section__k4UR9:not(:last-child){margin-bottom:40px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `components-NotLoggedIn-styles__button__x6i2f`,
	"titleMain": `components-NotLoggedIn-styles__titleMain__FhJyX`,
	"section": `components-NotLoggedIn-styles__section__k4UR9`
};
export default ___CSS_LOADER_EXPORT___;
