import React, { useRef, useState } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import styles from "./style.scss";

export default function PosterDropDown({
  onChange,
  disableClickOutside = true,
  initiallyOpen = false,
  src,
  index,
  selectedIndex,
  onIndexChange,
  handleImgChange,
  handleDeleteImg,
}) {
  const [isDropDownOpen, setIsDropDownOpen] = useState(initiallyOpen);
  const wrapperRef = useRef(null);

  // const { wrapperRef, dropdownOpen, toggleDropdown, closeDropdown } =
  //   useDropdown(disableClickOutside, initiallyOpen);

  async function downloadImage(url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const objectUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = "img";
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error("error:", error);
    }
    setIsDropDownOpen(false);
  }

  return (
    <>
      <div
        ref={wrapperRef}
        className={cn(styles.wrapper, styles.isPreviewChooser)}
      >
        <div className={styles.imgWrapper}>
          <img
            style={{
              border: index === selectedIndex ? "1px solid #F75A00" : "none",
            }}
            src={src}
            alt={"img"}
            onClick={() => onIndexChange(index, src)}
            className={styles.img}
          />
          <Icon toggleDropdown={() => setIsDropDownOpen(!isDropDownOpen)} />
        </div>
      </div>
      {isDropDownOpen && (
        <div className={styles.dropdown}>
          <ul className={styles.list}>
            <label htmlFor="photoLibrary" className={styles.item}>
              Photo Library
              <input
                id="photoLibrary"
                readOnly
                className={styles.uploadAudioInput}
                type="file"
                onChange={() => setIsDropDownOpen(false)}
              />
            </label>
            <label htmlFor="takePhoto" className={styles.item}>
              Take photo
              <input
                id="takePhoto"
                type="file"
                accept="image/*"
                capture="camera"
                onChange={(event) =>
                  handleImgChange(index, event, setIsDropDownOpen)
                }
                className={styles.uploadAudioInput}
              />
            </label>
            <label htmlFor="chooseFile" className={styles.item}>
              Choose File
              <input
                id="chooseFile"
                accept="image/*"
                className={styles.uploadAudioInput}
                onChange={(event) =>
                  handleImgChange(index, event, setIsDropDownOpen)
                }
                type="file"
              />
            </label>
            <li onClick={() => downloadImage(src)} className={styles.item}>
              Download
            </li>
            <li
              onClick={() => handleDeleteImg(index, setIsDropDownOpen)}
              className={styles.item}
            >
              Delete
            </li>
          </ul>
        </div>
      )}
    </>
  );
}

const Icon = ({ toggleDropdown }) => {
  return (
    <div className={styles.container}>
      <div className={styles.head}>
        <div onClick={toggleDropdown} className={styles.orangeCirclesWrap}>
          <div className={styles.orangeCirclesRow}>
            <div className={styles.orangeCircle}></div>
            <div className={styles.orangeCircle}></div>
          </div>
          <div className={styles.orangeCirclesRow}>
            <div className={styles.orangeCircle}></div>
            <div className={styles.orangeCircle}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

Icon.propTypes = {
  toggleDropdown: PropTypes.func,
};

PosterDropDown.propTypes = {
  onChange: PropTypes.func,
  selectedValue: PropTypes.string,
  disableClickOutside: PropTypes.bool,
  initiallyOpen: PropTypes.bool,
  src: PropTypes.string,
  selectedIndex: PropTypes.number,
  index: PropTypes.number,
  onIndexChange: PropTypes.func,
  handleDeleteImg: PropTypes.func,
  handleImgChange: PropTypes.func,
};

PosterDropDown.defaultProps = {
  selectedValue: "",
  disableClickOutside: false,
  initiallyOpen: false,
};
