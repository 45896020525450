import React from "react";
import s from "./style.scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  changeInputVisibility,
  searchValueChange,
  search,
} from "actions/hashtagSearch";
// import IconButton from "components/IconButton";
import classNames from "classnames";

const mapStateToProps = function (state) {
  return {
    searchValueState: state.hashtag.searchValue,
    isInputHiddenState: state.hashtag.isInputHidden,
  };
};

const mapDispatchToProps = {
  onChangeInputVisibility: changeInputVisibility,
  onSearchValueChange: searchValueChange,
  onSearch: search,
};

class HeadSearch extends React.Component {
  static propTypes = {
    isInputHiddenState: PropTypes.bool,
    searchValueState: PropTypes.string,
  };

  static defaultProps = {
    isInputHiddenState: true,
    searchValueState: "",
  };

  render() {
    const {
      isInputHiddenState,
      searchValueState,
      onChangeInputVisibility,
      onSearchValueChange,
      onSearch,
    } = this.props;

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        if (isInputHiddenState) {
          onChangeInputVisibility(false);
        } else {
          onSearch();
        }
      }
    };

    // const handleHideInput = () => {
    //   onChangeInputVisibility(true);
    // };

    const onClickOrOnDoubleClick = () => {
      const delay = 300;
      if (!onClickOrOnDoubleClick.timer) {
        onClickOrOnDoubleClick.timer = setTimeout(() => {
          onSearch();
          onClickOrOnDoubleClick.timer = null;
        }, delay);
      } else {
        clearTimeout(onClickOrOnDoubleClick.timer);
        onClickOrOnDoubleClick.timer = null;
        onChangeInputVisibility(true);
      }
    };

    return (
      <div
        className={classNames(s.wrapper, {
          [s.whiteBackground]: !isInputHiddenState,
        })}
      >
        <button onClick={onClickOrOnDoubleClick} className={s.iconButton}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="30"
            viewBox="0 0 38 30"
            fill="none"
            className={s.svg}
          >
            <path
              d="M13.7426 20.6108C15.6477 22.6914 18.1847 23.9405 20.8756 24.1226C23.5665 24.3047 26.2257 23.4074 28.352 21.5996L35.6684 29.5788C35.9279 29.8521 36.2754 30.0034 36.6362 29.9999C36.9969 29.9965 37.342 29.8387 37.597 29.5605C37.8521 29.2824 37.9968 28.906 37.9999 28.5126C38.0031 28.1192 37.8644 27.7402 37.6138 27.4572L30.2974 19.478C32.0285 17.0551 32.847 14.0032 32.5858 10.9448C32.3247 7.8864 31.0036 5.05182 28.892 3.01916C26.7804 0.986497 24.0373 -0.0911313 21.2222 0.00604413C18.4072 0.10322 15.7321 1.36788 13.7426 3.54211C12.7146 4.66265 11.8991 5.99311 11.3427 7.45746C10.7864 8.92182 10.5 10.4914 10.5 12.0765C10.5 13.6616 10.7864 15.2311 11.3427 16.6955C11.8991 18.1598 12.7146 19.4903 13.7426 20.6108ZM15.6879 5.6667C17.048 4.18346 18.8375 3.26039 20.7517 3.05477C22.6658 2.84914 24.5861 3.37368 26.1854 4.53902C27.7847 5.70435 28.964 7.43838 29.5224 9.44566C30.0808 11.4529 29.9838 13.6093 29.2479 15.5473C28.512 17.4853 27.1826 19.0851 25.4864 20.0741C23.7902 21.0631 21.8321 21.3801 19.9456 20.9711C18.0591 20.5621 16.361 19.4524 15.1406 17.8311C13.9203 16.2097 13.2531 14.1771 13.2528 12.0795C13.249 10.8875 13.4623 9.70658 13.8804 8.60523C14.2984 7.50388 14.9128 6.50403 15.6879 5.66369V5.6667Z"
              fill="#939393"
            />
            <path
              d="M0 6.66667H18.5V25.3333H0V6.66667Z"
              className={s.tagWhite}
            />
            <path
              d="M0 6.66667H18.5V25.3333H0V6.66667Z"
              className={s.tagGray}
            />
            <path
              d="M7.60156 28L12.748 9.04167H14.1738L9.00781 28H7.60156ZM11.459 28L16.6152 9.04167H18.0215L12.8652 28H11.459ZM18.2168 16.2682H8.35352L8.58789 14.4583H18.4512L18.2168 16.2682ZM16.6738 22.6615H6.80078L7.03516 20.8646H16.9082L16.6738 22.6615Z"
              fill="#939393"
            />
          </svg>
        </button>
        {!isInputHiddenState && (
          <>
            <input
              type="text"
              value={searchValueState}
              className={s.input}
              onKeyDown={handleKeyDown}
              onChange={(e) => onSearchValueChange(e.target.value)}
            />
            {/* <IconButton
              onClick={handleHideInput}
              className={s.closeIcon}
              icon="close"
              size="sm"
              color="gray"
            /> */}
          </>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeadSearch);
