import PropTypes from 'prop-types';
import React from 'react';

import {compose} from 'react-apollo';
import {connect} from 'react-redux';

import {Redirect} from 'react-router-dom';

import Head from 'components/Head';
import Main from 'components/Main';

import Loading from './Loading';

const mapStateToProps = function(state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
  };
};

class ProfileRedirection extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool, // Auth reducer
    user: PropTypes.object, // Auth reducer
  }

  render() {
    const headProps = {
      back: true,
      heading: "Profile",
    };

    if (this.props.authenticated === null) {
      return <Loading headProps={headProps} />;
    }

    if (this.props.authenticated) {
      return <Redirect to={`/profile/${this.props.user.handle}`} />;
    }

    return (
      <div>
        <Head {...headProps} />
        <Main full>
          <p>Not logged in</p>
        </Main>
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps),
)(ProfileRedirection);
