import classNames from "classnames";
import { graphql } from "react-apollo";
import PropTypes from "prop-types";
import React from "react";

import Icon from "components/Icon";
import Loading from "components/Loading";
import Avatar from "components/Avatar";
import Handle from "components/Handle";

import { COMMENT_QUERY } from "components/Comment";
import FormError from "components/FormError";
import VideoUploadButton from "components/VideoUploadButton";

import styles from "./style.scss";
import VideoDropDown from "../../../../components/VideoDropDown";

class ViewUpload extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
      comment: PropTypes.object,
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
    }),
    onNavigate: PropTypes.func,
    onSubmit: PropTypes.func,
    parentHashid: PropTypes.string,
    style: PropTypes.object,
    handleSetData: PropTypes.func.isRequired,
    collabComments: PropTypes.array,
  };

  static defaultProps = {
    className: "",
    onNavigate: (f) => f,
    onSubmit: (f) => f,
    style: {},
    collabComments: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      isNextLocked: true,
      uploadedMedia: null,

      isPlayerOpen: false,
      isTrackPlaying: false,
      trackValues: {
        id: "",
        trackName: "",
        groupName: "",
        trackImg: "",
        track: "",
      },
    };

    this.$form = null;
    this.$message = null;
  }

  setNextStepState = () => {
    const getLocked = () => {
      if (this.state.uploadedMedia === null) {
        return true;
      }
      return false;
    };

    this.setState({
      isNextLocked: getLocked(),
    });
  };

  handleFileComplete = (media) => {
    this.setState(
      {
        uploadedMedia: media,
      },
      this.setNextStepState
    );
  };

  handleMessageInput = () => {
    this.setNextStepState();
  };

  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.state.isNextLocked) {
      return;
    }

    const form = this.$form.elements;

    const data = {
      mediaHashid: form.mediaHashid.value,
      mediaType: form.mediaType.value,
      message: form.message.value,
    };

    this.props.onSubmit(data);
  };

  render() {
    const { className, data, handleSetData, style, collabComments } =
      this.props;

    if (data.error) {
      return <div>Error</div>;
    }

    if (!data.comment && data.loading) {
      return <Loading />;
    }

    return (
      <div
        className={classNames(styles.root, className, "bg-off-white")}
        style={style}
      >
        <div className={styles.content}>
          {/* <div>
            <p>
              Use chosen fan idea in your final Video:
            </p>
            <DataBackedSimpleComment data={data} showVotes />
          </div> */}
          <div className={styles.uploadWrap}>
            <VideoUploadButton
              className={styles.VideoUploadButtonWrap}
              onComplete={this.handleFileComplete}
              endpoint={`/v1/media/video`}
            >
              <Icon icon="addVideo" size="md" />
              Add Video
            </VideoUploadButton>
            <VideoDropDown />
          </div>
          <form
            ref={(ref) => (this.$form = ref)}
            onSubmit={this.handleFormSubmit}
          >
            <input
              type="hidden"
              name="mediaHashid"
              value={
                this.state.uploadedMedia ? this.state.uploadedMedia.hashid : ""
              }
              required
            />
            <input
              type="hidden"
              name="mediaType"
              value={
                this.state.uploadedMedia ? this.state.uploadedMedia.type : ""
              }
              required
            />
            <div>
              {this.state.errors &&
                this.state.errors.fields &&
                this.state.errors.fields.message && (
                  <FormError errors={this.state.errors.fields.message} />
                )}
              {collabComments.length === 0 ? (
                <div className={styles.infoWrap}>
                  <div className={styles.fanAvatarAndNickname}>
                    <Avatar user={data.comment.user} size="md" />
                    <Handle handle={data.comment.user.handle} />
                  </div>
                  <div className={styles.fanComment}>
                    {data.comment.message}
                  </div>
                </div>
              ) : (
                collabComments.map((item, index) => (
                  <div
                    className={classNames(styles.infoWrap, {
                      [styles.marginInfoWrap]: collabComments.length >= 1,
                    })}
                    key={index}
                  >
                    <div className={styles.fanAvatarAndNickname}>
                      <Avatar user={item.user} size="md" />
                      <Handle handle={item.user.handle} />
                    </div>
                    <div className={styles.fanComment}>{item.message}</div>
                  </div>
                ))
              )}

              <div className={styles.votesAndAvatarBlock}>
                <div className={styles.votesBlock}>
                  <Icon icon="heart" color="active" size="lg" filled={true} />
                  <span>{data.comment.votes_count}</span>
                  <p>Votes</p>
                </div>
                <Avatar size="md" />
              </div>
              <textarea
                name="message"
                maxLength="200"
                placeholder="Additional message"
                defaultValue={
                  data.comment && collabComments.length === 0
                    ? `@${data.comment.user.handle} `
                    : collabComments.map((item) => `@${item.user.handle} `)
                }
                onInput={this.handleMessageInput}
                ref={(ref) => (this.$message = ref)}
                className={styles.textArea}
              />
            </div>

            <button
              onClick={() => handleSetData(data)}
              type="submit"
              className={classNames(styles.btn, {
                [styles.disabled]: this.state.isNextLocked,
              })}
            >
              Next
            </button>
            {/* <Navigator
              isNextLocked={this.state.isNextLocked}
              hasPrevious={false}
              onNavigate={onNavigate}
            /> */}
          </form>
        </div>
      </div>
    );
  }
}

const ViewUploadWithData = graphql(COMMENT_QUERY, {
  options: (props) => ({
    variables: {
      hashid: props.parentHashid,
    },
  }),
})(ViewUpload);
export default ViewUploadWithData;
