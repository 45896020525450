import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import s from "./style.scss";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import soundCloudImg from "./soundCloudImg.png";
import PlayButton from "./playButton";

const PAGE_METADATA_QUERY = gql`
  query PageMetadata($url: String!) {
    pageMetadata(url: $url) {
      name
      value
    }
  }
`;

class CommentCard extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    url: PropTypes.string,

    handleChangePlaying: PropTypes.func,
    handlePlayTrack: PropTypes.func,
    isTrackPlaying: PropTypes.bool,
    trackValues: PropTypes.object,
    hidePlayBtn: PropTypes.bool,

    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.object,
      pageMetadata: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    }),
  };

  constructor(props) {
    super(props);

    this.state = {
      id: "",
    };
  }

  generateRandomId(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }

    return randomId;
  }

  componentDidMount() {
    this.setState({
      id: this.generateRandomId(10),
    });
  }

  preparedTrackValues() {
    return {
      id: this.state.id,
      trackName: this.getDataByName(this.props.data.pageMetadata, "og:title"),
      groupName: this.props.url.includes("unitedmasters.com")
        ? this.getDataByName(this.props.data.pageMetadata, "description")
        : this.getName(
            this.getDataByName(this.props.data.pageMetadata, "description")
          ),
      trackImg: "",
      track: "",
    };
  }

  handlePlayMusic() {
    if (this.props.trackValues.id !== this.state.id) {
      this.props.handlePlayTrack(this.preparedTrackValues());
    } else {
      this.props.handleChangePlaying(true);
    }
  }

  getDataByName(metadata, nameToFind) {
    if (!metadata) {
      return -1;
    }
    for (const item of metadata) {
      if (item.name === nameToFind) {
        return item.value;
      }
    }
    return -1;
  }

  getName(text) {
    const match = text.match(/by\s(.*?)\son/);
    if (match) {
      return match[1];
    }
    return null;
  }

  render() {
    const { data } = this.props;
    if (data.loading) {
      return <React.Fragment></React.Fragment>;
    }

    if (data.error) {
      return <React.Fragment></React.Fragment>;
    }

    if (
      !this.props.url.includes("unitedmasters.com")
        ? this.getDataByName(data.pageMetadata, "og:site_name") === -1 ||
          this.getDataByName(data.pageMetadata, "og:site_name") !== "SoundCloud"
        : false
    ) {
      return <React.Fragment></React.Fragment>;
    }

    const handleShowIframeTag = (tag) => {
      this.setState({ iframe: tag, isTrackFetched: true });
    };
    const isLoadingChange = (value) => {
      this.setState({ loading: value });
    };
    const setError = (error) => {
      this.setState({ error });
    };

    const handleSelectDisplayVariant = (number) => {
      this.setState({ displayVariant: number });
    };

    return (
      <div>
        <div className={cx(s.root, this.props.className)}>
          <div className={s.header}>
            {this.props.url.includes("unitedmasters.com")
              ? "Unitedmasters"
              : this.getDataByName(data.pageMetadata, "og:site_name") ===
                  "SoundCloud" && (
                  <div className={s.soundCloudIconWrap}>
                    <img src={soundCloudImg} className={s.soundCloudIcon} />
                  </div>
                )}
            {!this.props.url.includes("unitedmasters.com") && (
              <span>
                {this.getDataByName(data.pageMetadata, "og:site_name")}
              </span>
            )}
          </div>
          <div className={s.flexRow}>
            <div className={s.flexCol}>
              <span>
                {this.props.url.includes("unitedmasters.com")
                  ? this.getDataByName(data.pageMetadata, "description")
                  : this.getName(
                      this.getDataByName(data.pageMetadata, "description")
                    )}
              </span>
              <span>{this.getDataByName(data.pageMetadata, "og:title")}</span>
            </div>
            {!this.props.url.includes("unitedmasters.com") ? (
              <PlayButton
                url={this.getDataByName(data.pageMetadata, "og:url")}
                isTrackFetched={this.state.isTrackFetched}
                isLoadingChange={isLoadingChange}
                handleShowIframeTag={handleShowIframeTag}
                setError={setError}
                isLoading={this.state.loading}
                hidePlayBtn={this.props.hidePlayBtn}
              />
            ) : (
              ""
            )}
          </div>
          {this.state.iframe !== "" ? (
            <div dangerouslySetInnerHTML={{ __html: this.state.iframe }} />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

const CommentCardWithData = graphql(PAGE_METADATA_QUERY, {
  options: (props) => ({
    variables: {
      url: props.url,
    },
  }),
})(CommentCard);

export default CommentCardWithData;
