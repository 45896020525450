import classNames from 'classnames';
import moment from 'moment';
import HttpStatus from 'http-status-codes';
import PropTypes from 'prop-types';
import React from 'react';

import {
  addAuthToXhr,
  getAccessTokenExpiryTime,
  haveAccessToken,
  refreshAccessToken,
  resolveEndpoint,
} from '~/auth';

import Button from 'components/Button';
import {GraphqlBackedMedia} from 'components/Media';
import Loading from 'components/Loading';
import ProgressBar from 'components/ProgressBar';

import styles from './style.scss';

const STATUS_IDLE = 0;
const STATUS_INITIALIZING = 1;
const STATUS_UPLOADING = 2;
const STATUS_AWAITING_RESPONSE = 3;
const STATUS_FINISHED = 4;
const STATUS_FAILED = 5;

export default class UploadButton extends React.Component {
  static propTypes = {
    accept: PropTypes.string,
    buttonSize: PropTypes.string,
    children: PropTypes.element,
    className: PropTypes.string,
    endpoint: PropTypes.string,
    onComplete: PropTypes.func,
    onError: PropTypes.func,
    plain: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    accept: [
      'image/png',
      '.png',
      'image/jpeg',
      '.jpg',
      '.jpeg',
      'image/webp',
      '.webp',
      'video/*',
    ].join(','),
    buttonSize: 'md',
    children: [],
    className: '',
    endpoint: '/v1/media/upload',
    media: null,
    onComplete: f => f,
    onError: f => f,
    plain: false,
  }

  constructor() {
    super();

    this.state = {
      progress: 0,
      status: STATUS_IDLE,
    };

    this.fileUploadXhr = null;

    this.$form = null;
    this.$input = null;
  }

  abortFileUpload = () => {
    if (this.fileUploadXhr) {
      this.fileUploadXhr.abort();
    }
    this.setState({
      progress: 0,
      status: STATUS_IDLE,
    });
  }

  reset = () => {
    this.abortFileUpload();
  }

  /**
   * Event onChange handler
   * @param {object} event event
   * @returns {void}
   */
  handleChange = () => {
    // Abort any existing upload
    this.abortFileUpload();

    // Do nothing if we don't have exactly one file selected
    if (this.getFiles().length !== 1) {
      this.setState({
        progress: 0,
        status: STATUS_IDLE,
      });
      return;
    }

    // Get form data
    const formData = new FormData(this.$form);

    // Start with a fulfilled promise
    let promise = Promise.resolve();

    // If token will expire soon, force a refresh first
    if (haveAccessToken() && getAccessTokenExpiryTime().isBefore(moment().add(2, 'minutes'))) {
      this.setState({
        progress: 0,
        status: STATUS_INITIALIZING,
      });
      promise = promise.then(refreshAccessToken).catch(() => {
        // TODO: deal with this
      });
    }

    promise.then(() => {
      this.fileUploadXhr = new XMLHttpRequest();

      // Listen for progress, error, and completion events
      this.fileUploadXhr.upload.onprogress = this.handleFileUploadRequestProgress;
      this.fileUploadXhr.upload.onload = this.handleFileUploadRequestLoad;
      this.fileUploadXhr.upload.onerror = this.handleFileUploadRequestError;
      this.fileUploadXhr.onprogress = this.handleFileUploadResponseProgress;
      this.fileUploadXhr.onload = this.handleFileUploadResponseLoad;
      this.fileUploadXhr.onerror = this.handleFileUploadResponseError;

      // Upload the file
      this.fileUploadXhr.open('post', resolveEndpoint(this.props.endpoint));
      addAuthToXhr(this.fileUploadXhr);
      this.fileUploadXhr.send(formData._blob ? formData._blob() : formData);

      this.setState({
        progress: 0,
        status: STATUS_UPLOADING,
      });
    });
  }

  handleFileUploadRequestProgress = (event) => {
    this.setState({
      progress: event.loaded / event.total,
      status: event.loaded < event.total ? STATUS_UPLOADING : STATUS_AWAITING_RESPONSE,
    });
  }

  handleFileUploadRequestLoad = () => {
    this.setState({
      progress: null,
      status: STATUS_AWAITING_RESPONSE,
    });
  }

  handleFileUploadRequestError = () => {
    this.setState({
      progress: 0,
      status: STATUS_FAILED,
    });
  }

  handleFileUploadResponseProgress = () => {
    this.setState({
      progress: null,
      status: STATUS_AWAITING_RESPONSE,
    });
  }

  handleFileUploadResponseLoad = () => {
    if (this.fileUploadXhr.status !== HttpStatus.OK) {
      this.setState({
        progress: 0,
        status: STATUS_FAILED,
      });
      return;
    }
    let data = JSON.parse(this.fileUploadXhr.response);

    this.setState({
      media: data.media,
      progress: 1,
      status: STATUS_FINISHED,
    });

    this.props.onComplete(data.media);
  }

  handleFileUploadResponseError = () => {
    this.setState({
      progress: 0,
      status: STATUS_FAILED,
    });
  }

  handleTryAgainClick = () => {
    this.setState({
      progress: 0,
      status: STATUS_IDLE,
    });
  }

  handleCancelClick = () => {
    this.abortFileUpload();
  }

  /**
   * Returns all files selected from input
   * @returns {array} Files
   */
  getFiles() {
    return this.$input.files;
  }

  render() {
    const {
      accept,
      buttonSize,
      children,
      className,
      plain,
    } = this.props;
    const status = this.state.status;
    const finished = status === STATUS_FINISHED;

    return (
      <form
        ref={ref => this.$form = ref}
        className={classNames(
          styles.root,
          !plain && styles.styled,
          !plain && (finished ? styles.finished : 'ratio ratio--square'),
          className,
        )}
      >
        {status === STATUS_IDLE &&
          <input
            ref={ref => this.$input = ref}
            accept={accept}
            name="file"
            type="file"
            onChange={this.handleChange}
          />
        }
        <div
          className={classNames(
            !plain && !finished && 'ratio__block',
            styles.container,
          )}
        >
          {status === STATUS_IDLE && children}
          {status === STATUS_INITIALIZING && !plain &&
            <div>
              <ProgressBar />
            </div>
          }
          {status === STATUS_UPLOADING &&
            <div>
              {!plain &&
                <ProgressBar className={styles.gutter} fraction={this.state.uploadProgress} />
              }
              <Button className={styles.gutter} size={buttonSize} onClick={this.handleCancelClick}>
                Cancel
                {plain &&
                  <span>
                    {' '}
                    <Loading inline size="sm" color="white" />
                  </span>
                }
              </Button>
            </div>
          }
          {status === STATUS_AWAITING_RESPONSE &&
            <div>
              {!plain &&
                <ProgressBar />
              }
              <Button className={styles.gutter} size={buttonSize} onClick={this.handleCancelClick}>
                Cancel
                {plain &&
                  <span>
                    {' '}
                    <Loading inline size="sm" color="white" />
                  </span>
                }
              </Button>
            </div>
          }
          {status === STATUS_FAILED &&
            <div>
              {!plain &&
                <p className="align-c">Upload failed.</p>
              }
              <Button className={styles.gutter} size={buttonSize} onClick={this.handleTryAgainClick}>
                {plain && 'Failed. '}
                Try again
              </Button>
            </div>
          }
          {finished && !plain &&
            <GraphqlBackedMedia
              size="lg"
              hashid={this.state.media.hashid}
            />
          }
        </div>
      </form>
    );
  }
}
