import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import {
  curry,
  filter,
  flow,
  get,
  intersection,
  isEmpty,
  isEqual,
  join,
  last,
  map,
  reject,
  split,
  uniqueId,
} from "lodash/fp";
import mousetrap from "mousetrap";

import Tag from "./elements/Tag";

import styles from "./style.scss";
import Alert from "../Alert";

const KEYBOARD_SHORTCUTS = ["enter", "esc", "backspace", "mod+a", "mod+v"];

const parseTags = map((t) => ({ ...t, __id: uniqueId("tag") }));
const stripIds = map((t) => ({ ...t, __id: null }));

const getPlainTextTags = flow(map(get("value")), join(","));

const hasBlacklistedChars = curry((blacklist, str) =>
  flow(split(""), intersection(blacklist), (i) => !isEmpty(i))(str)
);

const parseValuesWith = curry((blacklist, str) =>
  flow(
    split(","),
    map((value) => {
      if (hasBlacklistedChars(blacklist, value)) {
        return false;
      }
      return { value };
    }),
    reject(isEmpty)
  )(str)
);

// https://github.com/sentisis/react-tags-input

export default class TagsInput extends React.Component {
  static propTypes = {
    // Characters not allowed in the tags, defaults to `[',']` and must always
    // contain `,`
    blacklistChars: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    dataTut: PropTypes.string,
    // Error message rendered below the field. When the field is set it will
    // also has the class `is-error`
    error: PropTypes.string,
    // Rendered above the field itself
    label: PropTypes.string,
    // Same as the standard React SyntheticEvent
    onBlur: PropTypes.func,
    // Fired when changing the tags with the `tags` array as the argument
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    // Fired when the user interaction is considered complete, invoked with `tags`
    onSubmit: PropTypes.func,
    placeholder: PropTypes.string,
    // Array of tags to display
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
      })
    ),
  };

  static defaultProps = {
    blacklistChars: [","],
    className: "",
    onBlur: (f) => f,
    onChange: (f) => f,
    onFocus: (f) => f,
    onSubmit: (f) => f,
    tags: [],
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      // ID internally assigned to the input. You don't want to change this
      id: uniqueId("TagsInput_"),
      // When in focus
      isFocused: false,
      // When in selection mode renders a texarea containing comma-separated
      // list of tag values
      isSelectMode: false,
      tags: parseTags(props.tags),
      // Value is what the user is typing in the input
      value: "",
      isAlertShowed: false,
    };

    // Input events
    this.handleBlurInput = this.handleBlurInput.bind(this);
    this.handleFocusInput = this.handleFocusInput.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleClickFauxInput = this.handleClickFauxInput.bind(this);
    this.handleShortcut = this.handleShortcut.bind(this);

    // Textarea for copy/paste
    this.handleBlurTextarea = this.handleBlurTextarea.bind(this);
    this.handleFocusTextarea = this.handleFocusTextarea.bind(this);

    // Other events
    this.handleClickTagButton = this.handleClickTagButton.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.tags, nextProps.tags)) {
      this.setState({
        isPasteMode: false,
        tags: parseTags(nextProps.tags),
        value: "",
      });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    // When the state is in pasteMode and we have a value, parse the value
    // and reset it
    if (nextState.isPasteMode && nextState.value) {
      const parseValue = parseValuesWith(this.props.blacklistChars);
      const tags = [...nextProps.tags, ...parseValue(nextState.value)];

      // We can't change the state here, rely on the onChange event to reset
      // the state.
      //
      // It works a folows:
      //   1. onChange is fired with new tags
      //   2. The element using this component will pass down the new tags
      //   3. In the UNSAFE_componentWillReceiveProps lyfecycle the value is reset
      nextProps.onChange(tags);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // When going from select mode to normal, focus again on the input.
    // This must live in the didUpdate lyfecycle because the filed needs to be
    // already renderered with the correct params.
    if (prevState.isSelectMode && !this.state.isSelectMode) {
      this.input.focus();
    }
  }

  handleFocusInput() {
    // Ignore the event in selectMode
    if (this.state.isSelectMode) {
      return;
    }

    this.setState({
      isFocused: true,
    });
    this.props.onFocus();
    this.attachListeners();
  }

  handleBlurInput() {
    // Ignore the blur event in selectMode
    if (this.state.isSelectMode) {
      return;
    }

    this.setState({
      isFocused: false,
    });
    this.props.onBlur();
    this.removeListeners();
  }

  spaceHasBeenClicked() {
    this.setState({
      isAlertShowed: true,
    });
    setTimeout(() => {
      this.setState({
        isAlertShowed: false,
      });
    }, 2000);
  }

  handleChangeInput(event) {
    const value = event.target.value;
    const lastChar = last(value);

    if (lastChar === " ") {
      this.spaceHasBeenClicked();
    }

    // If the value ends with a comma and it's not just a comma create a new
    // tag.
    // The order is imorant, this conditions goes before the blacklisting
    if (value.length > 1 && lastChar === ",") {
      return this.createTag();
    }

    // Ignore leading white spaces
    if (value.length === 1 && value === " ") {
      return null;
    }

    if (this.props.blacklistChars.includes(lastChar)) {
      return null;
    }

    return this.setState({
      value,
    });
  }

  handleClickFauxInput(event) {
    if (this.state.isFocused) {
      event.preventDefault();
      event.stopPropagation();

      return;
    }

    this.input.focus();
  }

  handleFocusTextarea() {
    mousetrap.bind("mod+c", () => this.handleShortcut("mod+c"));
  }

  handleBlurTextarea() {
    this.setState({
      isSelectMode: false,
    });
    mousetrap.unbind("mod+c");
  }

  // Fire a change event without the passed tag
  handleClickTagButton(id) {
    const tags = flow(
      filter((t) => t.__id !== id),
      stripIds
    )(this.state.tags);

    this.props.onChange(tags);
  }

  handleShortcut(type) {
    switch (type) {
      // Create a new tag
      case "enter": {
        // Whe the user press enter and there is no text currently being
        // wrtitten, fire the onSubmit event
        if (!this.state.value.length) {
          return this.submitTags();
        }

        return this.createTag();
      }

      case "esc": {
        // Reset the input value when pressing esc if we have a value
        if (this.state.value) {
          return this.setState({
            value: "",
          });
        }

        return this.input.blur();
      }

      // When the value of the input is empty and the user presses backspace,
      // delete the previous tag
      case "backspace": {
        if (this.state.value.length || !this.props.tags.length) {
          return null;
        }

        // Remove the last tag in the array (in an immutable way)
        const tags = [...this.props.tags];
        tags.pop();

        return this.props.onChange(tags);
      }

      // Select the content of the plaintext field
      case "mod+a": {
        // Ignore the event if the user is typing
        if (this.state.value.length) {
          return null;
        }

        return this.setState({
          isSelectMode: true,
        });
      }

      // The user copied to the clipboard, so reset the selcted state
      case "mod+c":
        return window.setTimeout(
          () => this.setState({ isSelectMode: false }),
          100
        );

      // When pasting, we'll receive the data in the next state update
      case "mod+v":
        return this.setState({ isPasteMode: true });

      default:
        return null;
    }
  }

  // Listen for keys and key combinations
  attachListeners() {
    KEYBOARD_SHORTCUTS.forEach((key) =>
      mousetrap.bind(key, () => this.handleShortcut(key))
    );
  }

  // Remove all the document listeners
  removeListeners() {
    KEYBOARD_SHORTCUTS.forEach((key) => mousetrap.unbind(key));
  }

  // Create a tag from the current state value
  createTag() {
    const tags = [
      ...this.state.tags,
      {
        value: this.state.value,
      },
    ];

    this.props.onChange(tags);
  }

  submitTags() {
    const tags = stripIds(this.state.tags);
    this.props.onSubmit(tags);
  }

  textareaRef(el) {
    if (!el) {
      return;
    }

    // Focus on the textarea
    el.focus();
  }

  renderActiveTags() {
    return this.state.tags.map((tag) => (
      <div key={tag.__id} className={styles.tag}>
        <Tag
          onClick={() => this.handleClickTagButton(tag.__id)}
          value={tag.value}
        />
      </div>
    ));
  }

  render() {
    const { className, dataTut, error, placeholder } = this.props;

    const inputRef = (el) => {
      this.input = el;
    };
    const inputWidth = `${this.state.value.length + 1}ch`;
    const inputClassName = classNames(styles.input, {
      [`styles.isError`]: error,
      [`styles.isFocused`]: this.state.isFocused || this.state.isSelectMode,
      [`styles.isSelected`]: this.state.isSelectMode,
    });

    let activeTags = null;

    // In selectMode render a texarea whith the content already pre-selected.
    // Why a texarea? Beacause it can wrap to the next line, an input can't
    if (this.state.isSelectMode) {
      activeTags = (
        <textarea
          className={classNames(styles.textarea, "mousetrap")}
          value={getPlainTextTags(this.state.tags)}
          ref={(el) => this.textareaRef(el)}
          onFocus={this.handleFocusTextarea}
          onBlur={this.handleBlurTextarea}
          readOnly
        />
      );
    } else {
      activeTags = this.renderActiveTags();
    }

    let placeholderBlock = null;
    if (placeholder && !this.state.value && !this.state.tags.length) {
      placeholderBlock = (
        <span className={styles.placeholder}>{placeholder}</span>
      );
    }

    return (
      <div className={classNames(styles.root, className)} data-tut={dataTut}>
        {this.state.isAlertShowed && (
          <Alert
            type="warning"
            message={"Warning! Use <Enter> to save the hashtag."}
          />
        )}

        <div
          className={classNames(styles.field, {
            [styles.textAreaWarning]: this.state.isAlertShowed,
          })}
        >
          <div className={inputClassName} onClick={this.handleClickFauxInput}>
            {activeTags}
            {placeholderBlock}

            <input
              type="text"
              id={this.state.id}
              style={{ width: inputWidth }}
              className={classNames(styles.text, "mousetrap")}
              value={this.state.value}
              ref={inputRef}
              onChange={this.handleChangeInput}
              onBlur={this.handleBlurInput}
              onFocus={this.handleFocusInput}
            />
          </div>
        </div>
      </div>
    );
  }
}
