/**
 * Handle a validation error returned by a graphql mutation.
 *
 * @param {Error} error - The error from GraphQL
 * @param {string[]} acceptedFields - An array of field names which will be
 * handled. Any other named fields encountered will be included in the
 * 'unhandledFields' object.
 *
 * @return {object} Object which may have any combination of the keys fields,
 * unhandledFields, general.
 */
export default function getErrors(error, acceptedFields) {
  // If this doesn't look like the kind of error we know how to deal with,
  // re-raise it
  if (!error.graphQLErrors) {
    throw error;
  }

  const errors = {};

  // Loop through all errors
  for (const gqlError of error.graphQLErrors) {
    // Handle validation errors specially
    if (gqlError.validation) {
      // Loop through returned field names
      for (const field in gqlError.validation) {
        const contents = gqlError.validation[field];

        if (field === '') {
          if (!('general' in errors)) {
            errors.general = [];
          }
          errors.general.push(...contents);
        } else if (acceptedFields.includes(field)) {
          if (!('fields' in errors)) {
            errors.fields = {};
          }
          errors.fields[field] = contents;
        } else {
          if (!('unhandledFields' in errors)) {
            errors.unhandledFields = {};
          }
          errors.unhandledFields[field] = contents;
        }
      }
    } else if (gqlError.message) {
      if (!('general' in errors)) {
        errors.general = [];
      }
      errors.general.push(gqlError.message);
    }
  }

  return errors;
}
