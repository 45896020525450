import React from "react";
import styles from "./style.scss";
// import talentPartnerWebsiteLogo from "./../../../public/images/talentPartnerWebsiteLogo.png";
import DeltaIceHawksSparkCards from "components/DeltaIceHawksSparkCards";

class DeltaIceHawks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGetSparkitPopUpOpen: false,
    };
  }

  handleOpenGetSparkitPopUp = () => {
    this.setState({ isGetSparkitPopUpOpen: true });
  };

  handleCloseGetSparkitPopUp = () => {
    this.setState({ isGetSparkitPopUpOpen: false });
  };

  render() {
    return (
      <div className={styles.wrapper}>
        {this.state.isGetSparkitPopUpOpen && (
          <div
            className={styles.popUpGlobalWrapper}
            onClick={this.handleCloseGetSparkitPopUp}
          >
            <div className={styles.popUpWrapper}>
              <div className={styles.popUpTextWrap}>
                <div className={styles.popUpText}>
                  <div>{` About Sparkit.buzz`}</div>
                  With our cutting-edge <br />
                  collaboration platform, Sparkit <br />
                  Technologies brings together Pro <br />
                  Athletes, Sports Teams and their <br />
                  SuperFans in a digital engagement <br />
                  and advertising revolution.
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className={`${styles.headerWrapper} ${
            this.state.isGetSparkitPopUpOpen ? "" : styles.stickyHeaderWrapper
          }`}
        >
          <div className={styles.container}>
            <button
              onClick={this.handleOpenGetSparkitPopUp}
              className={`${styles.button} ${styles.aboutSparkBtn}`}
            >
              Get Sparkit
            </button>
            <div className={styles.endContent}>
              <a
                href="https://exch.sparkit.buzz/api/auth/signUpIframeAdvertisers"
                target="_blank"
                rel="noreferrer"
                className={`${styles.button} ${styles.signInBtn}`}
              >
                Sign In/Sign Up
              </a>
            </div>
          </div>
        </div>
        <div className={styles.sparksWrapper}>
          <DeltaIceHawksSparkCards deltaIceHawksPage />
        </div>
      </div>
    );
  }
}

DeltaIceHawks.propTypes = {};

DeltaIceHawks.defaultProps = {};

export default DeltaIceHawks;
