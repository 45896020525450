import jwtDecode from "jwt-decode";
import PropTypes from "prop-types";
import React from "react";

import Main from "components/Main";

import RegistrationForm from "components/RegistrationForm";
import HeaderForAuthorizedUsers from "../components/HeaderForAuthorizedUsers";

export default class AuthRegister extends React.Component {
  static propTypes = {
    location: PropTypes.object,
  };

  constructor() {
    super();

    this.accessToken = null;

    this.state = {
      registrationError: null,
      showSuccess: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.accessToken = new URLSearchParams(this.props.location.search).get(
      "accessToken"
    );

    this.setState({
      registrationError: null,
      showSuccess: false,
    });
  }

  componentWillUnmount() {
    this.accessToken = null;
  }

  tokenValid = () => {
    try {
      let decoded = jwtDecode(this.accessToken);
      return (
        decoded.via &&
        decoded.via === "email" &&
        decoded.email &&
        decoded.exp > Date.now() / 1000
      );
    } catch (error) {
      // Ignore
    }
    return false;
  };

  getEmail = () => {
    try {
      let decoded = jwtDecode(this.accessToken);
      return decoded.email;
    } catch (error) {
      return null;
    }
  };

  getReferralId = () => {
    try {
      let decoded = jwtDecode(this.accessToken);
      return decoded.referral_id;
    } catch (error) {
      return null;
    }
  };

  render = () => {
    let content = null;

    if (this.state.showSuccess) {
      content = <p>Thanks for registering. You’ve been logged in.</p>;
    } else if (!this.tokenValid()) {
      content = (
        <p className="add-padding">
          No valid token was found. Make sure you’re using the entire URL from
          the email you received.
        </p>
      );
    } else {
      content = (
        <RegistrationForm
          accessToken={this.accessToken}
          viaEmail
          email={this.getEmail()}
          referralId={this.getReferralId()}
          fullPage
        />
      );
    }

    return (
      <div>
        {/* <Head heading="Register" /> */}
        <HeaderForAuthorizedUsers
          heading="Sign-Up"
          leftContent={{ content: { type: "logo" } }}
        />
        <Main className="bg-off-white">
          {/* <h1 className="title title-lg add-padding">Register</h1> */}
          {content}
        </Main>
      </div>
    );
  };
}
