
import React, {useState} from 'react';
import PropTypes from 'prop-types';

import styles from "./style.scss";

function RangePicker({min, max, handleChange, value}) {
  const [isHovered, setIsHovered] = useState(false);
  const percentage = ((value - min) / (max - min)) * 100;

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const handleInputChange = (e) => {
    handleChange(parseFloat(e.target.value));
  };

  const inlineStyle = {
    backgroundImage: `-webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(${percentage}%, ${isHovered ? '#F75A00' : '#F75A00'}),
      color-stop(${percentage}%, #6a6a6a)
    )`,
  };

  return (
    <input
      className={styles.range}
      max={max}
      min={min}
      onChange={handleInputChange}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      step="0.01"
      style={inlineStyle}
      type="range"
      value={value}
    />
  );
}

RangePicker.defaultProps = {
  min: 0,
};

RangePicker.propTypes = {
  max: PropTypes.number.isRequired,
  min: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default RangePicker;
