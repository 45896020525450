import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./style.scss";

export default class Ad extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    offer: PropTypes.object,
    onClick: PropTypes.func,
    isThereBackground: PropTypes.bool,
  };

  static defaultProps = {
    offer: null,
    isThereBackground: true,
  };

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    const offer = this.props.offer;

    if (offer.file_url) {
      return (
        <div
          className={classNames(styles.root, this.props.className, {
            [styles.isThereBackground]: this.props.isThereBackground,
          })}
          onClick={this.handleClick}
        >
          <a href={offer.offer_url} target="_blank" rel="noreferrer">
            <img src={offer.file_url} className={classNames(styles.img)} />
          </a>
        </div>
      );
    }

    return (
      <div
        className={classNames(styles.iframeRoot, this.props.className, {
          [styles.isThereBackground]: this.props.isThereBackground,
        })}
        onClick={this.handleClick}
      >
        {/* <a href={offer.offer_url} target='_blank'>
          <img src={adImage}  className={classNames(styles.img)} />
        </a> */}
        <iframe
          src={offer.ad_url}
          className={styles.iframe}
          scrolling="no"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        />
      </div>
    );
  }
}
