import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Icon from "components/Icon";

import styles from "./style.scss";

export default class VotesRemainingCounter extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    count: PropTypes.number,
  };

  render() {
    const {className, count, ...otherProps} = this.props;

    return (
      <div
        className={classNames(
          styles.root,
          className,
          this.props.count <= 0 && styles.empty
        )}
        {...otherProps}
      >
        <Icon
          className={styles.icon}
          icon="whiteHeart"
          size="md"
        />
        <div className={styles.countBox}>
          <span className={styles.count}>{this.props.count}</span>{" "}
          <span className={styles.text}>{count === 1 ? "Vote" : "Votes"}</span>
        </div>
      </div>
    );
  }
}
