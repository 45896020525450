import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './style.scss';

export default class Main extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    full: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    full: false,
  }

  render() {
    const {
      children,
      className,
      full,
    } = this.props;

    return (
      <main className={classNames('main', className, styles.root, full && styles.full)}>
        {children}
      </main>
    );
  }
}
