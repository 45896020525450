import React from 'react';
import classNames from 'classnames';

import Button from 'components/Button';

import {logout} from '~/auth';

import styles from './style.scss';

export default class LogOutButton extends React.Component {
  render = () => {
    function handleLogout() {
      logout().then(() => {
      });
    }

    return (
      <Button size="sm" color="primary" onClick={handleLogout} className={classNames(styles.button)}>Sign Out</Button>
    );
  }
}
