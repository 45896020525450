import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/Button';

import styles from './style.scss';

export default class CTA extends React.Component {
  static propTypes = {
    buttonLink: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    message: PropTypes.string.isRequired,
  }

  static defaultProps = {
    color: "primary",
  }

  render() {
    return (
      <div className={classNames(styles.root, this.props.className, `bg-${this.props.color}`)}>
        <h1 className={classNames(styles.title, "title", "title-md")}>
          {this.props.message}
        </h1>
        <Button size="md" color={this.props.color} link={this.props.buttonLink} border={true}>
          {this.props.buttonText}
        </Button>
      </div>
    );
  }
}
