import React, { Component } from "react";
import styles from "./style.scss";
import IconButton from "components/IconButton";
import Icon from "components/Icon";
import PropTypes from "prop-types";
import classNames from "classnames";

class VoiceRecorder extends Component {
  static propTypes = {
    className: PropTypes.string,
    handle: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    handle: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      recording: false,
      isUploaded: null,
      audioURL: null,
      audioFileName: "",
      audioDuration: 0,
      currentTime: 0,
      isPlaying: false,
      accessDenied: false,
    };
    this.mediaRecorder = null;
    this.audioRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isUploaded && this.state.audioURL && !this.state.recording) {
      this.setState({ isUploaded: true });
    }
  }

  startRecording = () => {
    if (!navigator.mediaDevices) {
      console.error("navigator.mediaDevices is not supported in this browser");
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.mediaRecorder = new MediaRecorder(stream);
        const chunks = [];

        this.mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
        this.mediaRecorder.onstop = () => {
          const blob = new Blob(chunks, { type: "audio/mp3" });
          const audioURL = URL.createObjectURL(blob);
          this.setState({ audioURL });

          const audio = new Audio(audioURL);
          audio.addEventListener("loadedmetadata", () => {
            this.setState({
              audioDuration: Math.round(audio.duration),
            });
          });
        };

        this.mediaRecorder.start();
        this.setState({ recording: true });
      })
      .catch((err) => {
        console.error("Unable to access microphone", err);
        this.setState({ accessDenied: true });
      });
  };

  stopRecording = () => {
    if (this.mediaRecorder && this.state.recording) {
      this.mediaRecorder.stop();
      this.setState({ recording: false });
    }
  };

  toggleRecording = () => {
    if (this.state.recording) {
      this.stopRecording();
    } else {
      this.startRecording();
    }
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    const audioFileName = file
      ? file.name
      : `${this.props.handle} Voice Over.mp3`;
    this.setState({ audioFileName });

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ audioURL: e.target.result });

        const audio = new Audio(e.target.result);
        audio.addEventListener("loadedmetadata", () => {
          this.setState({
            audioDuration: Math.round(audio.duration),
          });
        });
      };
      reader.readAsDataURL(file);
    }
  };

  togglePlay = () => {
    const audio = this.audioRef.current;

    if (!audio) {
      return;
    }

    if (!this.state.isPlaying) {
      audio.currentTime = this.state.currentTime;
      audio.play();
      this.setState({ isPlaying: true });
    } else {
      audio.pause();
      this.setState({ isPlaying: false });
    }
  };

  handleTimeUpdate = () => {
    const audio = this.audioRef.current;

    if (!audio) {
      return;
    }

    this.setState({ currentTime: Math.round(audio.currentTime) });
  };

  handleDownloadRecord = () => {
    const { audioURL, audioFileName } = this.state;
    if (audioURL) {
      const link = document.createElement("a");
      link.href = audioURL;
      link.download = audioFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  render() {
    const {
      recording,
      audioURL,
      audioFileName,
      audioDuration,
      currentTime,
      isPlaying,
      accessDenied,
    } = this.state;

    if (accessDenied) {
      return <div>Access to the microphone is blocked</div>;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.audioUploader}>
          <button onClick={this.toggleRecording} disabled={recording}>
            {recording ? (
              <IconButton
                icon="microphoneOff"
                onClick={() => this.setState({ isUploaded: false })}
                size="sm"
                className={styles.microphoneIcon}
              />
            ) : (
              <IconButton
                icon="microphone"
                onClick={() => this.setState({ isUploaded: false })}
                size="sm"
                className={styles.microphoneIcon}
              />
            )}
          </button>
          <div>
            {this.state.isUploaded === null ? (
              <label htmlFor="audioInput">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 11"
                    fill="none"
                  >
                    <path
                      d="M7.84283 9.64315C7.84283 8.48113 7.84283 7.33619 7.84283 6.19125C7.84283 5.89873 7.83906 5.60668 7.84396 5.31417C7.84962 4.97945 8.04002 4.73482 8.28925 4.73055C8.54677 4.72581 8.7466 4.97376 8.74735 5.32175C8.75037 6.8308 8.7515 8.33985 8.74698 9.84843C8.74509 10.4064 8.4148 10.7976 7.95594 10.7985C6.60501 10.8009 5.25444 10.7995 3.90351 10.7995C2.8855 10.7995 1.86786 10.7999 0.849851 10.7995C0.300125 10.799 0.000754082 10.4221 0.000377041 9.73038C0 8.2768 -0.000377041 6.82322 0.000754082 5.36916C0.00113112 4.96855 0.21529 4.69737 0.497317 4.73197C0.742771 4.76184 0.905276 5.00743 0.905653 5.3611C0.907161 6.69616 0.906407 8.03122 0.906407 9.36627C0.906407 9.45256 0.906407 9.53884 0.906407 9.6422C3.21729 9.64315 5.51875 9.64315 7.84283 9.64315Z"
                      fill="#F75A00"
                    />
                    <path
                      d="M3.92164 2.01903C3.92164 2.10247 3.92164 2.18591 3.92164 2.26983C3.92164 4.17238 3.92164 6.0754 3.92164 7.97796C3.92164 8.05666 3.91824 8.1363 3.92465 8.21453C3.94765 8.50326 4.13881 8.72418 4.36579 8.72987C4.60106 8.73556 4.8009 8.51274 4.82465 8.21311C4.83106 8.13488 4.82767 8.05523 4.82767 7.97653C4.82767 6.07398 4.82767 4.17096 4.82767 2.26841C4.82767 2.18307 4.82767 2.09773 4.82767 1.94317C5.06558 2.24517 5.27031 2.50498 5.47542 2.76478C5.56403 2.87667 5.65112 2.99046 5.74237 3.09902C5.94597 3.34081 6.21819 3.34982 6.39917 3.12415C6.58015 2.89895 6.58015 2.5486 6.38409 2.29875C5.89017 1.66915 5.39097 1.04666 4.89365 0.421323C4.82691 0.337408 4.76282 0.250648 4.69269 0.171948C4.49587 -0.0489807 4.25079 -0.0632038 4.06567 0.165785C3.48766 0.879301 2.91531 1.59993 2.34711 2.32577C2.16802 2.55476 2.17594 2.89753 2.34372 3.11562C2.5164 3.34081 2.79655 3.35409 2.98544 3.12273C3.2867 2.75435 3.57702 2.37176 3.87187 1.99533C3.88846 2.00339 3.90505 2.01097 3.92164 2.01903Z"
                      fill="#F75A00"
                    />
                  </svg>
                </div>
              </label>
            ) : (
              <div className={styles.rotated}>
                <svg
                  onClick={() => this.handleDownloadRecord()}
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 15 11"
                  fill="none"
                >
                  <path
                    d="M7.84283 9.64315C7.84283 8.48113 7.84283 7.33619 7.84283 6.19125C7.84283 5.89873 7.83906 5.60668 7.84396 5.31417C7.84962 4.97945 8.04002 4.73482 8.28925 4.73055C8.54677 4.72581 8.7466 4.97376 8.74735 5.32175C8.75037 6.8308 8.7515 8.33985 8.74698 9.84843C8.74509 10.4064 8.4148 10.7976 7.95594 10.7985C6.60501 10.8009 5.25444 10.7995 3.90351 10.7995C2.8855 10.7995 1.86786 10.7999 0.849851 10.7995C0.300125 10.799 0.000754082 10.4221 0.000377041 9.73038C0 8.2768 -0.000377041 6.82322 0.000754082 5.36916C0.00113112 4.96855 0.21529 4.69737 0.497317 4.73197C0.742771 4.76184 0.905276 5.00743 0.905653 5.3611C0.907161 6.69616 0.906407 8.03122 0.906407 9.36627C0.906407 9.45256 0.906407 9.53884 0.906407 9.6422C3.21729 9.64315 5.51875 9.64315 7.84283 9.64315Z"
                    fill="#F75A00"
                  />
                  <path
                    d="M3.92164 2.01903C3.92164 2.10247 3.92164 2.18591 3.92164 2.26983C3.92164 4.17238 3.92164 6.0754 3.92164 7.97796C3.92164 8.05666 3.91824 8.1363 3.92465 8.21453C3.94765 8.50326 4.13881 8.72418 4.36579 8.72987C4.60106 8.73556 4.8009 8.51274 4.82465 8.21311C4.83106 8.13488 4.82767 8.05523 4.82767 7.97653C4.82767 6.07398 4.82767 4.17096 4.82767 2.26841C4.82767 2.18307 4.82767 2.09773 4.82767 1.94317C5.06558 2.24517 5.27031 2.50498 5.47542 2.76478C5.56403 2.87667 5.65112 2.99046 5.74237 3.09902C5.94597 3.34081 6.21819 3.34982 6.39917 3.12415C6.58015 2.89895 6.58015 2.5486 6.38409 2.29875C5.89017 1.66915 5.39097 1.04666 4.89365 0.421323C4.82691 0.337408 4.76282 0.250648 4.69269 0.171948C4.49587 -0.0489807 4.25079 -0.0632038 4.06567 0.165785C3.48766 0.879301 2.91531 1.59993 2.34711 2.32577C2.16802 2.55476 2.17594 2.89753 2.34372 3.11562C2.5164 3.34081 2.79655 3.35409 2.98544 3.12273C3.2867 2.75435 3.57702 2.37176 3.87187 1.99533C3.88846 2.00339 3.90505 2.01097 3.92164 2.01903Z"
                    fill="#F75A00"
                  />
                </svg>
              </div>
            )}

            <input
              type="file"
              id="audioInput"
              accept="audio/*"
              className={styles.uploadAudioInput}
              onChange={this.handleFileChange}
            />
          </div>
        </div>
        <div className={styles.audioInfo}>
          {this.state.isUploaded && audioURL !== null && (
            <>
              <span className={styles.audioName}>
                {audioURL
                  ? `${audioFileName}`
                  : `${this.props.handle} Voice Over.mp3`}
              </span>
              <div className={styles.audioTimer}>
                {Math.floor(audioDuration / 60)}:
                {audioDuration % 60 < 10 ? "0" : ""}
                {audioDuration % 60}
              </div>
            </>
          )}
        </div>
        <div className={styles.controllersWrap}>
          {this.state.isUploaded && audioURL !== null && (
            <button onClick={this.togglePlay}>
              {isPlaying ? (
                <div className={styles.stopBtn}>
                  <div className={styles.stopColumn}></div>
                  <div className={styles.stopColumn}></div>
                </div>
              ) : (
                <Icon icon="playIcon" size="md" />
              )}
            </button>
          )}
        </div>
        {audioURL && (
          <div>
            {/* audio */}
            <audio
              ref={this.audioRef}
              src={audioURL}
              type="audio/mp3"
              onTimeUpdate={this.handleTimeUpdate}
              onEnded={() => this.setState({ isPlaying: false })}
              controls={false}
              style={{ display: "none" }}
            />
          </div>
        )}

        {/* <div>
currentTime: {Math.floor(currentTime / 60)}:
{currentTime % 60 < 10 ? "0" : ""}
{currentTime % 60}
</div> */}
      </div>
    );
  }
}

export default VoiceRecorder;
