import {compose, graphql} from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import {Redirect} from 'react-router-dom';
import {withRouter} from 'react-router';

import Loading from './Loading';

class CommentRedirection extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      comment: PropTypes.object,
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
    }),
    match: PropTypes.object.isRequired, // From router
  };

  render() {
    if (this.props.data.loading) {
      return (
        <Loading
          headProps={{
            back: true,
            minimal: true,
          }}
        />
      );
    }

    return <Redirect to={`/spark/${this.props.data.comment.spark.hashid}#comment-${this.props.data.comment.hashid}`} />;
  }
}

const COMMENT_QUERY = gql`
  query CommentDetail($hashid: ID!) {
    comment(hashid: $hashid) {
      hashid
      spark {
        hashid
      }
    }
  }
`;

export default compose(
  withRouter,
  graphql(COMMENT_QUERY, {
    options: (props) => {
      return {
        variables: {
          hashid: props.match.params.hashid,
        },
      };
    },
  })
)(CommentRedirection);
