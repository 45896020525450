import PropTypes from 'prop-types';
import React from 'react';

import styles from './style.scss';

export default class OffsetAnchor extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  }

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.anchor} id={this.props.id} />
      </div>
    );
  }
}
