import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.scss';
import convertTime from '../convertTime';
import RangePicker from '../RangePicker/index';

function TrackLine({currentTime, duration, handleTrackClick}) {
  return (
    <div className={styles.seeker}>
      <span className={styles.time}>{convertTime(currentTime)}</span>
      <RangePicker
        max={duration}
        value={currentTime}
        handleChange={handleTrackClick}
      />
      <span className={styles.time}>{convertTime(duration)}</span>
    </div>
  );
}

TrackLine.propTypes = {
  currentTime: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  handleTrackClick: PropTypes.func.isRequired,
};

export default TrackLine;
