import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {Link} from 'react-router-dom';

import styles from './style.scss';

export default class Button extends React.Component {
  static propTypes = {
    border: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    dataTut: PropTypes.string,
    disabled: PropTypes.bool,
    link: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.string,
    type: PropTypes.string.isRequired,
    // TODO require one of link or onclick
  }

  static defaultProps = {
    border: false,
    color: "primary",
    disabled: false,
    link: null,
    size: "md",
    type: 'button',
  }

  render() {
    let colorClass = null;
    switch (this.props.color) {
      case 'none':
        colorClass = styles.none;
        break;
      case 'underline':
        colorClass = styles.underline;
        break;
      case 'white':
        colorClass = styles.white;
        break;
      case 'primary':
        colorClass = styles.primary;
        break;
      case 'secondary':
        colorClass = styles.secondary;
        break;
      case 'tertiary':
        colorClass = styles.tertiary;
        break;
      case 'danger':
        colorClass = styles.danger;
        break;
    }

    let sizeClass = null;
    switch (this.props.size) {
      case 'xs':
        sizeClass = styles.xs;
        break;
      case 'sm':
        sizeClass = styles.sm;
        break;
      case 'sm-wide':
        sizeClass = styles.smWide;
        break;
      case 'md':
        sizeClass = styles.md;
        break;
      case 'lg':
        sizeClass = styles.lg;
        break;
    }

    let borderClass = null;
    if (this.props.border === true) {
      borderClass = styles.border;
    }

    if (this.props.link == null) {
      return (
        <button
          type={this.props.type}
          onClick={this.props.onClick}
          className={classNames(styles.root, this.props.className, styles.btn, colorClass, sizeClass, borderClass)}
          disabled={this.props.disabled}
          data-tut={this.props.dataTut}
        >
          {this.props.children}
        </button>
      );
    }
    // Handle external links
    if (this.props.link.startsWith('http') || this.props.link.startsWith('//')) {
      return (
        <a
          href={this.props.link}
          onClick={this.props.onClick}
          className={classNames(styles.root, this.props.className, styles.btn, colorClass, sizeClass, borderClass)}
          target="_blank"
          rel="noopener noreferrer"
          data-tut={this.props.dataTut}
        >
          {this.props.children}
        </a>
      );
    }
    return (
      <div data-tut={this.props.dataTut}>
        <Link
          to={this.props.link}
          onClick={this.props.onClick}
          className={classNames(styles.root, this.props.className, styles.btn, colorClass, sizeClass, borderClass)}
          disabled={this.props.disabled}
        >
          {this.props.children}
        </Link>
      </div>
    );
  }
}
