// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-StackViewNavigator-style__navigator__AavkH{width:100%;position:fixed;bottom:0;left:0;padding:20px 0}.components-StackViewNavigator-style__navigatorContainer__XznPX{padding:0 20px}.components-StackViewNavigator-style__navigatorPlaceholder__hIbKn{height:70px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigator": `components-StackViewNavigator-style__navigator__AavkH`,
	"navigatorContainer": `components-StackViewNavigator-style__navigatorContainer__XznPX`,
	"navigatorPlaceholder": `components-StackViewNavigator-style__navigatorPlaceholder__hIbKn`
};
export default ___CSS_LOADER_EXPORT___;
