import { connect } from "react-redux";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
  };
};

const ME_QUERY = gql`
  query CurrentUserForShareOptions {
    me {
      handle
      hashid
      linked_social_accounts {
        hashid
        is_writable
        provider
      }
    }
  }
`;

export default function withLinkedSocialAccounts(WrappedComponent) {
  return connect(
    mapStateToProps,
    null
  )(
    graphql(ME_QUERY)(
      class LinkedSocialAccountsWrapper extends React.Component {
        static propTypes = {
          auth: PropTypes.object, // Auth reducer
          data: PropTypes.shape({
            error: PropTypes.object,
            loading: PropTypes.bool.isRequired,
            me: PropTypes.object,
            refetch: PropTypes.func.isRequired,
          }),
        };

        UNSAFE_componentWillUpdate(nextProps) {
          if (nextProps.auth.authenticated !== this.props.auth.authenticated) {
            this.props.data.refetch();
          }
        }

        getLabel(provider) {
          switch (provider) {
            case "facebook":
              return "Facebook";
            case "google":
              return "Google";
            case "twitter":
              return "Twitter";
            default:
              throw new Error("Unknown provider");
          }
        }

        getStatus = (provider) => {
          if (
            this.props.data.loading ||
            !this.props.data.me ||
            typeof this.props.data.me.linked_social_accounts === "undefined"
          ) {
            return null;
          }

          for (const account of this.props.data.me.linked_social_accounts) {
            if (account.provider === provider) {
              return account.is_writable ? "rw" : "ro";
            }
          }

          return "unconnected";
        };

        render() {
          return (
            <WrappedComponent
              linkedSocialAccounts={{
                data: this.props.data,
                getLabel: this.getLabel,
                getStatus: this.getStatus,
              }}
            />
          );
        }
      }
    )
  );
}
