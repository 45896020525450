// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-CTA-style__root__ahZhj{display:block;text-align:center;padding:40px 20px}.components-CTA-style__title__lA1nW{margin-bottom:1em}@media only screen and (max-width: "(max-width:767px)"){.components-CTA-style__root__ahZhj{padding:40px 20px}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-CTA-style__root__ahZhj`,
	"title": `components-CTA-style__title__lA1nW`
};
export default ___CSS_LOADER_EXPORT___;
