// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-PageAudioPlayer-style__wrapper__y2hMy{z-index:9;position:fixed;width:100%;bottom:20px;left:0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.components-PageAudioPlayer-style__wrapper__y2hMy .components-PageAudioPlayer-style__content__Ldujb{border:.5px solid #f75a00;display:-webkit-box;display:-ms-flexbox;display:flex;gap:5px;padding:10px 20px;background-color:#fff;border-radius:20px;height:100%;width:70%}.components-PageAudioPlayer-style__btn__d6KrV{width:70px}.components-PageAudioPlayer-style__volumeWrap__sqDeu{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;min-width:40px;width:80px;max-width:100px}.components-PageAudioPlayer-style__trackInfo__L_mJR{-webkit-box-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.components-PageAudioPlayer-style__description__GzT0d{font-size:15px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;padding-right:24px;position:relative;text-align:center}.components-PageAudioPlayer-style__description__GzT0d .components-PageAudioPlayer-style__groupName__FWnBj{color:#007ffe}.components-PageAudioPlayer-style__description__GzT0d .components-PageAudioPlayer-style__trackName__TkT67{color:#000}.components-PageAudioPlayer-style__player__QbcKp{display:-webkit-box;display:-ms-flexbox;display:flex}.components-PageAudioPlayer-style__closeIcon__vmE9I{position:absolute;right:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `components-PageAudioPlayer-style__wrapper__y2hMy`,
	"content": `components-PageAudioPlayer-style__content__Ldujb`,
	"btn": `components-PageAudioPlayer-style__btn__d6KrV`,
	"volumeWrap": `components-PageAudioPlayer-style__volumeWrap__sqDeu`,
	"trackInfo": `components-PageAudioPlayer-style__trackInfo__L_mJR`,
	"description": `components-PageAudioPlayer-style__description__GzT0d`,
	"groupName": `components-PageAudioPlayer-style__groupName__FWnBj`,
	"trackName": `components-PageAudioPlayer-style__trackName__TkT67`,
	"player": `components-PageAudioPlayer-style__player__QbcKp`,
	"closeIcon": `components-PageAudioPlayer-style__closeIcon__vmE9I`
};
export default ___CSS_LOADER_EXPORT___;
