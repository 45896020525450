// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-CircleButton-style__root__ySbYu{font-size:1.6rem;color:#9b9b9b;width:30px;height:30px;border-radius:50%;-webkit-box-shadow:1px 1px 8px #9b9b9b;box-shadow:1px 1px 8px #9b9b9b;line-height:30px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-CircleButton-style__root__ySbYu`
};
export default ___CSS_LOADER_EXPORT___;
