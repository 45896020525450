import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { GraphqlBackedMedia } from "components/Media";
import { TagListFromArray } from "components/TagList";
import Icon from "components/Icon";
import Avatar from "components/Avatar";
import Handle from "components/Handle";
import ShareMenu from "components/ShareMenu";

import styles from "./style.scss";

export default class ViewSparkShare extends React.Component {
  static propTypes = {
    chosenPosterUrl: PropTypes.string,
    className: PropTypes.string,
    closesAt: PropTypes.number,
    locationName: PropTypes.string,
    locationPlaceId: PropTypes.string,
    mediaHashid: PropTypes.string.isRequired,
    mediaType: PropTypes.string,
    message: PropTypes.string,
    onNavigate: PropTypes.func,
    onSubmit: PropTypes.func,
    style: PropTypes.object,
    tags: PropTypes.array,
    username: PropTypes.string,
  };

  static defaultProps = {
    chosenPosterUrl: null,
    className: "",
    closesAt: 0,
    locationName: "",
    locationPlaceId: "",
    mediaType: "",
    message: "",
    onNavigate: (f) => f,
    onSubmit: (f) => f,
    style: {},
    tags: [],
    username: "",
  };

  constructor() {
    super();

    this.$form = null;

    this.state = {
      mediaProcessed: null,
      shareOpeningCommentOptionsOpen: false,
    };
  }

  onProcessedStatusChange = (processed) => {
    this.setState({
      mediaProcessed: processed,
    });
  };

  handleShareClick = () => {
    this.setState((prevState) => ({
      shareOpeningCommentOptionsOpen: !prevState.shareOpeningCommentOptionsOpen,
    }));
  };

  render() {
    const {
      chosenPosterUrl,
      className,
      closesAt,
      locationName,
      mediaHashid,
      mediaType,
      message,
      style,
      tags,
      username,
    } = this.props;

    let poster = null;
    if (mediaType === "video" && this.state.mediaProcessed && chosenPosterUrl) {
      poster = chosenPosterUrl;
    }

    return (
      <div className={classNames(styles.root, className)} style={style}>
        {mediaHashid && closesAt && (
          <div className={styles.wrapper}>
            <div className={styles.avatarBlock}>
              <Avatar size="md" />
              <div className={styles.nicknameAndLocationBlock}>
                <Handle handle={username} className={styles.handle} />
                {locationName && (
                  <div className={styles.locationWrap}>
                    <Icon icon="marker" color="primary" />
                    <span className={styles.locationName}>{locationName}</span>
                  </div>
                )}
              </div>
            </div>
            <div>
              <GraphqlBackedMedia
                size="lg"
                hashid={mediaHashid}
                onProcessedStatusChange={this.onProcessedStatusChange}
                poster={poster}
                className={styles.media}
              />
              {message && <p className={styles.message}>{message}</p>}
              {tags && tags.length > 0 && (
                <TagListFromArray
                  className={classNames("bg-white", styles.tagList)}
                  tags={tags.map((tag) => tag.value)}
                  linked={true}
                />
              )}
              <div className={styles.questionIconWrap}>
                <div className={styles.questionIconWrapper}>?</div>
              </div>
              <div className={styles.shareContainer}>
                <ShareMenu
                  listClassName={styles.shareOpeningCommentOptionsMenu}
                  onButtonClick={this.handleShareClick}
                  onShareMenuItemClick={this.handleShareOpeningCommentMenuClick}
                  open={this.state.shareOpeningCommentOptionsOpen}
                  isShareIconWithText
                  iconSize="lg"
                />
                <div className={styles.shareTextWrap}>
                  <p className={styles.shareText}>
                    Share this post to get more votes
                  </p>
                  <div className={styles.shareLinkContainer}>
                    http://app.sparkit.buzz/spark
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
