// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-DropdownMenu-style__root__idze_{font-weight:normal;position:absolute;list-style:none;margin:0;padding:0;z-index:1;min-width:150px}.components-DropdownMenu-style__root__idze_ li{margin:0}.components-DropdownMenu-style__root__idze_.components-DropdownMenu-style__narrow__q6ZEh{top:100%;background-color:#d7d7d7}.components-DropdownMenu-style__root__idze_.components-DropdownMenu-style__narrow__q6ZEh li{padding:10px}.components-DropdownMenu-style__root__idze_.components-DropdownMenu-style__narrow__q6ZEh li:hover{background-color:#9b9b9b}.components-DropdownMenu-style__root__idze_.components-DropdownMenu-style__narrow__q6ZEh li:not(:last-child){border-bottom:1px solid #fff}.components-DropdownMenu-style__root__idze_.components-DropdownMenu-style__wide__VyrD2{left:0;width:100%;-webkit-box-shadow:0 4px 4px #9b9b9b;box-shadow:0 4px 4px #9b9b9b;background-color:#fff}.components-DropdownMenu-style__root__idze_.components-DropdownMenu-style__wide__VyrD2 li{padding:0;border-top:1px solid #d7d7d7}.components-DropdownMenu-style__root__idze_.components-DropdownMenu-style__wide__VyrD2 li a,.components-DropdownMenu-style__root__idze_.components-DropdownMenu-style__wide__VyrD2 li button{display:block;width:100%;padding:10px;font-weight:bold}.components-DropdownMenu-style__root__idze_.components-DropdownMenu-style__wide__VyrD2 li a:hover,.components-DropdownMenu-style__root__idze_.components-DropdownMenu-style__wide__VyrD2 li button:hover{background-color:#f4f4f2}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-DropdownMenu-style__root__idze_`,
	"narrow": `components-DropdownMenu-style__narrow__q6ZEh`,
	"wide": `components-DropdownMenu-style__wide__VyrD2`
};
export default ___CSS_LOADER_EXPORT___;
