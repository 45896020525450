// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-VideoUploadButton-style__root__Cx5xV{position:relative;display:inline-block}.components-VideoUploadButton-style__root__Cx5xV input{position:absolute;z-index:1;width:100%;height:100%;opacity:0;cursor:pointer}.components-VideoUploadButton-style__root__Cx5xV.components-VideoUploadButton-style__styled__T8T_d{display:block;margin-bottom:20px;width:100%}.components-VideoUploadButton-style__root__Cx5xV.components-VideoUploadButton-style__styled__T8T_d .components-VideoUploadButton-style__gutter__h7Ru2{margin-top:20px}.components-VideoUploadButton-style__root__Cx5xV.components-VideoUploadButton-style__styled__T8T_d .components-VideoUploadButton-style__container__qfRDW{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:100%;height:100%;background-color:#f5f5f5;border-radius:17px}.components-VideoUploadButton-style__root__Cx5xV.components-VideoUploadButton-style__styled__T8T_d.components-VideoUploadButton-style__finished__vPMxb .components-VideoUploadButton-style__container__qfRDW{background-color:rgba(0,0,0,0)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-VideoUploadButton-style__root__Cx5xV`,
	"styled": `components-VideoUploadButton-style__styled__T8T_d`,
	"gutter": `components-VideoUploadButton-style__gutter__h7Ru2`,
	"container": `components-VideoUploadButton-style__container__qfRDW`,
	"finished": `components-VideoUploadButton-style__finished__vPMxb`
};
export default ___CSS_LOADER_EXPORT___;
