// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-HandleInput-styles__inputContainer__D2hJm{position:relative;width:100%;height:50px;border-radius:16px;background-color:#f9f9f9}.components-HandleInput-styles__inputContainer__D2hJm .components-HandleInput-styles__input__qxpQP{padding-left:21px;height:100%;border:none;border-radius:16px !important;background-color:#f9f9f9 !important}.components-HandleInput-styles__inputContainer__D2hJm .components-HandleInput-styles__input__qxpQP:focus{outline:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `components-HandleInput-styles__inputContainer__D2hJm`,
	"input": `components-HandleInput-styles__input__qxpQP`
};
export default ___CSS_LOADER_EXPORT___;
