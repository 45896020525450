import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import styles from "./style.scss";

export default class InfluencerStats extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    leaderboard: PropTypes.array.isRequired,
    onNavigate: PropTypes.func.isRequired,
    style: PropTypes.object,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {};
  }

  formatClicks = (clicks, adShows, impressions) => {
    if (adShows >= clicks) {
      return this.formatRatio(clicks, adShows);
    }
    return this.formatRatio(clicks, impressions);
  };

  formatCurrency = (value) => {
    return (
      value?.toLocaleString("en-US", {
        currency: "USD",
        style: "currency",
      }) || 0
    );
  };

  formatPercent = (value) => {
    return (
      value?.toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigitals: 1,
        style: "percent",
      }) || 0
    );
  };

  formatNumber = (value) => {
    return (
      value?.toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigitals: 1,
      }) || 0
    );
  };

  formatRatio = (top, bottom) => {
    if (bottom && bottom > 0) {
      return this.formatPercent(top / bottom);
    }
    return "-";
  };

  renderCampaigns = () => {
    return this.props.leaderboard.map((leaderboard) => {
      return (
        <div key={leaderboard.offer.hashid} className={styles.root}>
          <div>
            <span className={styles.logo}>
              <img src={leaderboard.offer.thumbnail_url} alt="" />
            </span>
            <span className={styles.offer}>
              <div className={classNames(styles.header, "color-secondary")}>
                <span className={styles.name}>
                  <h1>{leaderboard.offer.name}</h1>
                </span>
              </div>
            </span>
          </div>
          <table className={classNames("title", "title-sm")}>
            <tbody>
              <tr>
                <td>Campaign Leaderboard Rank:</td>
                <td className={classNames("align-r")}>{leaderboard.rank}</td>
              </tr>
              <tr>
                <td>Campaign Leaderboard Score:</td>
                <td className={classNames("align-r")}>
                  {this.formatNumber(leaderboard.sparkit_index)}
                </td>
              </tr>
              <tr>
                <td>Visitors:</td>
                <td className={classNames("align-r")}>
                  {leaderboard.impressions}
                </td>
              </tr>
              <tr>
                <td>Comments:</td>
                <td className={classNames("align-r")}>
                  {leaderboard.comments}
                </td>
              </tr>
              <tr>
                <td>Votes:</td>
                <td className={classNames("align-r")}>{leaderboard.votes}</td>
              </tr>
              <tr>
                <td>Shares:</td>
                <td className={classNames("align-r")}>{leaderboard.shares}</td>
              </tr>
              <tr>
                <td>Video Views:</td>
                <td className={classNames("align-r")}>
                  {leaderboard.video_starts}
                </td>
              </tr>
              <tr>
                <td>Ad Views:</td>
                <td className={classNames("align-r")}>
                  {leaderboard.ad_shows}
                </td>
              </tr>
              <tr>
                <td>Clicks:</td>
                <td className={classNames("align-r")}>
                  {this.formatNumber(leaderboard.clicks)}
                </td>
              </tr>
              <tr>
                <td>Clickthrough Rate:</td>
                <td className={classNames("align-r")}>
                  {this.formatClicks(
                    leaderboard.clicks,
                    leaderboard.ad_shows,
                    leaderboard.impressions
                  )}
                </td>
              </tr>
              <tr>
                <td>Conversion Rate:</td>
                <td className={classNames("align-r")}>
                  {this.formatRatio(
                    leaderboard.conversions,
                    leaderboard.clicks
                  )}
                </td>
              </tr>
              <tr>
                <td>Money Earned:</td>
                <td className={classNames("align-r")}>
                  {this.formatCurrency(leaderboard.payout)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });
  };

  render() {
    return (
      <div className={classNames("wrap")} style={this.props.style}>
        <div
          className={classNames(
            "wrap__inner",
            "wrap__gutter",
            "wrap__gutter--padded"
          )}
        >
          {this.renderCampaigns()}
        </div>
      </div>
    );
  }
}
