import PropTypes from 'prop-types';
import React from 'react';

export default class FormError extends React.Component {
  static propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    showHeading: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    showHeading: true,
  }

  render() {
    return (
      <div>
        {this.props.showHeading &&
          <h3>Error</h3>
        }
        <ul>
          {this.props.errors.map(message =>
            <li key={message}>{message}</li>
          )}
        </ul>
      </div>
    );
  }
}
