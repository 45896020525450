import PropTypes from 'prop-types';
import React from 'react';

import Grid from 'components/Grid';

export default class ResponsiveGrid extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    targetWidth: PropTypes.number.isRequired,
  }

  constructor() {
    super();

    this.state = {
      columns: 1,
    };
  }

  componentDidMount() {
    this.mediaQueries = []; // Set up a list of media queries
    const maxGridWidth = 2000; // Max width of grid in pixels, including gutters
    let mqlMin = null; // Min width for media queries

    for (let columns = 1; columns * this.props.targetWidth < maxGridWidth; columns++) {
      let mql = null;
      let mqlMax = Math.round((columns + 0.2) * this.props.targetWidth); // Max width for mql

      // Set mqlMax null if this is the last loop
      if ((columns + 1) * this.props.targetWidth >= maxGridWidth) {
        mqlMax = null;
      }

      if (mqlMin === null) {
        mql = window.matchMedia(`(max-width: ${mqlMax}px)`);
      } else if (mqlMax === null) {
        mql = window.matchMedia(`(min-width: ${mqlMin}px)`);
      } else {
        mql = window.matchMedia(`(min-width: ${mqlMin}px) and (max-width: ${mqlMax}px)`);
      }

      if (mql.matches) {
        this.setState({
          columns: columns,
        });
      }

      this.mediaQueries.push(mql);
      mqlMin = mqlMax + 1; // Set up next mql min width, 1 more pixel than previous max width

      // Listen for change events
      mql.addListener(this.handleMediaQueryChange);
    }
  }

  componentWillUnmount() {
    // Remove media query listeners and destroy media query lists
    for (let mql of this.mediaQueries) {
      mql.removeListener(this.handleMediaQueryChange);
    }
    this.mediaQueries = null;
  }

  handleMediaQueryChange = (eventMql) => {
    // Don't respond to the media query which has switched from matching to not
    if (!eventMql.matches) {
      return;
    }

    // Find the index (and therefore column count) of the matched media query
    for (let i = 0; i < this.mediaQueries.length; i++) {
      if (this.mediaQueries[i].media !== eventMql.media) {
        continue;
      }

      // Update the column count
      this.setState({
        columns: i + 1,
      });

      break;
    }
  }

  render() {
    return (
      <Grid columns={this.state.columns}>
        {this.props.children}
      </Grid>
    );
  }
}
