import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './style.scss';

export default class Tag extends React.Component {
  static propTypes = {
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
    linked: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    linked: false,
  }

  render() {
    const {
      children,
      className,
      linked,
      ...otherProps
    } = this.props;

    const tag = children;

    let Element = 'span';
    if (linked) {
      Element = 'a';
      otherProps.href = '#'; // TODO
    }

    return (
      <Element
        className={classNames(styles.root, linked && styles.linked, className)}
        {...otherProps}
      >
        <span className={styles.hash}>
          #
        </span>
        <span className={styles.tag}>
          {tag}
        </span>
      </Element>
    );
  }
}
