// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-PageAudioPlayer-RangePicker-style__range__zDTNx[type=range]::-webkit-slider-thumb{-webkit-appearance:none;appearance:none}.components-PageAudioPlayer-RangePicker-style__range__zDTNx::-ms-track{width:100%;cursor:pointer;background:rgba(0,0,0,0);border-color:rgba(0,0,0,0);color:rgba(0,0,0,0)}.components-PageAudioPlayer-RangePicker-style__range__zDTNx,.components-PageAudioPlayer-RangePicker-style__range__zDTNx[type=range]{-webkit-appearance:none;-moz-appearance:none;appearance:none;margin:0;width:100%;cursor:pointer;-webkit-box-sizing:border-box;box-sizing:border-box;height:6px;border-radius:3px;background-color:#6a6a6a}.components-PageAudioPlayer-RangePicker-style__range__zDTNx[type=range]::-webkit-slider-thumb,.components-PageAudioPlayer-RangePicker-style__range__zDTNx::-moz-range-thumb{width:12px;height:12px;background-color:#fff;border-radius:50%;-webkit-box-shadow:0 0 6px 6px rgba(0,0,0,.2);box-shadow:0 0 6px 6px rgba(0,0,0,.2);opacity:0}.components-PageAudioPlayer-RangePicker-style__range__zDTNx::-moz-range-thumb{margin-top:-3px}.components-PageAudioPlayer-RangePicker-style__range__zDTNx:hover::-webkit-slider-thumb,.components-PageAudioPlayer-RangePicker-style__range__zDTNx:hover::-moz-range-thumb{opacity:1}.components-PageAudioPlayer-RangePicker-style__range__zDTNx:focus{outline:none}.components-PageAudioPlayer-RangePicker-style__range__zDTNx:focus::-webkit-slider-thumb{outline:-webkit-focus-ring-color auto 5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"range": `components-PageAudioPlayer-RangePicker-style__range__zDTNx`
};
export default ___CSS_LOADER_EXPORT___;
