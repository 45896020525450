import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Icon from "components/Icon";

import styles from "./style.scss";

export default class Help extends React.Component {
  static propTypes = {
    link: PropTypes.string,
    linkText: PropTypes.string,
    message: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    visible: PropTypes.bool,
  };

  static defaultProps = {
    linkText: "Learn more",
    visible: true,
  };

  constructor(props) {
    super(props);

    const dismissed = window.localStorage.getItem(this.props.name) || false;

    this.state = {
      showHelp: this.props.visible && !dismissed,
    };
  }

  closeClick = () => {
    // Hide the help bar
    this.setState({
      showHelp: false,
    });

    // Add message to localstorage (by name)
    window.localStorage.setItem(this.props.name, true);
  };

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    return (
      <div
        className={classNames(
          styles.root,
          "bg-primary",
          this.state.showHelp ? "" : "dis-n"
        )}
        ref={(ref) => (this.helpContainer = ref)}
      >
        <div className={classNames(styles.inner)}>
          <div onClick={this.handleClick}>
            {this.props.message}{" "}
            {this.props.link && (
              <a
                href={this.props.link}
                target="_blank"
                rel="nofollow noreferrer"
              >
                {this.props.linkText}
              </a>
            )}
          </div>
          <button
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Dismiss help bar."
            className={classNames(styles.button)}
            onClick={this.closeClick}
          >
            <Icon icon="close" size="xs" color="white" />
          </button>
        </div>
      </div>
    );
  }
}
