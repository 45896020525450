// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-HeadSearch-style__wrapper__eoAPv{width:100%;min-width:83px;height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:5px}.components-HeadSearch-style__input__qZk72{height:30px;border:none;padding:3px 8px;min-width:40px;border-radius:8px;border:1px solid #14c4eb;background:#f9f9f9}.components-HeadSearch-style__input__qZk72:focus{border-color:#14c4eb !important}.components-HeadSearch-style__iconButton___JsPf{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;width:43px;padding:4px 4px 4px 0px;min-width:43px;border-radius:12px}.components-HeadSearch-style__iconButton___JsPf:active{background-color:#f3f3f3}.components-HeadSearch-style__iconButton___JsPf:active .components-HeadSearch-style__tagGray__OMAaS{fill:#f3f3f3}.components-HeadSearch-style__iconButton___JsPf:active .components-HeadSearch-style__tagWhite__DanF_{display:none}.components-HeadSearch-style__iconButton___JsPf:not(:active) .components-HeadSearch-style__tagGray__OMAaS{display:none}.components-HeadSearch-style__iconButton___JsPf:not(:active) .components-HeadSearch-style__tagWhite__DanF_{fill:#fff}.components-HeadSearch-style__closeIcon__pvFer{min-width:15px}.components-HeadSearch-style__whiteBackground__vwlSE{background-color:#fff}@media screen and (max-width: 481px){.components-HeadSearch-style__wrapper__eoAPv{gap:2px}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `components-HeadSearch-style__wrapper__eoAPv`,
	"input": `components-HeadSearch-style__input__qZk72`,
	"iconButton": `components-HeadSearch-style__iconButton___JsPf`,
	"tagGray": `components-HeadSearch-style__tagGray__OMAaS`,
	"tagWhite": `components-HeadSearch-style__tagWhite__DanF_`,
	"closeIcon": `components-HeadSearch-style__closeIcon__pvFer`,
	"whiteBackground": `components-HeadSearch-style__whiteBackground__vwlSE`
};
export default ___CSS_LOADER_EXPORT___;
