// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-Report-style__root__UvUUy{width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;padding-top:40px;background-color:#fff}.components-Report-style__logo__nLynb{vertical-align:top;padding:5px;background-color:#fff;width:30px;height:30px;border-radius:50%;overflow:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-ms-flex-negative:0;flex-shrink:0}.components-Report-style__logo__nLynb img{width:100%;height:auto}.components-Report-style__header__wYoXf{font-weight:700;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:10px;vertical-align:middle}.components-Report-style__offer__uBQyB{margin-left:20px;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}.components-Report-style__content__dNgTp{margin-top:21px;background-color:#fff;border-radius:17px;min-width:335px;width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:14px;padding:20px}.components-Report-style__statisticLine__zpLnF{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;font-family:"Roboto",sans-serif;font-size:15px;line-height:17.58px;font-weight:400;height:100%}.components-Report-style__statisticLine__zpLnF p{margin:0 !important;-webkit-box-flex:1;-ms-flex:1;flex:1}.components-Report-style__statisticLine__zpLnF div{font-weight:700}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-Report-style__root__UvUUy`,
	"logo": `components-Report-style__logo__nLynb`,
	"header": `components-Report-style__header__wYoXf`,
	"offer": `components-Report-style__offer__uBQyB`,
	"content": `components-Report-style__content__dNgTp`,
	"statisticLine": `components-Report-style__statisticLine__zpLnF`
};
export default ___CSS_LOADER_EXPORT___;
