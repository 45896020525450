import React from "react";
import PropTypes from "prop-types";
import styles from "./style.scss";
import classNames from "classnames";
import Avatar from "components/Avatar";
import IconButton from "components/IconButton";
import Icon from "components/Icon";
import HeadSearch from "../HeadSearch";

export default function HeaderForAuthorizedUsers({
  heading,
  leftContent,
  rightContent,
  className,
  withHashtagSearch,
  searchStyles,
}) {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div
        className={classNames(
          styles.additionalContentWrapper,
          styles.leftContent,
          leftContent.className
        )}
      >
        <AdditionalContent content={leftContent.content} keyArgument="left" />
      </div>
      <span
        className={classNames(styles.pageName, {
          [styles.hidden]: heading === "",
        })}
      >
        {heading}
      </span>

      {withHashtagSearch && (
        <div className={classNames(styles.hashtagSearchWrap, searchStyles)}>
          <HeadSearch />
        </div>
      )}
      <div
        className={classNames(
          styles.additionalContentWrapper,
          styles.rightContent,
          rightContent.className
        )}
      >
        <AdditionalContent content={rightContent.content} keyArgument="right" />
      </div>
    </div>
  );
}

function AdditionalContent({ content, keyArgument }) {
  if (content === null) {
    return (
      <div
        className={classNames(styles.additionalContentWrapper, styles.hidden)}
      />
    );
  }
  if (Array.isArray(content)) {
    return content.map((iconObj, index) => {
      switch (iconObj.type) {
        case "link":
          if (
            iconObj.link ===
            "https://www.notion.so/sparkit-buzz/Creator-Help-8b6007c2d7da4e2e8c66a71d2c463994"
          ) {
            return (
              <a
                href="https://www.notion.so/sparkit-buzz/Creator-Help-8b6007c2d7da4e2e8c66a71d2c463994"
                rel="noopener noreferrer"
                target="_blank"
              >
                <IconButton
                  size="md"
                  icon={iconObj.icon}
                  key={`${keyArgument}_${index}`}
                />
              </a>
            );
          }
          return (
            <IconButton
              size="md"
              icon={iconObj.icon}
              link={iconObj.link}
              key={`${keyArgument}_${index}`}
            />
          );
        case "button":
          return (
            <IconButton
              size="md"
              icon={iconObj.icon}
              onClick={iconObj.onClick}
              key={`${keyArgument}_${index}`}
            />
          );
        case "question":
          return (
            <a
              href="https://www.notion.so/sparkit-buzz/Creator-Help-8b6007c2d7da4e2e8c66a71d2c463994"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon size="md" icon="question" key={`${keyArgument}_${index}`} />
            </a>
          );
        case "avatar":
          return <Avatar size="sm" className={styles.arrayAvatar} />;
        case "logo":
          return (
            <a
              className={classNames(styles.arrayLogo)}
              href="//info.sparkit.buzz/"
            >
              <img src="/images/simplifiedLogo.svg" alt="Sparkit Logo" />
            </a>
          );
        default:
          return (
            <div>
              <IconButton
                size="md"
                icon="question"
                link={
                  "https://www.notion.so/sparkit-buzz/Creator-Help-8b6007c2d7da4e2e8c66a71d2c463994"
                }
                key={`${keyArgument}_${index}`}
              />
            </div>
          );
      }
    });
  }
  switch (content.type) {
    case "button":
      return (
        <button
          className={styles.additionalContentButton}
          onClick={content.onClick}
          type="button"
        >
          {content.buttonText}
        </button>
      );
    case "logo":
      return (
        <a
          className={classNames(styles.simplifiedLogo)}
          href="//info.sparkit.buzz/"
        >
          <img src="/images/simplifiedLogo.svg" alt="Sparkit Logo" />
        </a>
      );
    case "logoWithText":
      return (
        <a className={classNames(styles.logo)} href="//info.sparkit.buzz/">
          <img src="/images/logo_60x60.svg" alt="Sparkit Logo" />
        </a>
      );
    case "avatar":
      return <Avatar size="sm" />;
    default:
      return content;
  }
}

const CONTENT_TYPES = PropTypes.oneOfType([
  PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["link", "button"]).isRequired,
      link: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  PropTypes.shape({
    type: PropTypes.oneOf(["button"]).isRequired,
    buttonText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  PropTypes.shape({
    type: PropTypes.oneOf(["avatar"]).isRequired,
  }),
  PropTypes.shape({
    type: PropTypes.oneOf(["logo"]).isRequired,
  }),
  PropTypes.shape({
    type: PropTypes.oneOf(["logoWithText"]).isRequired,
  }),
  PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["button"]).isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  PropTypes.node,
  PropTypes.oneOf([null]),
]);

HeaderForAuthorizedUsers.propTypes = {
  heading: PropTypes.string,
  withHashtagSearch: PropTypes.bool,
  searchStyles: PropTypes.string,
  className: PropTypes.string,
  leftContent: PropTypes.shape({
    classNames: PropTypes.string,
    content: CONTENT_TYPES,
  }),
  rightContent: PropTypes.shape({
    classNames: PropTypes.string,
    content: CONTENT_TYPES,
  }),
};

AdditionalContent.propTypes = {
  content: CONTENT_TYPES,
  keyArgument: PropTypes.string,
};

HeaderForAuthorizedUsers.defaultProps = {
  heading: "",
  withHashtagSearch: false,
  className: "",
  leftContent: { content: null, className: "" },
  rightContent: { content: null, className: "" },
};

AdditionalContent.defaultProps = {
  keyArgument: "",
  content: null,
};
