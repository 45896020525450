// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sections-Leaderboard-elements-InfluencerStats-style__root__HaPvt{width:100%;padding-bottom:20px}.sections-Leaderboard-elements-InfluencerStats-style__logo__XmBFq{vertical-align:top;padding:5px;background-color:#fff;width:30px;height:30px;border-radius:50%;overflow:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-ms-flex-negative:0;flex-shrink:0}.sections-Leaderboard-elements-InfluencerStats-style__logo__XmBFq img{width:100%;height:auto}.sections-Leaderboard-elements-InfluencerStats-style__header__rlJMi{font-weight:700;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:10px;vertical-align:middle}.sections-Leaderboard-elements-InfluencerStats-style__offer__n71bc{margin-left:20px;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `sections-Leaderboard-elements-InfluencerStats-style__root__HaPvt`,
	"logo": `sections-Leaderboard-elements-InfluencerStats-style__logo__XmBFq`,
	"header": `sections-Leaderboard-elements-InfluencerStats-style__header__rlJMi`,
	"offer": `sections-Leaderboard-elements-InfluencerStats-style__offer__n71bc`
};
export default ___CSS_LOADER_EXPORT___;
