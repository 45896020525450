// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-ErrorBoundary-styles__button__SNVqt{text-transform:uppercase;font-weight:700;border-radius:15px;font-size:.9em;display:block;margin:10px auto}.components-ErrorBoundary-styles__titleMain__MPCr5{margin-bottom:20px}.components-ErrorBoundary-styles__section__KAa7G:not(:last-child){margin-bottom:40px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `components-ErrorBoundary-styles__button__SNVqt`,
	"titleMain": `components-ErrorBoundary-styles__titleMain__MPCr5`,
	"section": `components-ErrorBoundary-styles__section__KAa7G`
};
export default ___CSS_LOADER_EXPORT___;
