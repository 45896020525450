// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sections-SparkDecision-record-style__uploadAudioInput__cF6ad{display:none}.sections-SparkDecision-record-style__audioUploader__Bv5yO{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;gap:9px;background-color:#f5f5f5;width:60px;height:42px;border-radius:17px}.sections-SparkDecision-record-style__stopBtn__k3Jeb{width:15px;height:15px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-ms-flex-pack:distribute;justify-content:space-around}.sections-SparkDecision-record-style__stopBtn__k3Jeb .sections-SparkDecision-record-style__stopColumn__rDhuN{height:80%;width:3px;border-radius:3px;background-color:#f75a00}.sections-SparkDecision-record-style__microphoneIcon__wl096{height:15px !important}.sections-SparkDecision-record-style__wrapper__FUO9D{width:100%;height:42px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:5px;background-attachment:blue}.sections-SparkDecision-record-style__audioInfo__JOZwR{-webkit-box-flex:1;-ms-flex:1;flex:1}.sections-SparkDecision-record-style__microphoneIcon__wl096{margin-bottom:5px}.sections-SparkDecision-record-style__audioName__Qsueo{color:#172331;font-family:"Roboto",sans-serif;font-size:12px;font-style:normal;font-weight:600}.sections-SparkDecision-record-style__audioTimer__sCASE{color:#343f4b;font-family:"Roboto",sans-serif;font-size:12px;font-style:normal;font-weight:400;line-height:19px}.sections-SparkDecision-record-style__rotated__NkHWN{-webkit-transform:rotate(180deg);transform:rotate(180deg);margin-left:-5px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadAudioInput": `sections-SparkDecision-record-style__uploadAudioInput__cF6ad`,
	"audioUploader": `sections-SparkDecision-record-style__audioUploader__Bv5yO`,
	"stopBtn": `sections-SparkDecision-record-style__stopBtn__k3Jeb`,
	"stopColumn": `sections-SparkDecision-record-style__stopColumn__rDhuN`,
	"microphoneIcon": `sections-SparkDecision-record-style__microphoneIcon__wl096`,
	"wrapper": `sections-SparkDecision-record-style__wrapper__FUO9D`,
	"audioInfo": `sections-SparkDecision-record-style__audioInfo__JOZwR`,
	"audioName": `sections-SparkDecision-record-style__audioName__Qsueo`,
	"audioTimer": `sections-SparkDecision-record-style__audioTimer__sCASE`,
	"rotated": `sections-SparkDecision-record-style__rotated__NkHWN`
};
export default ___CSS_LOADER_EXPORT___;
