// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-Avatar-style__root__oZsP_{border-radius:50%;overflow:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.components-Avatar-style__root__oZsP_ img{width:100%;height:auto}.components-Avatar-style__sm__MJadc{width:30px;height:30px}.components-Avatar-style__md__A3u_t{width:42px;height:42px}.components-Avatar-style__lg__mOAqh{width:82px;height:82px}.components-Avatar-style__shadow__FGpYC{-webkit-box-shadow:3px 2px 8px #9b9b9b;box-shadow:3px 2px 8px #9b9b9b}.components-Avatar-style__mirrorImage__owYAE{-webkit-transform:scaleX(-1);transform:scaleX(-1)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-Avatar-style__root__oZsP_`,
	"sm": `components-Avatar-style__sm__MJadc`,
	"md": `components-Avatar-style__md__A3u_t`,
	"lg": `components-Avatar-style__lg__mOAqh`,
	"shadow": `components-Avatar-style__shadow__FGpYC`,
	"mirrorImage": `components-Avatar-style__mirrorImage__owYAE`
};
export default ___CSS_LOADER_EXPORT___;
