import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./style.scss";

export default class AllInfluencers extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    leaderboard: PropTypes.array.isRequired,
    offer: PropTypes.object,
    onNavigate: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    style: PropTypes.object,
  };

  static defaultProps = {
    className: "",
    offer: {},
    onNavigate: (f) => f,
    onSubmit: (f) => f,
    style: {},
  };

  formatClicks = (clicks, adShows, impressions) => {
    if (adShows >= clicks) {
      return this.formatRatio(clicks, adShows);
    }
    return this.formatRatio(clicks, impressions);
  };

  formatRatio = (top, bottom) => {
    if (bottom && bottom > 0) {
      return this.formatPercent(top / bottom);
    }
    return "-";
  };

  formatPercent = (percent) => {
    return (
      percent?.toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigitals: 1,
        style: "percent",
      }) || 0
    );
  };

  formatSparkitIndex = (sparkitIndex) => {
    return (
      sparkitIndex?.toLocaleString("en-US", {
        maximumFractionDigits: 0,
        minimumFractionDigitals: 0,
      }) || 0
    );
  };

  ordinalSuffixOf = (i) => {
    let j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  };

  renderCampaigns = () => {
    let campaigns = {};
    this.props.leaderboard.forEach((el) => {
      if (!campaigns[el.offer.hashid]) {
        campaigns[el.offer.hashid] = [];
      }
      campaigns[el.offer.hashid].push(el);
    });
    return Object.keys(campaigns).map((offer) => {
      return this.renderCampaign(campaigns[offer]);
    });
  };

  renderCampaign = (leaderboards) => {
    const leaderboard = leaderboards[0];

    return (
      <div key={leaderboard.offer.hashid} className={styles.root}>
        <div className={styles.header}>
          <span className={styles.logo}>
            <img src={leaderboard.offer.thumbnail_url} alt="" />
          </span>
          <span className={styles.offer}>
            <div className={classNames(styles.header, "color-secondary")}>
              <span className={styles.name}>
                <h1>{leaderboard.offer.name}</h1>
              </span>
            </div>
          </span>
        </div>
        <table className={classNames("title", "title-sm", styles.leaderboard)}>
          <tbody>
            <tr>
              <th>Influencer</th>
              <th>Rank</th>
              <th>Impact Score</th>
              <th>Comments</th>
              <th>Votes</th>
              <th>Shares</th>
              <th>Conversions</th>
            </tr>
            {this.influencerStats(leaderboards)}
          </tbody>
        </table>
      </div>
    );
  };

  influencerStats = (leaderboard) => {
    return leaderboard.map((lb) => {
      if (!lb.user || !lb.offer) {
        return null;
      }
      return (
        <tr key={`${lb.user.hashid}_${lb.offer.hashid}`}>
          <td>
            <a href={`/profile/${lb.user.handle}`}>{lb.user.handle}</a>
          </td>
          <td>{lb.rank}</td>
          <td>{this.formatSparkitIndex(lb.sparkit_index)}</td>
          <td>{lb.comments}</td>
          <td>{lb.votes}</td>
          <td>{lb.shares}</td>
          <td>
            {lb.conversions > 0
              ? this.ordinalSuffixOf(lb.conversions_rank)
              : `-`}
          </td>
        </tr>
      );
    });
  };

  render() {
    const { style } = this.props;

    return (
      <div className={classNames(styles.wrap)} style={style}>
        <div
          className={classNames(
            "wrap__inner",
            "wrap__gutter",
            "wrap__gutter--padded"
          )}
        >
          {/* This code didn't display anything */}
          {this.renderCampaigns()}
        </div>
      </div>
    );
  }
}

const CustomAllInfluencersTable = ({ tableName, theadNames, tbodyItems }) => {
  return (
    <div className={styles.tableWrapper}>
      <div className={styles.tableName}>{tableName}</div>
      <table className={styles.customTable}>
        <thead>
          <tr>
            {theadNames &&
              theadNames.map((name, index) => (
                <th
                  key={index}
                  className={classNames(styles.column, {
                    [styles.firstColumn]: index === 0,
                  })}
                >
                  {name}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {tbodyItems &&
            tbodyItems.map((tbody, index) => (
              <tr key={index}>
                {tbody &&
                  tbody.map((item, itemIndex) => (
                    <td
                      key={itemIndex}
                      className={classNames(styles.data, {
                        [styles.firstColumnItem]: itemIndex === 0,
                      })}
                    >
                      {item}
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

CustomAllInfluencersTable.propTypes = {
  tableName: PropTypes.string.isRequired,
  tbodyItems: PropTypes.array.isRequired,
  theadNames: PropTypes.array.isRequired,
};
