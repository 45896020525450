import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import BrandOpportunityList from "components/BrandOpportunityList";
import FilterBar from "components/FilterBar";
import FilterTab from "components/FilterTab";
import styles from "./style.scss";

export default class ViewOffer extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    exclusive: PropTypes.bool,
    onNavigate: PropTypes.func.isRequired,
    onSelectOffer: PropTypes.func.isRequired,
    style: PropTypes.object,
    handleSelectAddName: PropTypes.func.isRequired,
  };

  static defaultProps = {
    exclusive: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      exclusive: false,
      nextLabel: "skip",
    };
  }

  render() {
    return (
      <div className={classNames("wrap")} style={this.props.style}>
        {/* <div className={classNames("wrap__inner", "wrap__gutter", "wrap__gutter--padded")}>
          <h1 className="title title-md">Select Campaign</h1>
        </div> */}
        <div className="wrap__inner">
          <FilterBar className={styles.filterBar}>
            <FilterTab
              label="All Campaigns"
              state={!this.state.exclusive ? "active" : ""}
              onClick={this.handleAll}
              className={styles.left}
            />
            <FilterTab
              label="Exclusive"
              state={this.state.exclusive ? "active" : ""}
              onClick={this.handleExclusive}
              className={styles.right}
            />
          </FilterBar>
        </div>
        <div>
          <BrandOpportunityList
            handleSelectAddName={this.props.handleSelectAddName}
            exclusive={this.state.exclusive}
            onSelectOffer={this.handleSelectOffer}
            onNoOffers={this.handleNoOffers}
          />
        </div>
        <div>
          {/* <Navigator
            hasPrevious={false}
            onNavigate={this.handleNavigate}
            hasFormValidation={false}
            label={this.state.nextLabel}
          /> */}
        </div>
      </div>
    );
  }

  handleNoOffers = () => {
    this.setState({ nextLabel: "spark" });
  };

  handleNavigate = () => {
    this.props.onSelectOffer(null);
  };

  handleSelectOffer = (offer) => {
    this.props.onSelectOffer(offer);
  };

  handleAll = () => {
    this.setState({
      exclusive: false,
    });
  };

  handleExclusive = () => {
    this.setState({
      exclusive: true,
    });
  };
}
