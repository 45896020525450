// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-FilterTab-style__root__OI7De{-webkit-box-flex:1;-ms-flex:1 1 0px;flex:1 1 0;color:#f75a00;height:50px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;background-color:rgba(247,90,0,.1)}.components-FilterTab-style__root__OI7De a,.components-FilterTab-style__root__OI7De button{border-radius:10px;display:block;padding:10px 0;text-align:center;width:100%}.components-FilterTab-style__unread__F84W_ span{position:relative}.components-FilterTab-style__unread__F84W_ span::after{content:"";display:block;position:absolute;top:-4px;right:-10px;height:6px;width:6px;background-color:#f75a00;border-radius:50%}.components-FilterTab-style__active__c5Nts{background-color:#f75a00;color:#fff}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-FilterTab-style__root__OI7De`,
	"unread": `components-FilterTab-style__unread__F84W_`,
	"active": `components-FilterTab-style__active__c5Nts`
};
export default ___CSS_LOADER_EXPORT___;
