import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Tag from 'components/Tag';

import styles from './style.scss';

export default class TagList extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  }

  render() {
    const {
      children,
      className,
      ...otherProps
    } = this.props;

    return (
      <ul className={classNames(styles.root, className)} {...otherProps}>
        {children}
      </ul>
    );
  }
}

class TagItem extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  }

  render() {
    const {
      children,
      className,
      ...otherProps
    } = this.props;

    return (
      <li className={classNames(styles.item, className)} {...otherProps}>
        {children}
      </li>
    );
  }
}

class TagItemFromString extends React.Component {
  static propTypes = {
    children: PropTypes.string.isRequired,
    linked: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    linked: false,
  }

  render() {
    const {
      children,
      linked,
      ...otherProps
    } = this.props;

    return (
      <TagItem {...otherProps}>
        <Tag linked={linked}>
          {children}
        </Tag>
      </TagItem>
    );
  }
}

class TagListFromArray extends React.Component {
  static propTypes = {
    linked: PropTypes.bool.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }

  static defaultProps = {
    linked: false,
  }

  render() {
    const {
      linked,
      tags,
      ...otherProps
    } = this.props;

    return (
      <TagList {...otherProps}>
        {tags.map((tag) => {
          return (
            <TagItemFromString
              key={tag}
              linked={linked}
            >
              {tag}
            </TagItemFromString>
          );
        })}
      </TagList>
    );
  }
}

export {
  TagItem,
  TagItemFromString,
  TagListFromArray,
};
