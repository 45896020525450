import React, {useState} from "react";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from 'components/IconButton';
import Icon from 'components/Icon';
import styles from './style.scss';
import HelpIcon from "../HelpIcon";

const PasswordInput = ({isHidden, name, placeholder, required, className, title, titleStyles, helperText, withRequiredMark,  ...otherProps}) => {
  const [isPasswordShowed, setIsPasswordShowed] = useState(false);

  const handleShowPassword = () => {
    setIsPasswordShowed(prev=> !prev);
  };

  const requiredTest = isHidden ? false : required;

  return (
    <div
      className={classNames(styles.inputBlock, styles.passwordInputContainer, className, {
        [styles.hiddenBlock]: isHidden,
      })}
    >
      <div className={classNames(styles.InputHeader, styles.headerWithQuestionIcon)}>
        <span className={classNames(styles.formText, titleStyles)}>
          {title}
          {withRequiredMark && <p className={styles.requiredMark}>*</p>}
        </span>
        {
          helperText && <HelpIcon helperText="8-letter minimum using Upper and lowercase plus one special symbol" />
        }
      </div>
      <div className={classNames(styles.inputContainer)}>
        <div className={classNames(styles.iconWrapper)}>
          <Icon icon="lock" size="sm" />
        </div>
        <input
          type={isPasswordShowed ? "text" : "password"}
          name={name}
          required={requiredTest}
          placeholder={placeholder}
          {...otherProps}
          className={classNames(styles.input, styles.passwordInput)}
        />
        <div className={classNames(styles.eyeIconWrapper)}>
          <IconButton onClick={handleShowPassword} icon={isPasswordShowed ? "eyeClosed" : "seePassword"} />
        </div>
      </div>
    </div>
  );
};

export default PasswordInput;

PasswordInput.propTypes = {
  className: PropTypes.string,
  isHidden: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  title: PropTypes.string,
  titleStyles: PropTypes.string,
  helperText: PropTypes.string,
  withRequiredMark: PropTypes.bool,
};

PasswordInput.defaultProps = {
  isHidden: false,
  name: "password",
  placeholder: "Password",
  required: true,
  title: "Password",
  withRequiredMark: false,
};
