// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-TagList-style__root___GvVT{list-style:none;margin:0;padding:0}.components-TagList-style__item__GeBzh{margin-right:5px;margin-bottom:8px;display:inline-block}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-TagList-style__root___GvVT`,
	"item": `components-TagList-style__item__GeBzh`
};
export default ___CSS_LOADER_EXPORT___;
