import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.scss';

export default class BrandReport extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  }

  render() {
    return (
      <table className={classNames(styles.root, this.props.className)}>
        <tbody>
          <tr>
            <th>Visitors:</th>
            <td>1,000,000</td>
          </tr>
          <tr>
            <th>Clicks:</th>
            <td>1,000,000</td>
          </tr>
          <tr>
            <th>Engagement</th>
            <td>100%</td>
          </tr>
        </tbody>
      </table>
    );
  }
}
