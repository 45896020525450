import PropTypes from "prop-types";
import React from "react";

export default class BodyColour extends React.Component {
  static propTypes = {
    offWhite: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    offWhite: false,
  };

  setClasses = (props) => {
    document.body.classList.toggle("bg-off-white", props.offWhite);
  };

  componentDidMount() {
    this.setClasses(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setClasses(nextProps);
  }

  componentWillUnmount() {
    document.body.classList.remove("bg-off-white");
  }

  render() {
    return null;
  }
}
