import classNames from "classnames";
import { graphql } from "react-apollo";
import PropTypes from "prop-types";
import React from "react";

import Icon from "components/Icon";
import Loading from "components/Loading";
import Avatar from "components/Avatar";
import Handle from "components/Handle";

import FormError from "components/FormError";

import { GraphqlBackedMedia } from "components/Media";

import styles from "./style.scss";

export default class EditingComplete extends React.Component {
  static propTypes = {
    className: PropTypes.string,

    onNavigate: PropTypes.func,
    onSubmit: PropTypes.func,

    style: PropTypes.object,
    handleSetData: PropTypes.func.isRequired,

    chosenPosterUrl: PropTypes.string,
    mediaHashid: PropTypes.string.isRequired,
    mediaType: PropTypes.string,
    message: PropTypes.string,
    thumbnailIndex: PropTypes.number,
    data: PropTypes.shape({
      comment: PropTypes.object,
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
    }),
    collabComments: PropTypes.array,
  };

  static defaultProps = {
    className: "",
    onNavigate: (f) => f,
    onSubmit: (f) => f,
    style: {},
    collabComments: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      isNextLocked: false,
    };

    this.$form = null;
    this.$message = null;
  }

  handleFormSubmit = (event) => {
    event.preventDefault();

    const form = this.$form.elements;

    const shareOn = [];
    if (form.facebook && form.facebook.checked) {
      shareOn.push("facebook");
    }
    if (form.twitter && form.twitter.checked) {
      shareOn.push("twitter");
    }

    this.props.onSubmit(shareOn);
  };

  preparedMessage = (message) => {
    const regex = /(@\w+)/g;

    const processedMessage = message.split(regex).map((part, index) => {
      if (part.match(regex)) {
        return (
          <span key={index} className={styles.blue}>
            {part}
          </span>
        );
      }
      return part;
    });

    return processedMessage;
  };

  render() {
    const {
      chosenPosterUrl,
      className,
      mediaHashid,
      mediaType,
      message,
      thumbnailIndex,
      style,
      data,
      collabComments,
    } = this.props;

    if (data.error) {
      return <div>Error</div>;
    }

    if (!data.comment && data.loading) {
      return <Loading />;
    }

    return (
      <div
        className={classNames(styles.root, className, "bg-off-white")}
        style={style}
      >
        <div className={styles.content}>
          <GraphqlBackedMedia
            size="lg"
            hashid={mediaHashid}
            onProcessedStatusChange={this.onProcessedStatusChange}
            poster={chosenPosterUrl}
          />
          <div className={styles.orangeText}>Spark Edits</div>
          <div>
            {this.state.errors &&
              this.state.errors.fields &&
              this.state.errors.fields.message && (
                <FormError errors={this.state.errors.fields.message} />
              )}
            {collabComments.length === 0 ? (
              <div className={styles.infoWrap}>
                <div className={styles.fanAvatarAndNickname}>
                  <Avatar user={data.comment.user} size="md" />
                  <Handle handle={data.comment.user.handle} />
                </div>
                <div className={styles.fanComment}>{data.comment.message}</div>
              </div>
            ) : (
              collabComments.map((item, index) => (
                <div
                  className={classNames(styles.infoWrap, {
                    [styles.marginInfoWrap]: collabComments.length >= 1,
                  })}
                  key={index}
                >
                  <div className={styles.fanAvatarAndNickname}>
                    <Avatar user={item.user} size="md" />
                    <Handle handle={item.user.handle} />
                  </div>
                  <div className={styles.fanComment}>{item.message}</div>
                </div>
              ))
            )}
            <div className={styles.votesAndAvatarBlock}>
              <div className={styles.votesBlock}>
                <Icon icon="heart" color="active" size="lg" filled={true} />
                <span>{data.comment.votes_count}</span>
                <p>Votes</p>
              </div>
              <Avatar size="md" />
            </div>
            <div className={styles.myMessageBlock}>
              <div className={classNames(styles.myComment)}>
                {this.preparedMessage(message)}
              </div>
            </div>
          </div>

          <form
            ref={(ref) => (this.$form = ref)}
            onSubmit={this.handleFormSubmit}
          >
            <button className={styles.btn}>Post</button>
            {/* <div>
              <h1>Share This Post</h1>
              <ShareOptions />
            </div> */}
            {/* <Navigator
              label="Post"
              onNavigate={this.handleNavigate}
            /> */}
          </form>
          {/* <button
            onClick={() => handleSetData(data)}
            type="submit"
            className={classNames(styles.btn, {
              [styles.disabled]: this.state.isNextLocked,
            })}
          >
            Post
          </button> */}
        </div>
      </div>
    );
  }
}
