// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-Ad-style__root__EJ3X1{width:100%;height:100%;border-radius:16px;background-color:#fff;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.components-Ad-style__isThereBackground__gD3HW{padding:10px;background-color:#f9f9f9}.components-Ad-style__img__qsXTL{width:100%;height:100%;background-color:rgba(0,0,0,0)}.components-Ad-style__iframeRoot__HJALg .components-Ad-style__iframe__Fm587{width:320px;height:300px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-Ad-style__root__EJ3X1`,
	"isThereBackground": `components-Ad-style__isThereBackground__gD3HW`,
	"img": `components-Ad-style__img__qsXTL`,
	"iframeRoot": `components-Ad-style__iframeRoot__HJALg`,
	"iframe": `components-Ad-style__iframe__Fm587`
};
export default ___CSS_LOADER_EXPORT___;
