// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-PageAudioPlayer-TrackLine-style__seeker__T3T4z{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-flex:1;-ms-flex:1;flex:1;-webkit-box-align:center;-ms-flex-align:center;align-items:center;min-width:100px}.components-PageAudioPlayer-TrackLine-style__time__RvaGg{font-size:12px;padding:12px}.components-PageAudioPlayer-TrackLine-style__track__J2XtM{-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto;background-color:#333;border-radius:3px;position:relative;height:6px;cursor:pointer}.components-PageAudioPlayer-TrackLine-style__progress__UTE8j{background-color:#555;position:absolute;width:0;height:100%;border-radius:3px}.components-PageAudioPlayer-TrackLine-style__handle__MZqf9{position:absolute;width:12px;height:12px;background-color:#fff;border-radius:50%;left:0;top:-50%;margin-left:-6px;z-index:1;cursor:pointer;opacity:0;-webkit-box-shadow:0 0 6px 6px rgba(0,0,0,.2);box-shadow:0 0 6px 6px rgba(0,0,0,.2)}.components-PageAudioPlayer-TrackLine-style__seeker__T3T4z:hover .components-PageAudioPlayer-TrackLine-style__progress__UTE8j{background-color:green}.components-PageAudioPlayer-TrackLine-style__seeker__T3T4z:hover .components-PageAudioPlayer-TrackLine-style__handle__MZqf9{opacity:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"seeker": `components-PageAudioPlayer-TrackLine-style__seeker__T3T4z`,
	"time": `components-PageAudioPlayer-TrackLine-style__time__RvaGg`,
	"track": `components-PageAudioPlayer-TrackLine-style__track__J2XtM`,
	"progress": `components-PageAudioPlayer-TrackLine-style__progress__UTE8j`,
	"handle": `components-PageAudioPlayer-TrackLine-style__handle__MZqf9`
};
export default ___CSS_LOADER_EXPORT___;
