// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-ProgressBar-style__root__GKt2b{width:100%;height:1.5rem;position:relative;-webkit-box-shadow:3px 2px 8px #9b9b9b;box-shadow:3px 2px 8px #9b9b9b;border-radius:.75rem;overflow:hidden;background-color:#fff}.components-ProgressBar-style__progress__nSwxc{position:absolute;left:0;top:0;bottom:0;-webkit-transition:width .3s linear;transition:width .3s linear;background-color:#f75a00}.components-ProgressBar-style__indeterminate__xBKrz .components-ProgressBar-style__progress__nSwxc{width:100%;-webkit-animation:components-ProgressBar-style__indeterminate__xBKrz 2s linear infinite;animation:components-ProgressBar-style__indeterminate__xBKrz 2s linear infinite;-webkit-animation-timing-function:ease-in-out;animation-timing-function:ease-in-out}@-webkit-keyframes components-ProgressBar-style__indeterminate__xBKrz{from{left:-100%}to{left:100%}}@keyframes components-ProgressBar-style__indeterminate__xBKrz{from{left:-100%}to{left:100%}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-ProgressBar-style__root__GKt2b`,
	"progress": `components-ProgressBar-style__progress__nSwxc`,
	"indeterminate": `components-ProgressBar-style__indeterminate__xBKrz`
};
export default ___CSS_LOADER_EXPORT___;
