// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-CustomDropDown-style__wrapper__boZr6{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:#f9f9f9;height:50px;border-radius:16px;padding-left:21px}.components-CustomDropDown-style__head__naGlD{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:relative;width:100%}.components-CustomDropDown-style__dropdown__seU17{position:absolute;top:50px;left:0;width:100%;z-index:100;max-height:160px;overflow:auto;border-top:.5px solid #f75a00;border-bottom:.5px solid #f75a00}.components-CustomDropDown-style__list__ucWAp{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;list-style-type:none;width:100%;background-color:#fff}.components-CustomDropDown-style__list__ucWAp .components-CustomDropDown-style__item__ekfSV{border:.5px solid #f75a00;cursor:pointer;list-style-type:none;padding:12px 0 12px 30px;width:100%}.components-CustomDropDown-style__list__ucWAp .components-CustomDropDown-style__item__ekfSV:hover{background-color:#f9f9f9}.components-CustomDropDown-style__selectText__Q_Vb_{color:#a9a9a9;font-style:italic}.components-CustomDropDown-style__arrowIcon__sIgjW{position:absolute;top:0;right:15px;height:100%}.components-CustomDropDown-style__flippedIcon__H5bsV{-webkit-transform:rotateX(180deg);transform:rotateX(180deg)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `components-CustomDropDown-style__wrapper__boZr6`,
	"head": `components-CustomDropDown-style__head__naGlD`,
	"dropdown": `components-CustomDropDown-style__dropdown__seU17`,
	"list": `components-CustomDropDown-style__list__ucWAp`,
	"item": `components-CustomDropDown-style__item__ekfSV`,
	"selectText": `components-CustomDropDown-style__selectText__Q_Vb_`,
	"arrowIcon": `components-CustomDropDown-style__arrowIcon__sIgjW`,
	"flippedIcon": `components-CustomDropDown-style__flippedIcon__H5bsV`
};
export default ___CSS_LOADER_EXPORT___;
