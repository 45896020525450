import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './style.scss';

export default class ProgressBar extends React.Component {
  static propTypes = {
    fraction: PropTypes.number,
  }

  static defaultProps = {
    fraction: null,
  }

  render() {
    const {
      fraction,
    } = this.props;

    return (
      <div className={classNames(styles.root, fraction === null && styles.indeterminate)}>
        <div
          className={styles.progress}
          style={fraction === null ? null : {width: `${100 * fraction}%`}}
        />
      </div>
    );
  }
}
