import {ApolloClient} from 'apollo-client';
import {ApolloLink} from 'apollo-link';
import {createHttpLink} from 'apollo-link-http';
import {onError} from 'apollo-link-error';
import {InMemoryCache, IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';
import {setContext} from 'apollo-link-context';
import Log from '../log';
import {getApiUrl} from '../config';

import {
  accessTokenExpired,
  getAuthorizationHeader,
  refreshAccessToken,
} from '../auth';

const PARTIAL_SCHEMA = {
  __schema: {
    types: [
      {
        kind: "UNION",
        name: "Avatar",
        possibleTypes: [
          {
            name: "Media",
          },
          {
            name: "Url",
          },
        ],
      },
    ],
  },
};

const httpLink = createHttpLink({
  uri: getApiUrl() + '/graphql',
});

const authMiddlewareLink = setContext((context) => {
  let promise = Promise.resolve();

  // If we know for sure the access token is expired, refresh it
  if (accessTokenExpired()) {
    promise = promise.then(refreshAccessToken);
  }

  return promise.then(() =>  {
    // Create the header object if it doesn't already exist
    let {headers} = context;
    if (!headers) {
      headers = {};
    }

    // Apply the authorization header if possible
    const authorizationHeader = getAuthorizationHeader();
    if (authorizationHeader) {
      headers.authorization = authorizationHeader;
      return {
        headers: headers,
      };
    }

    return {};
  });
});

const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors) {
    graphQLErrors.map(({message}) => {
      Log(message, 'exception');
    });
  }

  if (networkError) {
    Log(networkError, 'exception');
  }
});

const cache = new InMemoryCache({
  dataIdFromObject: o => o.hashid,
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData: PARTIAL_SCHEMA,
  }),
});

const link = ApolloLink.from([errorLink, authMiddlewareLink.concat(httpLink)]);

const graphqlClient = new ApolloClient({
  cache: cache,
  link: link,
});

export default graphqlClient;
