import jwtDecode from "jwt-decode";
import PropTypes from "prop-types";
import React from "react";

import Main from "components/Main";

import ErrorMessage from "components/ErrorMessage";
import Loading from "components/Loading";

import { resetPassword } from "~/auth";
import HeaderForAuthorizedUsers from "../components/HeaderForAuthorizedUsers";
import styles from "./style.scss";
import classNames from "classnames";
import PasswordInput from "../components/PasswordInput";

export default class AuthPasswordReset extends React.Component {
  static propTypes = {
    location: PropTypes.object,
  };

  constructor() {
    super();

    this.accessToken = null;

    this.state = {
      resetError: null,
      showSuccess: false,
      tokenValid: null,
      isPasswordShowed: false,
      isPasswordShowed2: false,
    };
  }

  handleDisplayPassword = () => {
    this.setState((prevState) => ({
      isPasswordShowed: !prevState.isPasswordShowed,
    }));
  };

  handleDisplayPassword2 = () => {
    this.setState((prevState) => ({
      isPasswordShowed2: !prevState.isPasswordShowed2,
    }));
  };

  UNSAFE_componentWillMount() {
    this.accessToken = new URLSearchParams(this.props.location.search).get(
      "accessToken"
    );

    this.setState({
      resetError: null,
      showSuccess: false,
    });
  }

  componentDidMount() {
    this.checkTokenValidity();
  }

  componentWillUnmount() {
    this.accessToken = null;
  }

  handlePasswordConfirmationInput(event) {
    let input = event.target;
    let form = input.form;

    if (input.value.length && input.value !== form.elements.password.value) {
      input.setCustomValidity("Passwords must match.");
    } else {
      input.setCustomValidity("");
    }
  }

  handleFormSubmission = (event) => {
    event.preventDefault();

    // Retrieve values
    let formData = new FormData(this.form);

    // No need to send the password confirmation
    formData.delete("passwordConfirmation");

    // Reset the password
    resetPassword(formData, this.accessToken)
      .catch((error) => {
        if (error.name === "ValidationError") {
          // TODO: use error.data and a language map to show messages
        }
        this.setState({
          resetError: error,
          showSuccess: false,
        });
        throw error;
      })
      .then(() => {
        this.setState({
          resetError: null,
          showSuccess: true,
        });
      });
  };

  checkTokenValidity = () => {
    try {
      let decoded = jwtDecode(this.accessToken);
      if (
        !decoded.via ||
        decoded.via !== "email" ||
        decoded.exp <= Date.now() / 1000
      ) {
        this.setState({
          tokenValid: false,
        });
        return;
      }
    } catch (error) {
      this.setState({
        tokenValid: false,
      });
      return;
    }

    // Initiate a reset command without data, to check whether the token is valid
    resetPassword(new FormData(), this.accessToken).catch((error) => {
      switch (error.name) {
        case "AuthError":
          this.setState({
            tokenValid: false,
          });
          break;
        case "ValidationError":
          this.setState({
            tokenValid: true,
          });
          break;
        default:
          throw error;
      }
    });
  };

  _render = () => {
    let content = null;
    let headerProps = null;

    if (this.state.showSuccess) {
      headerProps = {
        leftContent: {
          content: [
            { type: "link", icon: "homeFilled", link: "/" },
          ],
        },
        heading: "Reset Password",
        rightContent: { content: [{ type: "link", icon: "leave", link: "/" }] },
      };
      content = (
        <div className={styles.notificationTextWrap}>
          <h2 className={styles.notificationTextHead}>Success!</h2>
          <p className={styles.notificationText}>
            Your password has been reset and you have been logged in.
          </p>
        </div>
      );
    } else if (this.state.tokenValid === null) {
      content = <Loading />;
    } else if (!this.state.tokenValid) {
      headerProps = {
        leftContent: {
          content: [
            { type: "link", icon: "homeFilled", link: "/" },
          ],
        },
        heading: "Reset Password",
        rightContent: { content: [{ type: "link", icon: "leave", link: "/" }] },
      };
      content = (
        <div className={styles.notificationTextWrap}>
          <h2 className={styles.notificationTextHead}>
            {" "}
            No valid token was found!
          </h2>
          <p className={styles.notificationText}>
            Make sure you’re using the entire URL from the email you received.
            It could also be that the token has elapsed, or perhaps you already
            used it to reset your password.
          </p>
        </div>
      );
    } else {
      headerProps = {
        heading: "Reset Password",
      };
      content = this.renderResetForm();
    }

    return (
      <div>
        {/* <Head heading="Reset password" /> */}
        <HeaderForAuthorizedUsers {...headerProps} />
        <Main>
          <div className="wrap bg-off-white wrap__page">
            <div
              className={classNames(
                "wrap__gutter wrap__gutter--padded",
                styles.wrapper
              )}
            >
              <div className={classNames("content align-c", styles.content)}>
                {content}
              </div>
            </div>
          </div>
        </Main>
      </div>
    );
  };
  get render() {
    return this._render;
  }
  set render(value) {
    this._render = value;
  }

  renderResetForm = () => {
    return (
      <form
        ref={(ref) => (this.form = ref)}
        className={styles.form}
        onSubmit={this.handleFormSubmission}
      >
        <div className={styles.formInputsWrap}>
          <PasswordInput
            title="New Password"
            titleStyles={styles.formText}
            placeholder="New password"
            minLength="8"
          />
          <PasswordInput
            title="Confirm New Password"
            titleStyles={styles.formText}
            placeholder="Confirm password"
            name="passwordConfirmation"
            onInput={this.handlePasswordConfirmationInput}
          />
        </div>
        <button className={classNames(styles.submitBtn)} type="submit">
          Update
        </button>
        {this.state.resetError ? (
          <ErrorMessage error={this.state.resetError} />
        ) : null}
      </form>
    );
  };
}
