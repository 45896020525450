import React from "react";
import useDropdown from "../../hooks/useDropdown";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./style.scss";
import Icon from "components/Icon";

export default function CustomDropDown({
  items,
  selectedValue,
  onChange,
  disableClickOutside,
  initiallyOpen,
}) {
  const { wrapperRef, dropdownOpen, toggleDropdown, closeDropdown } =
    useDropdown(disableClickOutside, initiallyOpen);
  const handleItemClick = (item) => {
    onChange(item);
    closeDropdown();
  };
  return (
    <div ref={wrapperRef} className={styles.wrapper}>
      <div onClick={toggleDropdown} className={styles.head}>
        <span
          className={classNames({ [styles.selectText]: selectedValue === "" })}
        >
          {selectedValue === "" ? "Select..." : selectedValue}
        </span>
        <Icon
          icon="arrowDown"
          className={classNames(styles.arrowIcon, {
            [styles.flippedIcon]: dropdownOpen,
          })}
        />
      </div>
      {dropdownOpen && (
        <div className={classNames(styles.dropdown)}>
          <ul className={styles.list}>
            {items.map((item, i) => (
              <li
                key={i}
                onClick={() => handleItemClick(item)}
                className={styles.item}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

CustomDropDown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
  selectedValue: PropTypes.string,
  disableClickOutside: PropTypes.bool,
  initiallyOpen: PropTypes.bool,
};

CustomDropDown.defaultProps = {
  items: [],
  selectedValue: "",
  disableClickOutside: false,
  initiallyOpen: false,
};
