import {useCallback, useEffect, useRef, useState} from "react";

import useClickOutside from "./useClickOutside";

const BASE_INTERCEPTOR = () => true;

const useDropdown = (
  disableClickOutside = false,
  initiallyOpen = false,
  interceptor = BASE_INTERCEPTOR,

) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const wrapperRef = useRef(null);

  const openDropdown = useCallback(() => {
    setDropdownOpen(interceptor());
  }, [interceptor]);

  const closeDropdown = useCallback(() => setDropdownOpen(false), []);

  const toggleDropdown = useCallback(() => {
    setDropdownOpen((isOpen) => {
      const shouldOpen = interceptor();
      return !isOpen && shouldOpen;
    });
  }, [interceptor]);

  useEffect(() => {
    setDropdownOpen(initiallyOpen);
  }, [initiallyOpen]);

  useClickOutside(
    closeDropdown,
    dropdownOpen && !disableClickOutside,
    wrapperRef
  );


  return {
    dropdownOpen,
    openDropdown,
    closeDropdown,
    toggleDropdown,
    wrapperRef,
  };
};

export default useDropdown;
