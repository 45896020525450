// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-UploadButton-style__root__Ap9B5{position:relative;display:inline-block}.components-UploadButton-style__root__Ap9B5 input{position:absolute;z-index:1;width:100%;height:100%;opacity:0;cursor:pointer}.components-UploadButton-style__root__Ap9B5.components-UploadButton-style__styled__DhFts{display:block;margin-bottom:20px;width:100%}.components-UploadButton-style__root__Ap9B5.components-UploadButton-style__styled__DhFts .components-UploadButton-style__gutter__qkLGh{margin-top:20px}.components-UploadButton-style__root__Ap9B5.components-UploadButton-style__styled__DhFts .components-UploadButton-style__container__Qm_AD{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:100%;height:100%;background-color:#e0e0e0}.components-UploadButton-style__root__Ap9B5.components-UploadButton-style__styled__DhFts.components-UploadButton-style__finished__ikKou .components-UploadButton-style__container__Qm_AD{background-color:rgba(0,0,0,0)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-UploadButton-style__root__Ap9B5`,
	"styled": `components-UploadButton-style__styled__DhFts`,
	"gutter": `components-UploadButton-style__gutter__qkLGh`,
	"container": `components-UploadButton-style__container__Qm_AD`,
	"finished": `components-UploadButton-style__finished__ikKou`
};
export default ___CSS_LOADER_EXPORT___;
