import {stringify} from 'qs';
import {getSessionToken}  from '~/auth';

/**
 * Produce a URL to share a given URL with a given provider.
 *
 * @param {string} provider - Provider
 * @param {Object} options - Options for the share operation
 * @param {string} options.url - URL to be shared
 * @param {string} options.title - Title of the item being shared, or main text
 * to share
 * @param {string} options.description - Description of the item being shared
 * @param {Object} servicesConfig - Site services configuration (required by
 * some providers)
 *
 * @return {string} Share URL
 */
export function shareUrl(provider, options = {}, servicesConfig = {}) {
  function buildUrl(url, mappings, otherParams = {}) {
    const params = {};
    for (const [from, to] of Object.entries(mappings)) {
      if (options[from] != null) {
        params[to] = options[from];
      }
    }
    Object.assign(params, otherParams);
    return url + '?' + stringify(params);
  }

  switch (provider) {
    case 'facebook':
      return buildUrl('https://www.facebook.com/dialog/feed', {
        title: 'caption',
        url: 'link',
      }, {
        app_id: servicesConfig.facebook.client_id,
        display: 'popup',
      });
    case 'twitter':
      return buildUrl('https://twitter.com/intent/tweet', {
        title: 'text',
        url: 'url',
      });
    case 'google':
      return buildUrl('https://plus.google.com/share', {
        url: 'url',
      });
    default:
      throw new Error(`Unknown provider '${provider}'`);
  }
}

export function sessionUrl(url) {
  let sessionToken = getSessionToken();
  if (sessionToken) {
    url = url + "?sid=" + sessionToken;
  }
  return url;
}
