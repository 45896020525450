import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './style.scss';

export default class SearchBar extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  }

  render() {
    return (
      <div className={classNames(styles.root, this.props.className, "bar", "bar--grey")}>
        <input type="search" placeholder="Search" />
      </div>
    );
  }
}
