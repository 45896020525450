export const AUTH_REQUEST = '@@auth/AUTH_REQUEST';
export const AUTH_REQUEST_RESOLVED = '@@auth/AUTH_REQUEST_RESOLVED';
export const LOGIN = '@@auth/LOGIN';
export const LOGOUT = '@@auth/LOGOUT';
export const UPDATE = '@@auth/UPDATE';

export const authRequest = function() {
  return {
    type: AUTH_REQUEST,
  };
};

export const authRequestResolved = function() {
  return {
    type: AUTH_REQUEST_RESOLVED,
  };
};

export const login = function(user) {
  return {
    payload: {
      user,
    },
    type: LOGIN,
  };
};

export const logout = function() {
  return {
    type: LOGOUT,
  };
};

export const update = function(user) {
  return {
    payload: {
      user,
    },
    type: UPDATE,
  };
};
