// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-Alert-style__wrapper__LFdDw{position:fixed;left:0;top:0;width:100vw;height:100vh;z-index:999;pointer-events:none}.components-Alert-style__alertWrapper__YSRrK{width:35%;height:48px;gap:15px;font-weight:600;font-size:16px;z-index:1000000000000000000;position:absolute;left:15px;bottom:15px;padding:16px 6px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:#ed6c02;color:#fff}.components-Alert-style__warningFilled__SCiSw{background-color:#ed6c02}.components-Alert-style__infoFilled__Zzka1{background-color:#0288d1}.components-Alert-style__errorFilled__p1qT5{background-color:#d32f2f}.components-Alert-style__successFilled__f33zZ{background-color:#2e7d32}.components-Alert-style__warningOutlined__uzIax{color:#663c00;background-color:#fff4e5}.components-Alert-style__infoOutlined__zEzh6{color:#014361;background-color:#e5f6fd}.components-Alert-style__errorOutlined__G6aeK{color:#5f2120;background-color:#fdeded}.components-Alert-style__successOutlined__o8xA1{color:#1e4620;background-color:#edf7ed}@media screen and (min-width: 482px)and (max-width: 879px){.components-Alert-style__content__IsOTd{width:60%}}@media screen and (max-width: 481px){.components-Alert-style__alertWrapper__YSRrK{width:75%}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `components-Alert-style__wrapper__LFdDw`,
	"alertWrapper": `components-Alert-style__alertWrapper__YSRrK`,
	"warningFilled": `components-Alert-style__warningFilled__SCiSw`,
	"infoFilled": `components-Alert-style__infoFilled__Zzka1`,
	"errorFilled": `components-Alert-style__errorFilled__p1qT5`,
	"successFilled": `components-Alert-style__successFilled__f33zZ`,
	"warningOutlined": `components-Alert-style__warningOutlined__uzIax`,
	"infoOutlined": `components-Alert-style__infoOutlined__zEzh6`,
	"errorOutlined": `components-Alert-style__errorOutlined__G6aeK`,
	"successOutlined": `components-Alert-style__successOutlined__o8xA1`,
	"content": `components-Alert-style__content__IsOTd`
};
export default ___CSS_LOADER_EXPORT___;
