import React from 'react';
import PropTypes from 'prop-types';
import RangePicker from './RangePicker';

import styles from "./style.scss";


function VolumeLine({volume, setVolume}) {
  return (
    <div className={styles.volumeWrap}>
      <RangePicker max={1} value={volume} handleChange={setVolume} />
    </div>
  );
}

VolumeLine.propTypes = {
  setVolume: PropTypes.func.isRequired,
  volume: PropTypes.number.isRequired,
};

export default VolumeLine;
