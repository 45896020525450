import { RESET_STATE, SET_PRIMARY_COLLAB_DATA } from "actions/collabEditor";

const initialState = {
  selectedComments: [],
  sparkHashid: "",
  creatorHashid: "",
  uploadedPreviews: [],
};

const collabEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        selectedComments: [],
        sparkHashid: "",
        creatorHashid: "",
        uploadedPreviews: [],
      };
    case SET_PRIMARY_COLLAB_DATA: {
      return {
        ...state,
        selectedComments: action.payload.comments,
        sparkHashid: action.payload.sparkHashid,
        creatorHashid: action.payload.sparkCreator,
      };
    }

    default:
      return state;
  }
};

export default collabEditorReducer;
