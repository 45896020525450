// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-Timer-style__root__R4drc{display:inline-block;min-width:70px;text-align:center;font-weight:600;font-size:14px;line-height:21px;text-transform:capitalize}.components-Timer-style__icon__f0wup{width:14px;color:#f75a00 !important}.components-Timer-style__label__JXrA8{display:inline-block;vertical-align:middle}.components-Timer-style__plain__dyaoD .components-Timer-style__label__JXrA8{margin-left:7.5px}.components-Timer-style__plain__dyaoD.components-Timer-style__active__y4QCd{color:#f75a00}.components-Timer-style__plain__dyaoD.components-Timer-style__active__y4QCd svg{color:#f75a00}.components-Timer-style__plain__dyaoD.components-Timer-style__inactive__cnk2C{color:rgba(247,90,0,.5)}.components-Timer-style__plain__dyaoD.components-Timer-style__inactive__cnk2C svg{color:rgba(247,90,0,.5)}.components-Timer-style__full__nzBAd{width:100%;padding:10px 5px;background-color:rgba(247,90,0,.1);border-radius:10px}.components-Timer-style__full__nzBAd .components-Timer-style__label__JXrA8{font-family:"Roboto",sans-serif;margin-left:10px;color:#f75a00;font-size:14px;font-weight:600;line-height:21px}.components-Timer-style__full__nzBAd.components-Timer-style__active__y4QCd{color:#f75a00;border-color:#f75a00}.components-Timer-style__full__nzBAd.components-Timer-style__active__y4QCd svg{color:#f75a00}.components-Timer-style__full__nzBAd.components-Timer-style__inactive__cnk2C{background-color:rgba(247,90,0,.1)}.components-Timer-style__full__nzBAd.components-Timer-style__inactive__cnk2C svg{color:#fff}.components-Timer-style__isProfileTimer__T4Onj{width:100%;height:44px;border-radius:9px;background:rgba(247,90,0,.1)}.components-Timer-style__isProfileTimer__T4Onj .components-Timer-style__contentWrap__yDP1y{width:100%;height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-Timer-style__root__R4drc`,
	"icon": `components-Timer-style__icon__f0wup`,
	"label": `components-Timer-style__label__JXrA8`,
	"plain": `components-Timer-style__plain__dyaoD`,
	"active": `components-Timer-style__active__y4QCd`,
	"inactive": `components-Timer-style__inactive__cnk2C`,
	"full": `components-Timer-style__full__nzBAd`,
	"isProfileTimer": `components-Timer-style__isProfileTimer__T4Onj`,
	"contentWrap": `components-Timer-style__contentWrap__yDP1y`
};
export default ___CSS_LOADER_EXPORT___;
