import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {DateRangePicker as BaseDateRangePicker} from 'react-dates';

export default class DateRangePicker extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  }

  render() {
    const {
      className,
      ...otherProps
    } = this.props;

    return (
      <div className={classNames(className, 'form-input')}>
        <BaseDateRangePicker
          daySize={30}
          displayFormat="YYYY-MM-DD"
          hideKeyboardShortcutsPanel
          numberOfMonths={1}
          {...otherProps}
        />
      </div>
    );
  }
}
