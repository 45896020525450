import React from "react";

import { Link } from "react-router-dom";

import Head from "components/Head";
import Icon from "components/Icon";
import Main from "components/Main";

import styles from "./style.scss";

export default class InfluencerHelp extends React.Component {
  render = () => {
    return (
      <div>
        <Head heading="Home" />
        <Main>
          <div className="wrap">
            <div className="wrap__gutter">
              <div className="wrap__inner">
                <h1 className="title title-lg">Influencer Help</h1>
                <div>
                  <a href="#download-the-app">Save the Web App</a>
                </div>
                <div>
                  <a href="#earn-money-with-sparkit">Earn Money with Sparkit</a>
                </div>
                <div>
                  <a href="#sign-up">Sign Up</a>
                </div>
                <div>
                  <a href="#how-to-spark">How to Spark</a>
                </div>
                <div>
                  <a href="#sign-in-sign-out-password-reset">
                    Sign In, Sign Out &amp; Password Reset
                  </a>
                </div>
                <div>
                  <a href="#profile">Profile</a>
                </div>
                <div>
                  <a href="#settings">Settings</a>
                </div>
                <div>
                  <a href="#sparkit-emails">Sparkit Emails</a>
                </div>

                <h2 className="title title-md">
                  <a name="tutorial">Sparking Tutorial With Carmel</a>
                </h2>
                <iframe
                  width="100%"
                  height="200"
                  align="left"
                  src="https://player.vimeo.com/video/696743077"
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen=""
                ></iframe>
                <p className={styles.help}></p>
                <h2 className="title title-md">
                  <a name="download-the-app">Save the Web App</a>
                </h2>
                <p className={styles.help}>
                  Save the Sparkit web app to iPhone:
                </p>
                <ol>
                  <li className={styles.help}>
                    Go to <Link to="/">https://app.sparkit.buzz</Link> on Safari
                    and sign in to your Sparkit account.
                  </li>
                  <li className={styles.help}>
                    Click the <Icon icon="appleShare" size="md" /> Download icon
                    at the bottom of your screen
                  </li>
                  <li className={styles.help}>
                    Select <Icon icon="addHome" size="sm" /> Add to Home Screen
                  </li>
                </ol>
                <p className={styles.help}>
                  Save the Sparkit web app to Android:
                </p>
                <ol>
                  <li className={styles.help}>Launch the browser</li>
                  <li className={styles.help}>
                    Press Menu then select Bookmarks
                  </li>
                  <li className={styles.help}>
                    Long press any bookmark and click Add Shortcut to Homescreen
                  </li>
                </ol>

                <p className={styles.help}>
                  **Access Sparkit from your Home Screen (no sign in required).
                </p>

                <img className={styles.screen} src="/images/help/image5.png" />

                <h2 className="title title-md">
                  <a name="how-to-spark">How To Spark</a>
                </h2>
                <ul>
                  <li className={styles.help}>
                    <a href="#start-a-spark">Start a Spark</a>
                  </li>
                  <li className={styles.help}>
                    <a href="#ask-fans-for-ideas">ASK Fans for Ideas</a>
                  </li>
                  <li className={styles.help}>
                    <a href="#customize-campaign-sponsor-ad">
                      Customize Campaign Sponsor Ad
                    </a>
                  </li>
                  <li className={styles.help}>
                    <a href="#fans-answer-with-suggestions">
                      Fans ANSWER with Suggestions
                    </a>
                  </li>
                  <li className={styles.help}>
                    <a href="#the-crowd-votes-for-favorites">
                      Fans VOTE for Favorites
                    </a>
                  </li>
                  <li className={styles.help}>
                    <a href="#voila-your-spark-is-complete">
                      Post Spark Finale and SHOUT OUT to Fans and Brands
                    </a>
                  </li>
                  <li className={styles.help}>
                    <a href="#more-sparking-tips">More Sparking Tips</a>
                  </li>
                </ul>
                <h2 className="title title-sm">
                  <a name="start-a-spark">Start a Spark</a>
                </h2>
                <ol>
                  <li className={styles.help}>
                    Click the <Icon icon="video" /> <b>Camera icon</b> on the
                    top left of your screen
                  </li>
                  <li className={styles.help}>
                    Review available campaigns on the <b>ALL</b> and{" "}
                    <b>EXCLUSIVE</b> tabs and choose one that appeals to you and
                    your fans the most.
                  </li>
                  <li className={styles.help}>
                    Click the <b>Start Spark</b> button
                  </li>
                  <li className={styles.help}>
                    Choose a location for your Spark. You can choose a general
                    location (airport or mall) or be more specific (LAX or The
                    Galleria).
                  </li>
                  <li className={styles.help}>
                    Choose a time <b>duration</b> for your fans to be able to
                    submit Spark ideas. Make sure to note when they expect you
                    back for your final Spark.
                  </li>
                </ol>
                <ul>
                  <li className={styles.help}>
                    <b>TIP:</b> specific locations are the most popular. If you
                    couple it with an activity related to your current schedule
                    (on tour, on set), even better.{" "}
                    <i>I’m in Chicago on my North American tour!</i>
                  </li>
                  <li className={styles.help}>
                    <b>TIP #2:</b> Give fans enough time to make suggestions and
                    crowdsource votes for their favorite Spark ideas. 24 hours
                    is the most popular, however, which timeframe you choose
                    depends on how many followers you have. If you have 100K+
                    followers, you will get more responses in a shorter period
                    of time.
                  </li>
                </ul>

                <h2 className="title title-sm">
                  <a name="ask-fans-for-ideas">ASK Fans for Ideas</a>
                </h2>
                <ol>
                  <li className={styles.help}>
                    Enter additional text (request for suggestions) in 200
                    characters or less. Ideally, we recommend a maximum of 140
                    characters as your Spark request is going out over social.
                  </li>
                  <li className={styles.help}>
                    Click <b>Next</b>
                  </li>
                  <li className={styles.help}>
                    Attach your video &quot;ASK&quot; by clicking the Add Video
                    Button.
                  </li>
                </ol>
                <ul>
                  <li className={styles.help}>
                    <b>TIP:</b> Make the first sentence a command.{" "}
                    <i>Hey guys I need your help!</i>
                  </li>
                  <li className={styles.help}>
                    <b>TIP #2:</b> Use the remaining characters to be creative
                    and descriptive. Personal messages have the most impact.
                    Keep sentences short and sweet.{" "}
                    <i>
                      Hey guys I need your help! I don’t know what to wear for
                      my opening number #rocktheworld. Black is in but I love
                      bright colors. What do you think?
                    </i>
                  </li>
                </ul>

                <h2 className="title title-sm">
                  <a name="customize-campaign-sponsor-ad">
                    Customize Campaign Sponsor Ad
                  </a>
                </h2>
                <p className={styles.help}>
                  This is where you can add a personal message, catch phrase,
                  special offer or brand shout-out above the campaign sponsor
                  advertisement. This is in order to encourage your fans to
                  engage all the way to your completed Spark and{" "}
                  <b>click through</b> to the sponsoring brand website. This is
                  an important step as the click throughs to the sponsor website
                  is the quickest way to generate significant revenue and unlock
                  cash bonuses on Sparkit.
                </p>
                <ol>
                  <li className={styles.help}>
                    Once you have started your Spark and posted it, Press{" "}
                    <b>Next</b>
                  </li>
                  <li className={styles.help}>
                    The pop up will allow you to add custom text around your Ad
                    Sponsor
                  </li>
                  <li className={styles.help}>
                    This is a golden opportunity to shout out to your fans and
                    encourage them to engage with the ad/special offer/contest.
                  </li>
                  <li className={styles.help}>
                    Each click generates more revenue for you
                  </li>
                  <li className={styles.help}>
                    Fill in the Dialog Box and click <b>Done</b>
                  </li>
                </ol>

                <h2 className="title title-sm">
                  <a name="post-spark-on-social">
                    Post Spark Links to Your Social Channels
                  </a>
                </h2>
                <p className={styles.help}>
                  Due to security and privacy restrictions on most social
                  channels, you need to manually post your video ASK to other
                  social media to call fans back to Sparkit to make suggestions
                  and vote.
                </p>
                <ol>
                  <li className={styles.help}>
                    Once you have started your Spark and posted your
                    &quot;ASK&quot;, go to the top of the page and{" "}
                    <b>Copy and Paste the URL</b> to Twitter, Facebook,
                    Instagram (link in bio), Insta Stories (swipe up link),
                    YouTube and TikTok.
                  </li>
                  <li className={styles.help}>
                    For Twitter ONLY, you can click the direct share button on
                    Sparkit.
                  </li>
                  <li className={styles.help}>
                    You want to post to as many channels as possible to get your
                    ENTIRE fanbase involved in the crowdsourcing of ideas.
                  </li>
                </ol>
                <video width="320" height="240" controls>
                  <source src="/videos/help/copypaste.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <h2 className="title title-sm">
                  <a name="fans-answer-with-suggestions">
                    Fans ANSWER with Suggestions
                  </a>
                </h2>
                <ol>
                  <li className={styles.help}>
                    Your Spark request will be immediately sent to your Sparkit
                    Super Fans to give them first chance at making suggestions.
                    One hour later your request will be sent over social to give
                    outside fans and followers a chance to vote for their
                    favorite ideas.
                  </li>
                  <li className={styles.help}>
                    Suggestions will appear below your Spark video
                    &quot;ASK&quot;.
                  </li>
                </ol>

                <h2 className="title title-sm">
                  <a name="the-crowd-votes-for-favorites">
                    Fans VOTE for Favorites
                  </a>
                </h2>
                <ol>
                  <li className={styles.help}>
                    As suggestions are shared over social (by the SuperFans) and
                    you continue to promote your Spark on your social channels,
                    the broader fan base will begin to VOTE (Like) their
                    favorites by clicking the <b>Heart icon</b> to the right of
                    the screen.{" "}
                  </li>
                  <li className={styles.help}>
                    Each fan has 5 VOTES to allocate.
                  </li>
                  <li className={styles.help}>
                    Most-VOTED suggestions will filter to the top of your list.
                  </li>
                </ol>
                <video width="320" height="240" controls>
                  <source src="/videos/help/likesvotes.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <h2 className="title title-sm">
                  <a name="voila-your-spark-is-complete">
                    Post Spark Finale and SHOUT OUT to Fans and Brands
                  </a>
                </h2>
                <ol>
                  <li className={styles.help}>
                    Go back to your original Spark feed and scroll down the list
                    of suggestions.
                  </li>
                  <li className={styles.help}>
                    Once the voting window has ended you will see a{" "}
                    <b>Choose Idea</b> button to the right of each suggestion.
                  </li>
                  <li className={styles.help}>
                    Choose the idea with the <b>MOST VOTES</b> by clicking the
                    button (e.g. 1,567 Likes Made This Happen) and use it to
                    complete your final Spark video.
                  </li>
                  <li className={styles.help}>
                    Once you Choose the idea, the <b>ADD Video</b> button will
                    appear
                  </li>
                  <li className={styles.help}>
                    Post your Final Spark and use the additional text space to
                    include a shout out to the winning fan. You can also include
                    a personalized reward/offer here.
                  </li>
                  <li className={styles.help}>
                    Include a shout out to the winner in your final Spark.
                  </li>
                  <li className={styles.help}>
                    Post your completed Spark by clicking the <b>Add Video</b>{" "}
                    Button
                  </li>
                  <li className={styles.help}>
                    <b>Voila!</b> Your Spark is Complete. Time to start another
                    one!
                  </li>
                </ol>
                <p className={styles.help}>
                  <b>NOTE:</b> A Spark consists of 2 videos on the same thread
                  (An opening Spark ASK and a closing Spark SHOUT OUT). Sparkit
                  is architected so you can easily see which suggestion is the
                  most popular, the one with the most votes, is the one fans
                  want to see.
                </p>
                <img className={styles.screen} src="/images/help/screen8.jpg" />
                <img className={styles.screen} src="/images/help/screen9.jpg" />
                <img
                  className={styles.screen}
                  src="/images/help/screen10.jpg"
                />

                <h2 className="title title-md">
                  <a name="earn-money-with-sparkit">Earn Money with Sparkit</a>
                </h2>
                <p className={styles.help}>
                  Sparks are a series of collaborative videos you create with
                  fans using crowdsourcing and voting to generate ideas. The
                  secret to great Sparks are the fan suggestions and votes. The
                  more suggestions and votes, the more valuable the Sparks.
                  Leverage your other social channels and get your crowd pumped
                  to generate ideas and vote for content on Sparkit.
                </p>
                <p className={styles.help}>
                  Sparkit pays $500 per 1M social per Spark <b>PLUS</b>{" "}
                  engagement bonuses. Unlike a product placement or standard
                  social post, you Spark multiple times for the same brand and
                  get paid each time. As your fans make suggestions and vote on
                  each consecutive Spark, you unlock bonus cash and payday
                  increases. We pay directly via PayPal.
                </p>
                <p className={styles.help}>
                  Sparks are unique - they <b>DO NOT</b> include or mention the
                  brand at all – instead you simply use campaign hashtags
                  provided as inspiration for your Spark videos. The <b>ONLY</b>{" "}
                  space where you promote your sponsor is in the customized Ad
                  text above the sponsor logo that appears at the end of each
                  Spark.
                </p>
                <p className={styles.help}>
                  <b>Note:</b> When signing up to Sparkit, make sure you include
                  your PayPal email address to get paid.{" "}
                  <Link to="/profile/">
                    Profile Page &gt; Edit &gt; PayPal Text Box &gt; Type Email
                  </Link>
                </p>

                <p className={styles.help}>
                  Once you sign-up to Sparkit and we activate your Influencer
                  account, you can access the brand campaign list by clicking on
                  the camera icon in the top left of your home screen, which
                  leads you to the{" "}
                  <Link to="/spark/post/">Select Campaign</Link> page from where
                  you start your Sparks.
                  <b>Note:</b> If you do not see any specific campaign detail on
                  this first page, you can still Spark by clicking the Spark
                  button in the bottom right. Get your fans engaged in helping
                  you crowdsource ideas for your Sparks. High engagement is what
                  brands want to see. We will syndicate your engagement levels
                  and demographic information to our Sponsors so that we can
                  find the right match for you.{" "}
                  <b>
                    *If you have been invited to Sparkit for a specific brand
                    campaign and do not see the campaign detail when you log in,
                    please contact{" "}
                    <a href="mailto:creators@sparkit.buzz">
                      creators@sparkit.buzz
                    </a>{" "}
                    for help.
                  </b>
                </p>

                <p className={styles.help}>
                  There are two types of campaign opportunities that you can
                  access as an influencer.
                </p>
                <ul>
                  <li className={styles.help}>All Campaigns</li>
                  <li className={styles.help}>Exclusive Campaigns</li>
                </ul>
                <p className={styles.help}>
                  <b>All</b> Campaigns are typically for first-time Sparkers and{" "}
                  <b>Exclusive campaigns</b> are those we have assigned to you
                  as best fit you and your fans. An initial Spark is required
                  for you to access the campaign list and/or elevate you to
                  Exclusive campaigns. Your content unlocks access to
                  advertisers from which you can earn the most revenue.
                </p>
                <img className={styles.screen} src="/images/help/image2.png" />

                <p className={styles.help}>
                  From your initial Spark you will see your name on that
                  campaign leaderboard. You get paid based on reach if you are
                  on the top of the leaderboard above the pay line indicated.
                  Sparkit pays via PayPal on the 15th and 30th of each month.
                </p>
                <img
                  className={styles.screenWide}
                  src="/images/help/image8.png"
                />

                <p className={styles.help}>
                  The leaderboard is based on your Impact Score. Our Impact
                  equation includes the following data points with the following
                  weightings applied as multiples:
                </p>
                <table>
                  <tr>
                    <td>Geography</td>
                    <td> -0.5</td>
                  </tr>
                  <tr>
                    <td>Social Click Through</td>
                    <td> -2</td>
                  </tr>
                  <tr>
                    <td>Comments</td>
                    <td> -0.5</td>
                  </tr>
                  <tr>
                    <td>Votes</td>
                    <td> -1</td>
                  </tr>
                  <tr>
                    <td>Shares</td>
                    <td> -1</td>
                  </tr>
                  <tr>
                    <td>Clicks</td>
                    <td> -2</td>
                  </tr>
                  <tr>
                    <td>Bonus</td>
                    <td> -3 (Conversions, target region clicks, CPA)</td>
                  </tr>
                </table>

                <p className={styles.help}>
                  The bonus is used to provide extra $ and activate predictive
                  metrics. We provide an estimate, or we are able to pass
                  through a direct offer amount from an advertiser. Like other
                  Ad Networks Sparkit retains an average of 30% after partner
                  payouts. Our baseline calculation for next payments are as
                  follows.
                </p>

                <p className={styles.blue}>CPC X 1 Click + Bonus $ X Bonus #</p>

                <p className={styles.help}>
                  With each Spark you will see how much money you made in your
                  profile report. You will also see your estimated next payment
                  in your profile. Your payments will be combined in your
                  payment on the 15th and 30th of each month.
                </p>

                <img className={styles.screen} src="/images/help/image1.png" />

                <h2 className="title title-sm">
                  <a name="more-sparking-tips">More Sparking Tips</a>
                </h2>
                <p className={styles.help}>
                  There are a few basic elements to making a great Spark.
                  Starting with a clever request for ideas to creative
                  incorporation of a fan’s winning suggestion in your final
                  Spark. Here are a few tips to make Sparks really fly:
                </p>
                <ul>
                  <li className={styles.help}>
                    <b>TIP #1</b> Play off ideas that mesh with your daily life.
                    Use ideas that fit into your schedule. Whether you are
                    scouting for locations for a new TV pilot or hunting for a
                    great place to eat while on vacation, the more specific and
                    personal you make your requests, the more widely consumed
                    and shared they will be.
                  </li>
                  <p className={styles.help}>
                    <i>
                      Hey Super Fans. Come shopping with me! It’s Grammy time
                      and I’m so excited! Gotta hunt down a fabulous gown for
                      the red carpet. What should I wear? #timetoshine
                    </i>
                  </p>
                  <li className={styles.help}>
                    <b>TIP #2:</b> Make your requests personal and intimate. Be
                    genuine and sincere. The closer your fans feel to you, the
                    more engaged they will be.
                  </li>
                  <p className={styles.help}>
                    <i>
                      I need your expert advice! Flying a redeye flight to
                      Europe tonight. I’m really scared of flying. What should I
                      throw into my carry-on to take my mind off of it?
                      #songsfromhome
                    </i>
                  </p>
                  <li className={styles.help}>
                    <b>TIP #3:</b> Be active on Sparkit. Spark on a regular
                    basis (e.g once a week). Set an expectation for your fans
                    and they will look forward to every request for ideas.
                  </li>
                  <p className={styles.help}>
                    <i>
                      Hey gang! It’s Friday again and I’m in New York with my
                      mom and we don’t know what to do tonight. Eat? Drink?
                      Dance? Theatre? Help us decide! #girlsnightout
                    </i>
                  </p>
                  <li className={styles.help}>
                    <b>TIP #4:</b> Include a co-star in your Spark. Fans love to
                    see Influencers with their friends, especially if their
                    friends are also famous.
                  </li>
                  <p className={styles.help}>
                    <i>
                      Celebrating with John Doe at the Hilton lobby bar. Haven’t
                      seen him in ages. The cocktail menu is daunting. No idea
                      what to order. What’s your favorite old school cocktail?
                      #cheerstoyou
                    </i>
                  </p>
                  <li className={styles.help}>
                    <b>TIP #5:</b> Countdown clocks are very effective. Include
                    a timeframe in your Spark requests. Examples could include
                    number of weeks to the launch of a project, input on
                    creation of a new product, ending to a tour or TV series.
                  </li>
                  <p className={styles.help}>
                    <i>
                      Hello lovelies! On the final leg of my tour. 1 more stop
                      in Paris – the city of lights. What would you like to see
                      backstage? #cityoflights
                    </i>
                  </p>
                </ul>

                <p className={styles.help}>
                  <b>Still Need Help?</b>{" "}
                  <a href="mailto:influencers@sparkit.buzz">
                    influencers@sparkit.buzz
                  </a>
                </p>

                <h1 className="title title-md">
                  <a name="sign-up">Sign In, Sign Out & Password Reset</a>
                </h1>
                <ul>
                  <li className={styles.help}>
                    <a href="#sign-up-options">Sparkit Sign In Options</a>
                  </li>
                  <li className={styles.help}>
                    <a href="#sign-up-with-twitter">Sign up with Twitter</a>
                  </li>
                  <li className={styles.help}>
                    <a href="#sign-up-with-facebook">Sign up with Facebook</a>
                  </li>
                  <li className={styles.help}>
                    <a href="#sign-up-with-google">Sign up with Google</a>
                  </li>
                  <li className={styles.help}>
                    <a href="#sign-up-with-a-referral-link">
                      Sign Up With a Referral Link
                    </a>
                  </li>
                </ul>

                <img className={styles.screen} src="/images/help/screen4.jpg" />

                <h2 className="title title-sm">
                  <a name="sign-options">Sparkit Sign In Options</a>
                </h2>
                <p className={styles.help}>
                  To access your account, go to{" "}
                  <Link to="/">https://app.sparkit.buzz</Link> and click on the
                  Sign Up/Sign In button. If you land on the Sign Up window,
                  simply click on Sign In at the bottom. From there you have the
                  following sign in options.
                </p>

                <h2 className="title title-sm">
                  <a name="sign-up-with-twitter">Sign up with Twitter</a>
                </h2>
                <ol>
                  <li className={styles.help}>
                    From the <Link to="/">https://app.sparkit.buzz</Link> Home
                    Page, click the <b>Twitter</b> button under Sign in to
                    Sparkit
                  </li>
                  <li className={styles.help}>
                    Sparkit will automatically sign you in using your Twitter
                    credentials.
                  </li>
                </ol>

                <h2 className="title title-sm">
                  <a name="sign-up-with-facebook">Sign Up with Facebook</a>
                </h2>
                <ol>
                  <li className={styles.help}>
                    From the <Link to="/">https://app.sparkit.buzz</Link> Home
                    Page, click the <b>Facebook</b> button under Sign in to
                    Sparkit
                  </li>
                  <li className={styles.help}>
                    Sparkit will automatically sign you in using your Facebook
                    credentials.
                  </li>
                </ol>

                <h2 className="title title-sm">
                  <a name="sign-up-with-google">Sign Up with Google</a>
                </h2>
                <ol>
                  <li className={styles.help}>
                    From the <Link to="/">https://app.sparkit.buzz</Link> Home
                    Page, click the <b>Google</b> button under Sign in to
                    Sparkit
                  </li>
                  <li className={styles.help}>
                    Sparkit will automatically sign you in using your Gmail
                    credentials.
                  </li>
                </ol>

                <h2 className="title title-sm">
                  <a name="sign-up-with-a-referral-link">
                    Sign In with Username and Password
                  </a>
                </h2>
                <ol>
                  <li className={styles.help}>
                    Enter your Email Address or Sparkit Handle.
                  </li>
                  <li className={styles.help}>Enter your Password.</li>
                  <li className={styles.help}>Click the Submit button.</li>
                </ol>

                <p className={styles.help}>
                  <b>Still Need Help?</b>{" "}
                  <a href="mailto:influencers@sparkit.buzz">
                    influencers@sparkit.buzz
                  </a>
                </p>

                <h2 className="title title-md">
                  <a name="sign-in-sign-out-password-reset">
                    Sign In, Sign Out &amp; Password Reset
                  </a>
                </h2>
                <ul>
                  <li className={styles.help}>
                    <a href="#sparkit-sign-in-options">
                      Sparkit Sign In Options
                    </a>
                    <li className={styles.help}>
                      <a href="#sign-in-with-twitter">Sign In with Twitter</a>
                    </li>
                    <li className={styles.help}>
                      <a href="#sign-in-with-facebook">Sign In with Facebook</a>
                    </li>
                    <li className={styles.help}>
                      <a href="#sign-in-with-google">Sign In with Google</a>
                    </li>
                    <li className={styles.help}>
                      <a href="#sign-in-with-username-and-password">
                        Sign In with Username and Password
                      </a>
                    </li>
                  </li>
                  <li className={styles.help}>
                    <a href="#sign-out-of-sparkit">Sign out of Sparkit</a>
                  </li>
                  <li className={styles.help}>
                    <a href="#password-reset">Password Reset</a>
                  </li>
                  <img
                    className={styles.screen}
                    src="/images/help/screen3.jpg"
                  />
                  <h2 className="title title-sm">
                    <a name="sparkit-sign-in-options">
                      Sparkit Sign In Options
                    </a>
                  </h2>
                  <p className={styles.help}>
                    To access your account, go to{" "}
                    <Link to="/">https://app.sparkit.buzz</Link> and click on
                    the <b>Sign Up/Sign In</b> button. If you land on the Sign
                    Up window, simply click on <b>Sign In</b> at the bottom.
                    From there you have the following sign in options.
                  </p>

                  <h2 className="title title-sm">
                    <a name="sign-in-with-twitter">Sign In with Twitter</a>
                  </h2>
                  <ol>
                    <li className={styles.help}>
                      From the <Link to="/">https://app.sparkit.buzz</Link> Home
                      Page, click the <b>Twitter</b> button under Sign in to
                      Sparkit
                    </li>
                    <li className={styles.help}>
                      Sparkit will automatically sign you in using your Twitter
                      credentials
                    </li>
                  </ol>

                  <h2 className="title title-sm">
                    <a name="sign-in-with-facebook">Sign In with Facebook</a>
                  </h2>
                  <ol>
                    <li className={styles.help}>
                      From the <Link to="/">https://app.sparkit.buzz</Link> Home
                      Page, click the <b>Facebook</b> button under Sign in to
                      Sparkit
                    </li>
                    <li className={styles.help}>
                      Sparkit will automatically sign you in using your Facebook
                      credentials
                    </li>
                  </ol>

                  <h2 className="title title-sm">
                    <a name="sign-in-with-google">Sign In with Google</a>
                  </h2>
                  <ol>
                    <li className={styles.help}>
                      From the <Link to="/">https://app.sparkit.buzz</Link> Home
                      Page, click the <b>Google</b> button under Sign in to
                      Sparkit
                    </li>
                    <li className={styles.help}>
                      Sparkit will automatically sign you in using your Gmail
                      credentials
                    </li>
                  </ol>

                  <h2 className="title title-sm">
                    <a name="sign-in-with-username-and-password">
                      Sign In with Username and Password
                    </a>
                  </h2>
                  <ol>
                    <li className={styles.help}>
                      Enter your Email Address or Sparkit Handle
                    </li>
                    <li className={styles.help}>Enter your Password</li>
                    <li className={styles.help}>Click the Submit button</li>
                  </ol>
                  <h2 className="title title-sm">
                    <a name="sign-out-of-sparkit">Sign out of Sparkit</a>
                  </h2>
                  <p>
                    From the <Link to="/">https://app.sparkit.buzz</Link> Home
                    Page or Profile page, click the orange Sign Out in the top
                    right.
                  </p>
                  <h2 className="title title-sm">
                    <a name="password-reset">Password Reset</a>
                  </h2>
                  <ol>
                    <li>
                      From your Profile page, click More (under your Cover Photo
                      Handle)
                    </li>
                    <li>Click Reset Password under your cover photo</li>
                    <li>Choose a new password</li>
                    <li>Then click Done</li>
                  </ol>
                </ul>
                <p className={styles.help}>
                  <b>Still Need Help?</b>{" "}
                  <a href="mailto:influencers@sparkit.buzz">
                    influencers@sparkit.buzz
                  </a>
                </p>

                <h2 className="title title-md">
                  <a name="profile">Profile</a>
                </h2>
                <ul>
                  <li className={styles.help}>
                    <Link to="#overview">Overview</Link>
                  </li>
                  <li className={styles.help}>
                    <Link to="#profile-picture">Profile Picture</Link>
                  </li>
                  <li className={styles.help}>
                    <Link to="#handle">Handle (Username)</Link>
                  </li>
                  <li className={styles.help}>
                    <Link to="#bio">Bio</Link>
                  </li>
                  <li className={styles.help}>
                    <Link to="#phone">Phone Number</Link>
                  </li>
                  <li className={styles.help}>
                    <Link to="#influencer-categories">Creator Categories</Link>
                  </li>
                  <li className={styles.help}>
                    <Link to="#paypal-settings">Paypal Settings</Link>
                  </li>
                  <li className={styles.help}>
                    <Link to="#notifications">Notifications</Link>
                  </li>
                </ul>
                <h2 className="title title-sm">
                  <a name="overview">Overview</a>
                </h2>
                <p className={styles.help}>
                  After you first sign up for Sparkit, make sure you create your
                  personal <Link to="/profile">Profile</Link>.
                </p>
                <ol>
                  <li className={styles.help}>
                    Sign in to <Link to="/">https://app.sparkit.buzz</Link>
                  </li>
                  <li className={styles.help}>
                    Click on your cover photo thumbnail in the top left corner
                    to access your <b>Profile</b>.
                  </li>
                  <li className={styles.help}>
                    Click <Icon icon="edit" size="sm" /> <b>Edit</b> under your
                    profile image and handle
                  </li>
                  <li className={styles.help}>
                    Complete <b>ALL</b> fields. Make sure you enter the email
                    address linked to your PayPal account as that is how you get
                    paid.
                  </li>
                </ol>

                <h2 className="title title-sm">
                  <a name="overview">Profile Picture</a>
                </h2>
                <p className={styles.help}>
                  Change your avatar or cover photo.
                </p>
                <ol>
                  <li className={styles.help}>
                    From your <Link to="/profile">Profile</Link> page, click{" "}
                    <Icon icon="edit" size="sm" /> <b>Edit</b> (under your Cover
                    Photo Handle)
                  </li>
                  <li className={styles.help}>
                    Click on <b>Change</b> under your cover photo
                  </li>
                  <li className={styles.help}>
                    Take a photo or upload an image from your library
                  </li>
                  <li className={styles.help}>
                    Then click <b>Done</b>
                  </li>
                </ol>

                <h2 className="title title-sm">
                  <a name="overview">Handle (Username)</a>
                </h2>
                <p className={styles.help}>
                  Your <b>Handle</b> is your username.
                </p>
                <ol>
                  <li className={styles.help}>
                    From your <Link to="/profile">Profile</Link> page, click{" "}
                    <Icon icon="edit" size="sm" /> <b>Edit</b> (under your Cover
                    Photo Handle)
                  </li>
                  <li className={styles.help}>Change your handle</li>
                  <li className={styles.help}>
                    Then click <b>Done</b>
                  </li>
                </ol>

                <h2 className="title title-sm">
                  <a name="bio">Bio</a>
                </h2>
                <p className={styles.help}>Add or edit your bio.</p>
                <ol>
                  <li className={styles.help}>
                    From your <Link to="/profile">Profile</Link> page, click{" "}
                    <Icon icon="edit" size="sm" /> <b>Edit</b> (under your Cover
                    Photo Handle)
                  </li>
                  <li className={styles.help}>
                    Type new text in the Bio text box
                  </li>
                  <li className={styles.help}>
                    Then click <b>Done</b>
                  </li>
                </ol>

                <h2 className="title title-sm">
                  <a name="phone">Phone Number</a>
                </h2>
                <p className={styles.help}>
                  Add a Phone Number for SMS Notifications.
                </p>
                <ol>
                  <li className={styles.help}>
                    From your <Link to="/profile">Profile</Link> page, click{" "}
                    <Icon icon="edit" size="sm" /> <b>Edit</b> (under your Cover
                    Photo Handle)
                  </li>
                  <li className={styles.help}>Add your phone number</li>
                  <li className={styles.help}>
                    Then click <b>Done</b>
                  </li>
                </ol>

                <p className={styles.help}>
                  *Note: it is important to include your phone number as we will
                  be sending Spark tips, campaign info and how to make more
                  money via SMS.
                </p>

                <h2 className="title title-sm">
                  <a name="influencer-categories">Creator Categories</a>
                </h2>
                <p className={styles.help}>
                  This is required by all Creators to ensure you are eligible
                  for and targeted with the &quot;best fit&quot; campaigns and
                  sponsors. The more specific you are with your segment choices,
                  the better aligned you will be with available Brand campaigns
                  on Sparkit.
                </p>
                <ol>
                  <li className={styles.help}>
                    From your <Link to="/profile">Profile</Link> page, click{" "}
                    <Icon icon="edit" size="sm" /> <b>Edit</b> (under your Cover
                    Photo Handle)
                  </li>
                  <li className={styles.help}>
                    Click the <b>Category</b> drop down
                  </li>
                  <li className={styles.help}>
                    Choose the option that best describes you
                  </li>
                  <li className={styles.help}>
                    You can choose up to three categories
                  </li>
                  <li className={styles.help}>
                    Then click <b>Done</b>
                  </li>
                </ol>

                <h2 className="title title-sm">
                  <a name="overview">Paypal Settings</a>
                </h2>
                <p className={styles.help}>
                  <b>
                    This is required by all Influencers to ensure we can pay
                    you.
                  </b>{" "}
                  It’s simple – just enter your PayPal email address.
                </p>
                <ol>
                  <li className={styles.help}>
                    From your <Link to="/profile">Profile</Link> page, click{" "}
                    <Icon icon="edit" size="sm" /> <b>Edit</b> (under your Cover
                    Photo Handle)
                  </li>
                  <li className={styles.help}>Scroll to the PayPal text box</li>
                  <li className={styles.help}>
                    Type in the email address associated with your PayPal
                    account
                  </li>
                  <li className={styles.help}>
                    Then click <b>Done</b>
                  </li>
                </ol>

                <h2 className="title title-sm">
                  <a name="overview">Notifications</a>
                </h2>
                <p className={styles.help}>
                  Changing your notification settings lets you control how often
                  you hear about what’s going on. You can turn them completely
                  OFF/ON or pick which types to receive.
                </p>
                <p className={styles.help}>To change email notifications:</p>
                <ol>
                  <li className={styles.help}>
                    From your <Link to="/profile">Profile</Link> page, click{" "}
                    <Icon icon="edit" size="sm" /> <b>Edit</b> (under your Cover
                    Photo Handle)
                  </li>
                  <li className={styles.help}>
                    Scroll to the list of notification settings
                  </li>
                  <li className={styles.help}>
                    You can turn each notification type on or off
                  </li>
                  <li className={styles.help}>
                    Then click <b>Done</b>
                  </li>
                </ol>

                <p className={styles.help}>
                  <b>Still Need Help?</b>{" "}
                  <a href="mailto:influencers@sparkit.buzz">
                    influencers@sparkit.buzz
                  </a>
                </p>
                <h2 className="title title-md">
                  <a name="settings">Settings</a>
                </h2>
                <p className={styles.help}>Accessing your Settings:</p>
                <ol>
                  <li className={styles.help}>
                    Click on your cover image thumbnail in the top left corner
                    of the screen to access your{" "}
                    <Link to="/profile">Profile</Link> page
                  </li>
                  <li className={styles.help}>
                    Click on More under your profile picture and handle.
                  </li>
                </ol>

                <p className={styles.help}>
                  You can access the following Settings options:
                </p>
                <ul>
                  <li className={styles.help}>
                    <a href="#change-password">Change Password</a>
                  </li>
                  <li className={styles.help}>
                    <a href="#link-social-accounts">Link Social Accounts</a>
                  </li>
                </ul>

                <h2 className="title title-sm">
                  <a name="change-password">Change Password</a>
                </h2>
                <p className={styles.help}>To change your password:</p>
                <ol>
                  <li className={styles.help}>
                    Select <b>Change Password</b> from the{" "}
                    <Icon icon="options" /> <b>More</b> menu
                  </li>
                  <li className={styles.help}>Type your current password</li>
                  <li className={styles.help}>
                    Then type in new password and confirm
                  </li>
                  <li className={styles.help}>
                    Then click <b>Done</b>
                  </li>
                </ol>

                <h2 className="title title-sm">
                  <a name="link-social-accounts">Link Social Accounts</a>
                </h2>
                <p className={styles.help}>
                  To connect your account to Twitter:
                </p>
                <ol>
                  <li className={styles.help}>
                    Select <b>Link Social Accounts</b> from the <b>More</b> menu
                  </li>
                  <li className={styles.help}>
                    Click <b>Connect to Twitter</b> button
                  </li>
                  <li className={styles.help}>
                    Click the <b>Authorize</b> button to connect Sparkit to your
                    Twitter account (you may need to enter your Twitter
                    credentials)
                  </li>
                  <li className={styles.help}>Confirm your current password</li>
                  <li className={styles.help}>
                    Click <b>Update</b> when finished
                  </li>
                </ol>

                <p className={styles.help}>
                  To connect your account to Facebook:
                </p>
                <ol>
                  <li className={styles.help}>
                    Select <b>Link Social Accounts</b> from the More menu
                  </li>
                  <li className={styles.help}>
                    Click <b>Connect to Facebook</b> button
                  </li>
                  <li className={styles.help}>
                    Click the <b>Authorize</b> button to connect Sparkit to your
                    Facebook account (you may need to enter your Facebook
                    credentials)
                  </li>
                  <li className={styles.help}>Confirm your current password</li>
                  <li className={styles.help}>
                    Click <b>Update</b> when finished
                  </li>
                </ol>
                <p className={styles.help}>
                  <b>Still Need Help?</b>{" "}
                  <a href="mailto:influencers@sparkit.buzz">
                    influencers@sparkit.buzz
                  </a>
                </p>

                <h2 className="title title-md">
                  <a name="sparkit-emails">Sparkit Emails</a>
                </h2>
                <p className={styles.help}>Not getting e-mails from us?</p>
                <ol>
                  <li className={styles.help}>
                    First, check your spam folder.
                  </li>
                  <li className={styles.help}>
                    Check if your email notifications are turned off/on in your
                    personal settings.
                  </li>
                  <li className={styles.help}>
                    If you have an &commat;gmail.com address, check the Social
                    tab make sure you&quot;re checking the correct email
                    address. Often people forget which email they used to sign
                    up
                  </li>
                </ol>
                <p className={styles.help}>
                  <b>Still Need Help?</b>{" "}
                  <a href="mailto:influencers@sparkit.buzz">
                    influencers@sparkit.buzz
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Main>
      </div>
    );
  };
}
