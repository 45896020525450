import classNames from "classnames";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import gql from "graphql-tag";
import React from "react";
import PropTypes from "prop-types";

import FilterBar from "components/FilterBar";
import FilterTab from "components/FilterTab";

import Loading from "components/Loading";
import Main from "components/Main";
import NotLoggedIn from "components/NotLoggedIn";

import InfluencerStats from "./elements/InfluencerStats";
import AllInfluencers from "./elements/AllInfluencers";

import styles from "./styles.scss";
import HeaderForAuthorizedUsers from "../../components/HeaderForAuthorizedUsers";

const mapStateToProps = function (state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
  };
};

class Leaderboard extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool,
    className: PropTypes.string,
    data: PropTypes.shape({
      error: PropTypes.object,
      leaderboard: PropTypes.arrayOf(PropTypes.object),
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
    }),
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      focusedInput: null,
      myleaderboard: true,
    };
  }

  onFocusChange = (inputName) => {
    this.setState({
      focusedInput: inputName,
    });
  };

  handleNavigate = (delta) => {
    this.setState((prevState) => {
      return {
        viewIndex: prevState.viewIndex + delta,
      };
    });
  };

  myLeaderboard = (leaderboard, user) => {
    let myLeaderboard = [];
    if (leaderboard) {
      leaderboard.forEach((el) => {
        if (el.user && el.user.hashid === user.hashid) {
          myLeaderboard.push(el);
        }
      });
    }

    return myLeaderboard;
  };

  render() {
    const { authenticated, user } = this.props;

    const headProps = {};

    if (!authenticated) {
      return <NotLoggedIn headProps={headProps} />;
    }

    if (!this.props.data.leaderboard && this.props.data.loading) {
      return <Loading headProps={headProps} />;
    }

    const { leaderboard } = this.props.data;

    if (this.props.data.error) {
      return <p>Error</p>;
    }

    const InfluencerHeaderProps = {
      leftContent: {
        content: [
          { type: "link", icon: "homeFilled", link: "/" },
        ],
      },
      rightContent: {
        content: [
          {
            type: "link",
            icon: "leaderboardNew",
            link: "/profile/leaderboard",
          },
          { type: "link", icon: "reportNew", link: "/profile/report" },
          { type: "link", icon: "video", link: "/spark/post" },
        ],
      },
    };
    const FanHeaderProps = {
      leftContent: { content: { type: "logo" } },
      rightContent: { content: { type: "avatar" } },
    };

    const headerProps =
      user && user.is_influencer ? InfluencerHeaderProps : FanHeaderProps;

    return (
      <div>
        {/* <Head {...headProps} /> */}
        <HeaderForAuthorizedUsers {...headerProps} />
        <Main>
          <div className="wrap__inner">
            <div
              className={classNames(
                "wrap__inner",
                "wrap__inner--skinny",
                "wrap__gutter",
                "wrap__gutter--padded"
              )}
            >
              <FilterBar className={styles.filterBar}>
                <FilterTab
                  label="My Stats"
                  state={this.state.myleaderboard ? "active" : ""}
                  onClick={this.handleMy}
                  className={styles.left}
                />
                <FilterTab
                  label="Leaderboard"
                  state={!this.state.myleaderboard ? "active" : ""}
                  onClick={this.handleAll}
                  className={styles.right}
                />
              </FilterBar>
            </div>
          </div>
          <div>
            {this.state.myleaderboard ? (
              <InfluencerStats
                leaderboard={this.myLeaderboard(leaderboard, user)}
                onNavigate={this.handleNavigate}
              />
            ) : (
              <AllInfluencers leaderboard={leaderboard} />
            )}
          </div>
        </Main>
      </div>
    );
  }

  handleMy = () => {
    this.setState({
      myleaderboard: true,
    });
  };

  handleAll = () => {
    this.setState({
      myleaderboard: false,
    });
  };
}

export const LEADERBOARD_QUERY = gql`
  query LeaderboardDetail {
    leaderboard {
      offer {
        hashid
        name
        thumbnail_url
      }
      user {
        hashid
        handle
      }
      ctr
      impressions
      clicks
      conversions
      comments
      votes
      shares
      video_starts
      ad_shows
      rank
      sparkit_index
      comments_rank
      votes_rank
      shares_rank
      clicks_rank
      ctr_rank
      conversions_rank
      payout
    }
  }
`;

export default compose(
  connect(mapStateToProps),
  graphql(LEADERBOARD_QUERY, {})
)(Leaderboard);
