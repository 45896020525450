import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import s from "./style.scss";

const PlayButton = ({
  isLoading,
  url,
  isLoadingChange,
  handleShowIframeTag,
  setError,
  isTrackFetched,
  hidePlayBtn,
}) => {
  const handleFetchIframe = () => {
    isLoadingChange(true);
    return new Promise((resolve, reject) => {
      fetch(`https://soundcloud.com/oembed?format=json&url=${url}`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Network response was not ok.");
        })
        .then((data) => {
          const modifiedHtml = data.html.replace(
            'height="400"',
            'height="200"'
          );
          handleShowIframeTag(modifiedHtml);
          isLoadingChange(false);
          resolve("Iframe successfully fetched.");
        })
        .catch((error) => {
          setError(error);
          isLoadingChange(false);
          reject(error);
        });
    });
  };

  return (
    <React.Fragment>
      {isTrackFetched ? (
        ""
      ) : !hidePlayBtn ? (
        // <div className={cx(s.stickWrap, s.noneSelect)}>
        //   <div className={s.stick}></div>
        //   <div className={s.stick}></div>
        // </div>
        <div className={s.optionalImageContainer}>
          {isLoading ? (
            <div className={s.loader} />
          ) : (
            <div
              className={cx(s.playStickWrap, s.noneSelect)}
              onClick={handleFetchIframe}
            >
              <div className={cx(s.playStick1, s.playStick)}></div>
              <div className={cx(s.playStick2, s.playStick)}></div>
              <div className={cx(s.playStick3, s.playStick)}></div>
              <div className={cx(s.playStick4, s.playStick)}></div>
              <div className={cx(s.playStick5, s.playStick)}></div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

PlayButton.propTypes = {
  url: PropTypes.string,
  isLoadingChange: PropTypes.func,
  handleShowIframeTag: PropTypes.func,
  setError: PropTypes.func,
  isTrackFetched: PropTypes.bool,
  isLoading: PropTypes.bool,
  hidePlayBtn: PropTypes.bool,
};

PlayButton.defaultProps = {
  isTrackFetched: false,
  hidePlayBtn: false,
};

export default PlayButton;
