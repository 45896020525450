import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { GraphqlBackedMedia } from "components/Media";
import { GraphqlBackedVideoFrameChooser } from "components/VideoFrameChooser";
import { TagListFromArray } from "components/TagList";
import Icon from "components/Icon";
import Button from "components/Button";
import Avatar from "components/Avatar";
import CommentCardWithData from "components/CommentCard";

import styles from "./style.scss";

export default class ViewShare extends React.Component {
  static propTypes = {
    chosenPosterUrl: PropTypes.string,
    className: PropTypes.string,
    closesAt: PropTypes.number,
    locationName: PropTypes.string,
    locationPlaceId: PropTypes.string,
    mediaHashid: PropTypes.string.isRequired,
    mediaType: PropTypes.string,
    message: PropTypes.string,
    onNavigate: PropTypes.func,
    onSubmit: PropTypes.func,
    onThumbnailIndexChange: PropTypes.func.isRequired,
    previousSteps: PropTypes.number.isRequired,
    style: PropTypes.object,
    tags: PropTypes.array,
    thumbnailIndex: PropTypes.number,

    handleChangePlaying: PropTypes.func,
    handlePlayTrack: PropTypes.func,
    isTrackPlaying: PropTypes.bool,
    trackValues: PropTypes.object,
    handleTrackPause: PropTypes.func,
  };

  static defaultProps = {
    chosenPosterUrl: null,
    className: "",
    closesAt: 0,
    locationName: "",
    locationPlaceId: "",
    mediaType: "",
    message: "",
    onNavigate: (f) => f,
    onSubmit: (f) => f,
    previousSteps: 1,
    style: {},
    tags: [],
  };

  constructor() {
    super();

    this.$form = null;

    this.state = {
      mediaProcessed: null,
    };
  }

  handleFormSubmit = (event) => {
    event.preventDefault();

    const form = this.$form.elements;

    const shareOn = [];
    if (form.facebook && form.facebook.checked) {
      shareOn.push("facebook");
    }
    if (form.twitter && form.twitter.checked) {
      shareOn.push("twitter");
    }

    this.props.onSubmit(shareOn);
  };
  onProcessedStatusChange = (processed) => {
    this.setState({
      mediaProcessed: processed,
    });
  };

  handleNavigate = () => {
    this.props.onNavigate(1);
  };

  preparedMessage = (message) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const allowedUrls = ["soundcloud", "unitedmasters"];

    const parts = message.split(urlRegex);

    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        const url = part;
        const urlMatch = url.match(/https?:\/\/([^/]+)/);
        if (urlMatch) {
          const hostname = urlMatch[1];
          if (allowedUrls.some((allowedUrl) => hostname.includes(allowedUrl))) {
            return null;
          } else if (
            !allowedUrls.some((allowedUrl) => hostname.includes(allowedUrl))
          ) {
            return (
              <span key={index} style={{ fontStyle: "italic" }}>
                link removed
              </span>
            );
          }
        }
      }
      return part;
    });
  };

  findUrlInMessage = (message) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = message.match(urlRegex);
    if (!matches) {
      return <React.Fragment></React.Fragment>;
    }
    return matches.map((url, index) => (
      <CommentCardWithData
        key={index}
        url={url}
        className={styles.commentCard}
        handleChangePlaying={this.props.handleChangePlaying}
        handlePlayTrack={this.props.handlePlayTrack}
        isTrackPlaying={this.props.isTrackPlaying}
        trackValues={this.props.trackValues}
      />
    ));
  };

  render() {
    const {
      chosenPosterUrl,
      className,
      closesAt,
      locationName,
      mediaHashid,
      mediaType,
      message,
      onThumbnailIndexChange,
      style,
      tags,
      thumbnailIndex,
    } = this.props;

    let poster = null;
    if (mediaType === "video" && this.state.mediaProcessed && chosenPosterUrl) {
      poster = chosenPosterUrl;
    }

    return (
      <div className={classNames(styles.root, className)} style={style}>
        {mediaHashid && closesAt && (
          <div>
            <GraphqlBackedMedia
              size="lg"
              hashid={mediaHashid}
              onProcessedStatusChange={this.onProcessedStatusChange}
              poster={poster}
              className={styles.media}
              onPlay={() => this.props.handleTrackPause()}
            />
            {mediaType === "video" &&
              this.state.mediaProcessed &&
              mediaHashid && (
                <div>
                  <h3>Select your video thumbnail</h3>
                  <GraphqlBackedVideoFrameChooser
                    hashid={mediaHashid}
                    index={thumbnailIndex}
                    onIndexChange={onThumbnailIndexChange}
                  />
                </div>
              )}
            {tags && tags.length > 0 && (
              <TagListFromArray
                className={classNames("bg-white", styles.tagList)}
                tags={tags.map((tag) => tag.value)}
                linked={true}
              />
            )}
            {locationName && (
              <div className={styles.locationWrap}>
                <Icon icon="marker" color="primary" />
                <span>{locationName}</span>
              </div>
            )}
            {/* <Timer expiresAt={closesAt} className={classNames('m-t-20', 'm-b-20')} /> */}
            {message && (
              <div className={styles.avatarAndMessageBlock}>
                <Avatar size="md" />
                <p className={styles.message}>
                  {this.preparedMessage(message)}
                </p>
                <div className={styles.commentCardsWrap}>
                  {this.findUrlInMessage(message)}
                </div>
              </div>
            )}
          </div>
        )}
        {/* <hr className={styles.separator} />
        <form ref={ref => this.$form = ref} onSubmit={this.handleFormSubmit}>
          <div>
            <h1>Share This Post</h1>
            <ShareOptions />
          </div>
          <Navigator
            label="Post"
            onNavigate={this.handleNavigate}
            previousSteps={previousSteps}
          />
        </form> */}
        <form
          ref={(ref) => (this.$form = ref)}
          onSubmit={this.handleFormSubmit}
        >
          <Button
            type="submit"
            onClick={this.handleNavigate}
            className={styles.nextBtn}
          >
            Next
          </Button>
        </form>
      </div>
    );
  }
}
