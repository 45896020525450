// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-VotesRemainingCounter-style__root__mH4EQ{width:6rem;height:6rem;border-radius:50%;background-color:#f75a00;color:#fff;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;text-align:center;-webkit-box-shadow:0 1px 3px rgba(0,0,0,.5);box-shadow:0 1px 3px rgba(0,0,0,.5)}.components-VotesRemainingCounter-style__root__mH4EQ.components-VotesRemainingCounter-style__empty__Z53PH{background-color:#626261}.components-VotesRemainingCounter-style__countBox__K2Fbc{margin-top:.5rem;font-size:1.1rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-VotesRemainingCounter-style__root__mH4EQ`,
	"empty": `components-VotesRemainingCounter-style__empty__Z53PH`,
	"countBox": `components-VotesRemainingCounter-style__countBox__K2Fbc`
};
export default ___CSS_LOADER_EXPORT___;
