import {useEffect} from "react";

const useClickOutside = (
  dismiss,
  isOpen,
  ref,
  deps = []
) => {
  useEffect(() => {
    const node = ref.current || null;

    if (!node || !isOpen) {
      return;
    }

    const handleClick = (e) => {
      const {target} = e;
      if (!node.contains(target)) {
        dismiss(e);
      }
    };

    document.addEventListener("mousedown", handleClick);
    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref, isOpen, ...deps]);
};

export default useClickOutside;
