// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sections-home__wrap__gutter__OHnmY{padding:0 20px}.sections-home__fanHomeSearch__z0AHV{position:absolute;right:20px}.sections-home__infHomeSearch__cptke{position:absolute;right:135px}@media screen and (min-width: 10px)and (max-width: 340px){.sections-home__wrap__gutter__OHnmY{padding:0px !important}}@media screen and (max-width: 481px){.sections-home__fanHomeSearch__z0AHV{width:50%;position:absolute;right:20px}.sections-home__infHomeSearch__cptke{width:40%;position:absolute;right:40%}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap__gutter": `sections-home__wrap__gutter__OHnmY`,
	"fanHomeSearch": `sections-home__fanHomeSearch__z0AHV`,
	"infHomeSearch": `sections-home__infHomeSearch__cptke`
};
export default ___CSS_LOADER_EXPORT___;
