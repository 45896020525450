import PropTypes from "prop-types";
import React from "react";

import { debounce } from "lodash";

// eslint-disable-next-line import/no-nodejs-modules
import { html5String as html5HandleRegex } from "constants/handleRegex";

import { api } from "~/auth";
import classNames from "classnames";
import styles from "./styles.scss";

export default class HandleInput extends React.Component {
  static propTypes = {
    onInvalid: PropTypes.func.isRequired,
    onValid: PropTypes.func.isRequired,
    whitelistedHandle: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    onInvalid: function () {},
    onValid: function () {},
  };

  constructor(props) {
    super(props);

    this.debouncedCheckHandle = debounce(this.checkHandle, 500);
  }

  handleHandleChange = () => {
    // Clear an unavailable handle error immediately
    this.input.setCustomValidity("");

    // Do nothing and cancel any pending check if the value is invalid
    if (!this.input.validity.valid && !this.input.validity.customError) {
      this.debouncedCheckHandle.cancel();
      this.props.onValid();
      return;
    }

    // If value is whitelisted, do nothing more
    if (
      this.props.whitelistedHandle &&
      this.input.value === this.props.whitelistedHandle
    ) {
      this.props.onValid();
      return;
    }

    this.debouncedCheckHandle();
  };

  checkHandle = () => {
    const handle = this.input.value;

    // Do nothing if this handle is whitelisted
    if (handle === this.props.whitelistedHandle) {
      return;
    }

    // Query the API
    api(`/v1/auth/register/check-handle?handle=${handle}`).then((data) => {
      // Ignore if the handle queried is not still in the input field
      if (handle !== this.input.value) {
        return;
      }

      // Update the validity status
      if (data.available) {
        this.input.setCustomValidity("");
        this.props.onValid();
      } else {
        this.input.setCustomValidity(
          "This handle isn't available; try another."
        );
        this.props.onInvalid();
      }
    });
  };

  render() {
    const { onInvalid, onValid, whitelistedHandle, className, ...otherProps } =
      this.props;

    return (
      <div className={classNames(styles.inputContainer)}>
        <input
          type="text"
          required
          minLength="1"
          maxLength="15"
          pattern={html5HandleRegex}
          onChange={this.handleHandleChange}
          onBlur={this.debouncedCheckHandle.flush}
          ref={(ref) => (this.input = ref)}
          {...otherProps}
          className={classNames(styles.input, className)}
        />
      </div>
    );
  }
}
