import classNames from "classnames";
import { compose, graphql } from "react-apollo";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";

import handleGqlError from "data/graphqlErrors";

import Main from "components/Main";

import ViewShare from "./elements/ViewShare";
import ViewUpload from "./elements/ViewUpload";
import EditingComplete from "./elements/EditingComplete";

import styles from "./style.scss";
import HeaderForAuthorizedUsers from "../../components/HeaderForAuthorizedUsers";
import { connect } from "react-redux";
import { resetState } from "actions/collabEditor";

const mapStateToProps = function (state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
    collabComments: state.collabEditor.selectedComments,
  };
};

const mapDispatchToProps = {
  onResetState: resetState,
};

class SparkDecision extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    history: PropTypes.object.isRequired, // From router
    match: PropTypes.object.isRequired, // From router
    mutate: PropTypes.func.isRequired,
    collabComments: PropTypes.array,
  };

  constructor() {
    super();

    this.state = {
      chosenPosterUrl: null,
      thumbnailIndex: 0,

      errors: null,
      mediaHashid: "",
      mediaType: "",
      message: "",
      viewIndex: 0,
      data: null,
      posters: [],
    };
  }

  //* *** */
  componentDidMount() {
    this.nullTrackValues();
  }
  componentWillUnmount() {
    this.StopPlaying();
    this.props.onResetState();
  }

  nullTrackValues = () => {
    const nullTrackValues = {
      id: "",
      trackName: "",
      groupName: "",
      trackImg: "",
      track: "",
    };
    this.setState({
      trackValues: nullTrackValues,
      isTrackPlaying: false,
    });
  };

  setPosters = (posters) => {
    this.setState({
      posters: posters,
    });
  };

  handleAddPoster = (poster) => {
    this.setState({
      posters: [...this.state.posters, ...poster],
    });
  };

  handlePlayTrack = (getedValues) => {
    if (this.state.isTrackPlaying) {
      this.nullTrackValues();
    }
    const newTrackValues = getedValues;
    if (this.state.trackValues.id === newTrackValues.id) {
      this.setState({
        isTrackPlaying: true,
        isPlayerOpen: true,
      });
    } else {
      this.setState({
        trackValues: newTrackValues,
        isTrackPlaying: true,
        isPlayerOpen: true,
      });
    }
  };

  handleChangePlaying = (isCommentCard = false) => {
    if (isCommentCard) {
      if (this.state.isTrackPlaying) {
        this.setState({
          isPlayerOpen: false,
        });
      } else {
        this.setState({
          isPlayerOpen: true,
        });
      }
      this.setState({
        isTrackPlaying: !this.state.isTrackPlaying,
      });
    } else {
      this.setState({
        isTrackPlaying: !this.state.isTrackPlaying,
      });
    }
  };

  StopPlaying = () => {
    this.nullTrackValues();
  };

  handleChangePlayerVisibility = () => {
    this.setState({
      isPlayerOpen: !this.state.isPlayerOpen,
      isTrackPlaying: false,
    });
  };

  handleTrackPause = () => {
    this.setState({
      isPlayerOpen: false,
      isTrackPlaying: false,
    });
  };

  //* *** */

  handleNavigate = (delta) => {
    this.setState((prevState) => {
      return {
        viewIndex: prevState.viewIndex + delta,
      };
    });
  };

  handleSubmitVideoPost = (data) => {
    this.setState(
      {
        mediaHashid: data.mediaHashid,
        mediaType: data.mediaType,
        message: data.message,
      },
      () => {
        this.handleNavigate(1);
      }
    );
  };

  handleThumbnailIndexChange = (index, url) => {
    this.setState({
      chosenPosterUrl: url,
      thumbnailIndex: index,
    });
  };
  handleSetData = (data) => {
    this.setState({
      data,
    });
  };

  handleSubmitPost = (shareOn) => {
    this.setState(
      {
        share_on: shareOn,
      },
      () => {
        const mutationVars = {
          mediaHashid: this.state.mediaHashid,
          message: this.state.message,
          parentHashid: this.props.match.params.commentHashid,
          shareOn: this.state.share_on,
          sparkHashid: this.props.match.params.hashid,
        };
        if (this.state.mediaType === "video") {
          mutationVars.video_thumbnail_index = this.state.thumbnailIndex;
        }
        this.props
          .mutate({
            variables: mutationVars,
          })
          .then(() => {
            this.props.history.push(`/spark/${this.props.match.params.hashid}`);
          })
          .catch((error) => {
            const e = handleGqlError(error, ["message"]);
            this.setState({
              errors: e,
            });
          })
          .finally(() => {
            window.location.reload();
          });
      }
    );
  };

  render() {
    const { className, match, collabComments } = this.props;

    const CloseSparkHeaderProps = {
      heading: "Fan Vote Winners",
      leftContent: {
        content: [{ type: "link", icon: "homeFilled", link: "/" }],
      },
    };

    return (
      <div>
        {/* <Head minimal back={`/spark/${match.params.hashid}`} /> */}
        <HeaderForAuthorizedUsers {...CloseSparkHeaderProps} />
        <Main>
          <div className={classNames(styles.root, className)}>
            {this.state.viewIndex === 0 ? (
              <ViewUpload
                onNavigate={this.handleNavigate}
                onSubmit={this.handleSubmitVideoPost}
                parentHashid={match.params.commentHashid}
                handleSetData={this.handleSetData}
                collabComments={collabComments}
              />
            ) : (
              <></>
            )}
            {this.state.viewIndex === 1 ? (
              <ViewShare
                chosenPosterUrl={this.state.chosenPosterUrl}
                mediaHashid={this.state.mediaHashid}
                mediaType={this.state.mediaType}
                message={this.state.message}
                onNavigate={this.handleNavigate}
                onSubmit={() => this.handleNavigate(1)}
                data={this.state.data}
                onThumbnailIndexChange={this.handleThumbnailIndexChange}
                thumbnailIndex={this.state.thumbnailIndex}
                handleChangePlaying={this.handleChangePlaying}
                handlePlayTrack={this.handlePlayTrack}
                isTrackPlaying={this.state.isTrackPlaying}
                trackValues={this.state.trackValues}
                handleTrackPause={this.handleTrackPause}
                setPosters={this.setPosters}
                posters={this.state.posters}
                handleAddPoster={this.handleAddPoster}
              />
            ) : (
              <></>
            )}
            {this.state.viewIndex === 2 ? (
              <EditingComplete
                onNavigate={this.handleNavigate}
                chosenPosterUrl={this.state.chosenPosterUrl}
                mediaHashid={this.state.mediaHashid}
                mediaType={this.state.mediaType}
                message={this.state.message}
                thumbnailIndex={this.state.thumbnailIndex}
                data={this.state.data}
                onSubmit={this.handleSubmitPost}
                collabComments={collabComments}
              />
            ) : (
              <></>
            )}
            {/* <StackViewNavigator indexSelected={this.state.viewIndex} >
              <ViewUpload
                onNavigate={this.handleNavigate}
                onSubmit={this.handleSubmitVideoPost}
                parentHashid={match.params.commentHashid}
                handleSetData={this.handleSetData}
              />
              <ViewShare
                chosenPosterUrl={this.state.chosenPosterUrl}
                mediaHashid={this.state.mediaHashid}
                mediaType={this.state.mediaType}
                message={this.state.message}
                onNavigate={this.handleNavigate}
                onSubmit={this.handleSubmitPost}
                data={this.state.data}
              />
              <ViewVideoFrame
                mediaHashid={this.state.mediaHashid}
                onNavigate={this.handleNavigate}
                onThumbnailIndexChange={this.handleThumbnailIndexChange}
                thumbnailIndex={this.state.thumbnailIndex}
              />
            </StackViewNavigator> */}
          </div>
        </Main>
      </div>
    );
  }
}

const DECISION_MUTATION = gql`
  mutation PostDecision(
    $sparkHashid: ID!
    $mediaHashid: ID!
    $message: String!
    $parentHashid: ID!
    $shareOn: [SocialNetworkEnum!]
    $videoThumbnailIndex: Int
  ) {
    postDecision(
      spark_hashid: $sparkHashid
      media_hashid: $mediaHashid
      message: $message
      parent_hashid: $parentHashid
      share_on: $shareOn
      video_thumbnail_index: $videoThumbnailIndex
    ) {
      created_at
      hashid
      is_voted_on_by_me
      media {
        hashid
        is_processed
        square_image_sources {
          url
          width
        }
        type
        video_sources {
          type
          url
        }
      }
      message
      permalink
      spark {
        hashid
        closing_comment {
          hashid
          parent {
            hashid
            children {
              hashid
            }
          }
        }
      }
      user {
        hashid
      }
      votes_count
    }
  }
`;

const SparkDecisionWithData = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(DECISION_MUTATION)
)(SparkDecision);

export default SparkDecisionWithData;
