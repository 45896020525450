// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-Location-style__name__RLEJR{margin-left:7px;font-size:90%;font-family:"Roboto",sans-serif;color:#939393;font-weight:500}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": `components-Location-style__name__RLEJR`
};
export default ___CSS_LOADER_EXPORT___;
