import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Ad from "components/Ad";
import TextArea from "components/TextArea";
import Avatar from "components/Avatar";
import Icon from "components/Icon";
import Button from "components/Button";
import { TagListFromArray } from "components/TagList";

import styles from "./style.scss";
import HelpIcon from "../../../../components/HelpIcon";

export default class ViewAd extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    offer: PropTypes.object,
    onNavigate: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    style: PropTypes.object,
    tags: PropTypes.array,
    locationName: PropTypes.string,
    selectAddName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    className: "",
    offer: {},
    onNavigate: (f) => f,
    onSubmit: (f) => f,
    style: {},
    tags: [],
    selectAddName: "",
    locationName: "",
  };

  render() {
    const { className, style, tags, selectAddName, locationName } = this.props;

    if (!this.props.offer) {
      return null;
    }

    const offer = {
      ad_url: this.props.offer.ad_preview_url,
    };

    return (
      <div className={classNames(styles.root, className)} style={style}>
        <form
          ref={(ref) => (this.$form = ref)}
          onSubmit={this.handleFormSubmit}
          className={styles.form}
        >
          <div className={styles.avatarBlock}>
            <Avatar size="md" />
          </div>
          <div className={styles.headerAddText}>
            I am working with{selectAddName === "" ? ":" : ` ${selectAddName}`}
          </div>
          <Ad offer={offer} isThereBackground={false} />
          {/* <div>
            <p>The above ad will be included at the end of your video.</p>
            <p>Include your own custom message that appears above the brand advertising message:</p>
          </div> */}
          <div className={styles.textAreaWrap}>
            <div className={classNames(styles.textAreaHeader)}>
              <span>Promote Sponsor Here</span>
              <HelpIcon helperText="Create customized text to promote your sponsor, announce exclusives, awards, or special discounts." />
            </div>
            <TextArea
              name="message"
              maxLength={140}
              required
              placeholder="I am working with sponsors"
            />
          </div>
          <div className={styles.locationWrap}>
            <Icon icon="marker" color="primary" />
            <span>{locationName}</span>
          </div>

          {tags && tags.length > 0 && (
            <TagListFromArray
              className={classNames("bg-white", styles.tagList)}
              tags={tags.map((tag) => tag.value)}
              linked={true}
            />
          )}
          <Button type="submit" className={styles.postSparkBtn}>
            Post Spark
          </Button>
          <div>
            {/* <textarea
              name="message"
              maxLength="200"
              required
              placeholder="Your custom message.."
            /> */}
          </div>
          {/* <div>
            <Navigator
              hasPrevious={true}
              onNavigate={this.handleNavigate}
            />
          </div> */}
        </form>
      </div>
    );
  }

  handleFormSubmit = (event) => {
    event.preventDefault();

    const form = this.$form.elements;

    const data = {
      adMessage: form.message.value,
    };

    this.props.onSubmit(data);
  };

  handleNavigate = (index) => {
    this.props.onNavigate(index);
  };
}
