import React from "react";
import styles from "./style.scss";

const Checkbox = (props) => {
  const { defaultChecked, name } = props;

  return (
    <div className={styles.checkboxWrapper}>
      <input
        type="checkbox"
        defaultChecked={defaultChecked}
        className={styles.switchCheckbox}
        name={name}
      />
      <label className={styles.switchLabel} htmlFor="switchNew">
        <span className={styles.switchButton} />
      </label>
    </div>
  );
};

export default Checkbox;
