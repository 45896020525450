// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-Media-style__root__nymcR{overflow:hidden;background-color:#9b9b9b;position:relative;width:100%}.components-Media-style__root__nymcR img,.components-Media-style__root__nymcR video{width:100%}.components-Media-style__container__dG32s{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.components-Media-style__thumbnail__bcH3y{height:30px;width:30px}.components-Media-style__rounded__HELz2.components-Media-style__sm__l81fI{border-radius:15px}.components-Media-style__rounded__HELz2.components-Media-style__md__ZuZC6{border-radius:20px}.components-Media-style__rounded__HELz2.components-Media-style__lg__Op06F{border-radius:20px}.components-Media-style__hasPlayButton__Js4EZ::before,.components-Media-style__hasPlayButton__Js4EZ::after{content:"";display:block;position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%)}.components-Media-style__hasPlayButton__Js4EZ::before{width:48px;height:48px;line-height:90px;padding-left:8px;border-radius:50%;background-color:#fff}.components-Media-style__hasPlayButton__Js4EZ::after{width:0;height:0;margin-left:3px;border-top:12px solid rgba(0,0,0,0);border-bottom:12px solid rgba(0,0,0,0);border-left:18px solid #172331}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-Media-style__root__nymcR`,
	"container": `components-Media-style__container__dG32s`,
	"thumbnail": `components-Media-style__thumbnail__bcH3y`,
	"rounded": `components-Media-style__rounded__HELz2`,
	"sm": `components-Media-style__sm__l81fI`,
	"md": `components-Media-style__md__ZuZC6`,
	"lg": `components-Media-style__lg__Op06F`,
	"hasPlayButton": `components-Media-style__hasPlayButton__Js4EZ`
};
export default ___CSS_LOADER_EXPORT___;
