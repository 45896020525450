import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Button from "components/Button";

import styles from "./style.scss";

export default class RadioButton extends React.Component {
  static propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string.isRequired,
  };

  static defaultProps = {
    checked: false,
    className: "",
    onChange: (f) => f,
  };

  constructor() {
    super();

    this.state = {
      color: "white",
    };

    this.$input = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.checked !== this.props.checked) {
      this.setState({
        color: nextProps.checked ? "primary" : "white",
      });
    }
  }

  /**
   * Event onChange handler
   * @param {object} event event
   * @returns {void}
   */
  handleChange = (event) => {
    this.props.onChange(event);
  };

  render() {
    const { checked, className, label, name, value } = this.props;

    return (
      <label className={classNames(styles.root, className)}>
        <input
          ref={(ref) => (this.$input = ref)}
          type="radio"
          checked={checked}
          name={name}
          value={value}
          onChange={this.handleChange}
          style={{ height: "100%", width: "100%" }}
        />
        <span className={classNames(styles.container)}>
          <Button
            size="xs"
            className={classNames(styles.btn, {
              [styles.activeBtn]: this.props.checked,
            })}
          >
            {label}
          </Button>
        </span>
      </label>
    );
  }
}
