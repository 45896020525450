import classNames from "classnames";
import { Link } from "react-router-dom";
import momentPropTypes from "react-moment-proptypes";
import React from "react";
import PropTypes from "prop-types";

import Avatar from "components/Avatar";
import Location from "components/Location";
import Handle from "components/Handle";
import Media from "components/Media";
import { TagListFromArray } from "components/TagList";
import IconButton from "components/IconButton";
import Timer from "components/Timer";
import ShareMenu from "components/ShareMenu";
import { sessionUrl, shareUrl } from "~/shareUrl";
import clipboardCopy from "clipboard-copy";

import Icon from "components/Icon";

import styles from "./style.scss";
import CommentCardWithData from "../CommentCard";

import gql from "graphql-tag";
import { compose, graphql } from "react-apollo";

class SparkCard extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isProfile: PropTypes.bool,
    currentDate: PropTypes.number,
    size: PropTypes.oneOf(["lg", "sm"]).isRequired,
    shareMutation: PropTypes.func.isRequired,
    spark: PropTypes.shape({
      closes_at: PropTypes.oneOfType([
        momentPropTypes.momentObj,
        PropTypes.number,
      ]).isRequired,
      has_closing_comment: PropTypes.bool.isRequired,
      hashid: PropTypes.string.isRequired,
      opening_comment: PropTypes.shape({
        hashid: PropTypes.string.isRequired,
        media: PropTypes.object.isRequired,
        message: PropTypes.string,
      }).isRequired,
      tags: PropTypes.arrayOf(PropTypes.string).isRequired,
      user: PropTypes.shape({
        avatar: PropTypes.object,
        handle: PropTypes.string.isRequired,
        hashid: PropTypes.string,
      }).isRequired,
    }).isRequired,
    targetWidth: PropTypes.number,
  };

  static defaultProps = {
    size: "lg",
    targetWidth: null,
    isProfile: false,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      shareOpeningCommentOptionsOpen: false,
      isShareSparkOpen: false,
    };
  }

  handleShareMutation = (hashid) => {
    return this.props.shareMutation(hashid);
  };

  handleShareOpeningCommentClick = () => {
    if (navigator.share) {
      window.dataLayer.push({
        event: "share",
        gaEventAction: "navigator.share",
        gaEventCategory: "Spark Share",
        gaEventLabel: this.props.spark.hashid,
      });
      navigator.share({
        url: sessionUrl(this.props.spark.permalink),
      });
    } else {
      this.setState({
        shareOpeningCommentOptionsOpen:
          !this.state.shareOpeningCommentOptionsOpen,
      });
    }
  };

  handleShareOpeningCommentMenuClick = (provider) => {
    window.dataLayer.push({
      event: "share",
      gaEventAction: provider,
      gaEventCategory: "Spark Share",
      gaEventLabel: this.props.spark.hashid,
    });
    if (provider === "_copy") {
      clipboardCopy(sessionUrl(this.props.spark.permalink));
    } else {
      const url = shareUrl(
        provider,
        {
          url: sessionUrl(this.props.spark.permalink),
        },
        this.state.config.services
      );
      const newWin = window.open(url);
      newWin.focus();
    }
    this.setState({
      shareOpeningCommentOptionsOpen: false,
    });
  };

  findUrlInMessage = (message) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = message.match(urlRegex);
    if (!matches) {
      return <React.Fragment>{message}</React.Fragment>;
    }
    return matches.map((url, index) => (
      <CommentCardWithData
        key={index}
        url={url}
        className={styles.commentCard}
        handleChangePlaying={this.props.handleChangePlaying}
        handlePlayTrack={this.props.handlePlayTrack}
        isTrackPlaying={this.props.isTrackPlaying}
        trackValues={this.props.trackValues}
      />
    ));
  };

  render() {
    let sizeClass = null;
    const { spark } = this.props;
    switch (this.props.size) {
      case "sm":
        sizeClass = styles.sm;
        break;
      case "lg":
        sizeClass = styles.lg;
        break;
    }

    function calculateTimeDifference(timestamp1 = 1, timestamp2 = 1) {
      const differenceSeconds = Math.abs(timestamp2 - timestamp1);

      if (differenceSeconds < 3600) {
        const differenceMinutes = Math.round(differenceSeconds / 60);
        return `${differenceMinutes} Minutes`;
      } else if (differenceSeconds < 86400) {
        const differenceHours = Math.round(differenceSeconds / 3600);
        return `${differenceHours} Hours`;
      }
      const differenceDays = Math.round(differenceSeconds / (24 * 3600));
      return `${differenceDays} Days`;
    }

    let sizes = null;
    if (this.props.targetWidth) {
      sizes = `${this.props.targetWidth}px`;
    }

    return (
      <div
        className={classNames(
          styles.root,
          {
            [styles.fullWidth]: this.props.isProfile,
          },
          this.props.className,
          sizeClass
        )}
        data-tut="influencerHome__step1"
      >
        <div className={classNames(styles.content)}>
          {this.props.isProfile && spark.has_closing_comment && (
            <div className={styles.winningCommentWrap}>
              <div className={styles.winningCommentAndHandleWrap}>
                <div className={styles.handleAndVotesWrap}>
                  <div className={styles.avatarAndHandle}>
                    <Avatar
                      size="md"
                      className={styles.avatar}
                      // user={spark.closing_comment.parent.user}
                    />
                    <Handle
                      className={styles.authorName}
                      handle={spark.closing_comment.parent.user.handle}
                    />
                  </div>
                  <div className={styles.votesWrap}>
                    <div className={styles.votesCount}>
                      {spark.closing_comment.parent.votes_count}
                      <Icon
                        color="active"
                        disabled={true}
                        filled={true}
                        icon="heart"
                        className={styles.heartIcon}
                      />
                    </div>
                    <div className={styles.votesText}>
                      Votes made this happen
                    </div>
                  </div>
                </div>
                <div className={styles.winningComment}>
                  {this.findUrlInMessage(spark.closing_comment.parent.message)}
                </div>
              </div>
              <div className={styles.commentAndShareHeadWrap}>
                <div
                  className={styles.commentAndShareItem}
                  style={{
                    opacity:
                      spark.has_closing_comment ||
                      spark.closes_at < this.props.currentDate
                        ? "0"
                        : "1",
                  }}
                >
                  <IconButton icon="comment" color="primary" />
                  Comment
                </div>
              </div>
            </div>
          )}
          <div className={classNames(styles.authorLockup)}>
            <div>
              <Avatar
                size="md"
                className={styles.avatar}
                user={this.props.spark.user}
              />
            </div>
            <div className={classNames(styles.nicknameAndLocation)}>
              <Handle
                className={styles.authorName}
                handle={this.props.spark.user.handle}
              />
              {/* {this.props.isProfile && (
                <div className={styles.handleText}>Here you go it was fun.</div>
              )} */}
              {spark && spark.location && (
                <Location name={spark.location.name} />
              )}
            </div>
            <div className={styles.sparkShare}>
              {/* <Icon className={styles.icon} icon="sparkShare" /> */}
              <ShareMenu
                listClassName={styles.shareOpeningCommentOptionsMenu}
                onButtonClick={this.handleShareOpeningCommentClick}
                onShareMenuItemClick={this.handleShareOpeningCommentMenuClick}
                open={this.state.shareOpeningCommentOptionsOpen}
                isShareIconWithText
              />
            </div>
          </div>
          <Link to={`/spark/${this.props.spark.hashid}`}>
            <Media
              imageOnly={true}
              className={classNames(styles.media)}
              size={this.props.size}
              media={this.props.spark.opening_comment.media}
              sizes={sizes}
            />
          </Link>
          {/* {this.props.isProfile && (
            <div className={styles.commentAndShareWrap}>
              <div
                className={styles.commentAndShareItem}
                style={{
                  opacity:
                    spark.has_closing_comment ||
                    spark.closes_at < this.props.currentDate
                      ? "0"
                      : "1",
                }}
              >
                <IconButton icon="comment" color="primary" />
                Comment
              </div>
              <div
                className={classNames(
                  styles.commentAndShareItem,
                  styles.shareSparkWrap
                )}
              >
                <ShareMenu
                  listClassName={styles.shareOpeningCommentOptionsMenu}
                  onButtonClick={() =>
                    this.handleShareOpeningCommentClick(true)
                  }
                  onShareMenuItemClick={this.handleShareOpeningCommentMenuClick}
                  open={this.state.isShareSparkOpen}
                  // isShareIconWithText
                />
              </div>
            </div>
          )} */}
          {this.props.spark.opening_comment.message && (
            <p
              className={classNames(styles.text, sizeClass, {
                [styles.fullWidth]: this.props.isProfile,
              })}
            >
              {this.props.spark.opening_comment.message}
            </p>
          )}
          <TagListFromArray
            tags={this.props.spark.tags}
            className={styles.tagList}
          />
          {spark.closing_comment === null ? (
            <div className={classNames(styles.countdown)}>
              <Timer
                plain
                className={styles.timeleft}
                expiresAt={this.props.spark.closes_at}
                isProfileTimer={this.props.isProfile}
              />
            </div>
          ) : (
            <div className={styles.timerWrapper}>
              <div className={styles.createdInTimer}>
                <Icon
                  size={"sm"}
                  className={styles.icon}
                  icon={"inactiveTimer"}
                />
                Spark Created in{" "}
                {calculateTimeDifference(
                  spark.created_at,
                  spark?.closing_comment?.created_at
                )}
              </div>
            </div>
          )}

          {/* <div className={classNames(styles.countdown)}>
            <Timer
              plain
              className={styles.timeleft}
              expiresAt={this.props.spark.closes_at}
              isProfileTimer={this.props.isProfile}
            />
          </div> */}
        </div>
      </div>
    );
  }
}

const SHARE_MUTATION = gql`
  mutation Share($hashid: ID!) {
    share(hashid: $hashid) {
      hashid
      spark {
        hashid
      }
    }
  }
`;

export default compose(
  graphql(SHARE_MUTATION, {
    props: ({ mutate }) => ({
      shareMutation: (hashid) => mutate({ variables: { hashid } }),
    }),
  })
)(SparkCard);
