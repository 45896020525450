import hello from 'hellojs';
import HttpStatus from 'http-status-codes';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import LogRocket from 'logrocket';
import Log from './log';
import {getApiUrl} from './config';

import * as authActions from 'actions/auth';

import AuthError from 'errors/AuthError';
import NotOkError from 'errors/NotOkError';
import ValidationError from 'errors/ValidationError';

import graphqlClient from 'data/graphqlClient';

import getConfig from '~/getConfig';
import store from '~/store';

let initializationPromise = null;

export const refetchHandles = {
  avatarHandle: null,
  sparkCardsHandle: null,
};

/**
 * Do we think this user has probably logged in before?
 *
 * @return {boolean} Whether we think the user has probably logged in before
 */
export function hasProbablyLoggedInBefore() {
  return window.localStorage.getItem('hasLoggedIn') !== null;
}

/**
 * Initialize the Hello library.
 *
 * @return {Promise} A promise for the library to be initialized.
 */
export function initializeHello() {
  if (initializationPromise === null) {
    initializationPromise = new Promise((resolve) => {
      getConfig()
      .then((config) => {
        hello.init({
          facebook: config.services.facebook.client_id,
          google: config.services.google.client_id,
          twitter: config.services.twitter.client_id,
        }, {
          display: 'popup',
          redirect_uri: '/login-response.html',
        });
        resolve();
      });
    });
  }
  return initializationPromise;
}

/**
 * Store an access token in local storage.
 *
 * @param {string} accessToken - Access token to be stored
 *
 * @return {void}
 */
function storeAccessToken(accessToken) {
  window.localStorage.setItem('accessToken', accessToken);
}

/**
 * Clear the access token.
 *
 * The access token is removed from local storage, and no other actions take
 * place.
 *
 * @return {void}
 */
function clearAccessToken() {
  window.localStorage.removeItem('accessToken');
}

/**
 * Get unique key for referral Id
 *
 * @return {string} The key used to store referral Id in local storage
 */
function getReferralIdKey() {
  return 'referralIdKey';
}

/**
 * Store an referral Id  in local storage.
 *
 * @param {string} referralId - Referral Id to be stored
 *
 * @return {void}
 */
export function storeReferralId(referralId) {
  window.localStorage.setItem(getReferralIdKey(), referralId);
}

/**
 * Get the current referral Id or null.
 *
 * @return {(string|null)} Referral Id (or null)
 */
export function getReferralId() {
  return window.localStorage.getItem(getReferralIdKey());
}

/**
 * Get unique key for app token
 *
 * Returns the key that will be used to store the app token in local storage
 *
 * @return {string} The key used to store the app token in local storage
 */
function getAppTokenKey() {
  return 'appTokenKey';
}

/**
 * Store an app token in local storage.
 *
 * @param {string} appToken - App token to be stored
 *
 * @return {void}
 */
export function storeAppToken(appToken) {
  window.localStorage.setItem(getAppTokenKey(), appToken);
}

/**
 * Get the current app token or null.
 *
 * @return {(string|null)} App token (or null)
 */
export function getAppToken() {
  return window.localStorage.getItem(getAppTokenKey());
}

/**
 * Actions to take after the user has logged in.
 *
 * @param {object} user - user object
 *
 * @return {void}
 */
function loggedIn(user) {
  window.localStorage.setItem('hasLoggedIn', 'yes');
  if (refetchHandles.avatarHandle) {
    refetchHandles.avatarHandle.refetch();
  }

  if (user) {
    LogRocket.identify(user.hashid, {
      email: user.email,
      name: user.handle,
      userType: (user.is_influencer ? 'influencer' : 'consumer'),
    });

    updateSession();
  }
}

/**
 * Actions to take after the user has definitively logged out.
 *
 * Data is cleared for social network auth, the access token in cleared and a
 * signal is sent to the dispatcher to deal with the user's logged-out status.
 *
 * @return {void}
 */
function loggedOut() {
  hello.logout('facebook');
  hello.logout('google');
  hello.logout('twitter');
  clearAccessToken();

  store.dispatch(authActions.logout());

  graphqlClient.resetStore();
}

/**
 * Get the current access token or null.
 *
 * The access token is parsed only to check whether it looks valid. It is not
 * tested for expiry. If invalid, it is cleared from local storage.
 *
 * @return {(string|null)} Access token (or null)
 */
export function getAccessToken() {
  let accessToken = window.localStorage.getItem('accessToken');
  if (accessToken === null) {
    return null;
  }
  try {
    jwtDecode(accessToken);
  } catch (error) {
    clearAccessToken();
    return null;
  }
  return accessToken;
}

/**
 * Do we have a valid-looking access token?
 *
 * This uses getAccessToken. The access token is not checked for expiry.
 *
 * @see getAccessToken
 * @return {boolean} True if we have an access token
 */
export function haveAccessToken() {
  return getAccessToken() !== null;
}

/**
 * Get the time at which the access token expires.
 *
 * @return {moment|null} Moment at which the token expires (or null)
 */
export function getAccessTokenExpiryTime() {
  const accessToken = getAccessToken();
  if (accessToken === null) {
    return null;
  }
  const decoded = jwtDecode(accessToken);
  return moment.unix(decoded.exp);
}

/**
 * Is the current access token expired?
 *
 * This is not intended to be used if there is no access token.
 *
 * @return {boolean} True if the access token is expired, false if there is no
 * access token or the access token is not expired
 */
export function accessTokenExpired() {
  if (haveAccessToken()) {
    return getAccessTokenExpiryTime().isBefore();
  }
  return false;
}

/**
 * Get an authorization header featuring the current access token.
 *
 * @return {(string|null)} Header of the form "Bearer abc123" or null if there is
 * no access token
 */
export function getAuthorizationHeader() {
  let accessToken = getAccessToken();

  if (accessToken === null) {
    return null;
  }
  return `Bearer ${getAccessToken()}`;
}

/**
 * Refresh the current access token.
 *
 * If the access token cannot be refreshed the user is logged out and an error
 * is thrown.
 *
 * @return {Promise} A promise for the access token to be updated.
 */
export function refreshAccessToken() {
  return fetch(getApiUrl() + '/v1/auth/refresh', {
    headers: {
      Authorization: getAuthorizationHeader(),
    },
    method: 'POST',
  })
  .then((response) => {
    return response.json()
    .then((data) => {
      // Handle an unrecoverable error response
      if (!response.ok && (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.UNAUTHORIZED)) {
        if (/^auth_(exception|token_(invalid|expired|blacklisted))$/.test(data.error)) {
          throw new AuthError(`${data.error}: ${data.detail}`);
        }
        Log(data.error, 'error');
      }
      return data;
    });
  })
  .then((data) => {
    storeAccessToken(data.accessToken);
  })
  .catch((error) => {
    Log(error, 'error');
    loggedOut();
  });
}

/**
 * Resolve a relative endpoint to a URL.
 *
 * @param {string} endpoint - The endpoint to call (it should start with a
 * slash, and include the version number)
 *
 * @return {string} URL to the endpoint
 */
export function resolveEndpoint(endpoint) {
  return getApiUrl() + endpoint;
}

/**
 * Make a call to the API.
 *
 * If there is an access token it will be used, and if it is expired a refresh
 * will be attempted first.
 *
 * @param {string} endpoint - The endpoint to call (it should start with a
 * slash, and include the version number)
 * @param {Object} options - An object of options to override the defaults
 * @param {boolean} allowRefresh - Pass false to skip any attempt to refresh the
 * token
 *
 * @return {Promise} A promise for a JSON-decoded response
 */
export function api(endpoint, options = {}, allowRefresh = true) {
  // Default request options
  let defaults = {
    headers: {
      Accept: 'application/json',
    },
  };

  // Start with a resolved promise
  let promise = Promise.resolve(true);

  // Include the access token if we have one
  if (haveAccessToken()) {
    defaults.headers.Authorization = getAuthorizationHeader();

    // If it's expired, refresh it first
    if (accessTokenExpired()) {
      if (allowRefresh) {
        promise = refreshAccessToken()
        .then(() => {
          // Update default options with new access token
          defaults.headers.Authorization = getAuthorizationHeader();
        });
      } else {
        throw new AuthError('auth_token_expired');
      }
    }
  }

  // Make the requested API call once ready
  return promise
  .then(() => fetch(resolveEndpoint(endpoint), Object.assign(defaults, options)))
  .then((response) => {
    return response.json()
    .then((data) => {
      if (!response.ok) {
        if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.UNAUTHORIZED) {
          // Check if the error is permanently bad access token
          if (/^auth_(exception|token_(invalid|blacklisted))$/.test(data.error)) {
            // Force logging the user out
            loggedOut();
            throw new AuthError(`${data.error}: ${data.detail}`);
          }

          // Check whether the error is an expired access token (which may be
          // recoverable)
          if (data.error === 'auth_token_expired') {
            if (allowRefresh) {
              // Attempt to refresh the token; if this fails it has its own error
              // handling logic
              return refreshAccessToken()
              .then(() => {
                // Try the API call again (recurse)
                return api(endpoint, options);
              });
            }
            throw new AuthError('auth_token_expired');
          }

          // Otherwise it's some other auth issue; throw it
          throw new AuthError(`${data.error}: ${data.detail}`);
        } else if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
          throw new ValidationError(data);
        } else {
          throw new NotOkError([response, data]);
        }
      }
      return data;
    });
  });
}

/**
 * Relay an OAuth response to the API and attempt to log the user in.
 *
 * @param {string} provider - Provider
 * @param {Object} authResponse - Auth response
 *
 * @return {Promise} A promise for the authentication attempt to be made
 */
function authenticate(provider, authResponse) {
  let formData = new FormData();
  formData.append('provider', provider);
  formData.append('providerAccessToken', authResponse.access_token);
  if (authResponse.expires) {
    formData.append('providerAccessTokenExpires', authResponse.expires);
  }
  return api('/v1/auth/login/social', {
    body: formData._blob ? formData._blob() : formData,
    method: 'POST',
  })
  .then((data) => {
    storeAccessToken(data.accessToken);

    store.dispatch(authActions.login(data.user));

    loggedIn(data.user);
    return data;
  });
}

/**
 * Initiate a password reset.
 *
 * @param {FormData} formData - Form data to send to the server
 *
 * @return {Promise} A promise for the procedure to begin
 */
export function initiatePasswordReset(formData) {
  // Add URL to reset page
  formData.append('url', `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/auth/password/reset`);

  return fetch(getApiUrl()  + '/v1/auth/password/reset/initiate', {
    body: formData._blob ? formData._blob() : formData,
    method: 'POST',
  })
  .then((response) => {
    return response.json()
    .then((data) => {
      if (!response.ok && response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
        throw new ValidationError(data);
      }
      return data;
    });
  });
}

/**
 * Complete a password reset.
 *
 * @param {FormData} formData - Form data to send to the server
 * @param {string} accessToken - The special access token to use
 *
 * @return {Promise} A promise for the server's response
 */
export function resetPassword(formData, accessToken) {
  return api('/v1/auth/password/reset', {
    body: formData._blob ? formData._blob() : formData,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    method: 'POST',
  }, false)
  .then((data) => {
    storeAccessToken(data.accessToken);

    store.dispatch(authActions.login(data.user));

    loggedIn(data.user);
    return data;
  });
}

/**
 * Log a user in via email address or handle and password.
 *
 * @param {FormData} formData - Form data to send to the server
 *
 * @return {Promise} A promise for the authentication attempt to be made
 */
export function loginEmail(formData) {
  return fetch(getApiUrl() + '/v1/auth/login', {
    body: formData._blob ? formData._blob() : formData,
    method: 'POST',
  })
  .then((response) => {
    return response.json()
    .then((data) => {
      if (!response.ok && data.error === 'invalid_credentials') {
        let e = new Error(data.detail);
        e.name = `AuthError: ${data.error}`;
        throw e;
      } else if (!response.ok && response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
        throw new ValidationError(data);
      }
      return data;
    });
  })
  .then((data) => {
    storeAccessToken(data.accessToken);

    store.dispatch(authActions.login(data.user));

    loggedIn(data.user);
  });
}

/**
 * Initiate user registration via email address.
 *
 * @param {FormData} formData - Form data to send to the server
 *
 * @return {Promise} A promise for the registration procedure to begin
 */
export function initiateEmailRegistration(formData) {
  // Add URL to reset page
  formData.append('url', `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/auth/register`);

  // Add the referralId
  const referralId = getReferralId();
  if (referralId) {
    formData.append('referral_id', referralId);
  }

  return fetch(getApiUrl() + '/v1/auth/register/initiate', {
    body: formData._blob ? formData._blob() : formData,
    method: 'POST',
  })
  .then((response) => {
    return response.json()
    .then((data) => {
      if (!response.ok && response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
        throw new ValidationError(data);
      }
      return data;
    });
  });
}

/**
 * Complete user registration via email address or third party.
 *
 * @param {FormData} formData - Form data to send to the server
 * @param {string} accessToken - The special access token to use
 *
 * @return {Promise} A promise for the registration response
 */
export function completeRegistration(formData, accessToken = null) {
  const referralId = getReferralId();
  if (referralId) {
    formData.append('referral_id', referralId);
  }

  let options = {
    body: formData._blob ? formData._blob() : formData,
    method: 'POST',
  };

  if (accessToken !== null) {
    options.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return api('/v1/auth/register', options, accessToken === null)
  .then((data) => {
    if (data.accessToken) {
      storeAccessToken(data.accessToken);

      store.dispatch(authActions.login(data.user));

      loggedIn(data.user);
    } else {
      store.dispatch(authActions.update(data.user));
    }
    return data;
  });
}

/**
 * Initiate logging in from the client side via an OAuth provider.
 *
 * @param {string} provider - Provider
 * @param {boolean} rw - Whether to ask for write permissions
 *
 * @return {Promise} A promise for all stages of the authentication to take
 * place
 */
export function login(provider, rw = false) {
  const scopes = [];
  switch (provider) {
    case 'facebook':
      scopes.push('email');
      if (rw) {
        scopes.push('publish_actions');
      }
      break;
    case 'google':
      scopes.push('https://www.googleapis.com/auth/userinfo.email');
      break;
  }

  return initializeHello()
  .then(() => hello.login(provider, {scope: scopes.join(',')}))
  .then(a => authenticate(provider, a.authResponse), function(e) {
    if (e.error && e.error.code === "cancelled") {
      // Ignore this
      return;
    }
    Log(e, 'warning');
  });
}

/**
 * Blacklist the current access token and log the current user out.
 *
 * @return {Promise} A promise for the user to be logged out
 */
export function logout() {
  return api('/v1/auth/logout', {
    method: 'POST',
  })
  .then(() => {
    loggedOut();
  });
}

/**
 * Attach authorization information to an XHR request.
 *
 * @param {XMLHttpRequest} xhr - XHR
 *
 * @return {void}
 */
export function addAuthToXhr(xhr) {
  if (haveAccessToken()) {
    xhr.setRequestHeader('Authorization', getAuthorizationHeader());
  }
}

export function getSessionToken() {
  let sessionToken = window.localStorage.getItem('sessionToken');

  return sessionToken;
}

export function haveSessionToken() {
  return getSessionToken() !== null;
}

export function storeSessionToken(data) {
  if (data.sessionToken) {
    window.localStorage.setItem('sessionToken', data.sessionToken);
  }
}

export function fetchSessionToken() {
  return fetch(getApiUrl() + '/v1/session', {
    method: 'GET',
  })
  .then((response) => {
    return response.json()
    .then((data) => {
      storeSessionToken(data);
    });
  })
  .catch((error) => {
    Log(error, 'error');
  });
}

export function updateSession() {
  let formData = new FormData();
  formData.append('sessionToken', getSessionToken());

  return api('/v1/session', {
    body: formData._blob ? formData._blob() : formData,
    method: 'POST',
  })
  .then((data) => {
    storeSessionToken(data);

    return data;
  });
}

/**
 * Check whether a user is logged in, and update application state as
 * appropriate.
 *
 * @return {Promise} A promise for the check to finish
 */
export function checkUserStatus() {
  let promise = Promise.resolve(true);
  if (!haveSessionToken()) {
    promise = fetchSessionToken();
  }
  if (!haveAccessToken()) {
    store.dispatch(authActions.logout());
    loggedOut();
    return promise;
  }

  return promise
  .then(() => api('/v1/user'))
  .catch((error) => {
    if (error.name === 'AuthError') {
      store.dispatch(authActions.logout());
      loggedOut();
    }
    throw error;
  })
  .then((response) => {
    store.dispatch(authActions.update(response.user));
    loggedIn(response.user);
  });
}

