import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import styles from './style.scss';

export default class DropdownMenu extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    open: PropTypes.bool.isRequired,
    wide: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    open: false,
    wide: false,
  }

  render() {
    const {
      children,
      className,
      open,
      wide,
    } = this.props;

    if (!open) {
      return null;
    }
    return (
      <ul className={classNames(styles.root, wide ? styles.wide : styles.narrow, className)}>
        {children}
      </ul>
    );
  }
}
