import classNames from "classnames";
import { compose, graphql } from "react-apollo";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { logout } from "~/auth";

import Main from "components/Main";

import Avatar from "components/Avatar";
import Button from "components/Button";
import Handle from "components/Handle";
import IconButton from "components/IconButton";
import FormError from "components/FormError";

import SparkCards from "components/SparkCards";
import DropdownMenu from "components/DropdownMenu";

import Loading from "sections/Loading";

import styles from "./style.scss";
import { Link } from "react-router-dom";
import ProfileSparksCounterWithData from "../../components/profileSparksCounter";

const mapStateToProps = function (state) {
  return {
    userAuth: state.auth.user,
  };
};

class AbbeyCanucksProfileIfreame extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
      user: PropTypes.object,
    }),
    followUser: PropTypes.func.isRequired, // GraphQL mutation
    match: PropTypes.object.isRequired, // React-router
    unfollowUser: PropTypes.func.isRequired, // GraphQL mutation
    userAuth: PropTypes.object, // Auth reducer
  };

  static defaultProps = {
    className: "",
  };

  constructor() {
    super();

    this.isLocked = false;
    this.state = {
      optionsDropdownOpen: false,
      errors: null,
      isGetSparkitPopUpOpen: false,
    };
  }

  followOrUnfollow = (follow) => {
    const { data } = this.props;

    if (this.isLocked) {
      return Promise.resolve();
    }
    this.isLocked = true;

    const unlock = () => {
      this.isLocked = false;
    };

    const func = follow ? this.props.followUser : this.props.unfollowUser;

    return func({
      hashid: data.user.hashid,
    })
      .then(unlock)
      .catch(unlock);
  };

  followUser = () => {
    return this.followOrUnfollow(true);
  };

  unfollowUser = () => {
    return this.followOrUnfollow(false);
  };

  handleMoreClick = () => {
    this.setState({
      optionsDropdownOpen: !this.state.optionsDropdownOpen,
    });
  };

  handleLogout = () => {
    logout().then(() => {});
  };

  handleOpenGetSparkitPopUp = () => {
    this.setState({ isGetSparkitPopUpOpen: true });
  };

  handleCloseGetSparkitPopUp = () => {
    this.setState({ isGetSparkitPopUpOpen: false });
  };

  render() {
    const { className, data, match, userAuth } = this.props;

    if (data.loading) {
      return <Loading />;
    }

    return (
      <div className={styles.wrapper}>
        {this.state.isGetSparkitPopUpOpen && (
          <div
            className={styles.popUpGlobalWrapper}
            onClick={this.handleCloseGetSparkitPopUp}
          >
            <div className={styles.popUpWrapper}>
              <div className={styles.popUpTextWrap}>
                <div className={styles.popUpText}>
                  <div>{` About Sparkit.buzz`}</div>
                  With our cutting-edge <br />
                  collaboration platform, Sparkit <br />
                  Technologies brings together Pro <br />
                  Athletes, Sports Teams and their <br />
                  SuperFans in a digital engagement <br />
                  and advertising revolution.
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={`${styles.headerWrapper} ${
            this.state.isGetSparkitPopUpOpen ? "" : styles.stickyHeaderWrapper
          }`}
        >
          <div className={styles.container}>
            <button
              onClick={this.handleOpenGetSparkitPopUp}
              className={`${styles.button} ${styles.aboutSparkBtn}`}
            >
              Get Sparkit
            </button>
            <div className={styles.endContent}>
              <a
                href="https://exch.sparkit.buzz/api/auth/signUpIframeAdvertisers"
                target="_blank"
                rel="noreferrer"
                className={`${styles.button} ${styles.signInBtn}`}
              >
                Sign In/Sign Up
              </a>
            </div>
          </div>
        </div>
        {/* {userAuth && match.params.handle === userAuth.handle  */}

        <Main>
          {/* <div className="wrap"> */}
          <div
            className={classNames(
              "wrap__inner",
              "wrap__inner--skinny",
              "wrap__gutter",
              styles.root,
              className
            )}
          >
            <div className={styles.content}>
              <div className={styles.userInfo}>
                <div className={styles.avatarAndNicknameBlock}>
                  <div className={styles.avatarBlock}>
                    {userAuth && match.params.handle === userAuth.handle && (
                      <IconButton
                        icon="editProfile"
                        color="white"
                        link="/profile/edit"
                      />
                    )}
                    <Avatar
                      size="lg"
                      user={data.user}
                      className={styles.avatar}
                      link={false}
                      shadow
                    />
                    {userAuth && match.params.handle === userAuth.handle && (
                      <div>
                        <IconButton
                          icon="moreicon"
                          onClick={this.handleMoreClick}
                        />
                        <DropdownMenu
                          className={styles.optionsDropdown}
                          wide
                          open={this.state.optionsDropdownOpen}
                        >
                          <li>
                            <Button color="none" link="/profile/edit/password">
                              Change Password
                            </Button>
                          </li>
                          <li>
                            <Button color="none" link="/profile/edit/social">
                              Linked Social Accounts
                            </Button>
                          </li>
                          <li>
                            <Button
                              color="none"
                              link={
                                userAuth.is_influencer
                                  ? "https://www.notion.so/sparkit-buzz/1-Creator-Help-WebPage-8b6007c2d7da4e2e8c66a71d2c463994?pvs=4"
                                  : "http://info.sparkit.buzz/fanhelp"
                              }
                            >
                              Get Help
                            </Button>
                          </li>
                          <li>
                            <Button color="none" onClick={this.handleLogout}>
                              Log Out
                            </Button>
                          </li>
                        </DropdownMenu>
                      </div>
                    )}
                  </div>
                  {this.state.errors &&
                    this.state.errors.fields &&
                    this.state.errors.fields.handle && (
                      <FormError errors={this.state.errors.fields.handle} />
                    )}
                  <Handle
                    className={styles.handle}
                    link={false}
                    handle={data.user.handle}
                  />
                </div>
                <form>
                  <div>
                    {data.user.bio && (
                      <div className={styles.bio}>
                        <p>{data.user.bio}</p>
                      </div>
                    )}
                  </div>
                </form>
                {/* {userAuth && data.user.is_influencer && match.params.handle !== userAuth.handle && */}
                <div
                  className={classNames(
                    "m-t-20 m-b-20",
                    styles.followBtnWrap,
                    styles.hidden,
                    {
                      [styles.isShowed]:
                        userAuth &&
                        data.user.is_influencer &&
                        match.params.handle !== userAuth.handle,
                    }
                  )}
                >
                  {data.user.is_followed_by_me ? (
                    <Button
                      onClick={this.unfollowUser}
                      className={styles.unfollowBtn}
                      color="tertiary"
                    >
                      Unfollow
                    </Button>
                  ) : (
                    <Button
                      onClick={this.followUser}
                      className={styles.followBtn}
                      color="tertiary"
                    >
                      Follow
                    </Button>
                  )}
                </div>
                {/* } */}
                <div className={styles.profileInfoWrap}>
                  <div
                    className={classNames(
                      styles.stats,
                      "bar",
                      "bar--dropshadow"
                    )}
                  >
                    {data.user.is_influencer !== false ? (
                      <React.Fragment>
                        <ProfileSparksCounterWithData
                          user={match.params.handle}
                        />

                        <div className={styles.line} />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className={styles.statsItem}>
                          <span className={styles.statsCount}>
                            {data.user.votesWon}
                          </span>
                          <span className={styles.statsText}>Votes Won</span>
                        </div>
                      </React.Fragment>
                    )}
                    <div className={styles.statsItem}>
                      <span className={styles.statsCount}>
                        {data.user.sharesByNetwork}
                      </span>
                      <span className={styles.statsText}>
                        Shares By Network
                      </span>
                    </div>
                  </div>
                  <div className={styles.followersContainer}>
                    <Link
                      to={`/followers/${this.props.data.user.handle}`}
                      className={styles.statusFollowersCountWrap}
                    >
                      <span className={styles.statusFollowersCount}>
                        {data.user.followers_count}
                      </span>
                      <span className={styles.statsFollowersText}>
                        Followers
                      </span>
                    </Link>
                    <Link
                      to={`/following/${this.props.data.user.handle}`}
                      iv
                      className={styles.statusFollowersCountWrap}
                    >
                      <span className={styles.statusFollowersCount}>
                        {data.user.followees_count}
                      </span>
                      <span className={styles.statsFollowersText}>
                        Following
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="wrap">
                {/* <SparkCards isProfile user={"Rockets"} responsive={false} /> */}
                <SparkCards isProfile user={"Abbycanucks"} responsive={false} />
              </div>
            </div>
          </div>
          {/* </div> */}
        </Main>
      </div>
    );
  }
}

const PROFILE_QUERY = gql`
  query Profile($handle: String!) {
    user(handle: $handle) {
      avatar {
        ... on Media {
          hashid
          is_processed
          square_image_sources {
            url
            width
          }
          type
        }
        ... on Url {
          url
        }
      }
      bio
      followers_count
      followees_count
      handle
      hashid
      is_followed_by_me
      sharesByNetwork
      votesWon
      is_influencer
      influencer {
        country
        gender
        paypal
        category_primary
        category_secondary
        category_tertiary
        notification_email_sparkreminder_enabled
        region
      }
      notification_email_closedspark_enabled
      notification_email_enabled
      notification_email_newspark_enabled
      notification_email_votedcomment_enabled
    }
  }
`;

const FOLLOW_USER_MUTATION = gql`
  mutation FollowUser($hashid: ID!) {
    followUser(hashid: $hashid) {
      followee {
        followers_count
        hashid
        is_followed_by_me
      }
    }
  }
`;

const UNFOLLOW_USER_MUTATION = gql`
  mutation UnfollowUser($hashid: ID!) {
    unfollowUser(hashid: $hashid) {
      followee {
        followers_count
        hashid
        is_followed_by_me
      }
    }
  }
`;

export const SPARKS_QUERY = gql`
  query SparksForHome($cursor: String, $user: String, $tags: [String!]) {
    sparks(first: 8, after: $cursor, user: $user, tags: $tags) {
      pageInfo {
        endCursor
        hasNextPage
        __typename
      }
      edges {
        cursor
        __typename
        node {
          closes_at
          has_closing_comment
          hashid
          permalink
          status
          location {
            name
            place_id
            __typename
          }
          tags
          closing_comment {
            hashid
            parent {
              hashid
              created_at
              is_voted_on_by_me
              message
              permalink
              votes_count
              user {
                handle
                hashid
              }
            }
          }
          opening_comment {
            hashid
            media {
              hashid
              is_processed
              square_image_sources {
                url
                width
              }
              type
              __typename
            }
            message
            __typename
          }
          tags
          user {
            avatar {
              ... on Media {
                hashid
                is_processed
                square_image_sources {
                  url
                  width
                }
                type
                __typename
              }
              ... on Url {
                url
                __typename
              }
            }
            handle
            hashid
            __typename
          }
          __typename
        }
      }
      __typename
    }
  }
`;

export default compose(
  graphql(PROFILE_QUERY, {
    options: (props) => ({
      variables: {
        // handle: "Rockets",
        handle: "Abbycanucks",
      },
    }),
  }),
  // graphql(SPARKS_QUERY, {
  //   options: (props) => ({
  //     notifyOnNetworkStatusChange: true,
  //     variables: {
  //       cursor: null,
  //       user: "Rockets",
  //       tags: [],
  //     },
  //   }),
  // }),
  graphql(FOLLOW_USER_MUTATION, {
    props: ({ ownProps, mutate }) => ({
      followUser: function (variables) {
        return mutate({
          optimisticResponse: {
            followUser: {
              __typename: "FollowEdge",
              followee: {
                __typename: "User",
                followers_count: ownProps.data.user.followers_count + 1,
                hashid: ownProps.data.user.hashid,
                is_followed_by_me: true,
              },
            },
          },
          variables: variables,
        });
      },
    }),
  }),
  graphql(UNFOLLOW_USER_MUTATION, {
    props: ({ ownProps, mutate }) => ({
      unfollowUser: function (variables) {
        return mutate({
          optimisticResponse: {
            unfollowUser: {
              __typename: "FollowEdge",
              followee: {
                __typename: "User",
                followers_count: ownProps.data.user.followers_count - 1,
                hashid: ownProps.data.user.hashid,
                is_followed_by_me: false,
              },
            },
          },
          variables: variables,
        });
      },
    }),
  }),
  connect(mapStateToProps, null)
)(AbbeyCanucksProfileIfreame);
