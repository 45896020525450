import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './style.scss';

export default class Tag extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }

  static defaultProps = {
    className: '',
    onClick: f => f,
    value: '',
  }

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const {className, onClick, value} = this.props;

    // If this is the #sponsor tag then don't render the delete component
    if (value === "sponsor") {
      return (
        <span className={classNames(styles.root, className)}>
          {value}
          <div className={styles.nobtn}>
            &nbsp;
          </div>
        </span>
      );
    }

    return (
      <span className={classNames(styles.root, className)}>
        {value}
        <div onClick={onClick} className={styles.btn}>
          &times;
        </div>
      </span>
    );
  }
}
