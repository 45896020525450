import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './style.scss';

export default class FilterTab extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    state: PropTypes.string,
    unread: PropTypes.bool,
  }

  static defaultProps = {
    label: '',
    unread: false,
  }

  render() {
    let unreadClass = null;
    if (this.props.unread === true) {
      unreadClass = styles.unread;
    }

    let activeClass = null;
    if (this.props.state === "active") {
      activeClass = styles.active;
    }

    let Element = null;
    const elementProps = {
      onClick: this.props.onClick,
    };
    if (this.props.href) {
      Element = 'a';
      elementProps.href = this.props.href;
    } else {
      Element = 'button';
    }

    return (
      <li className={classNames(styles.root, this.props.className, "list__item", activeClass, unreadClass)}>
        <Element {...elementProps}>
          <span>{this.props.label}</span>
        </Element>
      </li>
    );
  }
}
