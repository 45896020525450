import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import Button from "components/Button";
import Icon from "components/Icon";
import { TagListFromArray } from "components/TagList";

import gql from "graphql-tag";
import { graphql } from "react-apollo";

import styles from "./style.scss";

export class BrandOpportunity extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
      nextPayment: PropTypes.arrayOf(PropTypes.object),
    }),
    demo: PropTypes.bool,
    exclusive: PropTypes.bool,
    onCreateVideo: PropTypes.func,
    opportunity: PropTypes.object,
    handleSelectAddName: PropTypes.func.isRequired,
  };

  static defaultProps = {
    demo: false,
    label: "",
  };

  handleCreateVideo = () => {
    if (this.props.onCreateVideo) {
      this.props.handleSelectAddName(this.props.opportunity.name);
      this.props.onCreateVideo(this.props.opportunity);
    }
  };

  render() {
    const { data, opportunity } = this.props;

    if (data.loading) {
      return null;
    }

    if (data.error) {
      return <p>Error</p>;
    }

    const nextPayments = data.nextPayment.filter(
      (v) => v.offer.hashid === opportunity.hashid
    );
    let nextPayment = "$500 per 1M social";
    if (nextPayments && nextPayments.length === 1) {
      if (nextPayments[0].next_payment > 0) {
        nextPayment = nextPayments[0].next_payment.toLocaleString("en-US", {
          currency: "USD",
          style: "currency",
        });
      }
    }

    const purse = opportunity.payout_remaining.toLocaleString("en-US", {
      currency: "USD",
      style: "currency",
    });

    const isPromo = this.props.exclusive && !opportunity.is_exclusive;

    return (
      <div
        className={classNames(
          "wrap",
          styles.root,
          opportunity.is_exclusive && styles.backgroundExclusive
        )}
      >
        <div
          className={classNames(
            "wrap__inner",
            "wrap__gutter",
            "wrap__gutter--padded",
            styles.content
          )}
        >
          <div className={classNames(styles.contentMain)}>
            {opportunity.name === "Demo Campaign" ? (
              <>
                <div className={classNames(styles.logoBlock)}>
                  <img
                    src="/images/logo_60x60.svg"
                    alt=""
                    className={classNames(styles.logo)}
                  />
                  <span>{opportunity.name}</span>
                </div>
              </>
            ) : (
              <>
                {opportunity.thumbnail_url && (
                  <div className={classNames(styles.logoBlock)}>
                    <img
                      src={opportunity.thumbnail_url}
                      alt=""
                      className={classNames(styles.logo)}
                    />
                    <span>{opportunity.name}</span>
                  </div>
                )}
              </>
            )}

            <span className={classNames(styles.offer)}>
              <div className={styles.header}>
                {opportunity.is_exclusive && (
                  <span className={styles.exclusive}>
                    <Icon
                      icon="star"
                      filled
                      size="sm"
                      color="grey"
                      className={styles.exclusiveIcon}
                    />
                    <span className={styles.exclusiveText}>Exclusive</span>
                  </span>
                )}
              </div>

              <div>
                <span className={styles.header}>
                  <span className={styles.costsLabel}>Purse Remaining:</span>
                  <span className={styles.costsValue}>{purse}</span>
                </span>
              </div>
              <div className={styles.description}>
                {opportunity.description}
                {this.props.demo && (
                  <p>
                    <a href="https://sparkit-buzz.notion.site/Creator-Help-8b6007c2d7da4e2e8c66a71d2c463994">
                      Need Help?
                    </a>
                  </p>
                )}
              </div>

              <div>
                <TagListFromArray tags={opportunity.tags} linked={false} />
              </div>

              <table>
                <tbody>
                  <tr className={styles.nextPaymentRow}>
                    <th className={styles.nextPaymentLabel}>
                      Estimated Next Payment
                    </th>
                    <td className={styles.nextPaymentValue}>{nextPayment}</td>
                  </tr>
                  {opportunity.cpl_goal && (
                    <CostsRow goal={opportunity.cpl_goal} isPromo={isPromo} />
                  )}
                  {opportunity.cpa_goal && (
                    <CostsRow goal={opportunity.cpa_goal} isPromo={isPromo} />
                  )}
                </tbody>
              </table>
              {!this.props.demo && !isPromo && (
                <div className={styles.button}>
                  <Button
                    size="sm-wide"
                    className={styles.btn}
                    onClick={this.handleCreateVideo}
                  >
                    Start Spark
                  </Button>
                </div>
              )}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

class CostsRow extends React.Component {
  static propTypes = {
    goal: PropTypes.object.isRequired,
    isPromo: PropTypes.bool.isRequired,
  };

  render() {
    const goal = this.props.goal;
    if (!goal.payout > 0 && !goal.payout_percentage > 0) {
      return <div></div>;
    }
    let payout = "";
    switch (goal.payout_type) {
      case "cpa_flat":
      case "cpc":
      case "cpm":
        payout = goal.payout.toLocaleString("en-US", {
          currency: "USD",
          style: "currency",
        });
        break;
      case "cpa_percentage":
        payout = (goal.payout_percentage / 100).toLocaleString("en-US", {
          minimumFractionDigitals: 0,
          style: "percent",
        });
        break;
      case "cpm_demo":
        payout = "$";
        break;
      case "cpc_demo":
        payout = "$$";
        break;
      case "cpa_demo":
        payout = "$$$";
        break;
      default:
        throw new Error("Unsupported payout type");
    }

    if (this.props.isPromo) {
      return (
        <tr className={styles.costsRow}>
          <th className={styles.costsLabel}>{goal.promo}</th>
        </tr>
      );
    }

    return (
      <tr className={styles.costsRow}>
        <th className={styles.costsLabel}>{goal.payout_name}</th>
        <td className={styles.costsValue}>
          {this.props.isPromo ? goal.promo : payout}
        </td>
      </tr>
    );
  }
}

const nextPaymentQuery = gql`
  query NextPayment {
    nextPayment {
      id
      next_payment
      next_payment_reason
      offer {
        hashid
        name
      }
    }
  }
`;

const BrandOpportunityWithData = graphql(nextPaymentQuery)(BrandOpportunity);

export default BrandOpportunityWithData;
