export const RESET_STATE = "RESET_STATE";
export const SET_PRIMARY_COLLAB_DATA = "SET_PRIMARY_COLLAB_DATA";

export const resetState = () => ({
  type: RESET_STATE,
});

export const setPrimaryCollabData = (value) => ({
  type: SET_PRIMARY_COLLAB_DATA,
  payload: value,
});
