export const CHANGE_INPUT_VISIBILITY = "CHANGE_INPUT_VISIBILITY";
export const SEARCH_VALUE_CHANGE = "SEARCH_VALUE_CHANGE";
export const SEARCH = "SEARCH";
export const SET_VALUES = "SET_VALUES";

export const changeInputVisibility = (value) => ({
  type: CHANGE_INPUT_VISIBILITY,
  payload: value,
});

export const searchValueChange = (value) => ({
  type: SEARCH_VALUE_CHANGE,
  payload: value,
});

export const search = () => ({
  type: SEARCH,
});

export const setValues = (searchValue, tags) => ({
  type: SET_VALUES,
  payload: { searchValue, tags },
});
