import classNames from "classnames";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { withLastLocation } from "react-router-last-location";
import { withRouter } from "react-router";

import Avatar from "components/Avatar";
import IconButton from "components/IconButton";
import LogInButton from "components/LogInButton";
import LogOutButton from "components/LogOutButton";

import styles from "./style.scss";
import HeadSearch from "../HeadSearch";

const mapStateToProps = function (state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
    isInputHiddenState: state.hashtag.isInputHidden,
  };
};

class Head extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool,
    back: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    heading: PropTypes.string,
    history: PropTypes.object.isRequired, // From router
    lastLocation: PropTypes.object, // From react-router-last-location
    minimal: PropTypes.bool.isRequired,
    isInputHiddenState: PropTypes.bool,
    user: PropTypes.object,
  };

  static defaultProps = {
    back: false,
    isInputHiddenState: true,
    minimal: false,
  };

  componentDidMount() {
    if (localStorage["Referral-openSignup"]) {
      localStorage["Referral-openSignup"] = false;
    }
  }

  render = () => {
    let spacerSizeClass = null;
    if (this.props.authenticated) {
      spacerSizeClass = this.props.minimal
        ? styles.isMinimal
        : styles.isLoggedIn;
    }

    return (
      <header>
        <div className={classNames(styles.spacer, spacerSizeClass)} />
        <div
          className={classNames(
            styles.bar,
            "pos-f top-0 left-0 bg-white col-12"
          )}
        >
          {this.props.authenticated
            ? this.renderAuthenticated()
            : this.renderUnauthenticated()}
        </div>
      </header>
    );
  };

  renderUnauthenticated() {
    return (
      <div className={classNames(styles.header, styles.headerUnauthenticated)}>
        <a
          className={classNames(styles.logo, {
            [styles.hiddenLogo]: !this.props.isInputHiddenState,
          })}
          href="//info.sparkit.buzz/"
        >
          <img
            src="/images/logo_60x60.svg"
            className={styles.logoImg}
            alt="Sparkit Logo"
          />
        </a>
        <HeadSearch />

        <ul className={classNames(styles.list, styles.listRight)}>
          <li className={styles.listItem}>
            <LogInButton />
          </li>
        </ul>
      </div>
    );
  }

  renderInfluencerControls = () => {
    return (
      <ul className={classNames(styles.list, styles.listLeft)}>
        <li className={styles.listItem} data-tut="influencerHome__step10">
          <IconButton icon="leaderboardNew" link="/profile/leaderboard" />
        </li>
        <li className={styles.listItem} data-tut="influencerHome__step3">
          <IconButton icon="reportNew" link="/profile/report" />
        </li>
        <li className={styles.listItem} data-tut="influencerHome__step2">
          <IconButton icon="video" link="/spark/post" />
        </li>
      </ul>
    );
  };

  renderBackLink = () => {
    const otherProps = {};
    if (this.props.back === true) {
      if (this.props.lastLocation) {
        otherProps.onClick = () => {
          this.props.history.goBack();
        };
        otherProps.icon = "back";
      } else {
        otherProps.link = "/";
        otherProps.icon = "close";
      }
    } else {
      otherProps.link = this.props.back;
      otherProps.icon = "back";
    }

    return <IconButton color="grey" {...otherProps} />;
  };

  renderAuthenticated = () => {
    return (
      <div>
        {!this.props.minimal && (
          <div
            className={classNames(styles.header, styles.headerAuthenticated)}
          >
            <div className={classNames(styles.left)}>
              <Avatar size="sm" />

              {this.props.authenticated &&
                this.props.user &&
                this.props.user.is_influencer &&
                this.renderInfluencerControls()}
            </div>

            <ul className={classNames(styles.list, styles.listRight)}>
              <li className={styles.listItem}>
                <LogOutButton />
              </li>
            </ul>
          </div>
        )}
        <div className={classNames(styles.header, styles.headerSecondary)}>
          {this.props.back && this.renderBackLink()}
          {(!this.props.back || this.props.heading) && (
            <h1>{this.props.heading}</h1>
          )}
          <ul className={classNames(styles.list, styles.listRight)}>
            <li className={styles.listItem}>
              <IconButton icon="home" color="grey" link="/" />
            </li>
          </ul>
        </div>
      </div>
    );
  };
}

export default compose(
  connect(mapStateToProps, null),
  withLastLocation,
  withRouter
)(Head);
