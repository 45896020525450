import PropTypes from "prop-types";
import React from "react";

import Main from "components/Main";

import LoadingSpinner from "components/Loading";

export default class Loading extends React.Component {
  static propTypes = {
    headProps: PropTypes.object,
  };

  render() {
    return (
      <div>
        {/* <Head {...this.props.headProps} /> */}
        <Main full>
          <LoadingSpinner />
        </Main>
      </div>
    );
  }
}
