import React from "react";
import { connect } from "react-redux";

import Head from "components/Head";
import Main from "components/Main";
import Help from "components/Help";
import SparkCards from "components/SparkCards";

import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { animateScroll as scroll } from "react-scroll";

import PropTypes from "prop-types";
import HeaderForAuthorizedUsers from "../components/HeaderForAuthorizedUsers";
import { setValues } from "actions/hashtagSearch";

import style from "./home.scss";

const mapStateToProps = function (state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
  };
};
const mapDispatchToProps = {
  onSetValues: setValues,
};

const influencerSteps = [
  {
    content: "View Sparks from other influencers for inspiration.",
    selector: '[data-tut="influencerHome__step1"]',
  },
  {
    content:
      "Create new Sparks to engage with your fans, create authentic content and generate dependable revenue.",
    selector: '[data-tut="influencerHome__step2"]',
  },
  {
    content:
      "View your stats and see where and how you can improve your engagement.",
    selector: '[data-tut="influencerHome__step3"]',
  },
];

class Home extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool, // Auth reducer
    match: PropTypes.object.isRequired, // From router
    user: PropTypes.object, // Auth reducer
    onSetValues: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      isTourOpen: false,
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const hashtags = params.get("hashtags");

    if (hashtags) {
      const tags = hashtags.split(",");
      const searchValue = tags.map((tag) => "#" + tag).join(" ");
      this.props.onSetValues({ searchValue, tags });
    }
  }

  getSteps = (user) => {
    if (user && user.is_influencer) {
      return influencerSteps;
    }
    return [];
  };

  helpClick = () => {
    this.setIsTourOpen(true);
  };

  setIsTourOpen = (open) => {
    this.setState({ isTourOpen: open });
  };

  disableBody = (target) => disableBodyScroll(target);
  enableBody = (target) => enableBodyScroll(target);

  tourClose = () => {
    this.setIsTourOpen(false);
    scroll.scrollToTop();
  };

  render() {
    const { user } = this.props;

    const InfluencerHeaderProps = {
      leftContent: { content: { type: "avatar" } },
      rightContent: {
        content: [
          {
            type: "link",
            icon: "leaderboardNew",
            link: "/profile/leaderboard",
          },
          { type: "link", icon: "reportNew", link: "/profile/report" },
          { type: "link", icon: "video", link: "/spark/post" },
        ],
        className: "rightIcons",
      },
      searchStyles: style.infHomeSearch,
    };
    const FanHeaderProps = {
      leftContent: { content: [{ type: "avatar" }] },
      searchStyles: style.fanHomeSearch,
    };

    const headerProps =
      user && user.is_influencer ? InfluencerHeaderProps : FanHeaderProps;

    return (
      <div>
        {!user ? (
          <Head heading="Home" />
        ) : (
          <HeaderForAuthorizedUsers withHashtagSearch {...headerProps} />
        )}
        <Main>
          {user && user.is_influencer && (
            <Help
              message="Checkout what Sparkit can do for you."
              name="helpInfluencerHome"
              onClick={this.helpClick}
              visible={true}
            />
          )}
          <div className="wrap">
            <div className={style.wrap__gutter}>
              <div className="wrap__inner">
                <SparkCards />
              </div>
            </div>
          </div>
        </Main>
        <div>
          <Tour
            steps={this.getSteps(user)}
            isOpen={this.state.isTourOpen}
            onRequestClose={this.tourClose}
            onAfterOpen={this.disableBody}
            onBeforeClose={this.enableBody}
            startAt={0}
            accentColor="#f75a00"
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);
