// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-Icon-style__root__e5nvv{display:inline-block;vertical-align:middle}.components-Icon-style__root__e5nvv>span:first-child{height:100%;width:100%}.components-Icon-style__root__e5nvv svg{width:100%;height:100%}.components-Icon-style__xs__txyDY{height:10px;width:10px}.components-Icon-style__sm__waDTo{height:15px;width:15px}.components-Icon-style__md__R6Dps{height:20px;width:20px}.components-Icon-style__lg__tFvRe{height:30px;width:30px}.components-Icon-style__black__bcDKE{color:#000}.components-Icon-style__grey__vdMtO{color:#9b9b9b}.components-Icon-style__darkGrey__ymqMv{color:#626261}.components-Icon-style__active__PKHAm{color:#fd4238}.components-Icon-style__inactive__ZuyGk{color:#626261}.components-Icon-style__primary__CsCUv{color:#f75a00}.components-Icon-style__secondary__tg670{color:#007dff}.components-Icon-style__tertiary__ng3Xp{color:#1644ff}.components-Icon-style__locationOrange__sz6IS{color:#e2792c}.components-Icon-style__white___UtnR{color:#fff}.components-Icon-style__gray__vrsNK{color:#939393}.components-Icon-style__flag__fkaZJ{width:28px;height:22px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `components-Icon-style__root__e5nvv`,
	"xs": `components-Icon-style__xs__txyDY`,
	"sm": `components-Icon-style__sm__waDTo`,
	"md": `components-Icon-style__md__R6Dps`,
	"lg": `components-Icon-style__lg__tFvRe`,
	"black": `components-Icon-style__black__bcDKE`,
	"grey": `components-Icon-style__grey__vdMtO`,
	"darkGrey": `components-Icon-style__darkGrey__ymqMv`,
	"active": `components-Icon-style__active__PKHAm`,
	"inactive": `components-Icon-style__inactive__ZuyGk`,
	"primary": `components-Icon-style__primary__CsCUv`,
	"secondary": `components-Icon-style__secondary__tg670`,
	"tertiary": `components-Icon-style__tertiary__ng3Xp`,
	"locationOrange": `components-Icon-style__locationOrange__sz6IS`,
	"white": `components-Icon-style__white___UtnR`,
	"gray": `components-Icon-style__gray__vrsNK`,
	"flag": `components-Icon-style__flag__fkaZJ`
};
export default ___CSS_LOADER_EXPORT___;
