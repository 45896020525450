import { ApolloProvider } from "react-apollo";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import React from "react";
import { Tooltip } from "react-tooltip";

import CompleteRegistrationModal from "components/CompleteRegistrationModal";
import RouteHandler from "components/RouteHandler";

import graphqlClient from "data/graphqlClient";

import ErrorBoundary from "components/ErrorBoundary";
import * as sections from "sections";

import "~/scss/style.scss";

export default class App extends React.Component {
  render = () => {
    return (
      <ErrorBoundary>
        <ApolloProvider client={graphqlClient}>
          <BrowserRouter>
            <LastLocationProvider>
              <RouteHandler>
                <Switch>
                  {/**
                   * When routes change, the server-side redirections need to be
                   * updated in the sparkit-api project, in
                   * provision/ansible/playbook.yml.
                   *
                   * The API AMI then needs to be rebuilt.
                   */}

                  <Route exact path="/" component={sections.Home} />
                  <Route
                    path="/referral/:referralId"
                    component={sections.ReferralRedirection}
                  />
                  <Route
                    path="/app/:token"
                    component={sections.AppRedirection}
                  />

                  <Route path="/styleguide" component={sections.StyleGuide} />

                  <Route
                    exact
                    path="/profile"
                    component={sections.ProfileRedirection}
                  />
                  <Route
                    exact
                    path="/profile/edit"
                    component={sections.EditProfile}
                  />
                  <Route
                    exact
                    path="/profile/edit/password"
                    component={sections.SettingsPassword}
                  />
                  <Route
                    exact
                    path="/profile/edit/social"
                    component={sections.SettingsSocial}
                  />
                  <Route
                    exact
                    path="/profile/report"
                    component={sections.Report}
                  />
                  <Route
                    exact
                    path="/profile/leaderboard"
                    component={sections.Leaderboard}
                  />
                  <Route
                    exact
                    path="/profile/:handle"
                    component={sections.Profile}
                  />

                  <Route path="/spark/post" component={sections.SparkPost} />
                  <Route
                    path="/spark/:hashid/decision/:commentHashid"
                    component={sections.SparkDecision}
                  />
                  <Route path="/spark/:hashid" component={sections.Spark} />

                  <Route
                    from="/comment/:hashid"
                    component={sections.CommentRedirection}
                  />

                  <Route
                    path="/auth/password/reset"
                    component={sections.AuthPasswordReset}
                  />
                  <Route
                    path="/auth/register"
                    component={sections.AuthRegister}
                  />

                  <Route
                    path="/help/influencer"
                    component={sections.InfluencerHelp}
                  />
                  <Route
                    path="/influencerterms"
                    component={sections.InfluencerTerms}
                  />
                  <Route
                    path="/followers/:hashid"
                    component={sections.Followers}
                  />
                  <Route
                    path="/following/:hashid"
                    component={sections.Following}
                  />
                </Switch>

                <CompleteRegistrationModal />
                <Tooltip id="my-tooltip" effect="solid" />
              </RouteHandler>
            </LastLocationProvider>
          </BrowserRouter>
        </ApolloProvider>
      </ErrorBoundary>
    );
  };
}
