import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {TagListFromArray} from 'components/TagList';

import styles from './style.scss';

export default class Report extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
  }

  formatCurrency = (value) => {
    return value.toLocaleString('en-US', {
      currency: 'USD',
      style: 'currency',
    });
  }

  formatPercent = (percent) => {
    return (percent).toLocaleString('en-US', {
      minimumFractionDigitals: 4,
      style: 'percent',
    });
  }

  formatConversions = (conversions, clicks) => {
    if (clicks > 0 && conversions > 0) {
      return this.formatPercent(conversions / clicks);
    }
    return null;
  }

  formatCTR = (clicks, impressions) => {
    if (impressions > 0 && clicks > 0) {
      return this.formatPercent(clicks / impressions);
    }
    return null;
  }

  renderHeader = () => {
    if (this.props.report.offer) {
      return this.renderOfferHeader();
    }
    return null;
  }

  renderOfferHeader = () => {
    const offer = this.props.report.offer;

    return (
      <div className={styles.root}>
        <span className={styles.logo}>
          <img src={offer.thumbnail_url} alt="" />
        </span>
        <span className={styles.offer}>
          <div className={classNames(styles.header, 'color-secondary')}>
            <span className={styles.name}><h1>{offer.name}</h1></span>
          </div>
          <div className={styles.description}>
            {offer.description}
          </div>
          <div>
            <TagListFromArray tags={offer.tags} linked={false} />
          </div>
          <div>
            <span className={classNames('title', 'title-sm')}>Budget:</span> ...
          </div>
        </span>
      </div>
    );
  }

  render = () => {
    const report = this.props.report;
    const {impressions, comments, votes, video_starts, ad_shows, clicks, conversions, payout} = report;
    const reportDate = [
      {name: "Visitors", value: impressions},
      {name: "Comments", value: comments},
      {name: "Votes", value: votes},
      {name: "Video Views", value: video_starts},
      {name: "Ad Views", value: ad_shows},
      {name: "Clicks", value: clicks.toLocaleString()},
      {name: "Clickthrough Rate", value: this.formatCTR(clicks, ad_shows)},
      {name: "Conversion Rate", value: this.formatConversions(conversions, clicks)},
      {name: "Money Earned", value: this.formatCurrency(payout)},

    ];
    return (
      <div>
        {/* {this.renderHeader()} */}
        <div className={styles.content}>
          {
            reportDate && reportDate.map((reportLine, index)=> {
              return <StatisticLine key={index} lineName={reportLine.name} value={reportLine.value}  />;
            })
          }
        </div>
      </div>
    );
  }
}

const StatisticLine = ({lineName, value}) => (
  <div className={styles.statisticLine}>
    <p>{lineName}:</p>
    <div>{value}</div>
  </div>
);
StatisticLine.propTypes = {
  lineName: PropTypes.string.isRequired,
  value: PropTypes.any,
};
