import PropTypes from "prop-types";
import React from "react";

import Button from "components/Button";
import Modal from "components/Modal";

export default class DeleteCommentModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
  };

  render() {
    const { isOpen, onConfirm, onRequestClose } = this.props;

    return (
      <Modal
        contentLabel="Delete comment"
        extraPadding
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        deleteCommentCheck={"DeleteComment"}
      >
        <p>Are you sure you want to delete this Comment?</p>
        <div>
          <Button size="lg" color="danger" onClick={onConfirm}>
            Yes, delete this comment.
          </Button>
          <Button size="lg" color="secondary" onClick={onRequestClose}>
            Never mind. Don’t delete it.
          </Button>
        </div>
      </Modal>
    );
  }
}
