import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Resumablejs from "resumablejs";

import {
  getAuthorizationHeader,
  haveAccessToken,
  refreshAccessToken,
  resolveEndpoint,
} from "~/auth";

import Log from "~/log";

import Button from "components/Button";
import { GraphqlBackedMedia } from "components/Media";
import Loading from "components/Loading";
import ProgressBar from "components/ProgressBar";

import styles from "./style.scss";

const STATUS_IDLE = 0;
const STATUS_INITIALIZING = 1;
const STATUS_UPLOADING = 2;
const STATUS_AWAITING_RESPONSE = 3;
const STATUS_FINISHED = 4;
const STATUS_FAILED = 5;

export default class VideoUploadButton extends React.Component {
  static propTypes = {
    accept: PropTypes.array,
    buttonSize: PropTypes.string,
    children: PropTypes.element,
    className: PropTypes.string,
    dataTut: PropTypes.string,
    endpoint: PropTypes.string,
    onComplete: PropTypes.func,
    onError: PropTypes.func,
    plain: PropTypes.bool.isRequired,
    uploaderID: PropTypes.string,
  };

  static defaultProps = {
    accept: ["webm", "mp4", "mpg", "flv", "ts", "fmp4", "mov", "avi", "wmv"],
    buttonSize: "md",
    children: [],
    className: "",
    endpoint: "/v1/media/upload",
    media: null,
    onComplete: (f) => f,
    onError: (f) => f,
    plain: false,
    uploaderID: "default-resumable-uploader",
  };

  constructor() {
    super();

    this.state = {
      isPaused: false,
      isUploading: false,
      messageStatus: "",
      progressBar: 0,
      status: STATUS_IDLE,
      uploadingFile: null,
    };
  }

  componentDidMount = () => {
    let ResumableField = new Resumablejs({
      chunkSize: 1024 * 1024 * 10,
      fileParameterName: "file",
      fileType: this.props.accept,
      fileTypeErrorCallback: (file) => {
        Log("fileTypeErrorCallback " + file, "warning");
        this.setState({
          messageStatus: "We can't use that type of file!",
          status: STATUS_FAILED,
        });
      },
      forceChunkSize: false,
      headers: { Authorization: getAuthorizationHeader() },
      maxFileSize: 512000000,
      maxFileSizeErrorCallback: () => {
        this.setState({
          messageStatus: "File is too large to upload!",
          status: STATUS_FAILED,
        });
      },
      maxFiles: 1,
      permanentErrors: [400, 401, 404, 409, 415, 500, 501],
      query: {},
      simultaneousUploads: 1,
      target: resolveEndpoint(this.props.endpoint),
      testChunks: false,
      testMethod: "post",
      withCredentials: false,
    });

    ResumableField.assignBrowse(this.uploader);

    ResumableField.assignDrop(this.dropZone);

    ResumableField.on("fileAdded", (file) => {
      this.setState({
        messageStatus: " Starting upload! ",
        status: STATUS_UPLOADING,
        uploadingFile: file,
      });

      // Add the authorization again in case we have had a refresh
      ResumableField.opts.headers = { Authorization: getAuthorizationHeader() };

      ResumableField.upload();
    });

    ResumableField.on("fileSuccess", (file, fileServer) => {
      let objectServer = JSON.parse(fileServer);
      file.fileName = objectServer.media
        ? objectServer.media.original_filename
        : "";

      Log("VideoUploadButton::fileSuccess: " + fileServer, "error");

      if (!objectServer.media) {
        Log("media is null", "error");
        Log(fileServer, "error");
      }

      this.setState(
        {
          media: objectServer.media,
          messageStatus: fileServer,
          status: STATUS_FINISHED,
        },
        () => {
          if (typeof this.props.onComplete === "function") {
            this.props.onComplete(objectServer.media);
          }
        }
      );
    });

    ResumableField.on("progress", () => {
      this.setState({
        isUploading: ResumableField.isUploading(),
        status: STATUS_UPLOADING,
      });

      if (ResumableField.progress() * 100 < 100) {
        this.setState({
          messageStatus: parseInt(ResumableField.progress() * 100, 10) + "%",
          progressBar: ResumableField.progress() * 100,
        });
      } else {
        this.setState({
          progressBar: 100,
        });
      }
    });

    ResumableField.on("fileError", (file, errorCount) => {
      this.handleFileUploadRequestError(file, errorCount);
    });

    this.resumable = ResumableField;
  };

  abortFileUpload = () => {
    if (this.state.uploadingFile) {
      this.state.uploadingFile.abort();
    }

    this.setState({
      status: STATUS_IDLE,
      uploadingFile: null,
    });
  };

  reset = () => {
    this.abortFileUpload();
  };

  handleFileUploadRequestError = (file, message) => {
    let data = JSON.parse(message);
    if (data && data.error && data.error === "auth_token_expired") {
      if (haveAccessToken()) {
        let promise = Promise.resolve();
        // TODO: make refreshAccessToken a promise and set the status there, not below
        promise.then(refreshAccessToken).catch(() => {
          this.setState({
            messageStatus: "Login again!",
            status: STATUS_FAILED,
            uploadingFile: null,
          });
        });
        this.setState({
          status: STATUS_IDLE,
          uploadingFile: null,
        });
      } else {
        // TODO: go to login
      }
    } else {
      Log(data, "error");
      this.setState({
        messageStatus: "Upload Failed!",
        status: STATUS_FAILED,
        uploadingFile: null,
      });
    }
  };

  handleTryAgainClick = () => {
    this.setState({
      status: STATUS_IDLE,
    });
  };

  handleCancelClick = () => {
    this.abortFileUpload();
  };

  /**
   * Returns all files selected from input
   * @returns {array} Files
   */
  getFiles() {
    return this.uploader.files;
  }

  render() {
    const { accept, buttonSize, children, className, dataTut, plain } =
      this.props;
    const status = this.state.status;
    const finished = status === STATUS_FINISHED;

    return (
      <form
        ref={(node) => (this.dropZone = node)}
        className={classNames(
          styles.root,
          !plain && styles.styled,
          !plain && (finished ? styles.finished : "ratio ratio--square"),
          className
        )}
        data-tut={dataTut}
      >
        <div style={{ display: status === STATUS_IDLE ? "block" : "none" }}>
          <input
            ref={(node) => (this.uploader = node)}
            type="file"
            id={this.props.uploaderID}
            className="btn"
            name={this.props.uploaderID + "-upload"}
            accept={accept.join(".,")}
            // accept="video/*"
            // capture="user"
            disabled={false}
          />
        </div>
        <div
          className={classNames(
            !plain && !finished && "ratio__block",
            styles.container
          )}
        >
          {status === STATUS_IDLE && children}
          {status === STATUS_INITIALIZING && !plain && (
            <div>
              <ProgressBar />
            </div>
          )}
          {status === STATUS_UPLOADING && (
            <div>
              {!plain && (
                <ProgressBar
                  className={styles.gutter}
                  fraction={this.state.progressBar / 100}
                />
              )}
              <Button
                className={styles.gutter}
                size={buttonSize}
                onClick={this.handleCancelClick}
              >
                Cancel
                {plain && (
                  <span>
                    {" "}
                    <Loading inline size="sm" color="white" />
                  </span>
                )}
              </Button>
            </div>
          )}
          {status === STATUS_AWAITING_RESPONSE && (
            <div>
              {!plain && <ProgressBar />}
              <Button
                className={styles.gutter}
                size={buttonSize}
                onClick={this.handleCancelClick}
              >
                Cancel
                {plain && (
                  <span>
                    {" "}
                    <Loading inline size="sm" color="white" />
                  </span>
                )}
              </Button>
            </div>
          )}
          {status === STATUS_FAILED && (
            <div>
              {!plain && <p className="align-c">{this.state.messageStatus}</p>}
              <Button
                className={styles.gutter}
                size={buttonSize}
                onClick={this.handleTryAgainClick}
              >
                {plain && "Failed. "}
                Try again
              </Button>
            </div>
          )}
          {finished && !plain && (
            <GraphqlBackedMedia size="lg" hashid={this.state.media.hashid} />
          )}
        </div>
      </form>
    );
  }
}
