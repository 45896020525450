import PropTypes from 'prop-types';
import React from 'react';
import Select from "react-dropdown-select";

import classNames from 'classnames';

import styles from './style.scss';
/*
Const StyledSelect = styled(Select)`
  background: #333;
  border: #333 !important;
  color: #fff;
  .react-dropdown-select-clear,
  .react-dropdown-select-dropdown-handle {
    color: #fff;
  }
  .react-dropdown-select-option {
    border: 1px solid #fff;
  }
  .react-dropdown-select-item {
    color: #333;
  }
  .react-dropdown-select-input {
    color: #fff;
  }
  .react-dropdown-select-dropdown {
    position: absolute;
    left: 0;
    border: none;
    width: 500px;
    padding: 0;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    max-height: 300px;
    overflow: auto;
    z-index: 9;
    background: #333;
    box-shadow: none;
    color: #fff !important;
  }
  .react-dropdown-select-item {
    color: #f2f2f2;
    border-bottom: 1px solid #333;

    :hover {
       color: #ffffff80;
    }
  }
  .react-dropdown-select-item.react-dropdown-select-item-selected,
  .react-dropdown-select-item.react-dropdown-select-item-active {
    //background: #111;
    border-bottom: 1px solid #333;
    color: #fff;
    font-weight: bold;
  }
  .react-dropdown-select-item.react-dropdown-select-item-disabled {
    background: #777;
    color: #ccc;
  }
`;
*/

export default class Dropdown extends React.Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.array).isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool.isRequired,
    value: PropTypes.string,
  }

  static defaultProps = {
    defaultValue: '',
    placeholder: "Select an option",
    required: false,
    value: '',
  }

  onChange = (values) => {
    if (this.props.onChange) {
      this.props.onChange(values);
    }
  }

  render() {
    const options = this.props.options.map(([value, name]) => {
      return {label: name, value};
    });

    const values = options.filter(option => option.value === this.props.value);

    return (
      <Select
        className={classNames(styles.root)}
        style={{border: "none", outline: "none", boxShadow: "none"}}
        values={values}
        options={options}
        onChange={vals => this.onChange(vals)}
      />
    );
  }
}
