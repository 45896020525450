import {
  AUTH_REQUEST,
  AUTH_REQUEST_RESOLVED,
  LOGIN,
  LOGOUT,
  UPDATE,
} from 'actions/auth';

const initialState = {
  authRequestPending: false,
  authenticated: null,
  user: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case AUTH_REQUEST:
      return {
        authRequestPending: true,
        authenticated: state.authenticated,
      };
    case AUTH_REQUEST_RESOLVED:
      return {
        authRequestPending: false,
        authenticated: state.authenticated,
      };
    case LOGIN:
    case UPDATE:
      return {
        authenticated: true,
        user: action.payload.user,
      };
    case LOGOUT:
      return {
        authenticated: false,
        user: null,
      };
    default:
      return state;
  }
}
