import React, { useRef, useState } from "react";
import useDropdown from "../../hooks/useDropdown";
import cn from "classnames";
import PropTypes from "prop-types";
import styles from "./style.scss";

export default function VideoDropDown({ initiallyOpen }) {
  // const { wrapperRef, dropdownOpen, toggleDropdown, closeDropdown } =
  //   useDropdown(disableClickOutside, initiallyOpen);
  // const handleItemClick = (item) => {
  //   onChange(item);
  //   closeDropdown();
  // };
  const [isDropDownOpen, setIsDropDownOpen] = useState(initiallyOpen);
  const wrapperRef = useRef(null);

  return (
    <div ref={wrapperRef} className={cn(styles.wrapper)}>
      <div className={styles.container}>
        <div className={styles.head}>
          <div
            onClick={() => setIsDropDownOpen(!isDropDownOpen)}
            className={styles.orangeCirclesWrap}
          >
            <div className={styles.orangeCirclesRow}>
              <div className={styles.orangeCircle}></div>
              <div className={styles.orangeCircle}></div>
            </div>
            <div className={styles.orangeCirclesRow}>
              <div className={styles.orangeCircle}></div>
              <div className={styles.orangeCircle}></div>
            </div>
          </div>
        </div>

        {isDropDownOpen && (
          <div className={styles.dropdown}>
            <ul className={styles.list}>
              <label htmlFor="videoLibrary" className={styles.item}>
                Video Library
                <input
                  id="videoLibrary"
                  readOnly
                  className={styles.uploadAudioInput}
                  type="file"
                  onChange={() => setIsDropDownOpen(false)}
                />
              </label>
              <li className={styles.item}>Take Video</li>
              <li className={styles.item}>Choose File</li>
              <li className={styles.item}>Download</li>
              <li className={styles.item}>Delete</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

VideoDropDown.propTypes = {
  disableClickOutside: PropTypes.bool,
  initiallyOpen: PropTypes.bool,
};

VideoDropDown.defaultProps = {
  disableClickOutside: false,
  initiallyOpen: false,
};
