import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./style.scss";
import gql from "graphql-tag";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import Loading from "sections/Loading";
import LogInButton from "components/LogInButton";

const GET_FOLLOWEES = gql`
  query GetFollowee($handle: String!) {
    followee(handle: $handle) {
      handle
      hashid
      is_followed_by_me
      avatar {
        ... on Media {
          square_image_sources {
            url
          }
        }
        ... on Url {
          url
        }
      }
    }
  }
`;

const FOLLOW_USER_MUTATION = gql`
  mutation FollowUser($hashid: ID!) {
    followUser(hashid: $hashid) {
      followee {
        hashid
        is_followed_by_me
      }
    }
  }
`;

const UNFOLLOW_USER_MUTATION = gql`
  mutation UnfollowUser($hashid: ID!) {
    unfollowUser(hashid: $hashid) {
      followee {
        hashid
        is_followed_by_me
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
});

class Following extends Component {
  state = {
    isModalShowed: false,
    logInButtonHandler: null,
    handleCloseModal: null,
  };

  followOrUnfollow = async (follow, hashid) => {
    const mutation = follow ? this.props.followUser : this.props.unfollowUser;
    await mutation(hashid);
  };

  handleSignInClick = () => {
    if (this.state.logInButtonHandler) {
      this.setState({ isModalShowed: true });
      this.state.logInButtonHandler();
    }
  };

  componentDidUpdate(prevProps) {
    if (!this.props.data.loading && prevProps.data !== this.props.data) {
      this.props.data.refetch();
    }
    if (this.props.authenticated && this.state.isModalShowed) {
      this.state.handleCloseModal?.();
      this.setState({ isModalShowed: false });
    }
  }

  render() {
    if (this.props.data.loading) {
      return <Loading />;
    }
    const { followee } = this.props.data;

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>Following</div>
        <div className={styles.contentWrapper}>
          <div className={styles.title}>Following</div>
          <div className={styles.content}>
            <div className={styles.items}>
              {followee.map((user, index) => (
                <div key={index} className={styles.userCardWrapper}>
                  <div className={styles.avatarAndHandleWrap}>
                    <img
                      src={
                        user.avatar?.square_image_sources?.[0]?.url ||
                        user.avatar?.url
                      }
                      className={styles.userAvatar}
                    />
                    <div className={styles.userHandle}>{user.handle}</div>
                  </div>
                  <button
                    className={
                      user.is_followed_by_me
                        ? styles.unfollowBtn
                        : styles.followBtn
                    }
                    onClick={() =>
                      this.props.authenticated
                        ? this.followOrUnfollow(
                            !user.is_followed_by_me,
                            user.hashid
                          )
                        : this.handleSignInClick()
                    }
                  >
                    {user.is_followed_by_me ? "Unfollow" : "Follow"}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        <LogInButton
          isButtonHidden
          handleSignInClickRef={(handler) =>
            this.setState({ logInButtonHandler: handler })
          }
          handleCloseModalRef={(handler) =>
            this.setState({ handleCloseModal: handler })
          }
        />
      </div>
    );
  }
}

Following.propTypes = {
  authenticated: PropTypes.bool,
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    followee: PropTypes.array,
    refetch: PropTypes.func.isRequired,
  }),
  followUser: PropTypes.func.isRequired,
  unfollowUser: PropTypes.func.isRequired,
};

export default compose(
  graphql(GET_FOLLOWEES, {
    options: (props) => ({
      variables: { handle: props.match.params.hashid },
    }),
  }),
  graphql(FOLLOW_USER_MUTATION, {
    props: ({ mutate }) => ({
      followUser: (hashid) => mutate({ variables: { hashid } }),
    }),
  }),
  graphql(UNFOLLOW_USER_MUTATION, {
    props: ({ mutate }) => ({
      unfollowUser: (hashid) => mutate({ variables: { hashid } }),
    }),
  }),
  connect(mapStateToProps)
)(Following);
