import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { graphql } from "react-apollo";
import gql from "graphql-tag";

import Loading from "components/Loading";
import IconButton from "components/IconButton";
import Icon from "components/Icon";

import styles from "./style.scss";
import classNames from "classnames";
import VideoDropDown from "../VideoDropDown/index";
import PosterDropDown from "../PosterDropDown/index";

const VideoFrameChooser = ({
  index: selectedIndex,
  media,
  onIndexChange,
  isSparkCollab,
  setPosters,
  posters,
  handleAddPoster,
}) => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const scrollableRef = useRef(null);

  useEffect(() => {
    if (uploadedImages.length === 0) {
      setUploadedImages(media.poster_urls);
    }
  }, []);

  const handleDeleteImg = (index, closeDropDown) => {
    setUploadedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
    if (uploadedImages.length >= 1) {
      if (index === selectedIndex) {
        onIndexChange(0, uploadedImages[0]);
      }
    }
    closeDropDown(false);
  };

  const photoFileChange = (event) => {
    const photo = event.target.files[0];
    const photoReader = new FileReader();

    photoReader.onload = () => {
      const newPhoto = photoReader.result;
      setUploadedImages([...uploadedImages, newPhoto]);
    };

    photoReader.readAsDataURL(photo);
  };

  const handleImgChange = (index, event, closeDropDown) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;

        setUploadedImages((prevImages) => {
          const updatedImages = [...prevImages];
          updatedImages[index] = imageUrl;
          return updatedImages;
        });
        if (index === selectedIndex) {
          onIndexChange(index, imageUrl);
        }
      };
      reader.readAsDataURL(selectedFile);
    }

    closeDropDown(false);
  };

  const handlePrev = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollLeft -= 55;
    }
  };
  const handleNext = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollLeft += 55;
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const newPhoto = reader.result;
      setUploadedImages([...uploadedImages, newPhoto]);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (scrollableRef.current) {
      const scrollWidth = scrollableRef.current.scrollWidth;
      scrollableRef.current.scrollTo({
        left: scrollWidth,
        behavior: "smooth",
      });
    }
  }, [uploadedImages]);

  return (
    <div className={styles.wrapper}>
      {isSparkCollab && (
        <div className={styles.frameUploader}>
          <div className={styles.addVideoIconWrap}>
            <label htmlFor="photoUploader">
              <Icon icon="addVideo2" size="xl" />
            </label>
            <input
              type="file"
              id="photoUploader"
              capture="user"
              accept="image/*"
              className={styles.uploadInput}
              onChange={photoFileChange}
            />
          </div>
          <div>
            <label htmlFor="uploader">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 11"
                fill="none"
              >
                <path
                  d="M7.84283 9.64315C7.84283 8.48113 7.84283 7.33619 7.84283 6.19125C7.84283 5.89873 7.83906 5.60668 7.84396 5.31417C7.84962 4.97945 8.04002 4.73482 8.28925 4.73055C8.54677 4.72581 8.7466 4.97376 8.74735 5.32175C8.75037 6.8308 8.7515 8.33985 8.74698 9.84843C8.74509 10.4064 8.4148 10.7976 7.95594 10.7985C6.60501 10.8009 5.25444 10.7995 3.90351 10.7995C2.8855 10.7995 1.86786 10.7999 0.849851 10.7995C0.300125 10.799 0.000754082 10.4221 0.000377041 9.73038C0 8.2768 -0.000377041 6.82322 0.000754082 5.36916C0.00113112 4.96855 0.21529 4.69737 0.497317 4.73197C0.742771 4.76184 0.905276 5.00743 0.905653 5.3611C0.907161 6.69616 0.906407 8.03122 0.906407 9.36627C0.906407 9.45256 0.906407 9.53884 0.906407 9.6422C3.21729 9.64315 5.51875 9.64315 7.84283 9.64315Z"
                  fill="#F75A00"
                />
                <path
                  d="M3.92164 2.01903C3.92164 2.10247 3.92164 2.18591 3.92164 2.26983C3.92164 4.17238 3.92164 6.0754 3.92164 7.97796C3.92164 8.05666 3.91824 8.1363 3.92465 8.21453C3.94765 8.50326 4.13881 8.72418 4.36579 8.72987C4.60106 8.73556 4.8009 8.51274 4.82465 8.21311C4.83106 8.13488 4.82767 8.05523 4.82767 7.97653C4.82767 6.07398 4.82767 4.17096 4.82767 2.26841C4.82767 2.18307 4.82767 2.09773 4.82767 1.94317C5.06558 2.24517 5.27031 2.50498 5.47542 2.76478C5.56403 2.87667 5.65112 2.99046 5.74237 3.09902C5.94597 3.34081 6.21819 3.34982 6.39917 3.12415C6.58015 2.89895 6.58015 2.5486 6.38409 2.29875C5.89017 1.66915 5.39097 1.04666 4.89365 0.421323C4.82691 0.337408 4.76282 0.250648 4.69269 0.171948C4.49587 -0.0489807 4.25079 -0.0632038 4.06567 0.165785C3.48766 0.879301 2.91531 1.59993 2.34711 2.32577C2.16802 2.55476 2.17594 2.89753 2.34372 3.11562C2.5164 3.34081 2.79655 3.35409 2.98544 3.12273C3.2867 2.75435 3.57702 2.37176 3.87187 1.99533C3.88846 2.00339 3.90505 2.01097 3.92164 2.01903Z"
                  fill="#F75A00"
                />
              </svg>
            </label>
            <input
              type="file"
              id="uploader"
              accept="image/png, image/gif, image/jpeg"
              className={styles.uploadInput}
              onChange={handleFileChange}
            />
          </div>
        </div>
      )}

      <IconButton
        onClick={handlePrev}
        icon="arrowDown"
        className={styles.arrowLeft}
      />
      <div className={styles.imgContainer} ref={scrollableRef}>
        {uploadedImages.map((image, i) => (
          // <div className={styles.imgWrapper} key={i}>
          //   <img
          //     style={{
          //       border: i === selectedIndex ? "1px solid #F75A00" : "none",
          //     }}
          //     src={image}
          //     alt={"img"}
          //     onClick={() => onIndexChange(i, image)}
          //     className={styles.img}
          //   />
          //   {/* {isSparkCollab ? <PosterDropDown src={} index={} selectedIndex={} /> : <VideoDropDown />} */}
          // </div>
          <PosterDropDown
            src={image}
            index={i}
            selectedIndex={selectedIndex}
            onIndexChange={onIndexChange}
            handleImgChange={handleImgChange}
            handleDeleteImg={handleDeleteImg}
            key={i}
          />
        ))}
      </div>

      {uploadedImages.length >= 4 && (
        <IconButton
          onClick={handleNext}
          icon="arrowDown"
          className={styles.arrowRight}
        />
      )}
    </div>
  );
};

VideoFrameChooser.propTypes = {
  index: PropTypes.number.isRequired,
  media: PropTypes.object,
  onIndexChange: PropTypes.func.isRequired,
  isSparkCollab: PropTypes.bool,
  handleAddPoster: PropTypes.func,
};

VideoFrameChooser.defaultProps = {
  index: 0,
  isSparkCollab: false,
  onIndexChange: () => {},
};

export default VideoFrameChooser;

// export default class VideoFrameChooser extends React.Component {
//   static propTypes = {
//     index: PropTypes.number.isRequired,
//     media: PropTypes.object,
//     onIndexChange: PropTypes.func.isRequired,
//     isSparkCollab: PropTypes.bool,
//   };

//   static defaultProps = {
//     index: 0,
//     isSparkCollab: false,
//     onIndexChange: () => {},
//   };

//   constructor(props) {
//     super(props);
//     this.state = {
//       uploadedImages: [],
//     };
//   }

//   handleChange = (value) => {
//     this.props.onIndexChange(value, this.props.media.poster_urls[value]);
//   };

//   handleClick = (value) => {
//     const scrollableRef = this.scrollableRef;
//     const direction = value === "left" ? -1 : value === "right" ? 1 : 0;
//     const thumbnailWidth =
//       scrollableRef.querySelectorAll("label")[1].offsetLeft -
//         scrollableRef.querySelectorAll("label")[0].offsetLeft || 80;
//     const scrollAmount = direction * thumbnailWidth;

//     scrollableRef.scrollBy(scrollAmount, 0);
//   };

//   handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         const imageUrl = e.target.result;
//         this.setState((prevState) => ({
//           uploadedImages: [...prevState.uploadedImages, imageUrl],
//         }));
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   render() {
//     const { index, media } = this.props;

//     if (!media.poster_urls || !media.poster_urls.length) {
//       return null;
//     }

//     return (
//       <div className={styles.wrapper}>
//         <div className={styles.frameUploader}>
//           <div className={styles.iconWrap}>
//             <Icon icon="addVideo" size="xl" />
//           </div>
//           <div>
//             <label htmlFor="uploader">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="15"
//                 height="15"
//                 viewBox="0 0 15 11"
//                 fill="none"
//               >
//                 <path
//                   d="M7.84283 9.64315C7.84283 8.48113 7.84283 7.33619 7.84283 6.19125C7.84283 5.89873 7.83906 5.60668 7.84396 5.31417C7.84962 4.97945 8.04002 4.73482 8.28925 4.73055C8.54677 4.72581 8.7466 4.97376 8.74735 5.32175C8.75037 6.8308 8.7515 8.33985 8.74698 9.84843C8.74509 10.4064 8.4148 10.7976 7.95594 10.7985C6.60501 10.8009 5.25444 10.7995 3.90351 10.7995C2.8855 10.7995 1.86786 10.7999 0.849851 10.7995C0.300125 10.799 0.000754082 10.4221 0.000377041 9.73038C0 8.2768 -0.000377041 6.82322 0.000754082 5.36916C0.00113112 4.96855 0.21529 4.69737 0.497317 4.73197C0.742771 4.76184 0.905276 5.00743 0.905653 5.3611C0.907161 6.69616 0.906407 8.03122 0.906407 9.36627C0.906407 9.45256 0.906407 9.53884 0.906407 9.6422C3.21729 9.64315 5.51875 9.64315 7.84283 9.64315Z"
//                   fill="#F75A00"
//                 />
//                 <path
//                   d="M3.92164 2.01903C3.92164 2.10247 3.92164 2.18591 3.92164 2.26983C3.92164 4.17238 3.92164 6.0754 3.92164 7.97796C3.92164 8.05666 3.91824 8.1363 3.92465 8.21453C3.94765 8.50326 4.13881 8.72418 4.36579 8.72987C4.60106 8.73556 4.8009 8.51274 4.82465 8.21311C4.83106 8.13488 4.82767 8.05523 4.82767 7.97653C4.82767 6.07398 4.82767 4.17096 4.82767 2.26841C4.82767 2.18307 4.82767 2.09773 4.82767 1.94317C5.06558 2.24517 5.27031 2.50498 5.47542 2.76478C5.56403 2.87667 5.65112 2.99046 5.74237 3.09902C5.94597 3.34081 6.21819 3.34982 6.39917 3.12415C6.58015 2.89895 6.58015 2.5486 6.38409 2.29875C5.89017 1.66915 5.39097 1.04666 4.89365 0.421323C4.82691 0.337408 4.76282 0.250648 4.69269 0.171948C4.49587 -0.0489807 4.25079 -0.0632038 4.06567 0.165785C3.48766 0.879301 2.91531 1.59993 2.34711 2.32577C2.16802 2.55476 2.17594 2.89753 2.34372 3.11562C2.5164 3.34081 2.79655 3.35409 2.98544 3.12273C3.2867 2.75435 3.57702 2.37176 3.87187 1.99533C3.88846 2.00339 3.90505 2.01097 3.92164 2.01903Z"
//                   fill="#F75A00"
//                 />
//               </svg>
//             </label>
//             <input
//               type="file"
//               id="uploader"
//               accept="img/*"
//               className={styles.uploadAudioInput}
//               onChange={this.handleFileChange}
//             />
//           </div>
//         </div>
//         <div className={styles.videoFrameChooser}>
//           {media.poster_urls.concat(this.state.uploadedImages).length >= 4 && (
//             <IconButton
//               color="primary"
//               icon="back"
//               className={classNames(
//                 styles.videoFrameChooserButton,
//                 styles.videoFrameChooserButtonLeft
//               )}
//               onClick={() => this.handleClick("left")}
//             >
//               <span className="sr-only">Scroll left</span>
//             </IconButton>
//           )}

//           <div
//             className={styles.videoFrameChooserThumbnails}
//             ref={(ref) => (this.scrollableRef = ref)}
//           >
//             <div>
//               {/* className={styles.videoFrameChooserThumbnailsInner} */}
//               {media.poster_urls
//                 .concat(this.state.uploadedImages)
//                 .map((posterUrl, i) => {
//                   return (
//                     <label
//                       key={posterUrl}
//                       className={styles.videoFrameChooserThumbnail}
//                     >
//                       <input
//                         type="radio"
//                         name="poster_url"
//                         checked={index === i}
//                         onChange={(e) => {
//                           if (e.target.checked) {
//                             this.handleChange(i);
//                           }
//                         }}
//                       />
//                       <img src={posterUrl} alt={"Frame " + i} />
//                       {this.props.isSparkCollab && (
//                         <VideoDropDown initiallyOpen isPreviewChooser />
//                       )}
//                     </label>
//                   );
//                 })}
//             </div>
//           </div>
//           {media.poster_urls.concat(this.state.uploadedImages).length >= 4 && (
//             <IconButton
//               color="primary"
//               icon="back"
//               className={classNames(
//                 styles.videoFrameChooserButton,
//                 styles.videoFrameChooserButtonRight
//               )}
//               onClick={() => this.handleClick("right")}
//             >
//               <span className="sr-only">Scroll right</span>
//             </IconButton>
//           )}
//         </div>
//       </div>
//     );
//   }
// }

class DataBackedVideoFrameChooser extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
      media: PropTypes.object,
      startPolling: PropTypes.func.isRequired,
      stopPolling: PropTypes.func.isRequired,
    }),
    hashid: PropTypes.string.isRequired,
    onProcessedStatusChange: PropTypes.func,
    isSparkCollab: PropTypes.bool,
    setPosters: PropTypes.func,
    posters: PropTypes.array,
    handleAddPoster: PropTypes.func,
  };

  static defaultProps = {
    isSparkCollab: false,
  };

  UNSAFE_componentWillUpdate(nextProps) {
    if (!nextProps.onProcessedStatusChange) {
      return;
    }

    const processed = !this.props.data.media
      ? null
      : this.props.data.media.is_processed;
    const nextProcessed = !nextProps.data.media
      ? null
      : nextProps.data.media.is_processed;

    if (processed !== nextProcessed) {
      nextProps.onProcessedStatusChange(nextProcessed);
    }
  }

  componentDidMount() {
    if (this.props.onProcessedStatusChange) {
      this.props.onProcessedStatusChange(
        !this.props.data.media ? null : this.props.data.media.is_processed
      );
    }
  }

  render() {
    const { data, hashid, onProcessedStatusChange, posters, ...otherProps } =
      this.props;

    if (!data.media) {
      return <Loading />;
    }

    if (data.media.is_processed) {
      data.stopPolling();
    } else {
      data.startPolling(3000);
    }

    return (
      <VideoFrameChooser
        media={data.media}
        handleAddPoster={this.props.handleAddPoster}
        posters={posters}
        {...otherProps}
      />
    );
  }
}

const MEDIA_QUERY = gql`
  query MediaForVideoFrameChooser($hashid: ID!) {
    media(hashid: $hashid) {
      hashid
      is_processed
      poster_urls
      type
    }
  }
`;

export const GraphqlBackedVideoFrameChooser = graphql(MEDIA_QUERY, {
  options: (props) => ({
    variables: {
      hashid: props.hashid,
    },
  }),
})(DataBackedVideoFrameChooser);
