import React from 'react';

export default class Radio extends React.Component {
  render() {
    return (
      <span>
        <input type="radio" {...this.props} />
        <span className="radio"></span>
      </span>
    );
  }
}
