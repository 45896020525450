import PropTypes from "prop-types";
import React from "react";

import classNames from "classnames";

import Button from "components/Button";
import DropdownMenu from "components/DropdownMenu";
import Icon from "components/Icon";

import styles from "./style.scss";

export default class ShareMenu extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    listClassName: PropTypes.string,
    onButtonClick: PropTypes.func,
    onShareMenuItemClick: PropTypes.func,
    isShareIconWithText: PropTypes.bool,
    iconSize: PropTypes.string,
    sparkHashid: PropTypes.string,
  };

  static defaultProps = {
    isShareIconWithText: false,
    iconSize: "md",
    sparkHashid: null,
  };

  render() {
    const {
      className,
      listClassName,
      onButtonClick,
      isShareIconWithText,
      onShareMenuItemClick,
      ...otherProps
    } = this.props;

    const shareOptions = [
      // {label: "Facebook", provider: "facebook"},
      { label: "Twitter", provider: "twitter" },
    ];

    if (document.queryCommandSupported("copy")) {
      shareOptions.push({ label: "Copy share URL", provider: "_copy" });
    }

    return (
      <div className={classNames(styles.root, className)}>
        <div>
          <Button
            color="none"
            onClick={this.props.onButtonClick}
            className={styles.btn}
          >
            {isShareIconWithText ? (
              <Icon icon="shareNew" size={this.props.iconSize} />
            ) : (
              <div className={styles.shareIconWithText}>
                <div className={classNames(styles.shareIcon)}>
                  <Icon icon="share" size={this.props.iconSize} />
                </div>
                Share
              </div>
            )}
          </Button>
          <DropdownMenu
            className={classNames(className, listClassName, styles.dropdown)}
            {...otherProps}
          >
            {shareOptions.map(({ provider, label }) => {
              return (
                <li key={provider}>
                  <Button
                    color="none"
                    onClick={() => onShareMenuItemClick(provider)}
                  >
                    {label}
                  </Button>
                </li>
              );
            })}
          </DropdownMenu>
        </div>
      </div>
    );
  }
}
