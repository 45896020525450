import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'components/Icon';

import styles from './style.scss';

export default class Location extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
  }

  render() {
    return (
      <div className={classNames(styles.root, this.props.className)}>
        <Icon className={styles.icon} icon="pin" size="sm" color="primary" />
        <span className={styles.name}>
          {this.props.name}
        </span>
      </div>
    );
  }
}
