import classNames from 'classnames';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import Button from 'components/Button';
import Head from 'components/Head';
import Main from 'components/Main';

import * as Sentry from '@sentry/browser';

import styles from './styles.scss';

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.object,
    history: PropTypes.object.isRequired, // From router
  }


  constructor(props) {
    super(props);
    this.state = {eventId: null};
    this.routeChange = this.routeChange.bind(this);
  }

  // Leaving in for future compatabilty
  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({eventId});
    });
    this.setState({hasError: true});
  }

  routeChange() {
    this.setState({hasError: false});
    this.props.history.push('/');
  }

  render() {
    const headProps = {
      back: '/',
      minimal: true,
    };

    if (this.state.hasError) {
      return (
        <div>
          <Head {...headProps} />
          <Main>
            <div className={classNames(styles.root)}>
              <div className="wrap">
                <div className={classNames("wrap__inner", "wrap__inner--skinny", "wrap__gutter", "wrap__gutter--padded")}>
                  <h1 className={classNames(styles.titleMain, "title", "title-md")}>
                    We are sorry, but an error has occured
                  </h1>
                  <div className={classNames(styles.section)}>
                    <Button size="lg" color="danger" className={classNames(styles.button)} onClick={() => Sentry.showReportDialog({eventId: this.state.eventId})}>Report Feedback</Button>
                    <Button size="lg" color="primary" className={classNames(styles.button)} onClick={this.routeChange}>Home</Button>
                  </div>
                </div>
              </div>
            </div>
          </Main>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);

