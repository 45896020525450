/**
 * Scroll a DOM element into view.
 *
 * This is a pseudo-polyfill for element.scrollIntoView.
 *
 * @param {Element} el - Element to scroll into view
 * @param {boolean|Object} arg - Argument as for element.scrollIntoView (see
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView)
 *
 * @return {void}
 */
export default function scrollIntoView(el, arg) {
  // Note that the 'inline' option isn't supported

  // If no argument is given, it's as if the default boolean of true is passed
  if (arg == null) {
    scrollIntoView(el, true);
    return;
  }

  // If a boolean is passed, it's the alignToTop parameter
  if (typeof arg === 'boolean') {
    // These map to particular sets of options
    scrollIntoView(el, {
      block: arg ? 'start' : 'end',
      inline: 'nearest',
    });
    return;
  }

  try {
    el.scrollIntoView(arg);
    return;
  } catch (e) {
    if (e.name !== 'TypeError') {
      throw e;
    }

    // A TypeError could mean either one of the options wasn't understood
    // or the method doesn't exist
  }

  // Perpare to try scrollTo instead
  const height = document.documentElement.clientHeight;
  const scrollTop = document.scrollingElement.scrollTop;
  const scrollTopMax = document.scrollingElement.scrollHeight - height;
  const rect = el.getBoundingClientRect();

  function clamp(val) {
    return Math.max(0, Math.min(scrollTopMax, val));
  }

  let targetTop = 0;
  switch (arg.block || 'center') {
    case 'start':
      targetTop = scrollTop + rect.top;
      break;

    case 'end':
      targetTop = scrollTop + rect.bottom - height;
      break;

    case 'center':
      targetTop = scrollTop + rect.top + rect.height / 2 - height / 2;
      break;

    case 'nearest':
      if (rect.top < 0 && rect.bottom > height) {
        // Fills the entire screen; do nothing
        return;
      }

      if (rect.top < 0) {
        // Off top edge of viewport; same as start
        scrollIntoView(el, Object.assign(arg, {
          block: 'start',
        }));
        return;
      }

      if (rect.bottom > height) {
        // Off bottom edge of viewport; same as end
        scrollIntoView(el, Object.assign(arg, {
          block: 'end',
        }));
        return;
      }

      // Completely contained within viewport already; do nothing
      return;

    default:
      throw new TypeError(`Unknown anchor "${arg.block}"`);
  }

  window.scrollTo({
    behavior: arg.behavior || 'auto',
    top: clamp(targetTop),
  });

  // TODO: further fallback if scrollTo didn't work
  // TODO: what if the above worked but didn't support behavior: 'smooth'?
}
