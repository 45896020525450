import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import auth from "./auth";
import hashtagReducer from "./hashtagSearch";
import collabEditorReducer from "./collabEditor";

export default combineReducers({
  auth,
  router: routerReducer,
  hashtag: hashtagReducer,
  collabEditor: collabEditorReducer,
});
